import React, { FC } from "react";
import { PolygonTabState } from "./types";
import { AppConfig } from "../../../utils/appconfig";

interface PolygonSummaryProps {
  PolygonTabState: PolygonTabState;
  appConfig: AppConfig;
}

const PolygonSummary: FC<PolygonSummaryProps> = ({
  PolygonTabState,
  appConfig,
}) => {
  const summary = PolygonTabState.selectedPolygon?.properties?.summary;

  if (summary)
    return (
      <>
        <h2 className="font-bold text-xl mb mt-6">Polygon Summary</h2>
        <div className="mb-2">
          {Object.entries(summary).map(([key, value]) => (
            <div key={key} className="text-sm leading-6 text-gray-900">
              <span className="font-bold">{appConfig.fields[key].name}: </span>
              {appConfig.fields[key].format(value as number)}
            </div>
          ))}
        </div>
      </>
    );

  return null;
};

export default PolygonSummary;
