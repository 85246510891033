import { PoiFeature } from "./types";
import { US_POSTAL_CODE_MAP, US_POSTAL_CODE_TO_FIPS, UsState } from "./us";

export function extractUsStateFromPoiFeature(
  poi: PoiFeature
): Nullable<UsState> {
  const postal = extractUsPostalFromPoiFeature(poi);
  if (!postal) return null;
  return (US_POSTAL_CODE_MAP.get(postal) as UsState) ?? null;
}

export function extractUsStateCodeFromPoiFeature(
  poi: PoiFeature
): Nullable<string> {
  const postal = extractUsPostalFromPoiFeature(poi);
  if (!postal) return null;
  return US_POSTAL_CODE_TO_FIPS.get(postal) ?? null;
}

function extractUsPostalFromPoiFeature(poi: PoiFeature): string {
  let postal = "";
  const id = poi.id as string;

  if (id.startsWith("region.")) {
    if (poi.properties) {
      postal = postalFromShortCode(poi.properties["short_code"]);
    }
  } else {
    const context = poi.context.find((c) => c.id.startsWith("region."));
    if (context && context.short_code) {
      postal = postalFromShortCode(context.short_code);
    }
  }
  return postal;
}

function postalFromShortCode(shortCode: string): string {
  if (shortCode.startsWith("US-")) {
    return shortCode.replace("US-", "");
  }
  return "";
}
