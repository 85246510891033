import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import LogoutButton from "../components/LogoutButton";
import { useLocalStorage } from "../hooks/useLocalStorage";
import App01JoeHome from "./apps/app01joehome";
import App02ExpoFranchise from "./apps/app02expofranchise";
import App03Gopher from "./apps/app03gopher";
import App04YC from "./apps/app04yc";
import App05HelloSugar from "./apps/app05hellosugar";
import App06RakkanRamen from "./apps/app06rakkanramen";
import mixpanel from "mixpanel-browser";
import { User, useAuth0 } from "@auth0/auth0-react";
import { AppConfig, loadAppConfig } from "../utils/appconfig";
import Template02 from "./templates/template02";
import Exp01ChatgptDataFields from "./apps/exp01chatgptdatafields";

const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY || "";

const STATIC_APPS = [
  "app01joehome",
  "app02expofranchise",
  "app03gopher",
  "app04yc",
  "app05hellosugar",
  "app06rakkanramen",
];

const MIXPANEL_API_HOST = process.env.REACT_APP_MIXPANEL_API_HOST;
mixpanel.init(MIXPANEL_API_KEY, { api_host: MIXPANEL_API_HOST });

const ErrorScreen: FC<{ message?: string | React.ReactNode }> = ({
  message,
}) => {
  return (
    <div className="flex w-full h-full">
      <div className="m-auto">
        <p className="text-2xl">
          {message || "Something went wrong, please try again later."}
        </p>
        <p className="mt-4 text-center">
          <LogoutButton />
        </p>
      </div>
    </div>
  );
};

const AuthFailedScreen: FC<{}> = () => {
  return (
    <ErrorScreen
      message={
        <>
          Please contact{" "}
          <a className="text-indigo-500" href="mailto:team@unearthinsights.com">
            team@unearthinsights.com
          </a>{" "}
          for access!
        </>
      }
    />
  );
};

const MatchApp: FC<{ app: string; user: User | undefined }> = ({
  app,
  user,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Nullable<string>>(null);
  const [appConfig, setAppConfig] = useState<AppConfig>();
  useEffect(() => {
    if (!app) {
      return;
    }
    const loadAsync = async () => {
      setLoading(true);
      setError(null);
      if (STATIC_APPS.includes(app)) {
        setLoading(false);
        return;
      }
      try {
        const config = await loadAppConfig(app);
        setAppConfig(config);
        setLoading(false);
      } catch (e) {
        setError("Failed to load app, please reload the page.");
        console.error(e);
      } finally {
        setLoading(false);
      }
    };
    loadAsync();
  }, [app]);
  mixpanel.track("Load App", {
    App: app,
  });
  if (app === "app01joehome") {
    return <App01JoeHome />;
  } else if (app === "app02expofranchise") {
    return <App02ExpoFranchise />;
  } else if (app === "app03gopher") {
    return <App03Gopher />;
  } else if (app === "app04yc") {
    return <App04YC />;
  } else if (app === "app05hellosugar") {
    return <App05HelloSugar />;
  } else if (app === "app06rakkanramen") {
    return <App06RakkanRamen />;
  } else if (app === "exp01chatgptdatafields") {
    return <Exp01ChatgptDataFields />;
  }
  if (loading) {
    return <LoadingScreen />;
  } else if (error) {
    return <ErrorScreen message={error} />;
  } else if (appConfig) {
    return (
      <Template02
        appConfig={appConfig}
        setAppConfig={setAppConfig}
        user={user}
      />
    );
  }
  return <AuthFailedScreen />;
};

interface SelectAppProps {
  apps: string[];
  selected: Nullable<string>;
  onSelect: (app: string) => void;
  user: User | undefined;
}

const SelectApp: FC<SelectAppProps> = ({ apps, selected, onSelect, user }) => {
  if (selected !== null) {
    return <MatchApp app={selected} user={user} />;
  }
  return (
    <div className="flex w-full h-full">
      <div className="m-auto">
        <div className="text-2xl">
          Please select an app to continue:
          <ul className="mt-4">
            {apps.map((app) => (
              <li key={app}>
                <button
                  className="w-full hover:text-indigo-500 py-1"
                  onClick={() => onSelect(app)}
                >
                  {app}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-8 text-center">
          <LogoutButton />
        </div>
      </div>
    </div>
  );
};

const Home: FC<{}> = () => {
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [app, setApp] = useLocalStorage<Nullable<string>>("app", null);
  const [params] = useSearchParams();

  if (isLoading) {
    return <LoadingScreen />;
  }
  if (params.get("overture") !== null) {
    if (app !== "app23overture") setApp("app23overture");
    return <MatchApp app={"app23overture"} user={user} />;
  }
  if (!isAuthenticated || !user) {
    loginWithRedirect();
    return <LoadingScreen />;
  }
  const apps = user["app.unearthinsights.com/apps"] as string[];
  if (!apps || !apps.length) {
    return <AuthFailedScreen />;
  }
  mixpanel.identify(user.email);
  if (apps.length > 1) {
    return (
      <SelectApp user={user} apps={apps} selected={app} onSelect={setApp} />
    );
  }
  return <MatchApp user={user} app={apps[0]} />;
};

export default Home;
