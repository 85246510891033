export type LayerDataItem = Record<string, any>;
export type LayerData = LayerDataItem[];
export interface IconLayerState {
  id: string;
  name: string;
  data: LayerData;
  visible: boolean;
}
export interface IconLayerConfig {
  id: string;
  name: string;
  coordinates: (item: LayerDataItem) => [number, number];
  color: string;
  propname: (item: LayerDataItem) => string;
  propaddress: (item: LayerDataItem) => string;
  loadOn: "mapmove" | "mapload";
  load: (lat: number, lon: number, zoom: number) => Promise<LayerData>;
}
export interface LayerConfig {
  iconLayers: IconLayerConfig[];
}

export const initialState = (layerConfig: LayerConfig): LayerState => ({
  iconLayers: layerConfig.iconLayers.reduce(
    (acc, config) => ({
      [config.id]: {
        id: config.id,
        name: config.name,
        data: [],
      },
    }),
    {}
  ),
});

export interface LayerState {
  iconLayers: Record<string, IconLayerState>;
}

export enum ActionTypes {
  LoadIconLayerData = "LoadIconLayerData",
  SetVisibleIconLayers = "SetVisibleIconLayers",
}

export interface Action {
  type: ActionTypes;
  payload: any;
}

export function reducer(state: LayerState, action: Action): LayerState {
  switch (action.type) {
    case ActionTypes.LoadIconLayerData:
      return {
        ...state,
        iconLayers: {
          ...state.iconLayers,
          [action.payload.id]: {
            ...state.iconLayers[action.payload.id],
            data: action.payload.data,
          },
        },
      };
    case ActionTypes.SetVisibleIconLayers: {
      const arr = action.payload as string[];
      const visibleLayers = new Set(arr);
      Object.keys(state.iconLayers).forEach((key) => {
        state.iconLayers[key].visible = visibleLayers.has(key);
      });
      return {
        ...state,
        iconLayers: {
          ...state.iconLayers,
        },
      };
    }
    default:
      return state;
  }
}
