import jsyaml from "js-yaml";
import {
  COLOR_SET_LENGTH,
  COLOR_GOLD_HIGHLIGHT,
  COLOR_GOLD_TRANSPARENT,
  COLOR_ICON_BLUE,
  COLOR_SET_1,
  COLOR_SET_2,
  COLOR_SET_3,
  COLOR_SET_4,
  fromHex,
} from "./colors";
import { ENVIRONMENT } from "./env";
import {
  formatFloat,
  formatInt,
  formatPercent,
  formatCurrency,
} from "./format";
import { Color } from "./geo";

const CONFIG_BASE_URL = `https://unearth-${ENVIRONMENT}.s3.us-west-2.amazonaws.com/appconfig`;

const getAppConfigUrl = (app: string) =>
  `${CONFIG_BASE_URL}/apps/${app}/config.yml`;
const getFieldsConfigUrl = () => `${CONFIG_BASE_URL}/fields.yml`;
const getStatesConfigUrl = (geography: string) =>
  `${CONFIG_BASE_URL}/geos/${geography}.yml`;

const FIELDS_COLOR_SETS = [COLOR_SET_1, COLOR_SET_2, COLOR_SET_3, COLOR_SET_4];

const FIELD_FORMATTERS = {
  float: formatFloat,
  int: formatInt,
  percent: formatPercent,
  currency: formatCurrency,
};

export type LayerType = "geodata" | "geojson" | "icon";
export type LoadOnType = "mapmove" | "stateload";

export interface StateCode {
  name: string;
  code: string;
}

export interface FieldConfig {
  id: string;
  name: string;
  format: (value: number) => string;
  formatLong: (value: number) => string;
  colorSet: Color[];
}

export interface LayerHoverConfig {
  fill?: Color;
  stroke?: Color;
}

export type LayerDataSourceType = "cdn" | "api";

export interface LayerDataConfig {
  source: LayerDataSourceType;
  dynamic: boolean;
  notCompressed?: boolean;
}

export interface GeodataLayer {
  id: string;
  shape: string;
  fieldsEmbedded: boolean;
  stroked: boolean;
  fields: Record<string, FieldConfig>;
  initialField: string;
  filters: string[];
}

export interface GeojsonLayer {
  id: string;
  data: LayerDataConfig;
  fillColor: Color;
  strokeColor: Color;
  pickable: boolean;
  minZoom?: number;
}

export interface IconLayer {
  id: string;
  data: LayerDataConfig;
  color: Color;
  coordinates: (item: Record<string, any>) => [number, number];
  minZoom: number;
  pickable: boolean;
  name?: string;
  url?: string;
  size?: number;
  nationwide: boolean;
}

export interface LayersConfig {
  geodataLayers: GeodataLayer[];
  geojsonLayers: GeojsonLayer[];
  iconLayers: IconLayer[];
}

export interface FilterGroup {
  id: string;
  name: string;
  type: "fields" | "layers";
  items: FilterFieldConfig[] | FilterLayerConfig[];
}

export interface FilterFieldConfig {
  layer: string;
  name: string;
  field: string;
}

export interface FilterLayerConfig {
  name: string;
  layers: string[];
}

export interface AppConfig {
  id: string;
  geography: string;
  states: string[];
  stateNameMap: Record<string, string>;
  layers: LayersConfig;
  fields: Record<string, FieldConfig>;
  layerHover: Record<string, LayerHoverConfig>;
  filterGroups: FilterGroup[];
  summary?: SummaryConfigYml;
  foottraffic?: any;
  territory?: any;
  polygonTerritory?: any;
  driveboundary?: DriveBoundaryConfig;
  overture: {
    enable: boolean;
  };
  geocoding_box: {
    enable: boolean;
  };
}

interface AppConfigYml {
  id: string;
  geography: string;
  states: string[];
  layers: LayerConfigYml[];
  filterGroups: FilterGroupYml[];
  summary?: SummaryConfigYml;
  foottraffic?: any;
  territory?: any;
  polygonTerritory?: any;
  driveboundary?: DriveBoundaryConfigYml;
  overture: {
    enable: boolean;
  };
  geocoding_box: {
    enable: boolean;
  };
}

interface LayerConfigYml {
  id: string;
  type: LayerType;
  shape?: string;
  fieldsEmbedded?: boolean;
  stroked?: boolean;
  fields: string[];
  data: LayerDataConfigYml;
  hover?: LayerColorConfigYml;
  color?: LayerColorConfigYml;
  coordinates?: string;
  minzoom?: number;
  url?: string;
  size?: number;
  filters?: string[];
  pickable?: boolean;
  nationwide?: boolean;
}

interface LayerColorConfigYml {
  fill?: string;
  stroke?: string;
}

interface LayerDataConfigYml {
  source: "cdn" | "api";
  dynamic: boolean;
}

interface FilterGroupYml {
  id: string;
  name: string;
  type: "fields" | "layers";
  items: FilterItemFieldYml[] | FilterItemLayerYml[];
}

interface FilterItemFieldYml {
  field: string;
  name: string;
  layer?: string;
}

interface FilterItemLayerYml {
  name: string;
  layers: string[];
}

interface SummaryPoiConfigYml {
  id: string;
  name: string;
  category: string;
}

interface SummaryConfigYml {
  fields: string[];
  pois?: SummaryPoiConfigYml[];
  score?: any;
}

type FieldFormatType = "float" | "int" | "percent" | "currency";

interface FieldConfigYml {
  id: string;
  name: string;
  format: FieldFormatType;
  formatLong: string;
  colorSet: string[];
}

interface StateConfigYml {
  name: string;
  code: string;
}

type DriveBoundaryProviderType = "mapbox" | "traveltime";

interface DriveBoundaryConfigYml {
  provider: DriveBoundaryProviderType;
  defaults: Record<string, any>;
}

interface DriveBoundaryConfig {
  provider: DriveBoundaryProviderType;
  defaults: Record<string, any>;
}

async function loadAppConfigYml(app: string): Promise<AppConfigYml> {
  const url = getAppConfigUrl(app);
  const data = await fetch(url);
  const text = await data.text();
  const { appconfig } = jsyaml.load(text) as { appconfig: AppConfigYml };
  return appconfig;
}

async function loadFieldsConfigYml(): Promise<Record<string, FieldConfigYml>> {
  const url = getFieldsConfigUrl();
  const data = await fetch(url);
  const text = await data.text();
  const { fields } = jsyaml.load(text) as { fields: FieldConfigYml[] };
  return fields.reduce((acc, field) => {
    acc[field.id] = field;
    return acc;
  }, {} as Record<string, FieldConfigYml>);
}

async function loadStatesConfigYml(
  geography: string
): Promise<Record<string, StateConfigYml>> {
  const url = getStatesConfigUrl(geography);
  const data = await fetch(url);
  const text = await data.text();
  const { states } = jsyaml.load(text) as { states: StateConfigYml[] };
  return states.reduce((acc, state) => {
    acc[state.code] = state;
    return acc;
  }, {} as Record<string, StateConfigYml>);
}

export async function loadAppConfig(app: string): Promise<AppConfig> {
  const [appConfig, fieldsConfig] = await Promise.all([
    loadAppConfigYml(app),
    loadFieldsConfigYml(),
  ]);
  const statesConfig = await loadStatesConfigYml(appConfig.geography);
  const stateNameMap = Object.values(statesConfig).reduce((acc, state) => {
    acc[state.code] = state.name;
    return acc;
  }, {} as Record<string, string>);

  const geodataLayers: GeodataLayer[] = appConfig.layers
    .filter((layer) => layer.type === "geodata")
    .map((layerConfig) => createGeodataLayer(layerConfig, fieldsConfig));

  const fields = geodataLayers.reduce((acc, layer) => {
    Object.values(layer.fields).forEach((field) => {
      acc[field.id] = field;
    });
    return acc;
  }, {} as Record<string, FieldConfig>);

  const remainingFields: Record<string, FieldConfig> = {};
  for (const field of appConfig.summary?.fields || []) {
    if (!fields[field]) {
      remainingFields[field] = toFieldConfig(field, fieldsConfig, 0);
    }
  }
  if (appConfig.summary?.score) {
    remainingFields["score"] = toFieldConfig("score", fieldsConfig, 0);
  }
  Object.entries(remainingFields).forEach(([id, field]) => {
    fields[id] = field;
  });

  if (appConfig.id === "app23overture" || appConfig?.overture?.enable) {
    const savedIconLayers = JSON.parse(
      localStorage.getItem(
        appConfig.id === "app23overture" ? "layers" : `layers-${appConfig.id}`
      ) || "[]"
    );

    if (savedIconLayers && savedIconLayers.length > 0) {
      let poisFieldGroupIndex = appConfig.filterGroups
        .map((group) => group.id)
        .indexOf("pois");

      if (poisFieldGroupIndex == -1) {
        appConfig.filterGroups.push({
          id: "pois",
          name: "POIs",
          items: [],
          type: "layers",
        });

        poisFieldGroupIndex = appConfig.filterGroups.length - 1;
      }

      savedIconLayers.map((layer: IconLayer) =>
        appConfig.filterGroups[poisFieldGroupIndex].items.push({
          name: layer.name || "",
          layers: [layer.id],
          field: "",
        })
      );
    }
  }

  const geojsonLayers: GeojsonLayer[] = appConfig.layers
    .filter((layer) => layer.type === "geojson")
    .map((layerConfig) => ({
      id: layerConfig.id,
      fillColor:
        layerConfig.color && layerConfig.color.fill
          ? fromHex(layerConfig.color.fill)
          : COLOR_GOLD_TRANSPARENT,
      strokeColor:
        layerConfig.color && layerConfig.color.stroke
          ? fromHex(layerConfig.color.stroke)
          : COLOR_GOLD_HIGHLIGHT,
      data: {
        source: layerConfig.data.source,
        dynamic: layerConfig.data.dynamic,
      },
      pickable: layerConfig.pickable ?? true,
    }));

  const iconLayers: IconLayer[] = appConfig.layers
    .filter((layer) => layer.type === "icon")
    .map((layerConfig) => ({
      id: layerConfig.id,
      color:
        layerConfig.color && layerConfig.color.fill
          ? fromHex(layerConfig.color.fill)
          : COLOR_ICON_BLUE,
      coordinates: (item: Record<string, any>) =>
        item[layerConfig.coordinates ?? "coordinates"],
      minZoom: layerConfig.minzoom ?? 6,
      data: {
        source: layerConfig.data.source,
        dynamic: layerConfig.data.dynamic,
      },
      url: layerConfig.url,
      size: layerConfig.size,
      pickable: layerConfig.pickable ?? true,
      nationwide: layerConfig.nationwide ?? false,
    }));

  console.log(`Loaded app config for ${app}`, appConfig);

  return {
    id: appConfig.id,
    geography: appConfig.geography,
    states: appConfig.states,
    stateNameMap,
    layers: {
      geodataLayers,
      geojsonLayers,
      iconLayers,
    },
    overture: appConfig.overture,
    geocoding_box: appConfig.geocoding_box,
    fields,
    layerHover: appConfig.layers.reduce((acc, layer) => {
      if (layer.hover) {
        acc[layer.id] = {
          fill: layer.hover.fill ? fromHex(layer.hover.fill) : undefined,
          stroke: layer.hover.stroke ? fromHex(layer.hover.stroke) : undefined,
        };
      }
      return acc;
    }, {} as Record<string, LayerHoverConfig>),
    filterGroups: appConfig.filterGroups.map((group) => ({
      id: group.id,
      name: group.name,
      type: group.type,
      items:
        group.type === "fields"
          ? (group.items as FilterItemFieldYml[]).map(
              (item): FilterFieldConfig => ({
                field: item.field,
                name: fieldsConfig[item.field].name,
                layer: item.layer ?? "base",
              })
            )
          : (group.items as FilterItemLayerYml[]).map(
              (item): FilterLayerConfig => {
                return {
                  name: item.name,
                  layers: item.layers,
                };
              }
            ),
    })),
    summary: appConfig.summary
      ? {
          fields: appConfig.summary.fields,
          pois: appConfig.summary.pois,
          score: appConfig.summary.score,
        }
      : undefined,
    foottraffic: appConfig.foottraffic,
    territory: appConfig.territory,
    polygonTerritory: appConfig.polygonTerritory,
    driveboundary: {
      provider: appConfig.driveboundary?.provider ?? "traveltime",
      defaults: appConfig.driveboundary?.defaults ?? {},
    },
  };
}

const createGeodataLayer = (
  layer: LayerConfigYml,
  fieldsConfig: Record<string, FieldConfigYml>
): GeodataLayer => ({
  id: layer.id,
  shape: layer.shape!,
  fieldsEmbedded: layer.fieldsEmbedded ?? false,
  stroked: layer.stroked ?? false,
  initialField: layer.fields[0],
  filters: layer.filters ?? layer.fields,
  fields: layer.fields.reduce(
    (acc, field, idx) => ({
      ...acc,
      [field]: toFieldConfig(field, fieldsConfig, idx),
    }),
    {} as Record<string, FieldConfig>
  ),
});

function toFieldConfig(
  field: string,
  fieldsConfig: Record<string, FieldConfigYml>,
  fieldIdx: number
): FieldConfig {
  return {
    id: field,
    name: fieldsConfig[field].name,
    format: fieldFormatter(fieldsConfig[field].format),
    formatLong: fieldLongFormatter(
      fieldsConfig[field].format,
      fieldsConfig[field].formatLong
    ),
    colorSet: fieldColorSet(fieldsConfig[field].colorSet, fieldIdx),
  };
}

function fieldColorSet(configColorSet: string[], fieldIdx: number): Color[] {
  if (configColorSet && configColorSet.length === COLOR_SET_LENGTH) {
    return configColorSet.map((hex) => fromHex(hex));
  }
  return FIELDS_COLOR_SETS[fieldIdx % FIELDS_COLOR_SETS.length];
}

function fieldFormatter(type: FieldFormatType): (val: number) => string {
  return FIELD_FORMATTERS[type];
}

function fieldLongFormatter(
  type: FieldFormatType,
  fmt: string
): (value: number) => string {
  const formatter = fieldFormatter(type);
  if (!fmt) return formatter;
  return (value: number) => fmt.replace("{val}", formatter(value));
}
