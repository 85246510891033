import React, {
  Dispatch,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import {
  Layer,
  MapViewState,
  PickingInfo,
  Position,
  FlyToInterpolator,
  WebMercatorViewport,
} from "@deck.gl/core/typed";
import { GeoJsonLayer, IconLayer, TextLayer } from "@deck.gl/layers/typed";
import DeckGL from "@deck.gl/react/typed";
import { User } from "@auth0/auth0-react";
import polylabel from "polylabel";
import turf from "turf";
import nearestPointOnLine from "@turf/nearest-point-on-line";
import fontColorContrast from "font-color-contrast";

import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Polygon,
} from "geojson";
import mixpanel from "mixpanel-browser";
import { AttributionControl, default as MapGL } from "react-map-gl";
import ColorLegend from "../../../components/ColorLegend";
import GeoLocator from "../../../components/GeoLocator";
import HeadingMenu from "../../../components/HeadingMenu";
import LoadingOverlay from "../../../components/LoadingOverlay";
import LogoutButton from "../../../components/LogoutButton";
import ToggleButtonGroup from "../../../components/ToggleButtonGroup";
import DiscordButton from "../../../components/DiscordButton";
import FAQButton from "../../../components/FAQButton";
import { useDataCache } from "../../../hooks/useDataCache";
import iconFootTraffic from "../../../images/foottraffic_icons.png";
import iconAtlasImg from "../../../images/icon-atlas-2.png";
import unearthAiLogo from "../../../images/unearth-ai-logo.png";

import {
  COLOR_ICON_ORANGE,
  COLOR_TRANSPARENT,
  COLOR_YELLOW_HIGHLIGHT,
  fromHex,
  getTransparentColor,
  randomColor,
  toHex,
  fromHexWithTransparency,
} from "../../../utils/colors";
import { Color, mergeFeatureCollections } from "../../../utils/geo";
import {
  DEFAULT_VIEW_STATE,
  GEO_VIEW_STATES,
  MapPin,
  US_VIEW_STATE,
  createBoundsForStates,
} from "../../../utils/map";
import { PoiFeature } from "../../../utils/types";
import { UsState } from "../../../utils/us";
import {
  DropPinAction,
  DropPinActionType,
  EvaluateTabState,
  EvaluateTabActionType,
  EvaluateTabAction,
  evaluateTabReducer,
  initialEvaluateTabState,
  DroppedPinState,
  initialDroppedPinState,
  dropPinReducer,
  SEARCH_PIN_ID,
  initialPolygonTabState,
  polygonReducer,
  PolygonTabState,
  PolygonActionType,
  PolygonGroup,
  PolygonAction,
} from "./types";
import ExploreTab from "./ExploreTab";
import FootTrafficTab, {
  Location as FootTrafficLocation,
  ActionType as FootTrafficTabActionType,
  reducer as footTrafficTabReducer,
  initialState as initialFootTrafficTabState,
} from "./FootTrafficTab";
import {
  API_URL,
  ICON_MAPPING,
  MAPBOX_TOKEN,
  MENU_TABS,
  MenuTabs,
  TERRITORRY_FEATURES_COLORS_BY_TAGS,
  TerritoryFeaturesColorsByTagsKeys,
  TERRITORRY_FEATURES_COLORS_BY_TAGS_911,
  TerritoryFeaturesColorsByTagsKeys911,
} from "./constants";
import {
  LayerDataItem,
  LayerState,
  initialState as initalLayerState,
  reducer as layerStateReducer,
} from "./reducer";

import { MapPinIcon } from "@heroicons/react/24/outline";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import SelectMenu from "../../../components/SelectMenu";
import ToggleButton from "../../../components/ToggleButton";
import useDebounced from "../../../hooks/useDebounced";
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import LayerLoader from "../../../utils/LayerLoader";
import {
  AppConfig,
  IconLayer as IconLayerType,
} from "../../../utils/appconfig";
import { BASE_APPDATA_URL } from "../../../utils/constants";
import { extractUsStateCodeFromPoiFeature } from "../../../utils/helper";
import { findFirstPosition } from "../../../utils/math";
import { areSetsEqual } from "../../../utils/sets";
import LocationSummaryTable from "./LocationSummaryTable";
import {
  BasicTooltip,
  GeoDataLayerTooltip,
  GeojsonLayerTooltip,
  IconLayerTooltip,
} from "./Tooltip";
import { ICON_MAPPING_COLOR } from "./constants";
import { DrawPolygonMode, ViewMode, ModifyMode } from "@nebula.gl/edit-modes";
import { EditableGeoJsonLayer } from "@nebula.gl/layers";
import EvaluateTab from "./EvaluateTab";
import Typeform from "./Typeform";
import OvertureQuery from "./OvertureQuery";
import TerritoryTab, {
  TerritoryTabActionType,
  initialTerritoryTabState,
  territoryTabReducer,
} from "./TerritoryTab";
import { cyrb53 } from "../../../utils/crypto";
import PolygonTab from "./PolygonTab";
import { formatInt } from "../../../utils/format";

const GEOCODE_DROPPED_PINS = false;
const EMPTY_FEATURE_COLLECTION: FeatureCollection<Geometry, GeoJsonProperties> =
  {
    type: "FeatureCollection",
    features: [],
  };

const toIconLayerId = (id: string) => `iconlayer|${id}`;
const fromIconLayerId = (id: string) => id.split("|")[1];
const isIconLayerId = (id: string) => id.startsWith("iconlayer|");

const toGeojsonLayerId = (id: string) => `geojsonlayer|${id}`;
const fromGeojsonLayerId = (id: string) => id.split("|")[1];
const isGeojsonLayerId = (id: string) => id.startsWith("geojsonlayer|");

const toGeodataLayerId = (id: string, usState: string, activeField: string) =>
  `geodatalayer|${id}|${usState}|${activeField}}`;
const fromGeodataLayerId = (id: string) => id.split("|")[1];
const isGeodataLayerId = (id: string) => id.startsWith("geodatalayer|");

const isFixedTerritoryLayerId = (id: string) =>
  id.startsWith("fixed|territory|");

type LayerType = "geodatalayer" | "geojsonlayer" | "iconlayer" | "fixed";
const parseDeckglLayerId = (
  deckglId: string
): { type: LayerType; id: string } => {
  const [type, id] = deckglId.split("|");
  return { type: type as LayerType, id };
};

interface Props {
  appConfig: AppConfig;
  setAppConfig: React.Dispatch<React.SetStateAction<AppConfig | undefined>>;
  user: User | undefined;
}

const Template02: FC<Props> = ({ appConfig, setAppConfig, user }) => {
  const { loadDataset, getDataset } = useDataCache();
  const [loading, setLoading] = useState<boolean>(false);
  const menuTabs = useMemo(() => {
    let tabs = MENU_TABS;
    if (appConfig.id === "app18cleanrest") {
      tabs = tabs.filter((t) => t.key !== MenuTabs.Evaluate);
      const idx = tabs.findIndex((t) => t.key === MenuTabs.Territory);
      [tabs[0], tabs[idx]] = [tabs[idx], tabs[0]];
    }
    if (!appConfig.summary) {
      const idx = tabs.findIndex((t) => t.key === MenuTabs.Evaluate);
      tabs[idx].disabled = true;
    }
    if (!appConfig.foottraffic) {
      const idx = tabs.findIndex((t) => t.key === MenuTabs.Visitation);
      tabs = tabs.filter((t, index) => index !== idx);
    }
    if (!appConfig.territory) {
      tabs = tabs.filter((t) => t.key !== MenuTabs.Territory);
    }
    if (!appConfig.polygonTerritory) {
      tabs = tabs.filter((t) => t.key !== MenuTabs.Polygon);
    }
    return tabs;
  }, [appConfig]);
  const [activeTab, setActiveTab] = useState<string>(menuTabs[0].key);
  const geodataLayersForActiveTab = useMemo(() => {
    if (activeTab === MenuTabs.Visitation) {
      return appConfig.layers.geodataLayers.filter(
        (l) => l.id === "foottraffic"
      );
    } else if (activeTab === MenuTabs.Territory) {
      return appConfig.layers.geodataLayers.filter(
        (l) => l.id !== "territory" && l.id !== "base"
      );
    }
    return appConfig.layers.geodataLayers.filter((l) => l.id !== "foottraffic");
  }, [appConfig.layers, activeTab]);
  const [selectedStateCode, setSelectedStateCode] = useState<string>(
    appConfig.states[0]
  );

  const [aditionalStateCodes, setAditionalStateCodes] = useState<string[]>([
    selectedStateCode,
  ]);

  const [mapViewState, setMapViewState] = useState<MapViewState>(US_VIEW_STATE);
  const [initialViewState, setInitialViewState] = useState<MapViewState>(
    GEO_VIEW_STATES.get(appConfig.geography) ?? DEFAULT_VIEW_STATE
  );
  const debouncedMapViewState = useDebounced(mapViewState, 500);

  const [demoEmail, setDemoEmail] = useLocalStorage<Nullable<string>>(
    "demoEmail",
    null
  );

  useEffect(() => {
    if (!demoEmail) return;
    mixpanel.identify(demoEmail);
    mixpanel.track("Load App", {
      App: appConfig.id,
    });
  }, [demoEmail]);

  const [savedIconLayers, setSavedIconLayers] = useLocalStorage<
    IconLayerType[]
  >(appConfig.id === "app23overture" ? "layers" : `layers-${appConfig.id}`, []);

  const [layerState, layerDispatch] = useReducer(
    layerStateReducer,
    initalLayerState({
      ...appConfig.layers,
      iconLayers: [
        ...appConfig.layers.iconLayers,
        ...(appConfig.id === "app23overture" || appConfig?.overture?.enable
          ? savedIconLayers
          : []),
      ],
    })
  );

  const [temporaryIconLayer, setTemporaryLayer] =
    useState<Nullable<IconLayerType>>(null);
  const [territoryTabState, territoryTabDispatch] = useReducer(
    territoryTabReducer,
    initialTerritoryTabState()
  );

  const layerLoader = useMemo(
    () =>
      new LayerLoader(
        appConfig.id,
        {
          ...appConfig.layers,
          iconLayers: [
            ...appConfig.layers.iconLayers,
            ...(temporaryIconLayer ? [temporaryIconLayer] : []),
            ...(appConfig.id === "app23overture" || appConfig?.overture?.enable
              ? savedIconLayers
              : []),
          ],
        },
        loadDataset,
        getDataset,
        appConfig
      ),
    [loadDataset, getDataset, temporaryIconLayer]
  );

  useEffect(() => {
    setLoading(true);

    console.log(
      "LayerLoader responsible UseEffect fired.",
      "selectedStateCode: ",
      selectedStateCode,
      "aditionalStateCodes: ",
      aditionalStateCodes
    );

    layerLoader
      .onMapLoad(
        selectedStateCode,
        layerDispatch,
        activeTab === MenuTabs.Territory &&
          appConfig.id === "app30restoration911",
        aditionalStateCodes
      )
      .finally(() => setLoading(false));
  }, [selectedStateCode, temporaryIconLayer, aditionalStateCodes]);

  useEffect(() => {
    if (!PolygonTabState.selectedPolygon) return;

    const selectedGroup = PolygonTabState.groups.find((group) =>
      group.features.find(
        (feature) =>
          feature?.properties?.color ===
          PolygonTabState.selectedPolygon?.properties?.color
      )
    );

    if (selectedGroup?.name !== "Default") {
      PolygonTabDispatch({
        type: PolygonActionType.SetSelectedPolygon,
        payload: null,
      });
    }
  }, [selectedStateCode]);

  const [visibleLayers, setVisibleLayers] = useState<Set<string>>(new Set());
  useEffect(() => {
    const v = new Set(
      Object.values(layerState.iconLayers)
        .filter((l) => l.visible)
        .map((l) => l.id)
    );
    if (!areSetsEqual(v, visibleLayers)) setVisibleLayers(v);
  }, [layerState]);

  useEffect(() => {
    const { latitude, longitude, zoom } = debouncedMapViewState;
    layerLoader.onMapMove(
      visibleLayers,
      selectedStateCode,
      latitude,
      longitude,
      zoom,
      layerDispatch
    );
  }, [selectedStateCode, debouncedMapViewState, visibleLayers]);

  const [droppedPinState, droppedPinDispatch] = useReducer(
    dropPinReducer,
    initialDroppedPinState
  );
  const [evaluateTabState, evaluateTabDispatch] = useReducer(
    evaluateTabReducer,
    initialEvaluateTabState(appConfig.driveboundary?.defaults?.minutes)
  );
  const [footTrafficTabState, footTrafficTabDispatch] = useReducer(
    footTrafficTabReducer,
    initialFootTrafficTabState()
  );

  const initialterritoryTabStateValue = useMemo(
    () => initialPolygonTabState(appConfig, user),
    []
  );
  const [PolygonTabState, PolygonTabDispatch] = useReducer(
    polygonReducer,
    initialterritoryTabStateValue
  );

  const [isDroppingPin, setIsDroppingPin] = useState<boolean>(false);
  const [selectedPoi, setSelectedPoi] = useState<Nullable<PoiFeature>>(null);
  const [hoverInfo, setHoverInfo] = useState<PickingInfo>();

  const handleSelectUsState = (states: string | string[]) => {
    if (activeTab === MenuTabs.Territory && Array.isArray(states)) {
      if (states.length === 0) states = [selectedStateCode];

      const bounds = createBoundsForStates(
        states.map((code) => appConfig.stateNameMap[code]) as UsState[]
      );

      if (states.length === 1 || !states.includes(selectedStateCode)) {
        setSelectedStateCode(states[0]);
      }

      setAditionalStateCodes(states.flat(1));

      setInitialViewState({
        ...new WebMercatorViewport(mapViewState).fitBounds(bounds, {
          padding: { top: 5, left: 5, right: 5, bottom: 75 },
        }),
        transitionDuration: "auto",
        transitionInterpolator: new FlyToInterpolator(),
      });
    } else if (typeof states === "string") {
      setSelectedStateCode(states);
      const stateName = appConfig.stateNameMap[states];

      setInitialViewState({
        ...new WebMercatorViewport(mapViewState).fitBounds(
          createBoundsForStates([stateName as UsState]),
          {
            padding: { top: 5, left: 5, right: 5, bottom: 75 },
          }
        ),
        transitionDuration: "auto",
        transitionInterpolator: new FlyToInterpolator(),
      });

      mixpanel.track("Select State", { State: stateName });
    }
  };

  const isochroneGeom = useMemo(
    () => mergeFeatureCollections(Object.values(droppedPinState.isochroneMap)),
    [droppedPinState.isochroneMap]
  );

  const geocodeDroppedPin = async (
    lon: number,
    lat: number
  ): Promise<PoiFeature> => {
    const coordStr = [lon, lat].map((c) => c.toFixed(6)).join(", ");
    const poi: PoiFeature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [lon, lat],
      },
      properties: {},
      place_name: `Dropped Pin (${coordStr})`,
      center: [lon, lat],
      context: [],
    };
    if (!GEOCODE_DROPPED_PINS) {
      return poi;
    }
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lon},${lat}.json?country=US&access_token=${MAPBOX_TOKEN}`;
    const resp = await fetch(url);
    const data = await resp.json();
    if (!data.features || !data.features.length) {
      return poi;
    } else {
      return data.features[0];
    }
  };

  const handleDropPin = useCallback(async (coordinates: Position) => {
    const [lon, lat] = coordinates;
    const poi = await geocodeDroppedPin(lon, lat);
    setSelectedPoi(poi);
    droppedPinDispatch({
      type: DropPinActionType.AddPin,
      payload: {
        coordinates,
      },
    });
    setIsDroppingPin(false);
    evaluateTabDispatch({
      type: EvaluateTabActionType.SetSummary,
      payload: [],
    });
    mixpanel.track("Drop Pin", {
      Lon: lon,
      Lat: lat,
    });
  }, []);

  const handleSelectPoi = (poi: PoiFeature) => {
    setSelectedPoi(poi);
    const pin: MapPin = {
      id: SEARCH_PIN_ID,
      name: poi.place_name,
      coordinates: poi.center,
      color: COLOR_ICON_ORANGE,
    };
    droppedPinDispatch({
      type: DropPinActionType.SetSearchPin,
      payload: { pin },
    });
    evaluateTabDispatch({
      type: EvaluateTabActionType.SetSummary,
      payload: [],
    });
    const code = extractUsStateCodeFromPoiFeature(poi);
    if (code && code !== selectedStateCode) {
      handleSelectUsState(code);
    }
    mixpanel.track("Geolocate POI", {
      Poi: poi.place_name,
      Lon: poi.center[0],
      Lat: poi.center[1],
    });
  };

  const handleCloseLocationSummary = () => {
    evaluateTabDispatch({
      type: EvaluateTabActionType.SetSummary,
      payload: [],
    });
  };

  const geodataLayers = useMemo<Nullable<GeoJsonLayer>[]>(() => {
    return geodataLayersForActiveTab.map((layerConfig) => {
      const layer = layerState.geodataLayers[layerConfig.id];
      if (!layer.activeField) return null;
      const getFeatureColor = (
        d: Feature<Geometry, GeoJsonProperties>
      ): Color => {
        if (!d.properties || !layer.activeField || !layer.data)
          return COLOR_TRANSPARENT;
        let value: number;
        if (layerConfig.fieldsEmbedded) {
          if (layer.activeField in d.properties) {
            value = d.properties[layer.activeField];
          } else {
            return COLOR_TRANSPARENT;
          }
        } else {
          if (!layer.data.fields) return COLOR_TRANSPARENT;
          const geoid = d.properties["geoid"];
          if (!geoid || !layer.data.fields[geoid]) return COLOR_TRANSPARENT;
          value = layer.data.fields[geoid][layer.activeField];
        }
        const interval = findFirstPosition(
          layer.data.stats[layer.activeField].intervals,
          value
        );
        return layerConfig.fields[layer.activeField].colorSet[interval];
      };

      return new GeoJsonLayer({
        id: toGeodataLayerId(
          layerConfig.id,
          selectedStateCode,
          layer.activeField
        ),
        data: layer.filteredGeojson ?? undefined,
        pickable: true,
        stroked: layerConfig.stroked,
        filled: true,
        extruded: false,
        pointType: "circle",
        lineWidthMinPixels: 1,
        getFillColor: getFeatureColor,
        getLineColor: getFeatureColor,
        getPointRadius: 100,
        getLineWidth: 40,
        getElevation: 30,
      });
    });
  }, [geodataLayersForActiveTab, layerState.geodataLayers, selectedStateCode]);

  const [isRenaming, setIsRenaming] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [selectedPolygon, setSelectedPolygon] = useState<Feature<
    Polygon,
    GeoJsonProperties
  > | null>(null);
  const currentProfile = useMemo(
    () => PolygonTabState.profilesMap[PolygonTabState.currentProfileId],
    [PolygonTabState]
  );

  const selectedFeatureIndexes = useMemo<number[]>(() => {
    if (!selectedPolygon || !currentProfile) return [];

    const polygons = [
      ...PolygonTabState.groups
        .map((group) => group.features)
        .flat(1)
        .sort((b, a) => a.properties?.area - b.properties?.area),
    ];

    const curFeature = polygons.find(
      (feature) =>
        feature?.properties?.color === selectedPolygon?.properties?.color
    );
    if (!curFeature) return [];

    return [
      polygons.findIndex(
        (feature) =>
          feature?.properties?.color === curFeature?.properties?.color
      ),
    ];
  }, [selectedPolygon, currentProfile]);

  const mode = useMemo<DrawPolygonMode | ViewMode | ModifyMode>(() => {
    if (isDrawing) {
      return new DrawPolygonMode();
    } else if (isEditing) {
      return new ModifyMode();
    } else {
      return new ViewMode();
    }
    cnt;
  }, [isDrawing, isEditing]);

  const cnt = useMemo<number>(() => {
    if (currentProfile) {
      return currentProfile.features.length + 1;
    } else {
      return 0;
    }
  }, [currentProfile]);

  function handleUpdate(
    updatedData: FeatureCollection<Polygon, GeoJsonProperties>,
    editType: string
  ) {
    updatedData = {
      ...updatedData,
      features: [...updatedData.features].sort(
        (b, a) => a.properties?.area - b.properties?.area
      ),
    };

    if (editType === "addFeature") {
      const newFeature = updatedData.features[updatedData.features.length - 1];
      newFeature.properties = {
        name: "New Territory",
        color: toHex(randomColor()),
        tag: "Existing Territory",
        area: turf.area(
          turf.polygon([[...newFeature.geometry.coordinates[0]]])
        ),
      };
      newFeature.id = cnt;
      const updatedProfilesMap = {
        ...PolygonTabState.profilesMap,
        [PolygonTabState.currentProfileId]: updatedData,
      };
      PolygonTabDispatch({
        type: PolygonActionType.SetProfileMap,
        payload: updatedProfilesMap,
      });
      PolygonTabDispatch({
        type: PolygonActionType.AddPolygon,
        payload: newFeature,
      });
      mixpanel.track("Created Polygon", { AppID: appConfig.id });
      setIsDrawing(false);
      setIsRenaming(true);
      setSelectedPolygon(newFeature);
      PolygonTabDispatch({
        type: PolygonActionType.SetSelectedPolygon,
        payload: newFeature,
      });
    } else {
      if (editType === "finishMovePosition") {
        updatedData.features[selectedFeatureIndexes[0]].properties = {
          properties: {
            ...updatedData.features[selectedFeatureIndexes[0]].properties,
            area: turf.area(
              turf.polygon([
                [
                  ...updatedData.features[selectedFeatureIndexes[0]].geometry
                    .coordinates[0],
                ],
              ])
            ),
          },
          summary: null,
        };
        PolygonTabDispatch({
          type: PolygonActionType.SetSelectedPolygon,
          payload: updatedData.features[selectedFeatureIndexes[0]],
        });
      }

      if (
        (editType === "addPosition" || editType === "movePosition") &&
        selectedFeatureIndexes.length > 0
      ) {
        const ungroupedFeatures = [
          ...PolygonTabState.groups.map((group) => group.features).flat(1),
        ];

        const feature = ungroupedFeatures[selectedFeatureIndexes[0]];

        const updateDataFeature = updatedData.features.find(
          (localFeature) =>
            localFeature?.properties?.color === feature?.properties?.color
        );

        const groups = [...PolygonTabState.groups];

        const groupIndex = groups.findIndex(
          (group) =>
            group.features.find(
              (localFeature) =>
                localFeature.properties?.color === feature.properties?.color
            ) !== undefined
        );

        const featureIndex = groups[groupIndex].features.findIndex(
          (localFeature) =>
            localFeature.properties?.color === feature.properties?.color
        );

        if (
          updateDataFeature &&
          feature.geometry?.coordinates[0].length <=
            updateDataFeature.geometry?.coordinates[0].length
        ) {
          groups[groupIndex].features[featureIndex] = {
            ...updateDataFeature,
            properties: {
              ...updateDataFeature.properties,
              summary: null,
              area: turf.area(
                turf.polygon([
                  [
                    ...updatedData.features[selectedFeatureIndexes[0]].geometry
                      .coordinates[0],
                  ],
                ])
              ),
            },
          };

          PolygonTabDispatch({
            type: PolygonActionType.SetGroups,
            payload: {
              groups,
              changes: true,
            },
          });
        }
      }

      const updatedProfilesMap = {
        ...PolygonTabState.profilesMap,
        [PolygonTabState.currentProfileId]: updatedData,
      };

      PolygonTabDispatch({
        type: PolygonActionType.SetProfileMap,
        payload: updatedProfilesMap,
      });
    }
  }

  async function loadProfiles() {
    try {
      const url = API_URL + "/apps/" + appConfig.id + "/territory-profiles";

      const getPolygons = async (
        id: number
      ): Promise<{
        [x: number]: any;
      }> => {
        const res = await fetch(url + "/" + id, { method: "GET" });

        const profileData = await res.json();

        return profileData;
      };

      const response = await fetch(url);
      const data = await response.json();

      const groups: PolygonGroup[] = await Promise.all(
        await data.profiles.map(async (profile: any) => {
          return {
            id: profile.id,
            name: profile.name,
            state: profile.state,
            ...(await getPolygons(profile.id)),
          };
        })
      );

      groups.sort((a: PolygonGroup, b: PolygonGroup) => {
        if (a.name === "Default") {
          return -1;
        } else if (b.name === "Default") {
          return 1;
        } else {
          return a.name.localeCompare(b.name);
        }
      });

      groups.forEach((group: PolygonGroup) => {
        group.features.sort(
          (a: PolygonGroup["features"][0], b: PolygonGroup["features"][0]) => {
            if (!a.properties || !b.properties) return 0;
            return a.properties.name.localeCompare(b.properties.name);
          }
        );
      });

      PolygonTabDispatch({
        type: PolygonActionType.SetGroups,
        payload: {
          groups,
        },
      });

      const id = groups[0].id;

      PolygonTabDispatch({
        type: PolygonActionType.SetProfileId,
        payload: id,
      });

      PolygonTabDispatch({
        type: PolygonActionType.SetProfileMap,
        payload: {
          [id]: {
            features: groups[0].features,
            type: "FeatureCollection",
          },
        },
      });

      PolygonTabDispatch({
        type: PolygonActionType.Saved,
        payload: {},
      });
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadProfiles();
  }, []);

  const PolygonLayer = useMemo<Nullable<EditableGeoJsonLayer>>(() => {
    return new (EditableGeoJsonLayer as any)({
      id: "Polygon-layer",
      mode: mode,
      data:
        {
          type: "FeatureCollection",
          features: [
            ...PolygonTabState.groups
              .map((group) => group.features)
              .flat(1)
              .sort((a, b) => b.properties?.area - a.properties?.area),
          ],
        } ?? EMPTY_FEATURE_COLLECTION,
      selectedFeatureIndexes,
      pickable: true,
      onEdit: ({
        updatedData,
        editType,
      }: {
        updatedData: FeatureCollection<Polygon, GeoJsonProperties>;
        editType: string;
      }) => {
        handleUpdate(updatedData, editType);
      },
      _subLayerProps: {
        geojson: {
          getFillColor: (d: Feature) => {
            return (
              getTransparentColor(
                100,
                fromHex(
                  TERRITORRY_FEATURES_COLORS_BY_TAGS[
                    (d.properties?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                      "Existing Territory"
                  ]
                )
              ) ?? COLOR_TRANSPARENT
            );
          },
          getLineColor: (d: Feature) => {
            return (
              fromHex(
                TERRITORRY_FEATURES_COLORS_BY_TAGS[
                  (d.properties?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                    "Existing Territory"
                ]
              ) ?? COLOR_TRANSPARENT
            );
          },
          getLineWidth: () => 3,
        },
      },
    });
  }, [
    mode,
    currentProfile,
    PolygonTabState.profilesMap,
    PolygonTabState.groups,
  ]);
  const ownedTerritoryColors = useMemo<Map<string, Color>>(() => {
    let territories = territoryTabState.territories;
    if (territoryTabState.editing) {
      territories = territories.filter(
        (t) => t.id !== territoryTabState.editTerritory.id
      );
    }
    return new Map(
      territories.flatMap((t) => {
        const color =
          t.id === territoryTabState.selectedTerritoryId
            ? fromHexWithTransparency(t.color, 215)
            : fromHex(t.color);
        return t.geoids.map((c) => [c, color]);
      })
    );
  }, [territoryTabState]);

  const ownedTerritoryHash = useMemo<string>(() => {
    const str = JSON.stringify(territoryTabState.territories);
    return cyrb53(str).toString();
  }, [territoryTabState.territories]);

  const editTerritoryGeoids = useMemo<Set<string>>(() => {
    return new Set(territoryTabState.editTerritory.geoids);
  }, [territoryTabState.editTerritory]);

  const editTerritoryHash = useMemo<string>(() => {
    const str = JSON.stringify({
      codes: Array.from(editTerritoryGeoids),
    });
    return cyrb53(str).toString();
  }, [editTerritoryGeoids]);

  const territoryLayer = useMemo<
    Nullable<[Nullable<GeoJsonLayer>, Nullable<TextLayer>]>
  >(() => {
    if (activeTab !== MenuTabs.Territory) return null;
    const baseLayer = appConfig.layers.geodataLayers.find(
      (l) => l.id === "base"
    );
    if (!baseLayer) return null;
    const layer = layerState.geodataLayers[baseLayer.id];

    const getFeatureColor = (
      d: Feature<Geometry, GeoJsonProperties>
    ): Color => {
      if (!d.properties || !d.properties.geoid) return COLOR_TRANSPARENT;

      if (appConfig.id === "app30restoration911") {
        const territory = territoryTabState.territories.find((territory) =>
          territory.geoids.includes(d.properties?.geoid)
        );

        if (
          territoryTabState.editing &&
          editTerritoryGeoids.has(d.properties.geoid)
        ) {
          return fromHexWithTransparency(
            territoryTabState.editTerritory.color,
            180
          );
        }

        if (territoryTabState.selectedTerritoryId === territory?.id)
          return fromHexWithTransparency(territory?.color, 130);

        if (!territory) return COLOR_TRANSPARENT;

        return fromHexWithTransparency(territory?.color, 100);
      }

      if (
        territoryTabState.editing &&
        editTerritoryGeoids.has(d.properties.geoid)
      ) {
        return fromHexWithTransparency(
          territoryTabState.editTerritory.color,
          180
        );
      }

      return ownedTerritoryColors.get(d.properties.geoid) ?? COLOR_TRANSPARENT;
    };

    const features: Feature<Polygon, GeoJsonProperties>[] = [];

    layer.data?.geojson?.features
      .map((feature) => {
        return {
          ...feature,
          properties: {
            ...feature.properties,
            area:
              turf.area(turf.polygon([feature.geometry.coordinates[0]])) /
              1000000,
            id:
              Math.random().toString(36).substring(2, 15) +
              Math.random().toString(36).substring(2, 15),
          },
        };
      })
      .filter((feature) => feature.properties.area > 1)
      .sort((a, b) => b.properties.area - a.properties.area)
      .map((feature) => {
        features.push(feature);
      });

    features.sort((a, b) => {
      const aTerritory = territoryTabState.territories.find((territory) =>
        territory.geoids.includes(a.properties?.geoid)
      );

      const bTerritory = territoryTabState.territories.find((territory) =>
        territory.geoids.includes(b.properties?.geoid)
      );

      if (!aTerritory && bTerritory) return -1;
      if (aTerritory && !bTerritory) return 1;

      return b.properties?.area - a.properties?.area;
    });

    interface Label {
      geoid: string;
      coordinates: number[] & { distance: number };
      area: number;
      id: string;
    }

    const labels: Label[] = features.map((feature) => ({
      geoid: feature?.properties?.geoid,
      coordinates: polylabel(feature.geometry.coordinates, 0.001),
      area: feature.properties?.area,
      id: feature.properties?.id,
    }));

    const getLabelBackgroundColor = (
      geoid: string
    ): [number, number, number, number] => {
      const isTerritory = territoryTabState.territories.find((territory) =>
        territory.geoids.includes(geoid)
      );

      if (!isTerritory) return [79, 70, 229, 255];

      return [...fromHex(isTerritory.color).slice(0, 3), 255] as [
        number,
        number,
        number,
        number
      ];
    };

    return [
      new GeoJsonLayer({
        id: `fixed|territory|${editTerritoryHash}-${ownedTerritoryHash}-${territoryTabState.selectedTerritoryId}`,
        data: features ?? undefined,
        pickable: true,
        stroked: true,
        filled: true,
        extruded: false,
        pointType: "circle",
        lineWidthMinPixels: 2,
        getFillColor: getFeatureColor,
        textSizeUnits: "meters",
        getLineColor: (d: Feature) => {
          if (appConfig.id !== "app30restoration911")
            return [115, 115, 115, 255];

          const territory = territoryTabState.territories.find((territory) =>
            territory.geoids.includes(d.properties?.geoid)
          );

          if (!territory) return [115, 115, 115, 255];

          if (territory.tag)
            return [
              ...fromHex(
                TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                  territory.tag as TerritoryFeaturesColorsByTagsKeys911
                ]
              ).slice(0, 3),
              255,
            ] as [number, number, number, number];

          return [115, 115, 115, 255];
        },
        getPointRadius: 100,
        getElevation: 30,
        getText: () => "TEST",
        getSize: 32,
      }),
      mapViewState.zoom < 5.5
        ? null
        : new TextLayer({
            id: "text-layer",
            data: labels,
            getPosition: (label: Label) => [
              label.coordinates[0],
              label.coordinates[1],
            ],
            getText: (d: Label) => {
              const isTerritory = territoryTabState.territories.find(
                (territory) => territory.geoids.includes(d.geoid)
              );

              return isTerritory?.name || d.geoid;
            },
            background: true,
            backgroundPadding: [4, 2],
            getBackgroundColor: (d) => getLabelBackgroundColor(d.geoid),
            getColor: (label: Label) => {
              const rgb = getLabelBackgroundColor(label.geoid);

              rgb.pop();

              return fromHex(fontColorContrast(...rgb));
            },
            getSize: (label: Label) => {
              const isTerritory = territoryTabState.territories.find(
                (territory) => territory.geoids.includes(label.geoid)
              );

              const text = isTerritory?.name || label.geoid;

              const feature = features.find(
                (feature) => feature?.properties?.id === label.id
              );

              if (!feature) return 16;

              const closestDistanceToLine =
                nearestPointOnLine(
                  turf.lineString(feature.geometry.coordinates[0]),
                  turf.point(label.coordinates),
                  { units: "meters" }
                ).properties.dist || 16;

              return (closestDistanceToLine * 2) / text.length;
            },
            sizeUnits: "meters",
          }),
    ];
  }, [
    mapViewState.zoom < 5.5,
    layerState.geodataLayers,
    selectedStateCode,
    activeTab,
    territoryTabState,
    editTerritoryHash,
    ownedTerritoryHash,
  ]);

  const hoverLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!hoverInfo || !hoverInfo.object || !hoverInfo.layer) return null;
    const { type, id } = parseDeckglLayerId(hoverInfo.layer.id);
    if (type !== "geodatalayer" && type !== "geojsonlayer" && type !== "fixed")
      return null;
    if (type === "fixed" && id !== "territory") return null;
    const hover =
      id === "territory"
        ? appConfig.layerHover["base"]
        : appConfig.layerHover[id];
    if (!hover) return null;

    return new GeoJsonLayer({
      id: `hoverlayer-${hoverInfo.x}-${hoverInfo.y}`,
      data: hoverInfo.object,
      pickable: false,
      stroked: true,
      filled: !!hover.fill,
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 2,
      getFillColor: hover.fill ?? COLOR_TRANSPARENT,
      getLineColor: hover.stroke ?? COLOR_TRANSPARENT,
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [hoverInfo]);

  const isochroneLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!isochroneGeom) return null;
    return new GeoJsonLayer({
      id: `isochronelayer-${isochroneGeom.features.length}`,
      data: isochroneGeom,
      pickable: false,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 3,
      getFillColor: [60, 60, 60, 40],
      getLineColor: (d: Feature) => {
        const curPin = droppedPinState.pins.find(
          (pin) => pin.id === d.properties!["pinId"]
        );
        return curPin?.color ?? COLOR_YELLOW_HIGHLIGHT;
      },
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [isochroneGeom]);

  const iconLayers = useMemo<Nullable<IconLayer>[]>(() => {
    if (activeTab === MenuTabs.Visitation) {
      return [];
    }
    return [
      ...appConfig.layers.iconLayers,
      temporaryIconLayer,
      ...(appConfig.id === "app23overture" || appConfig?.overture?.enable
        ? savedIconLayers
        : []),
    ].map((layerConfig) => {
      if (!layerConfig) return null;

      const layer = layerState.iconLayers[layerConfig.id];
      if (!layer.visible || mapViewState.zoom < layerConfig.minZoom)
        return null;

      const isFullUrl = layerConfig.url?.startsWith("http");

      return new IconLayer({
        id: toIconLayerId(layerConfig.id),
        data: layer.data,
        pickable: layerConfig.pickable,
        iconAtlas: layerConfig.url
          ? isFullUrl
            ? layerConfig.url
            : `${BASE_APPDATA_URL}/${appConfig.id}/icons/${layerConfig.url}`
          : iconAtlasImg,
        iconMapping: layerConfig.url ? ICON_MAPPING_COLOR : ICON_MAPPING,
        getIcon: () => "marker",
        sizeScale: 15,
        getPosition: (d: LayerDataItem) =>
          layerConfig?.coordinates
            ? layerConfig?.coordinates(d)
            : d["coordinates"],
        getSize: () => layerConfig.size ?? 1.8,
        getColor: () => layerConfig.color,
      });
    });
  }, [layerState.iconLayers, mapViewState.zoom, activeTab, temporaryIconLayer]);

  const geojsonLayers = useMemo<Nullable<GeoJsonLayer>[]>(() => {
    if (activeTab === MenuTabs.Visitation) {
      return [];
    }
    return appConfig.layers.geojsonLayers.map((layerConfig) => {
      const layer = layerState.geojsonLayers[layerConfig.id];
      if (
        !layer.visible ||
        !layer.data ||
        mapViewState.zoom < (layerConfig.minZoom || 0)
      )
        return null;

      return new GeoJsonLayer({
        id: toGeojsonLayerId(layerConfig.id),
        data: layer.data,
        pickable: layerConfig.pickable,
        stroked: true,
        filled: true,
        extruded: false,
        pointType: "text",
        lineWidthScale: 1,
        lineWidthMinPixels: 2,
        getFillColor: layerConfig.fillColor,
        getLineColor: layerConfig.strokeColor,
        getPointRadius: 50,
        getLineWidth: 1,
        getElevation: 30,
      });
    });
  }, [
    appConfig.layers.geojsonLayers,
    layerState.geojsonLayers,
    activeTab,
    mapViewState.zoom,
  ]);

  const footTrafficIconLayer = useMemo<Nullable<IconLayer>>(() => {
    if (activeTab !== MenuTabs.Visitation) return null;
    return new IconLayer({
      id: toIconLayerId(`foottraffic|${footTrafficTabState.locationId}`),
      data: footTrafficTabState.locations,
      pickable: true,
      iconAtlas: iconFootTraffic,
      iconMapping: ICON_MAPPING_COLOR,
      getIcon: (d: FootTrafficLocation) =>
        d.id === footTrafficTabState.locationId ? "marker2" : "marker3",
      sizeScale: 15,
      getPosition: (d: FootTrafficLocation) => d.coordinates,
      getSize: (d: FootTrafficLocation) =>
        d.id === footTrafficTabState.locationId ? 2.4 : 1.8,
    });
  }, [
    footTrafficTabState.locations,
    activeTab,
    footTrafficTabState.locationId,
  ]);

  const droppedPinLayer = useMemo<IconLayer>(() => {
    return new IconLayer({
      id: "droppedpinlayer",
      data: droppedPinState.pins,
      pickable: true,
      iconAtlas: iconAtlasImg,
      iconMapping: ICON_MAPPING,
      getIcon: () => "marker",
      sizeScale: 15,
      getPosition: (d: MapPin) => d.coordinates,
      getSize: () => 2.5,
      getColor: (d: MapPin) => d.color,
    });
  }, [droppedPinState.pins]);

  const pinContextMenuXY = useMemo<[number, number]>(() => {
    const viewport =
      droppedPinLayer.internalState?.viewport ??
      droppedPinLayer.context?.viewport;
    if (!droppedPinState.selectedPinId || !viewport) {
      return [0, 0];
    }
    const pin =
      droppedPinState.selectedPinId === SEARCH_PIN_ID
        ? droppedPinState.searchPin
        : droppedPinState.pins.find(
            (p) => p.id === droppedPinState.selectedPinId
          );
    if (!pin) {
      return [0, 0];
    }
    const [x, y] = viewport.project(pin.coordinates as [number, number]);
    return [x + 360, y];
  }, [droppedPinLayer, mapViewState, droppedPinState.selectedPinId]);

  const PolygonContextMenuXY = useMemo<[number, number]>(() => {
    if (selectedPolygon === null) {
      return [0, 0];
    }
    const viewport =
      droppedPinLayer.internalState?.viewport ??
      droppedPinLayer.context?.viewport;
    //calculate centorid of Polygon
    const coordinates = selectedPolygon.geometry.coordinates[0];
    let x_sum = 0;
    let y_sum = 0;

    for (let i = 0; i < coordinates.length; i++) {
      x_sum += coordinates[i][0];
      y_sum += coordinates[i][1];
    }

    const [x, y] = viewport.project([
      x_sum / coordinates.length,
      y_sum / coordinates.length,
    ] as [number, number]);

    return [x + 360, y];
  }, [selectedPolygon, mapViewState]);

  const searchPinLayer = useMemo<Nullable<IconLayer>>(() => {
    if (!droppedPinState.searchPin) {
      return null;
    }
    return new IconLayer({
      id: "searchpinlayer",
      data: [droppedPinState.searchPin],
      pickable: true,
      iconAtlas: iconAtlasImg,
      iconMapping: ICON_MAPPING,
      getIcon: () => "marker2",
      sizeScale: 15,
      getPosition: (d: MapPin) => d.coordinates,
      getSize: () => 2.5,
      getColor: (d: MapPin) => d.color,
    });
  }, [droppedPinState.searchPin]);

  const deckglLayers = useMemo<Layer[]>(() => {
    const layers = [
      ...geodataLayers,
      territoryLayer,
      ...geojsonLayers,
      isochroneLayer,
      hoverLayer,
      footTrafficIconLayer,
      PolygonLayer,
      droppedPinLayer,
      searchPinLayer,
      ...iconLayers,
    ];
    return layers.filter((l) => l !== null) as Layer[];
  }, [
    geodataLayers,
    geojsonLayers,
    territoryLayer,
    isochroneLayer,
    footTrafficIconLayer,
    droppedPinLayer,
    searchPinLayer,
    iconLayers,
    hoverLayer,
    PolygonLayer,
  ]);

  if (!demoEmail && appConfig.id === "app23overture") {
    return <Typeform setDemoEmail={setDemoEmail} />;
  }

  return (
    <div>
      {loading && <LoadingOverlay />}
      {hoverInfo && hoverInfo.object && hoverInfo.layer && (
        <LayerTooltip
          info={hoverInfo}
          appConfig={appConfig}
          layerState={layerState}
        />
      )}
      <PolygonContextMenu
        selectedPolygon={selectedPolygon}
        PolygonContextMenuXY={PolygonContextMenuXY}
        PolygonTabDispatch={PolygonTabDispatch}
        PolygonTabState={PolygonTabState}
        setSelectedPolygon={setSelectedPolygon}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
        isRenaming={isRenaming}
        setIsRenaming={setIsRenaming}
        appConfig={appConfig}
      />
      <PinContextMenu
        xy={pinContextMenuXY}
        state={droppedPinState}
        dispatch={droppedPinDispatch}
        layer={droppedPinLayer}
        evaluateTabDispatch={evaluateTabDispatch}
        evaluateTabState={evaluateTabState}
      />
      <div className="absolute right-1 bottom-6 z-10">
        {appConfig.layers.geodataLayers.map((layerConfig, idx) => {
          const layer = layerState.geodataLayers[layerConfig.id];
          if (layerConfig.id === "base" && activeTab === MenuTabs.Visitation)
            return null;
          if (
            layerConfig.id === "foottraffic" &&
            activeTab !== MenuTabs.Visitation
          )
            return null;
          if (!layer.activeField || !layer.data) return null;
          const field = layerConfig.fields[layer.activeField];
          return (
            <div key={idx} className="m-1">
              <ColorLegend
                title={field.name}
                colors={field.colorSet}
                intervals={layer.data.stats[field.id].intervals}
                transform={field.format}
              />
            </div>
          );
        })}
      </div>
      {(appConfig.id === "app23overture" || appConfig?.overture?.enable) && (
        <OvertureQuery
          temporaryIconLayer={temporaryIconLayer}
          setTemporaryIconLayer={setTemporaryLayer}
          appConfig={appConfig}
          setAppConfig={setAppConfig}
          layerDispatch={layerDispatch}
          demoEmail={demoEmail}
          selectedStateCode={selectedStateCode}
          savedIconLayers={savedIconLayers}
          setSavedIconLayers={setSavedIconLayers}
          setInitialViewState={setInitialViewState}
          mapViewState={mapViewState}
        />
      )}
      <div className="absolute bg-white text-gray-700 min-h-[200px] h-auto w-[360px] top-0 left-0 bottom-0 rounded-sm p-8 z-10 drop-shadow-md flex flex-col overflow-auto">
        <div className="flex flex-col">
          <div className="self-center">
            <img src={unearthAiLogo} height={32} width={244} alt="" />
          </div>
          <div className="my-6 mx-5 text-sm leading-6">
            <div className="font-bold text-sm mb-4">
              Select State for Demographic Layers:
            </div>
            <SelectMenu
              value={selectedStateCode}
              options={appConfig.states}
              labels={appConfig.stateNameMap}
              onChange={handleSelectUsState}
              multiple={activeTab === MenuTabs.Territory}
              useUpdated={appConfig.id === "app30restoration911"}
              selectedStateCode={selectedStateCode}
              aditionalStateCodes={aditionalStateCodes}
              setAditionalStateCodes={setAditionalStateCodes}
            />
          </div>
          <div className="self-center">
            <HeadingMenu tabs={menuTabs} onChange={setActiveTab} />
          </div>
          {activeTab === MenuTabs.Explore && (
            <ExploreTab
              setAppConfig={setAppConfig}
              setSavedIconLayers={setSavedIconLayers}
              appConfig={appConfig}
              layerState={layerState}
              layerDispatch={layerDispatch}
            />
          )}
          {activeTab === MenuTabs.Evaluate && (
            <EvaluateTab
              appConfig={appConfig}
              pins={droppedPinState.pins}
              state={evaluateTabState}
              dispatch={evaluateTabDispatch}
              droppedPinDispatch={droppedPinDispatch}
            />
          )}
          {activeTab === MenuTabs.Visitation && (
            <FootTrafficTab
              appConfig={appConfig}
              layerState={layerState}
              layerDispatch={layerDispatch}
              layerLoader={layerLoader}
              usStateCode={selectedStateCode}
              state={footTrafficTabState}
              dispatch={footTrafficTabDispatch}
            />
          )}
          {activeTab === MenuTabs.Polygon && (
            <PolygonTab
              appConfig={appConfig}
              state={PolygonTabState}
              dispatch={PolygonTabDispatch}
              selectedStateCode={selectedStateCode}
            />
          )}
          {activeTab === MenuTabs.Territory && (
            <TerritoryTab
              appConfig={appConfig}
              state={territoryTabState}
              dispatch={territoryTabDispatch}
              aditionalStateCodes={aditionalStateCodes}
            />
          )}
        </div>
        <div className="mt-auto border-t border-gray-100 py-2">
          {(appConfig.id === "app23overture" ||
            appConfig?.overture?.enable) && (
            <div
              className={`flex space-x-2 ${
                appConfig?.overture?.enable && "mb-3"
              }`}
            >
              <DiscordButton />
              <FAQButton />
            </div>
          )}
          {appConfig.id !== "app23overture" && <LogoutButton />}
        </div>
      </div>
      <div className="absolute top-2 right-4 drop-shadow-md z-10">
        <div className="flex flex-col gap-2 items-end">
          {appConfig.id !== "app23overture" &&
            appConfig?.geocoding_box?.enable !== false && (
              <GeoLocator
                mapboxAccessToken={MAPBOX_TOKEN}
                mapViewState={mapViewState}
                onViewStateChange={setInitialViewState}
                selectedPoi={selectedPoi}
                onSelectPoi={handleSelectPoi}
              />
            )}
          <ul className="space-y-2">
            <ToggleButtonGroup>
              <ToggleButton
                className="w-9 h-9"
                selected={isDroppingPin}
                setSelected={setIsDroppingPin}
              >
                <MapPinIcon className="inline-block w-5 h-5 mb-1" />
              </ToggleButton>
              {appConfig.polygonTerritory && !PolygonTabState.readOnly && (
                <ToggleButton
                  className="w-9 h-9"
                  selected={isDrawing}
                  setSelected={setIsDrawing}
                >
                  <PencilSquareIcon className="inline-block w-5 h-5 mb-1" />
                </ToggleButton>
              )}
            </ToggleButtonGroup>
          </ul>
        </div>
      </div>
      <DeckGL
        getCursor={() => {
          if (activeTab === MenuTabs.Territory && territoryTabState.editing) {
            return "crosshair";
          } else {
            return "grab";
          }
        }}
        style={{ left: "360px" }}
        width={"calc(100vw - 360px)"}
        controller={true}
        layers={deckglLayers}
        onHover={setHoverInfo}
        initialViewState={initialViewState}
        onViewStateChange={({ viewState }) => {
          setMapViewState(viewState as MapViewState);
        }}
        onDragStart={(info, e) => {
          if (
            info.layer &&
            info.layer.id === "droppedpinlayer" &&
            info.object
          ) {
            e.stopPropagation();
          }
          if (droppedPinState.selectedPinId) {
            droppedPinDispatch({
              type: DropPinActionType.ClearSelection,
              payload: {},
            });
          }
        }}
        onDrag={(info, e) => {
          if (
            info.layer &&
            info.layer.id === "droppedpinlayer" &&
            info.object
          ) {
            e.stopPropagation();
            const pin = info.object as MapPin;
            const coordinates = info.layer.unproject([
              e.center.x - 360,
              e.center.y,
            ]) as [number, number];
            droppedPinDispatch({
              type: DropPinActionType.ModifyPin,
              payload: {
                id: pin.id,
                coordinates,
              },
            });
          }
        }}
        onDragEnd={(info, e) => {
          if (
            info.layer &&
            info.layer.id === "droppedpinlayer" &&
            info.object
          ) {
            e.stopPropagation();
            const pin = info.object as MapPin;
            const coordinates = info.layer.unproject([
              e.center.x - 360,
              e.center.y,
            ]) as [number, number];
            droppedPinDispatch({
              type: DropPinActionType.ModifyPin,
              payload: {
                id: pin.id,
                coordinates,
              },
            });
          }
        }}
        onClick={(info, e) => {
          let clearContextMenu = true;
          let clearPolygonMenu = true;

          //condition to fix bug where Polygon rename closes when creating new Polygon
          if (
            info.layer &&
            info.layer.id === "Polygon-layer" &&
            info.object &&
            !isDrawing &&
            !isEditing &&
            isRenaming &&
            info.object.geometry.type === "Point"
          ) {
            clearPolygonMenu = false;
          }

          if (
            info.layer &&
            info.layer.id === "Polygon-layer" &&
            info.object &&
            info.object.geometry.type === "Polygon" &&
            !isDrawing &&
            !isEditing &&
            !isRenaming
          ) {
            setSelectedPolygon(info.object);
            PolygonTabDispatch({
              type: PolygonActionType.SetSelectedPolygon,
              payload: info.object,
            });
            clearPolygonMenu = false;
          } else if (
            info.layer &&
            isIconLayerId(info.layer.id) &&
            info.layer.id.includes("foottraffic") &&
            info.object &&
            info.object.id
          ) {
            footTrafficTabDispatch({
              type: FootTrafficTabActionType.SetLocationId,
              payload: info.object.id,
            });
          } else if (
            info.layer &&
            (info.layer.id === "droppedpinlayer" ||
              info.layer.id === "searchpinlayer") &&
            info.object &&
            info.object.id
          ) {
            clearContextMenu = false;
            const pin = info.object as MapPin;
            droppedPinDispatch({
              type: DropPinActionType.SelectPin,
              payload: {
                id: pin.id,
              },
            });
          } else if (
            isDroppingPin &&
            info.coordinate &&
            info.coordinate.length >= 2 &&
            e.type === "click"
          ) {
            footTrafficTabDispatch({
              type: FootTrafficTabActionType.SetLocationId,
              payload: undefined,
            });
            handleDropPin(info.coordinate as Position);
          } else if (
            activeTab === MenuTabs.Territory &&
            info.layer &&
            info.layer.id.startsWith("fixed|territory") &&
            info.object &&
            info.object.properties &&
            info.object.properties.geoid
          ) {
            if (territoryTabState.editing) {
              territoryTabDispatch({
                type: TerritoryTabActionType.ToggleEditTerritoryCode,
                payload: info.object.properties.geoid,
              });
            } else {
              const territory = territoryTabState.territories.find((t) => {
                return t.geoids.includes(info.object!.properties!.geoid);
              });

              if (!territory) return;

              territoryTabDispatch({
                type: TerritoryTabActionType.SelectTerritory,
                payload: territory.id,
              });
            }
          }
          if (clearContextMenu) {
            droppedPinDispatch({
              type: DropPinActionType.ClearSelection,
              payload: {},
            });
          }
          if (clearPolygonMenu) {
            setSelectedPolygon(null);
            setIsEditing(false);
            setIsRenaming(false);
          }
        }}
      >
        <MapGL
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/streets-v12"
          attributionControl={
            appConfig.id !== "app23overture" && !appConfig?.overture?.enable
          }
        >
          {(appConfig.id === "app23overture" ||
            appConfig?.overture?.enable) && (
            <AttributionControl customAttribution="© Overture Maps Foundation" />
          )}
        </MapGL>
      </DeckGL>
      {evaluateTabState.summary.length > 1 && (
        <div className="absolute bg-white text-gray-700 h-[50vh] w-auto bottom-0 right-0 left-[360px] rounded-sm px-8 z-10 drop-shadow-md border border-gray-200 overflow-auto">
          <LocationSummaryTable
            appConfig={appConfig}
            pins={droppedPinState.pins}
            summaries={evaluateTabState.summary}
            summaryFields={appConfig.summary?.fields.concat(
              appConfig.summary.score ? ["score"] : []
            )}
            summaryPois={appConfig.summary?.pois?.map((poi) => poi.name)}
            onClose={handleCloseLocationSummary}
          />
        </div>
      )}
    </div>
  );
};

interface LayerToolTipProps {
  info: PickingInfo;
  appConfig: AppConfig;
  layerState: LayerState;
}

const LayerTooltip: FC<LayerToolTipProps> = ({
  info,
  appConfig,
  layerState,
}) => {
  if (isGeodataLayerId(info.layer!.id)) {
    const layerId = fromGeodataLayerId(info.layer!.id);
    const layer = layerState.geodataLayers[layerId];
    return (
      <GeoDataLayerTooltip info={info} appConfig={appConfig} layer={layer} />
    );
  } else if (isGeojsonLayerId(info.layer!.id)) {
    const layerId = fromGeojsonLayerId(info.layer!.id);
    const layer = layerState.geojsonLayers[layerId];
    return <GeojsonLayerTooltip info={info} layer={layer} />;
  } else if (isIconLayerId(info.layer!.id)) {
    const layerId = fromIconLayerId(info.layer!.id);
    if (layerId === "foottraffic") {
      return (
        <IconLayerTooltip
          info={info}
          layer={{
            id: "foottraffic",
            data: [],
            visible: true,
          }}
        />
      );
    }
    const layer = layerState.iconLayers[layerId];
    return <IconLayerTooltip info={info} layer={layer} />;
  } else if (isFixedTerritoryLayerId(info.layer!.id)) {
    const { object, x, y } = info;
    if (!object.properties || !object.properties.geoid) {
      return null;
    }
    const title = `Zip code: ${object.properties.geoid}`;
    const content = [];
    const baseLayer = layerState.geodataLayers["base"];
    if (baseLayer && baseLayer.data?.fields) {
      const fields = baseLayer.data.fields[object.properties.geoid];
      if (fields) {
        if ("total_pop" in fields) {
          content.push(`Population: ${formatInt(fields["total_pop"])}`);
        }
        if ("restaurant_count" in fields) {
          content.push(`Restaurants: ${formatInt(fields["restaurant_count"])}`);
        }
      }
    }
    return <BasicTooltip x={x} y={y} title={title} content={content} />;
  } else {
    return null;
  }
};

const PolygonContextMenu: FC<{
  selectedPolygon: Feature<Polygon, GeoJsonProperties> | null;
  PolygonContextMenuXY: [number, number];
  PolygonTabState: PolygonTabState;
  PolygonTabDispatch: React.Dispatch<PolygonAction>;
  setSelectedPolygon: React.Dispatch<
    React.SetStateAction<Feature<Polygon, GeoJsonProperties> | null>
  >;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  isRenaming: boolean;
  setIsRenaming: React.Dispatch<React.SetStateAction<boolean>>;
  appConfig: AppConfig;
}> = ({
  selectedPolygon,
  PolygonContextMenuXY,
  PolygonTabState,
  setIsEditing,
  isEditing,
  isRenaming,
  setIsRenaming,
  setSelectedPolygon,
  PolygonTabDispatch,
}) => {
  if (PolygonTabState.readOnly) {
    return null;
  }

  const [renameInput, setRenameInput] = useState<string>("");
  if (selectedPolygon === null) return null;
  if (isEditing) return null;

  const [x, y] = PolygonContextMenuXY;

  const handleRemove = () => {
    const groupIndex = PolygonTabState.groups.findIndex((group) => {
      return group.features
        .map((feature) => feature?.properties?.color)
        .includes(selectedPolygon?.properties?.color);
    });

    const featureIndex = PolygonTabState.groups[groupIndex].features.findIndex(
      (feature) => {
        return (
          feature?.properties?.color === selectedPolygon?.properties?.color
        );
      }
    );

    PolygonTabDispatch({
      type: PolygonActionType.Remove,
      payload: {
        group: PolygonTabState.groups[groupIndex],
        feature: PolygonTabState.groups[groupIndex].features[featureIndex],
      },
    });

    setSelectedPolygon(null);
  };

  const handleRename = () => {
    PolygonTabDispatch({
      type: PolygonActionType.Rename,
      payload: {
        feature: selectedPolygon,
        name: renameInput,
      },
    });

    setSelectedPolygon(null);
    setRenameInput("");
  };

  if (isRenaming) {
    return (
      <div
        className={classNames(
          "absolute bg-white border border-gray-300 rounded-lg shadow-md z-10 flex flex-col px-6 pb-2 pt-1 justify-between"
        )}
        style={{ top: y, left: x }}
      >
        <div className="text-lg text-slate-500 my-1">Territory Name</div>
        <input
          className="rounded-md border-2 border-slate-200"
          type="text"
          placeholder="Give this territory a name"
          defaultValue={selectedPolygon?.properties?.name}
          onChange={(e) => setRenameInput(e.target.value)}
        ></input>
        <button
          className="rounded-md border bg-blue-100 text-lg text-indigo-600 mt-2 mb-1"
          onClick={handleRename}
        >
          Rename
        </button>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "absolute bg-white border border-gray-300 rounded-md shadow-md z-10"
      )}
      style={{ top: y, left: x }}
    >
      <div className="flex flex-col">
        <button
          className={classNames(
            "text-red-500 hover:bg-red-600 hover:text-white p-2 border-t border-t-gray-100",
            "rounded-b-md"
          )}
          onClick={() => setIsRenaming(true)}
        >
          Rename
        </button>
        <button
          className={classNames(
            "text-red-500 hover:bg-red-600 hover:text-white p-2 border-t border-t-gray-100",
            "rounded-b-md"
          )}
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
        <button
          className={classNames(
            "text-red-500 hover:bg-red-600 hover:text-white p-2 border-t border-t-gray-100",
            "rounded-b-md"
          )}
          onClick={handleRemove}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

const PinContextMenu: FC<{
  xy: [number, number];
  state: DroppedPinState;
  dispatch: Dispatch<DropPinAction>;
  layer: Layer;
  evaluateTabDispatch: Dispatch<EvaluateTabAction>;
  evaluateTabState: EvaluateTabState;
}> = ({ xy, state, dispatch, evaluateTabState, evaluateTabDispatch }) => {
  if (!state.selectedPinId) return null;

  let pin: MapPin | undefined;
  let isSearchPin = false;
  if (state.selectedPinId === SEARCH_PIN_ID) {
    pin = state.searchPin;
    isSearchPin = true;
  } else {
    pin = state.pins.find((p) => p.id === state.selectedPinId);
  }
  if (!pin) return null;
  const [x, y] = xy;
  const top = y + 4;
  const left = x + 2;

  const handleRemove = () => {
    if (isSearchPin) {
      dispatch({
        type: DropPinActionType.UnsetSearchPin,
        payload: {},
      });
    } else {
      dispatch({
        type: DropPinActionType.RemovePin,
        payload: {
          id: pin!.id,
        },
      });

      if (evaluateTabState.summary.length) {
        evaluateTabDispatch({
          type: EvaluateTabActionType.RemoveSummary,
          payload: {
            id: pin!.id,
          },
        });
      }
    }
  };
  const handleAdd = () => {
    if (!isSearchPin) return;
    dispatch({
      type: DropPinActionType.ConvertSearchPin,
      payload: {},
    });
  };
  return (
    <div
      className={classNames(
        "absolute bg-white border border-gray-300 rounded-md shadow-md z-10"
      )}
      style={{ top, left }}
    >
      <div className="flex flex-col">
        {isSearchPin && (
          <button
            className="p-2 hover:bg-indigo-600 hover:text-white rounded-t-md"
            onClick={handleAdd}
          >
            Add pin
          </button>
        )}
        <button
          className={classNames(
            "text-red-500 hover:bg-red-600 hover:text-white p-2 border-t border-t-gray-100",
            isSearchPin ? "rounded-b-md" : "rounded-md"
          )}
          onClick={handleRemove}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default Template02;
