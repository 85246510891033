import React, { FC } from "react";
import Template01 from "../../templates/template01";
import { DataField } from "../../templates/template01/data";
import { UsState } from "../../../utils/us";

export const LAYERS_FIELD_LIST = [
  DataField.Pop5yGrow,
  DataField.PopDensity,
  DataField.MedianAge,
  DataField.MedianHHIncome,
  DataField.JobDensity,
];

const SUMMARY_FIELD_LIST = [
  DataField.MedianHHIncome,
  DataField.HomeVal,
  DataField.PopDensity,
  DataField.Pop5yGrow,
  DataField.JobDensity,
  DataField.PopCount,
  DataField.JobCount,
];

const US_STATES = [
  UsState.Florida,
  UsState.Georgia,
  UsState.NorthCarolina,
  UsState.SouthCarolina,
  UsState.Texas,
];

const App04YC: FC<{}> = () => {
  return (
    <Template01
      title="App04YC"
      usStates={US_STATES}
      layerFields={LAYERS_FIELD_LIST}
      summaryFields={SUMMARY_FIELD_LIST}
      layerConfig={{ iconLayers: [] }}
    />
  );
};

export default App04YC;
