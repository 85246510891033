import React, { FC, useMemo } from "react";
import { useDataCache } from "../../../hooks/useDataCache";
import { US_STATE_CODE_MAP, UsState } from "../../../utils/us";
import Template01 from "../../templates/template01";
import {
  DataLoadResults,
  IDataProvider,
} from "../../templates/template01/IDataProvider";
import { DataField } from "../../templates/template01/data";

export const LAYERS_FIELD_LIST = [
  DataField.Fpop2040,
  DataField.Fpop2040p,
  DataField.TotalPop,
  DataField.BeautySpend,
  DataField.HhIncome2544,
  DataField.HhChildP,
  DataField.Top5PopP,
  DataField.PopulationWhite,
  DataField.PopulationHispanic,
  DataField.PopulationBlack,
  DataField.PopulationAsian,
];

const SUMMARY_FIELD_LIST = [
  DataField.Fpop2040,
  DataField.Fpop2040p,
  DataField.TotalPop,
  DataField.BeautySpend,
  DataField.HhIncome2544,
  DataField.HhChildP,
  DataField.Top5Pop,
  DataField.Top5PopP,
  DataField.PopulationWhite,
  DataField.PopulationHispanic,
  DataField.PopulationBlack,
  DataField.PopulationAsian,
];

const BASE_SHAPES_URL = "https://dfb6p47bmcaq.cloudfront.net";
const BASE_APP_URL = "https://d205bfh24nfn0n.cloudfront.net";

const US_STATES = [
  UsState.Alabama,
  UsState.Arizona,
  UsState.Arkansas,
  UsState.California,
  UsState.Colorado,
  // UsState.Connecticut,
  UsState.Delaware,
  UsState.DistrictOfColumbia,
  UsState.Florida,
  UsState.Georgia,
  UsState.Hawaii,
  UsState.Idaho,
  UsState.Illinois,
  UsState.Indiana,
  UsState.Iowa,
  UsState.Kansas,
  UsState.Kentucky,
  UsState.Louisiana,
  UsState.Maine,
  UsState.Maryland,
  UsState.Massachusetts,
  UsState.Michigan,
  UsState.Minnesota,
  UsState.Mississippi,
  UsState.Missouri,
  UsState.Montana,
  UsState.Nebraska,
  UsState.Nevada,
  UsState.NewHampshire,
  UsState.NewJersey,
  UsState.NewMexico,
  UsState.NewYork,
  UsState.NorthCarolina,
  UsState.NorthDakota,
  UsState.Ohio,
  UsState.Oklahoma,
  UsState.Oregon,
  UsState.Pennsylvania,
  UsState.RhodeIsland,
  UsState.SouthCarolina,
  UsState.SouthDakota,
  UsState.Tennessee,
  UsState.Texas,
  UsState.Utah,
  UsState.Vermont,
  UsState.Virginia,
  UsState.Washington,
  UsState.WestVirginia,
  UsState.Wisconsin,
  UsState.Wyoming,
];

class AppDataProvider implements IDataProvider {
  loadDataset: (dataset: string, url: string) => Promise<any>;
  getDataset: (dataset: string) => any;

  constructor(
    loadDataset: (dataset: string, url: string) => Promise<any>,
    getDataset: (dataset: string) => any
  ) {
    this.loadDataset = loadDataset;
    this.getDataset = getDataset;
  }

  getGeoDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `bg/tl_2022_${code}_bg_001_mc.geojson.gz`;
  }

  getDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `usa_bg_data_${code}.json.gz`;
  }

  getStatsDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `usa_bg_data_stats_${code}.json`;
  }

  async load(usState: UsState): Promise<DataLoadResults> {
    const geoDatasetName = this.getGeoDatasetName(usState);
    const geoUrl = `${BASE_SHAPES_URL}/${geoDatasetName}`;
    const geo = await this.loadDataset(geoDatasetName, geoUrl);
    const dataDatasetName = this.getDatasetName(usState);
    const dataUrl = `${BASE_APP_URL}/v2/${dataDatasetName}`;
    const data = await this.loadDataset(dataDatasetName, dataUrl);
    const statsDatasetName = this.getStatsDatasetName(usState);
    const statsUrl = `${BASE_APP_URL}/v2/${statsDatasetName}`;
    const stats = await this.loadDataset(statsDatasetName, statsUrl);
    return { geo, data, stats };
  }
}

const App05HelloSugar: FC<{}> = () => {
  const { loadDataset, getDataset } = useDataCache();
  const dataProvider = useMemo(
    () => new AppDataProvider(loadDataset, getDataset),
    []
  );
  return (
    <Template01
      title="App05HelloSugar"
      usStates={US_STATES}
      layerFields={LAYERS_FIELD_LIST}
      summaryFields={SUMMARY_FIELD_LIST}
      fieldGroups={[]}
      dataProvider={dataProvider}
      useApi={true}
      evaluateParams={{ minutes: 14, profile: "driving" }}
      showPois={true}
      storeAreaUrl={(code: string) =>
        `${BASE_APP_URL}/stores/stores-area-${code}.geojson`
      }
      storePointUrl={(code: string) =>
        `${BASE_APP_URL}/stores/stores-point-${code}.json`
      }
      layerConfig={{ iconLayers: [] }}
    />
  );
};

export default App05HelloSugar;
