import { XMarkIcon, CubeIcon } from "@heroicons/react/24/solid";
import {
  CubeIcon as CubeIconOutline,
  MapIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import fontColorContrast from "font-color-contrast";
import zipState from "zip-state";
import mixpanel from "mixpanel-browser";
import React, { FC, useEffect, useMemo } from "react";
import { Button } from "../../../components/Button";
import { AppConfig } from "../../../utils/appconfig";
import { randomTransparentColor, toHex } from "../../../utils/colors";
import {
  US_POSTAL_CODE_TO_FIPS,
  CANADA_ZIP_CODE_TO_POSTAL_CODE,
} from "../../../utils/us";
import {
  API_URL,
  TERRITORRY_FEATURES_COLORS_BY_TAGS_911,
  TerritoryFeaturesColorsByTagsKeys911,
} from "./constants";

const US_FIPS_TO_POSTAL_CODE = (code: string) =>
  [...US_POSTAL_CODE_TO_FIPS].find(([, value]) => code === value)![0];

type TerritorySummary = Record<string, number>;

interface Territory {
  id: number;
  name: string;
  color: string;
  geoids: string[];
  primary_state?: string;
  tag?: string;
}

type TerritoryWithSummary = Territory & {
  summary?: TerritorySummary;
};

interface CreateTerritoryParams {
  name: string;
  color: string;
  codes: string[];
}

export interface TerritoryTabState {
  territories: TerritoryWithSummary[];
  selectedTerritoryId: number;
  editing: boolean;
  editTerritory: Territory;
  unavailableGeoids: Set<string>;
}

export function initialTerritoryTabState(): TerritoryTabState {
  return {
    territories: [],
    selectedTerritoryId: 0,
    editing: false,
    editTerritory: {
      id: 0,
      name: "",
      color: "",
      geoids: [],
    },
    unavailableGeoids: new Set(),
  };
}

export enum TerritoryTabActionType {
  SetTerritories = "SetTerritories",
  SelectTerritory = "SelectTerritory",
  StartEditing = "StartEditing",
  DoneEditing = "DoneEditing",
  EditTerritoryName = "EditTerritoryName",
  ToggleEditTerritoryCode = "ToggleEditTerritoryCode",
  ToggleTag = "ToggleTag",
}

export interface TerritoryTabAction {
  type: TerritoryTabActionType;
  payload?: any;
}

export function territoryTabReducer(
  state: TerritoryTabState,
  action: TerritoryTabAction
): TerritoryTabState {
  switch (action.type) {
    case TerritoryTabActionType.SetTerritories:
      return {
        ...state,
        territories: action.payload,
      };
    case TerritoryTabActionType.SelectTerritory:
      return {
        ...state,
        selectedTerritoryId: action.payload,
        editing: false,
        editTerritory: {
          id: 0,
          color: "",
          name: "",
          geoids: [],
        },
      };
    case TerritoryTabActionType.StartEditing: {
      const unavailableGeoids = new Set<string>();
      state.territories
        .filter((t) => t.id !== action.payload.id)
        .forEach((t) => {
          t.geoids.forEach((geoid) => unavailableGeoids.add(geoid));
        });
      return {
        ...state,
        editing: true,
        editTerritory: action.payload,
        unavailableGeoids,
      };
    }

    case TerritoryTabActionType.DoneEditing:
      return {
        ...state,
        editing: false,
        editTerritory: {
          id: 0,
          color: "",
          name: "",
          geoids: [],
          tag: "",
          primary_state: "",
        },
        unavailableGeoids: new Set(),
      };
    case TerritoryTabActionType.EditTerritoryName:
      return {
        ...state,
        editTerritory: {
          ...state.editTerritory,
          name: action.payload,
        },
      };
    case TerritoryTabActionType.ToggleEditTerritoryCode: {
      const index = state.editTerritory.geoids.indexOf(action.payload);
      const actualIndex = state.territories.findIndex(
        (t) => t.id === state.editTerritory.id
      );

      if (index === -1) {
        if (state.unavailableGeoids.has(action.payload)) {
          return state;
        }

        if (state.territories[actualIndex])
          state.territories[actualIndex].geoids.push(action.payload);

        state.editTerritory.primary_state = [
          ...state.editTerritory.geoids,
          action.payload,
        ]
          .map(
            (geoid) =>
              zipState(geoid) || CANADA_ZIP_CODE_TO_POSTAL_CODE.get(geoid)
          )
          .reduce<any>(
            (acc, curr) => {
              const count = [
                ...state.editTerritory.geoids,
                action.payload,
              ].filter(
                (geoid) =>
                  zipState(geoid) === curr ||
                  CANADA_ZIP_CODE_TO_POSTAL_CODE.get(geoid) === curr
              ).length;
              if (count > acc.count) {
                return {
                  count,
                  state: curr,
                };
              }
              return acc;
            },
            { count: 0, state: "" }
          ).state;

        return {
          ...state,
          editTerritory: {
            ...state.editTerritory,
            geoids: [...state.editTerritory.geoids, action.payload],
          },
        };
      } else {
        if (state.territories[actualIndex])
          state.territories[actualIndex].geoids = state.territories[
            actualIndex
          ].geoids.filter((geoid) => geoid !== action.payload);

        state.editTerritory.primary_state = [...state.editTerritory.geoids]
          .filter((geoid) => geoid !== action.payload)
          .map(
            (geoid) =>
              zipState(geoid) || CANADA_ZIP_CODE_TO_POSTAL_CODE.get(geoid)
          )
          .reduce<any>(
            (acc, curr) => {
              const count = [...state.editTerritory.geoids]
                .filter((geoid) => geoid !== action.payload)
                .filter(
                  (geoid) =>
                    zipState(geoid) === curr ||
                    CANADA_ZIP_CODE_TO_POSTAL_CODE.get(geoid) === curr
                ).length;
              if (count > acc.count) {
                return {
                  count,
                  state: curr,
                };
              }
              return acc;
            },
            { count: 0, state: "" }
          ).state;

        return {
          ...state,
          editTerritory: {
            ...state.editTerritory,
            geoids: state.editTerritory.geoids.filter(
              (geoid) => geoid !== action.payload
            ),
          },
        };
      }
    }
    case TerritoryTabActionType.ToggleTag: {
      const {
        territory,
        appConfig,
      }: { territory: Territory; appConfig: AppConfig } = action.payload;

      const tag = territory.tag || "Pending";

      const tagKeys = Object.keys(TERRITORRY_FEATURES_COLORS_BY_TAGS_911);

      const tagIndex = tagKeys.findIndex((t) => t === tag);

      const nextTag = tagKeys[(tagIndex + 1) % tagKeys.length];

      const index = state.territories.findIndex((t) => t.id === territory.id);

      state.territories[index].tag = nextTag;

      fetch(`${getTerritoriesUrl(appConfig)}/${state.territories[index].id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state.territories[index]),
      });

      return {
        ...state,
      };
    }
    default:
      return state;
  }
}

const getTerritoriesUrl = (appConfig: AppConfig) =>
  `${API_URL}/apps/${appConfig.id}/geoid-territories`;

const TerritoryTab: FC<{
  appConfig: AppConfig;
  state: TerritoryTabState;
  dispatch: React.Dispatch<TerritoryTabAction>;
  aditionalStateCodes: string[];
}> = ({ appConfig, state, dispatch, aditionalStateCodes }) => {
  const fetchTerritories = async () => {
    const res = await fetch(getTerritoriesUrl(appConfig));
    const data = await res.json();
    const territories = data.territories as TerritoryWithSummary[];
    dispatch({
      type: TerritoryTabActionType.SetTerritories,
      payload: territories,
    });
  };

  useEffect(() => {
    fetchTerritories();
  }, []);

  const handleSelectTerritory = (territory: Territory) => {
    dispatch({
      type: TerritoryTabActionType.SelectTerritory,
      payload: territory.id,
    });
  };

  const handleEditTerritory = () => {
    if (!selectedTerritory) {
      return;
    }
    const editTerritory: Territory = {
      id: selectedTerritory.id,
      name: selectedTerritory.name,
      color: selectedTerritory.color,
      geoids: selectedTerritory.geoids,
      tag: selectedTerritory.tag,
      primary_state: selectedTerritory.primary_state,
    };

    dispatch({
      type: TerritoryTabActionType.StartEditing,
      payload: editTerritory,
    });
  };

  const handleCreateNew = () => {
    const color = randomTransparentColor(127);
    const newTerritory: Territory = {
      id: 0,
      name: "",
      color: toHex(color),
      tag: appConfig.id === "app30restoration911" ? "pending" : undefined,
      primary_state: US_FIPS_TO_POSTAL_CODE(aditionalStateCodes[0]),
      geoids: [],
    };
    dispatch({
      type: TerritoryTabActionType.StartEditing,
      payload: newTerritory,
    });
  };

  const handleCancelEdit = () => {
    dispatch({
      type: TerritoryTabActionType.DoneEditing,
    });
  };

  const handleSave = async () => {
    const url = getTerritoriesUrl(appConfig);
    if (state.editTerritory.id) {
      await fetch(`${url}/${state.editTerritory.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state.editTerritory),
      });
    } else {
      await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(state.editTerritory),
      });

      mixpanel.track("Save Existing Zip Territory", {
        app: appConfig.id,
        name: state.editTerritory.name,
        zip_codes: state.editTerritory.geoids,
        primary_state: state.editTerritory.primary_state,
      });
    }
    dispatch({
      type: TerritoryTabActionType.DoneEditing,
    });
    mixpanel.track("Create Zip Territory", {
      app: appConfig.id,
      name: state.editTerritory.name,
      zip_codes: state.editTerritory.geoids,
      primary_state: state.editTerritory.primary_state,
    });
    fetchTerritories();
  };

  const handleDelete = async () => {
    if (!state.editTerritory.id) {
      return;
    }
    const url = getTerritoriesUrl(appConfig);
    await fetch(`${url}/${state.selectedTerritoryId}`, {
      method: "DELETE",
    });
    dispatch({
      type: TerritoryTabActionType.DoneEditing,
    });
    fetchTerritories();
  };

  const isTerritoryValid = useMemo(() => {
    return (
      state.editTerritory.name.length > 0 &&
      state.editTerritory.geoids.length > 0 &&
      state.editTerritory.color.length > 0
    );
  }, [state.editTerritory]);

  const selectedTerritory = useMemo<Nullable<TerritoryWithSummary>>(() => {
    return (
      state.territories.find((t) => t.id === state.selectedTerritoryId) ?? null
    );
  }, [state.territories, state.selectedTerritoryId]);

  return (
    <>
      <h2 className="font-bold text-xl mt-4">Territories</h2>
      <div className="text-sm leading-6 text-gray-900 mt-2 mb-5">
        {appConfig.id !== "app30restoration911" &&
          state.territories.map((territory, idx) => (
            <div
              key={idx}
              className={classNames(
                "relative group hover:bg-indigo-100 rounded-sm",
                territory.id === state.selectedTerritoryId
                  ? "bg-indigo-100"
                  : ""
              )}
            >
              <button onClick={() => handleSelectTerritory(territory)}>
                <MapIcon
                  className="inline-block w-5 h-5 mr-1 mb-1"
                  style={{ color: territory.color }}
                />
                {territory.name}
              </button>
            </div>
          ))}
        {appConfig.id === "app30restoration911" &&
          state.territories
            .filter((territory) => {
              return (
                territory.primary_state &&
                aditionalStateCodes
                  .flat()
                  .map((code: string) => US_FIPS_TO_POSTAL_CODE(code))
                  .includes(territory.primary_state)
              );
            })
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
            .map((territory, idx) => (
              <div
                key={idx}
                onClick={() => handleSelectTerritory(territory)}
                className={classNames(
                  "py-2 px-3 rounded-lg flex items-center cursor-pointer duration-300 relative mb-3",
                  territory.id === state.selectedTerritoryId
                    ? "bg-indigo-100"
                    : ""
                )}
              >
                <CubeIcon
                  className="w-4 h-4 mr-3 peer"
                  onClick={() => {
                    dispatch({
                      type: TerritoryTabActionType.ToggleTag,
                      payload: {
                        territory,
                        appConfig,
                      },
                    });
                  }}
                  style={{
                    color:
                      appConfig.id === "app30restoration911" && territory.tag
                        ? TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                            territory.tag as TerritoryFeaturesColorsByTagsKeys911
                          ]
                        : territory.color,
                  }}
                />
                <div className="flex-1 text-gray-600 text-sm peer-hover:opacity-0 duration-500">
                  {territory.name}
                </div>
                <div
                  className="absolute left-0 h-full w-[34px] rounded-l-lg opacity-0 peer-hover:opacity-100 duration-500 z-0 flex items-center pointer-events-none"
                  style={{
                    backgroundColor:
                      TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                        (territory.tag as TerritoryFeaturesColorsByTagsKeys911) ||
                          "Pending"
                      ],
                  }}
                >
                  <CubeIconOutline
                    color={fontColorContrast(
                      TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                        (territory.tag as TerritoryFeaturesColorsByTagsKeys911) ||
                          "Pending"
                      ],
                      0.7
                    )}
                    className="w-4 h-4 mr-1.5 ml-3 duration-500"
                  />
                </div>
                <div
                  className="absolute text-sm left-[34px] opacity-0 peer-hover:opacity-100 h-full flex items-center pr-4 rounded-r-full duration-500"
                  style={{
                    backgroundColor:
                      TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                        (territory.tag as TerritoryFeaturesColorsByTagsKeys911) ||
                          "Pending"
                      ],
                    color: fontColorContrast(
                      TERRITORRY_FEATURES_COLORS_BY_TAGS_911[
                        (territory.tag as TerritoryFeaturesColorsByTagsKeys911) ||
                          "Pending"
                      ],
                      0.7
                    ),
                  }}
                >
                  {(territory.tag || "Pending")
                    .split(" ")[0]
                    .slice(0, 1)
                    .toUpperCase() +
                    (territory.tag || "Pending").split(" ")[0].slice(1)}
                </div>
              </div>
            ))}
      </div>
      {!state.editing && (
        <>
          <div className="flex flex-row mb-2">
            <Button
              disabled={!state.selectedTerritoryId}
              onClick={handleEditTerritory}
              className="w-full mr-2"
            >
              Edit
            </Button>
            <Button onClick={handleCreateNew} className="w-full">
              Create New
            </Button>
          </div>
          {selectedTerritory && selectedTerritory.summary && (
            <div>
              <h3 className="font-bold text-lg mt-4">Summary</h3>
              <div className="text-sm leading-6 text-gray-900 my-2">
                {Object.entries(selectedTerritory.summary).map(
                  ([key, value]) => (
                    <div key={key}>
                      {appConfig.fields[key].name}:{" "}
                      <span className="font-bold">
                        {appConfig.fields[key].format(value)}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </>
      )}
      {state.editing && (
        <div>
          <div className="flex flex-row mb-2">
            <Button
              disabled={!isTerritoryValid}
              onClick={handleSave}
              className="w-full mr-1"
            >
              Save
            </Button>
            <Button onClick={handleCancelEdit} className="w-full">
              Cancel
            </Button>
          </div>
          <input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            type="text"
            placeholder="Enter territory name"
            value={state.editTerritory.name}
            onChange={(e) =>
              dispatch({
                type: TerritoryTabActionType.EditTerritoryName,
                payload: e.target.value,
              })
            }
          />
          {state.editTerritory.geoids.length > 0 && (
            <div className="text-sm leading-6 text-gray-900 my-2">
              <div className="font-bold">Codes: </div>
              {state.editTerritory.geoids.map((code, idx) => (
                <div key={idx} className="relative group rounded-sm">
                  <div>
                    <button
                      onClick={() =>
                        dispatch({
                          type: TerritoryTabActionType.ToggleEditTerritoryCode,
                          payload: code,
                        })
                      }
                    >
                      <XMarkIcon
                        className="inline-block w-5 h-5 mr-1 mb-1"
                        style={{ color: `rgb(205,85,85)` }}
                      />
                    </button>
                    {code}
                  </div>
                </div>
              ))}
            </div>
          )}
          {!!state.editTerritory.id && (
            <Button onClick={handleDelete} className="my-4" destructive>
              Remove territory
            </Button>
          )}
        </div>
      )}
    </>
  );
};

export default TerritoryTab;
