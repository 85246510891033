import React, { FC } from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadingScreen: FC<{}> = () => {
  return (
    <div className="flex w-full h-full">
      <div className="m-auto">
        <LoadingSpinner />
      </div>
    </div>
  );
};

export default LoadingScreen;
