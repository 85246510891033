import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { FC, Fragment, useEffect, useState } from "react";
import Select, {
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from "react-select";

interface props {
  value?: string;
  options: string[];
  labels?: Record<string, string>;
  selectedStateCode?: string;
  onChange: (item: string | string[]) => void;
  aditionalStateCodes: string[];
  setAditionalStateCodes: React.Dispatch<React.SetStateAction<string[]>>;
  multiple?: boolean;
  useUpdated?: boolean;
}

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpDownIcon
        className="h-5 w-5 text-gray-400 hover:text-[#5046e5] duration-300 cursor-pointer"
        aria-hidden="true"
      />{" "}
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps<any, true>) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  return (
    <div
      {...restInnerProps}
      ref={ref}
      style={getStyles("clearIndicator", props) as any}
    >
      <div className="mr-0.5">
        <XMarkIcon
          className="h-5 w-5 text-gray-400 hover:text-[#5046e5] duration-300 cursor-pointer"
          aria-hidden="true"
        />
      </div>
    </div>
  );
};

const SelectMenu: FC<props> = ({
  value,
  options,
  labels,
  onChange,
  multiple,
  useUpdated,
  selectedStateCode,
  aditionalStateCodes,
  setAditionalStateCodes,
}) => {
  const label = value ? (labels ? labels[value] : value) : "Select";

  useEffect(() => {
    if (selectedStateCode && !multiple) {
      setAditionalStateCodes([selectedStateCode]);
    }
  }, [selectedStateCode]);

  if (useUpdated) {
    return (
      <div className="relative w-[120%] -left-[10%]">
        <Select
          classNamePrefix={"state-dropdown"}
          components={{ DropdownIndicator, ClearIndicator }}
          styles={{
            multiValue: (base) => ({
              ...base,
              backgroundColor: "#ffffff",
              transitionDuration: "0.3s",
              color: "#171e2d",
              ":hover": {
                color: "#5046e5",
              },
              marginTop: "0",
              marginBottom: "0",
            }),
            multiValueLabel: (base) => ({
              ...base,
              fontSize: "0.875rem",
              color: "inherit",
              paddingTop: "0",
              paddingBottom: "0",
            }),
            multiValueRemove: (base) => ({
              ...base,
              color: "inherit",
              ":hover": {
                backgroundColor: "#ffffff",
              },
              paddingTop: "0.15rem",
              paddingLeft: "0",
            }),
            container: (base) => ({
              ...base,
              width: "100%",
              borderRadius: "0.375rem",
              border: "1.5px solid #d1d5db",
              transitionDuration: "0.3s",
              backgroundColor: "#ffffff",
              color: "#ffffff",
              ":hover": {
                borderColor: "#5046e5",
              },
              ":focus": {
                borderColor: "#5046e5",
              },
            }),
            control: (base) => ({
              ...base,
              border: "none",
              boxShadow: "none",
            }),
            menu: (base) => ({
              ...base,
              borderRadius: "0.375rem",
              border: "1.5px solid #d1d5db",
              boxShadow: "none",
            }),
            option: (base) => ({
              ...base,
              color: "#374151",
              ":hover": {
                backgroundColor: "#e5e7eb",
              },
              ":active": {
                backgroundColor: "#e5e7eb",
              },
            }),
            placeholder: (base) => ({
              ...base,
              color: "#374151",
            }),
            singleValue: (base) => ({
              ...base,
              color: "#374151",
            }),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: "0.35rem",
              paddingTop: "0.15rem",
              paddingBottom: "0.15rem",
            }),
            indicatorSeparator: (base) => ({
              ...base,
              display: "none",
            }),
            indicatorsContainer: (base) => ({
              ...base,
            }),
            dropdownIndicator: (base) => ({
              ...base,
              paddingLeft: "0",
              color: "#9ca3af",
              ":hover": {
                color: "#5046e5",
              },
            }),
            clearIndicator: (base) => ({
              ...base,
              paddingRight: "0.2rem",
              paddingLeft: "0",
              color: "#9ca3af",
              transitionDuration: "0.3s",
              cursor: "pointer",
              ":hover": {
                color: "#5046e5",
              },
            }),
            input: (base) => ({
              ...base,
              color: "#374151",
              paddingTop: "0",
              paddingBottom: "0",
              marginTop: "0",
              marginBottom: "0",
              borderColor: "transparent",
              focus: {
                borderColor: "transparent",
              },
            }),
          }}
          className="w-full"
          value={
            multiple
              ? aditionalStateCodes.map((item) => ({
                  value: [item],
                  label: labels![item],
                }))
              : {
                  value: [selectedStateCode],
                  label: labels![selectedStateCode!],
                }
          }
          onChange={(selected) => {
            if (!multiple) {
              onChange(selected.value[0]);
              return;
            }

            if (selected.length === 0) {
              onChange({
                value: [selectedStateCode],
                label: labels![selectedStateCode!],
              });
            } else {
              onChange(selected.map((item) => item.value[0]));
            }
          }}
          options={options
            .map((option) => ({
              value: [option],
              label: labels[option],
            }))
            .filter((option) => {
              if (multiple)
                return !aditionalStateCodes.includes(option.value[0]);
              return selectedStateCode !== option.value[0];
            })}
          isMulti={multiple ? true : undefined}
        />
      </div>
    );
  }

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              data-cy="select-menu-button"
            >
              <span className="block truncate">{label}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((item, idx) => (
                  <Listbox.Option
                    key={idx}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                    data-cy="select-menu-option"
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {labels ? labels[item] : item}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

export default SelectMenu;
