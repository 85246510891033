import {
  COLOR_SET_1,
  COLOR_SET_2,
  COLOR_SET_3,
  COLOR_SET_4,
  COLOR_SET_BI_2,
  COLOR_TRANSPARENT,
  MEDIUM_BLUE,
  MEDIUM_GREEN,
  MEDIUM_GREY,
  MEDIUM_ORANGE,
  MEDIUM_RED,
  MEDIUM_YELLOW,
} from "../../../utils/colors";
import {
  formatCurrency,
  formatFloat,
  formatInt,
  formatPercent,
} from "../../../utils/format";
import { Color } from "../../../utils/geo";
import { NumberRange, SeriesStats } from "../../../utils/series";

export enum DataField {
  MedianAge = "median_age",
  PopCount = "pop_count",
  Pop5yGrow = "pop_5y_grow",
  PopDensity = "pop_density",
  HomeVal = "home_val",
  HomeVal5yGrow = "home_val_5y_grow",
  HomeVal1yGrow = "home_val_1y_grow",
  MedianHHIncome = "median_hh_income",
  PercentU18 = "percent_u18",
  PercentBachelorDegree = "p_bachelor_degree",
  JobCount = "job_count",
  JobCountU30 = "jobs_u30",
  JobCountHigh = "jobs_high",
  JobCountMed = "jobs_med",
  JobCountLow = "jobs_low",
  JobDensity = "job_density",
  AvgFamSize = "avg_fam_size",
  Pop24 = "pop_24",
  Pop25_34 = "pop_25_34",
  Pop35_44 = "pop_35_44",
  Pop45_54 = "pop_45_54",
  Pop55_64 = "pop_55_64",
  Pop65_74 = "pop_65_74",
  Pop75 = "pop_75",
  Income24 = "income_24",
  Income25_34 = "income_25_34",
  Income35_44 = "income_35_44",
  Income45_54 = "income_45_54",
  Income55_64 = "income_55_64",
  Income65_74 = "income_65_74",
  Income75 = "income_75",
  TotalCrime = "total_crime",
  TotalBusinesses = "total_buss",
  TotalEmployees = "total_emp",
  BachelorPercent = "bachelor_p",
  AvgDailyTraffic = "aadt",
  AvgDailyTraffic5yGrow = "aadt_5y_grow",
  // Hello Sugar
  BeautySpend = "beauty_spend",
  Fpop2040 = "fpop_20_40",
  Fpop2040p = "fpop_20_40_p",
  HhChildP = "hh_child_p",
  HhIncome2544 = "hh_income_25_44",
  Top5Pop = "top5_pop",
  Top5PopP = "top5_pop_p",
  TotalPop = "total_pop",
  Score = "score",
  // Rakkan Ramen
  HouseholdIncome = "hh_inc",
  PopulationHispanic = "pop_hisp_p",
  PopulationWhite = "pop_white_p",
  PopulationBlack = "pop_black_p",
  PopulationAmind = "pop_amind_p",
  PopulationAsian = "pop_asian_p",
}

export enum AggregateType {
  Sum = "sum",
  Avg = "avg",
}

interface DataFieldProps {
  colorSet: Color[];
  displayName: string;
  format: (val: number) => string;
  formatShort?: (val: number) => string;
  aggregate: AggregateType;
}

export const DATA_FIELD_PROPS: Record<DataField, DataFieldProps> = {
  [DataField.PopCount]: {
    colorSet: COLOR_SET_1,
    displayName: "Total population",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop5yGrow]: {
    colorSet: COLOR_SET_BI_2,
    displayName: "Total population (5-year growth)",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopDensity]: {
    colorSet: COLOR_SET_2,
    displayName: "Population density",
    format: (val) => `${formatInt(val)} ppl/sqmi`,
    formatShort: formatInt,
    aggregate: AggregateType.Avg,
  },
  [DataField.MedianAge]: {
    colorSet: COLOR_SET_2,
    displayName: "Median age",
    format: (val) => `${formatFloat(val)} years`,
    aggregate: AggregateType.Avg,
  },
  [DataField.HomeVal]: {
    colorSet: COLOR_SET_1,
    displayName: "Median home value",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.HomeVal5yGrow]: {
    colorSet: COLOR_SET_2,
    displayName: "Median home value (5-year CAGR)",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.HomeVal1yGrow]: {
    colorSet: COLOR_SET_3,
    displayName: "Median home value (1-year growth)",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.MedianHHIncome]: {
    colorSet: COLOR_SET_2,
    displayName: "Median income",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.PercentU18]: {
    colorSet: COLOR_SET_2,
    displayName: "% Household with kids under 18",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PercentBachelorDegree]: {
    colorSet: COLOR_SET_2,
    displayName: "Bachelor+ attainment rate",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.BachelorPercent]: {
    colorSet: COLOR_SET_2,
    displayName: "Bachelor degree rate",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.JobCount]: {
    colorSet: COLOR_SET_1,
    displayName: "Total jobs",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.JobCountU30]: {
    colorSet: COLOR_SET_1,
    displayName: "Workers under 30",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.JobCountHigh]: {
    colorSet: COLOR_SET_1,
    displayName: "High income workers",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.JobCountMed]: {
    colorSet: COLOR_SET_1,
    displayName: "Medium income workers",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.JobCountLow]: {
    colorSet: COLOR_SET_1,
    displayName: "Low income workers",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.JobDensity]: {
    colorSet: COLOR_SET_1,
    displayName: "Job density",
    format: (val) => `${formatInt(val)} jobs/sq mi`,
    formatShort: formatInt,
    aggregate: AggregateType.Avg,
  },
  [DataField.AvgFamSize]: {
    colorSet: COLOR_SET_4,
    displayName: "Average family size",
    format: (val) => `${formatFloat(val)} ppl`,
    formatShort: formatFloat,
    aggregate: AggregateType.Avg,
  },
  [DataField.Pop24]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (< 24yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop25_34]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (25-34yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop35_44]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (35-44yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop45_54]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (45-54yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop55_64]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (55-64yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop65_74]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (65-74yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Pop75]: {
    colorSet: COLOR_SET_1,
    displayName: "Population (> 75yo)",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Income24]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (< 24yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income25_34]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (25-34yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income35_44]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (35-44yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income45_54]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (45-54yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income55_64]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (55-64yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income65_74]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (65-74yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Income75]: {
    colorSet: COLOR_SET_1,
    displayName: "Average income (> 75yo)",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.TotalCrime]: {
    colorSet: COLOR_SET_1,
    displayName: "Crime rate index",
    format: (val) => formatInt(val),
    aggregate: AggregateType.Avg,
  },
  [DataField.TotalBusinesses]: {
    colorSet: COLOR_SET_2,
    displayName: "Number of businesses",
    format: (val) => formatInt(val),
    aggregate: AggregateType.Sum,
  },
  [DataField.TotalEmployees]: {
    colorSet: COLOR_SET_3,
    displayName: "Number of employees",
    format: (val) => formatInt(val),
    aggregate: AggregateType.Sum,
  },
  [DataField.AvgDailyTraffic]: {
    colorSet: [MEDIUM_GREEN, MEDIUM_YELLOW, MEDIUM_RED],
    displayName: "Average daily traffic",
    format: (val) => `${formatInt(val)} vehicles/day`,
    formatShort: formatInt,
    aggregate: AggregateType.Avg,
  },
  [DataField.AvgDailyTraffic5yGrow]: {
    colorSet: [MEDIUM_BLUE, MEDIUM_GREY, MEDIUM_ORANGE],
    displayName: "Average daily traffic (5-year growth)",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.BeautySpend]: {
    colorSet: COLOR_SET_2,
    displayName: "Female personal care spending",
    format: formatCurrency,
    aggregate: AggregateType.Sum,
  },
  [DataField.Fpop2040]: {
    colorSet: COLOR_SET_4,
    displayName: "20-40 yr female population",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Fpop2040p]: {
    colorSet: COLOR_SET_2,
    displayName: "% 20-40 yr female population",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.HhChildP]: {
    colorSet: COLOR_SET_3,
    displayName: "% Households with children",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.HhIncome2544]: {
    colorSet: COLOR_SET_4,
    displayName: "Median household income 25-44 yr",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.Top5PopP]: {
    colorSet: COLOR_SET_1,
    displayName: "% of top 5 customer segments",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.Top5Pop]: {
    colorSet: COLOR_SET_1,
    displayName: "Population top 5 segments",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.TotalPop]: {
    colorSet: COLOR_SET_1,
    displayName: "Total population",
    format: formatInt,
    aggregate: AggregateType.Sum,
  },
  [DataField.Score]: {
    colorSet: COLOR_SET_1,
    displayName: "Score",
    format: formatFloat,
    aggregate: AggregateType.Avg,
  },
  [DataField.HouseholdIncome]: {
    colorSet: COLOR_SET_1,
    displayName: "Household income",
    format: formatCurrency,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopulationHispanic]: {
    colorSet: COLOR_SET_3,
    displayName: "% Population Hispanic",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopulationWhite]: {
    colorSet: COLOR_SET_2,
    displayName: "% Population White",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopulationBlack]: {
    colorSet: COLOR_SET_4,
    displayName: "% Population Black",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopulationAmind]: {
    colorSet: COLOR_SET_1,
    displayName: "% Population American Indian",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
  [DataField.PopulationAsian]: {
    colorSet: COLOR_SET_2,
    displayName: "% Population Asian",
    format: formatPercent,
    aggregate: AggregateType.Avg,
  },
};

export interface FieldState {
  field: DataField;
  stats: SeriesStats;
  filterRange: NumberRange;
  getFillColor: (val: number) => Color;
}

export interface FieldGroup {
  name: string;
  fields: DataField[];
  activeFields: DataField[];
  stats: GroupStats;
}

export interface DataVizState {
  fields: {
    [key: string]: FieldState;
  };
  filters: {
    [key: string]: NumberRange;
  };
  activeField: Nullable<DataField>;
  fieldGroups: FieldGroup[];
  error: string;
}

function defaultSeriesStats(): SeriesStats {
  return new SeriesStats(0, 1000, []);
}

function defaultFilterRange(): NumberRange {
  return { min: 0, max: 1000 };
}

const EMPTY_DATA_VIZ_STATE: DataVizState = {
  fields: {},
  filters: {},
  activeField: null,
  fieldGroups: [],
  error: "",
};

export interface FieldGroupInit {
  name: string;
  fields: DataField[];
}

export function InitDataVizState(
  fields: DataField[],
  fieldGroupInitList?: FieldGroupInit[]
): DataVizState {
  const fieldGroups = (fieldGroupInitList || []).map(({ name, fields }) => {
    return {
      name,
      fields,
      activeFields: [],
      stats: {},
    };
  });

  const state: DataVizState = {
    ...EMPTY_DATA_VIZ_STATE,
    activeField: fields[0],
    fieldGroups,
  };
  return fields.reduce((acc, field) => {
    acc.fields[field] = {
      field: field,
      stats: defaultSeriesStats(),
      filterRange: defaultFilterRange(),
      getFillColor: () => {
        return COLOR_TRANSPARENT;
      },
    };
    return acc;
  }, state);
}

export enum ActionType {
  DataLoaded = "data_loaded",
  DataLoadErr = "data_load_error",
  FilterChanged = "filter_changed",
  SetActiveField = "set_active_field",
  SetGroupActiveFields = "set_group_active_fields",
}

export interface DataStats {
  [key: string]: {
    min: number;
    max: number;
    intervals: number[];
  };
}

export interface GroupStats {
  [key: string]: {
    q10: number;
    q50: number;
    q90: number;
  };
}

export interface Action {
  type: ActionType;
  dataLoaded?: {
    dataStats: DataStats;
    groupStats: GroupStats[];
  };
  dataLoadErr?: {
    error: string;
  };
  filterChanged?: {
    field: DataField;
    range: NumberRange;
  };
  setActiveField?: {
    activeField: Nullable<DataField>;
  };
  setGroupActiveFields?: {
    index: number;
    activeFields: DataField[];
  };
}

export type DataVizReducerType = (
  state: DataVizState,
  action: Action
) => DataVizState;

export function DataVizReducer(fieldList: DataField[]): DataVizReducerType {
  return (state: DataVizState, action: Action) => {
    if (action.type === ActionType.DataLoadErr) {
      if (!action.dataLoadErr) {
        return state;
      }
      const { error } = action.dataLoadErr;
      return {
        ...state,
        error,
      };
    } else if (action.type === ActionType.DataLoaded) {
      if (!action.dataLoaded) {
        return state;
      }
      const {
        dataLoaded: { dataStats, groupStats },
      } = action;
      const filters: Record<string, NumberRange> = {};
      const fields = fieldList.reduce(
        (acc, field): Record<string, FieldState> => {
          const { min, max, intervals } = dataStats[field];
          const stats = new SeriesStats(min, max, intervals);
          filters[field] = stats.range;
          const fieldState: FieldState = {
            field,
            stats,
            filterRange: stats.range,
            getFillColor: (val: number) => {
              if (!val) {
                return COLOR_TRANSPARENT;
              }
              const idx = stats.findInterval(val);
              return DATA_FIELD_PROPS[field].colorSet[idx];
            },
          };
          return {
            ...acc,
            [field]: fieldState,
          };
        },
        {} as Record<string, FieldState>
      );
      const fieldGroups = state.fieldGroups.map((fieldGroup, index) => ({
        ...fieldGroup,
        stats: groupStats[index],
      }));
      return {
        ...state,
        fields,
        filters,
        fieldGroups,
      };
    } else if (action.type === ActionType.FilterChanged) {
      if (!action.filterChanged) {
        return state;
      }
      const { field, range } = action.filterChanged;
      const fieldState = state.fields[field];
      return {
        ...state,
        fields: {
          ...state.fields,
          [field]: {
            ...fieldState,
            filterRange: range,
          },
        },
        filters: {
          ...state.filters,
          [field]: range,
        },
      };
    } else if (action.type === ActionType.SetActiveField) {
      if (!action.setActiveField) {
        return state;
      }
      const { activeField } = action.setActiveField;
      return {
        ...state,
        activeField,
      };
    } else if (action.type === ActionType.SetGroupActiveFields) {
      if (!action.setGroupActiveFields) {
        return state;
      }
      const { index, activeFields } = action.setGroupActiveFields;
      const fieldGroups = state.fieldGroups.map((group, idx) => {
        if (idx === index) {
          return {
            ...group,
            activeFields,
          };
        }
        return group;
      });
      return {
        ...state,
        fieldGroups,
      };
    }
    throw Error("Unknown action.");
  };
}
