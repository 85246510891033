import React, { FC, useState } from "react";
import { API_URL } from "../../templates/template02/constants";
import LoadingSpinner from "../../../components/LoadingSpinner";
import LogoutButton from "../../../components/LogoutButton";

const DEFAULT_SYSTEM_PROMPT = "You are a business analyst.";
const DEFAULT_PROMPT =
  "You are helping a client to find the best location for their new business. Here is the description of the business:\n{business_desc}.\nPick the top {filter_size} most relevant datapoints to look at when analyzing a potential new location. Only choose items from this list:\n{data_fields}";
const DEFAULT_BUSINESS_DESC =
  "Hello Sugar is a waxing salon. We offer waxing, sugaring and other skin care services. Our main competitor is European Wax Center";

const DATASETS: Record<string, string> = {
  ags_base: "AGS Base",
  ags_cex: "AGS Consumer Expenditure",
  ags_panorama: "AGS Panorama",
  ags_crimerisk: "AGS Crime Risk",
  ags_environment: "AGS Environment",
  ags_retail: "AGS Retail",
  ags_nrp: "AGS Non Resident Population",
  ags_all: "AGS All",
};

const Exp01ChatgptDataFields: FC<{}> = () => {
  const [systemPrompt, setSystemPrompt] = useState<string>(
    DEFAULT_SYSTEM_PROMPT
  );
  const [prompt, setPrompt] = useState<string>(DEFAULT_PROMPT);
  const [businessDesc, setBusinessDesc] = useState<string>(
    DEFAULT_BUSINESS_DESC
  );
  const [gptResponse, setGptResponse] = useState<string>("");
  const [selectedDataset, setSelectedDataset] = useState<string>("ags_base");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const resp = await fetch(`${API_URL}/exp/chatgpt/recommend-data`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          system_prompt: systemPrompt,
          prompt: prompt,
          business_desc: businessDesc,
          dataset: selectedDataset,
          filter_size: 5,
          final_filter_size: 10,
        }),
      });
      const { text } = await resp.json();
      setGptResponse(text);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col align-middle justify-center items-center py-4">
      <h1 className="text-2xl py-2">Explore our data schema</h1>
      <h2 className="text-lg py-2 max-w-2xl text-green-700">
        Welcome to Applied Geographic Solutions, powering market decisions over
        two decades. You can explore our datasets using this interactive tool.
      </h2>
      {/* <p className="text-xl">System prompt</p>
      <textarea
        className="w-1/2 h-24"
        placeholder="Enter your message here..."
        value={systemPrompt}
        onChange={(e) => setSystemPrompt(e.target.value)}
      />
      <p className="text-xl">Prompt</p>
      <textarea
        className="w-1/2 h-48"
        placeholder="Enter your message here..."
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      /> */}
      <p className="text-lg max-w-2xl py-2">
        Tell us a bit about your use case for our data, and we will recommend
        some datasets for you.
      </p>
      <textarea
        className="w-1/2 h-36 rounded-md py-2 my-2"
        placeholder="Enter your message here..."
        value={businessDesc}
        onChange={(e) => setBusinessDesc(e.target.value)}
      />
      <select
        className="w-1/2 h-12 rounded-md py-2 my-2"
        value={selectedDataset}
        onChange={(e) => setSelectedDataset(e.target.value)}
      >
        <option value="">Select Dataset</option>
        {Object.keys(DATASETS).map((key) => (
          <option key={key} value={key}>
            {DATASETS[key]}
          </option>
        ))}
      </select>
      <button
        className="w-1/2 h-12 bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-md my-4 disabled:bg-gray-400"
        onClick={handleSubmit}
        disabled={loading || selectedDataset === ""}
      >
        {loading ? <LoadingSpinner /> : "Explore"}
      </button>
      <textarea
        readOnly
        className="w-1/2 h-48 mb-8 rounded-md py-2 my-2"
        placeholder="Response from our LLM assistant"
        value={gptResponse}
        onChange={(e) => setBusinessDesc(e.target.value)}
      />
      <LogoutButton />
    </div>
  );
};

export default Exp01ChatgptDataFields;
