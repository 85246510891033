import React, { FC, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface SelectLayersProps {
  items: string[];
  displayNames: string[];
  single?: boolean;
  initialSelected: string[];
  onChange?: (items: string[]) => void;
}

const SelectLayers: FC<SelectLayersProps> = ({
  items,
  displayNames,
  single,
  initialSelected,
  onChange,
}) => {
  const [selected, setSelected] = useState<string[]>([...initialSelected]);
  const isSelected = (item: string): boolean => {
    return selected.includes(item);
  };
  const handleSelect = (item: string) => {
    let newSelected: string[];
    if (single) {
      if (isSelected(item)) {
        newSelected = [];
      } else {
        newSelected = [item];
      }
    } else {
      if (isSelected(item)) {
        newSelected = [...selected];
        newSelected.splice(selected.indexOf(item), 1);
      } else {
        newSelected = [...selected, item];
      }
    }
    setSelected(newSelected);
    if (onChange) onChange(newSelected);
  };
  return (
    <div>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={classNames(
            "flex items-end cursor-pointer",
            isSelected(item) && "font-medium"
          )}
          onClick={() => handleSelect(item)}
          data-cy="select-layer-item"
        >
          <div>{displayNames[idx]}</div>
          <div className="ml-auto mb-1">
            {isSelected(item) ? (
              <EyeIcon width={16} height={16} />
            ) : (
              <EyeSlashIcon width={16} height={16} />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectLayers;
