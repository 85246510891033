import React, { FC, useCallback, useMemo } from "react";
import MultiRangeSlider from "../../../components/MultiRangeSlider";
import SelectLayersControlled from "../../../components/SelectLayersControlled";
import {
  AppConfig,
  FilterFieldConfig,
  FilterLayerConfig,
  IconLayer,
} from "../../../utils/appconfig";
import { NumberRange } from "../../../utils/series";
import {
  Action as LayerAction,
  ActionTypes as LayerActionTypes,
  LayerState,
  StatsDataItem,
} from "./reducer";

interface ExploreTabProps {
  appConfig: AppConfig;
  layerState: LayerState;
  layerDispatch: React.Dispatch<LayerAction>;
  setSavedIconLayers: (value: IconLayer[]) => void;
  setAppConfig: React.Dispatch<React.SetStateAction<AppConfig | undefined>>;
}

interface FilterGroupFieldsSelctProps {
  items: FilterFieldConfig[];
  layerState: LayerState;
  layerDispatch: React.Dispatch<LayerAction>;
}

const FilterGroupFieldsSelect: FC<FilterGroupFieldsSelctProps> = ({
  items,
  layerState,
  layerDispatch,
}) => {
  const isActiveField = (item: FilterFieldConfig): boolean => {
    return layerState.geodataLayers[item.layer].activeField === item.field;
  };
  const selected = useMemo(
    () => items.filter(isActiveField),
    [layerState.geodataLayers]
  );
  const onToggle = useCallback(
    (item: FilterFieldConfig) => {
      layerDispatch({
        type: LayerActionTypes.SetLayerActiveField,
        payload: {
          id: item.layer,
          field: isActiveField(item) ? null : item.field,
        },
      });
    },
    [layerDispatch, layerState.geodataLayers]
  );
  return (
    <SelectLayersControlled
      items={items}
      selected={selected}
      onToggle={onToggle}
      layerDispatch={layerDispatch}
    />
  );
};

interface FilterGroupLayersSelctProps {
  items: FilterLayerConfig[];
  layerState: LayerState;
  layerDispatch: React.Dispatch<LayerAction>;
  deletable?: boolean;
  setSavedIconLayers: (value: IconLayer[]) => void;
  appConfig: AppConfig;
  setAppConfig: React.Dispatch<React.SetStateAction<AppConfig | undefined>>;
}

const FilterGroupLayersSelect: FC<FilterGroupLayersSelctProps> = ({
  items,
  layerState,
  layerDispatch,
  deletable,
  setSavedIconLayers,
  appConfig,
  setAppConfig,
}) => {
  const isGeojsonLayer = (layer: string): boolean =>
    layer in layerState.geojsonLayers;
  const isIconLayer = (layer: string): boolean =>
    layer in layerState.iconLayers;
  const isSelected = (item: FilterLayerConfig): boolean =>
    item.layers
      .filter(isGeojsonLayer)
      .every((layer) => layerState.geojsonLayers[layer].visible) &&
    item.layers
      .filter(isIconLayer)
      .every((layer) => layerState.iconLayers[layer].visible);
  const selected = useMemo(
    () => items.filter(isSelected),
    [layerState.geojsonLayers, layerState.iconLayers]
  );
  const onToggle = useCallback(
    (item: FilterLayerConfig) => {
      layerDispatch({
        type: LayerActionTypes.SetLayerGroupVisible,
        payload: {
          geojson: item.layers.filter(isGeojsonLayer),
          icon: item.layers.filter(isIconLayer),
          visible: !isSelected(item),
        },
      });
    },
    [layerState.geojsonLayers, layerState.iconLayers, layerDispatch]
  );
  return (
    <SelectLayersControlled
      items={items}
      selected={selected}
      onToggle={onToggle}
      deletable={deletable}
      layerDispatch={layerDispatch}
      appConfig={appConfig}
      setSavedIconLayers={setSavedIconLayers}
      setAppConfig={setAppConfig}
    />
  );
};

const DEFAULT_STATS_ITEM: StatsDataItem = {
  min: 0,
  max: 1,
  intervals: [],
};

interface RangeFilterItem {
  layerId: string;
  fieldId: string;
  fieldName: string;
  fieldFormat: (x: number) => string;
  range: NumberRange;
  stats: StatsDataItem;
}

const ExploreTab: FC<ExploreTabProps> = ({
  appConfig,
  layerState,
  layerDispatch,
  setSavedIconLayers,
  setAppConfig,
}) => {
  const rangeFilters = useMemo(
    () =>
      appConfig.layers.geodataLayers.flatMap((layerConfig) => {
        const layer = layerState.geodataLayers[layerConfig.id];
        return Object.entries(layer.filters).map(
          ([fieldId, range]): RangeFilterItem => ({
            layerId: layerConfig.id,
            fieldId,
            fieldName: layerConfig.fields[fieldId].name,
            fieldFormat: layerConfig.fields[fieldId].format,
            range,
            stats: layer.data?.stats[fieldId] ?? DEFAULT_STATS_ITEM,
          })
        );
      }),
    [layerState.geodataLayers, appConfig.layers.geodataLayers]
  );

  return (
    <>
      <h2 className="font-bold text-xl my-4 mb-2">Layer Control</h2>
      {appConfig.filterGroups.map((group, idx) => (
        <div key={idx} className="mb-2">
          <div>{group.name}</div>
          <div className="ml-3 text-sm leading-6 text-gray-900">
            {group.type === "fields" && (
              <FilterGroupFieldsSelect
                items={group.items as FilterFieldConfig[]}
                layerState={layerState}
                layerDispatch={layerDispatch}
              />
            )}
            {group.type === "layers" && (
              <FilterGroupLayersSelect
                deletable={
                  group.id == "pois" &&
                  (appConfig.id === "app23overture" ||
                    appConfig?.overture?.enable)
                }
                items={group.items as FilterLayerConfig[]}
                layerState={layerState}
                appConfig={appConfig}
                setAppConfig={setAppConfig}
                setSavedIconLayers={setSavedIconLayers}
                layerDispatch={layerDispatch}
              />
            )}
          </div>
        </div>
      ))}

      {rangeFilters.length > 0 && (
        <h2 className="font-bold text-xl my-4 mb-2">Define Criteria</h2>
      )}
      {rangeFilters.map((item: RangeFilterItem, idx: number) => (
        <div key={idx} data-cy="layer-filter-item">
          <div className="mb-1 text-sm">{item.fieldName}</div>
          <MultiRangeSlider
            min={item.stats.min}
            max={item.stats.max}
            onChange={(range: NumberRange) => {
              layerDispatch({
                type: LayerActionTypes.SetLayerFieldFilterRange,
                payload: {
                  id: item.layerId,
                  field: item.fieldId,
                  range,
                },
              });
            }}
            format={item.fieldFormat}
          />
        </div>
      ))}
    </>
  );
};

export default ExploreTab;
