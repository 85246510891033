import {
  MapViewState,
  FlyToInterpolator,
  Position,
  WebMercatorViewport,
} from "@deck.gl/core/typed";
import { UsState } from "./us";
import { strict as assert } from "assert";
import { Color } from "./geo";

const KM_TO_METERS = 1000;

/*
  TODO: make this a function of the screen size
  zoom level 15: you can see 5 km radius (on large screen)
  zoom level 14: you can see 10 km radius (on large screen)
  ...
*/
export function zoomToRadius(zoom: number): number {
  return 1.25 * Math.pow(0.5, zoom - 15) * KM_TO_METERS;
}

export interface MapPin {
  id: number;
  name?: string;
  coordinates: Position;
  color: Color;
}

export function formatMapPin(pin: MapPin): string {
  if (pin.name) return pin.name;
  return formatMapPinCoords(pin);
}

export function formatMapPinCoords(pin: MapPin): string {
  return (pin.coordinates as number[]).map((d) => d.toFixed(4)).join(", ");
}

export const UK_VIEW_STATE: MapViewState = {
  longitude: -1.415726785873005,
  latitude: 52.232395363869415,
  zoom: 6,
  pitch: 0,
  bearing: 0,
};

export const US_VIEW_STATE: MapViewState = {
  longitude: -96.93821288851717,
  latitude: 36.49104884499897,
  zoom: 4,
  pitch: 0,
  bearing: 0,
};

export const DEFAULT_VIEW_STATE: MapViewState = {
  longitude: -96.93821288851717,
  latitude: 36.49104884499897,
  zoom: 4,
  pitch: 0,
  bearing: 0,
};

export const GEO_VIEW_STATES: Map<string, MapViewState> = new Map([
  ["us", US_VIEW_STATE],
  ["eu", UK_VIEW_STATE],
]);

const STATE_MAP_VIEWS_INIT = new Map<
  UsState,
  {
    lat: number;
    lon: number;
    bbox?: { xmin: number; xmax: number; ymin: number; ymax: number };
  }
>([
  [
    UsState.Alabama,
    {
      lat: 32.806671,
      lon: -86.79113,
      bbox: {
        xmin: -88.473227,
        ymin: 30.223334,
        xmax: -84.88908,
        ymax: 35.008028,
      },
    },
  ],
  [
    UsState.Alaska,
    {
      lat: 61.370716,
      lon: -152.404419,
      bbox: {
        xmin: -179.148909,
        ymin: 51.214183,
        xmax: 179.77847,
        ymax: 71.365162,
      },
    },
  ],
  [
    UsState.Arizona,
    {
      lat: 34.865970000000004,
      lon: -111.763811,
      bbox: {
        xmin: -114.81651,
        ymin: 31.332177,
        xmax: -109.045223,
        ymax: 37.00426,
      },
    },
  ],
  [
    UsState.Arkansas,
    {
      lat: 34.969704,
      lon: -92.373123,
      bbox: {
        xmin: -94.617919,
        ymin: 33.004106,
        xmax: -89.644395,
        ymax: 36.4996,
      },
    },
  ],
  [
    UsState.California,
    {
      lat: 36.116203,
      lon: -119.681564,
      bbox: {
        xmin: -124.409591,
        ymin: 32.534156,
        xmax: -114.131211,
        ymax: 42.009518,
      },
    },
  ],
  [
    UsState.Colorado,
    {
      lat: 39.113014,
      lon: -105.358887,
      bbox: {
        xmin: -109.060253,
        ymin: 36.992426,
        xmax: -102.041524,
        ymax: 41.003444,
      },
    },
  ],
  [
    UsState.Connecticut,
    {
      lat: 41.597782,
      lon: -72.755371,
      bbox: {
        xmin: -73.727775,
        ymin: 40.980144,
        xmax: -71.786994,
        ymax: 42.050587,
      },
    },
  ],
  [
    UsState.Delaware,
    {
      lat: 39.318523,
      lon: -75.507141,
      bbox: {
        xmin: -75.788658,
        ymin: 38.451013,
        xmax: -75.048939,
        ymax: 39.839007,
      },
    },
  ],
  [
    UsState.DistrictOfColumbia,
    {
      lat: 38.897438,
      lon: -77.026817,
      bbox: {
        xmin: -77.119759,
        ymin: 38.791645,
        xmax: -76.909395,
        ymax: 38.99511,
      },
    },
  ],
  [
    UsState.Florida,
    {
      lat: 28.531237489080013,
      lon: -81.3588034551829,
      bbox: {
        xmin: -87.634938,
        ymin: 24.523096,
        xmax: -80.031362,
        ymax: 31.000888,
      },
    },
  ],
  [
    UsState.Georgia,
    {
      lat: 32.363268396835714,
      lon: -83.14875997864637,
      bbox: {
        xmin: -85.605165,
        ymin: 30.357851,
        xmax: -80.839729,
        ymax: 35.000659,
      },
    },
  ],
  [
    UsState.Hawaii,
    {
      lat: 21.094318,
      lon: -157.498337,
      bbox: {
        xmin: -178.334698,
        ymin: 18.910361,
        xmax: -154.806773,
        ymax: 28.402123,
      },
    },
  ],
  [
    UsState.Idaho,
    {
      lat: 44.068201,
      lon: -114.742041,
      bbox: {
        xmin: -117.243027,
        ymin: 41.988057,
        xmax: -111.043564,
        ymax: 49.001146,
      },
    },
  ],
  [
    UsState.Illinois,
    {
      lat: 40.633125,
      lon: -89.398528,
      bbox: {
        xmin: -91.513079,
        ymin: 36.970298,
        xmax: -87.494756,
        ymax: 42.508481,
      },
    },
  ],
  [
    UsState.Indiana,
    {
      lat: 40.551217,
      lon: -85.602364,
      bbox: {
        xmin: -88.09776,
        ymin: 37.771742,
        xmax: -84.784579,
        ymax: 41.760592,
      },
    },
  ],
  [
    UsState.Iowa,
    {
      lat: 41.9393,
      lon: -93.3897,
      bbox: {
        xmin: -96.639704,
        ymin: 40.375501,
        xmax: -90.140061,
        ymax: 43.501196,
      },
    },
  ],
  [
    UsState.Kansas,
    {
      lat: 38.5266,
      lon: -96.726486,
      bbox: {
        xmin: -102.051744,
        ymin: 36.993016,
        xmax: -94.588413,
        ymax: 40.003162,
      },
    },
  ],
  [
    UsState.Kentucky,
    {
      lat: 37.66814,
      lon: -84.670067,
      bbox: {
        xmin: -89.571509,
        ymin: 36.497129,
        xmax: -81.964971,
        ymax: 39.147458,
      },
    },
  ],
  [
    UsState.Louisiana,
    {
      lat: 31.169546,
      lon: -91.867805,
      bbox: {
        xmin: -94.043147,
        ymin: 28.928609,
        xmax: -88.817017,
        ymax: 33.019457,
      },
    },
  ],
  [
    UsState.Maine,
    {
      lat: 44.693947,
      lon: -69.381927,
      bbox: {
        xmin: -71.083924,
        ymin: 42.977764,
        xmax: -66.949895,
        ymax: 47.459686,
      },
    },
  ],
  [
    UsState.Maryland,
    {
      lat: 39.063946,
      lon: -76.802101,
      bbox: {
        xmin: -79.487651,
        ymin: 37.911717,
        xmax: -75.048939,
        ymax: 39.723043,
      },
    },
  ],
  [
    UsState.Massachusetts,
    {
      lat: 42.230171,
      lon: -71.530106,
      bbox: {
        xmin: -73.508142,
        ymin: 41.237964,
        xmax: -69.928393,
        ymax: 42.886589,
      },
    },
  ],
  [
    UsState.Michigan,
    {
      lat: 43.326618,
      lon: -84.536095,
      bbox: {
        xmin: -90.418136,
        ymin: 41.696118,
        xmax: -82.413474,
        ymax: 48.2388,
      },
    },
  ],
  [
    UsState.Minnesota,
    {
      lat: 45.694454,
      lon: -93.900192,
      bbox: {
        xmin: -97.239209,
        ymin: 43.499356,
        xmax: -89.491739,
        ymax: 49.384358,
      },
    },
  ],
  [
    UsState.Mississippi,
    {
      lat: 32.741646,
      lon: -89.678696,
      bbox: {
        xmin: -91.655009,
        ymin: 30.173943,
        xmax: -88.097888,
        ymax: 34.996052,
      },
    },
  ],
  [
    UsState.Missouri,
    {
      lat: 38.304662,
      lon: -92.437099,
      bbox: {
        xmin: -95.774704,
        ymin: 35.995683,
        xmax: -89.098843,
        ymax: 40.61364,
      },
    },
  ],
  [
    UsState.Montana,
    {
      lat: 46.921925,
      lon: -110.454353,
      bbox: {
        xmin: -116.050003,
        ymin: 44.358221,
        xmax: -104.039138,
        ymax: 49.00139,
      },
    },
  ],
  [
    UsState.Nebraska,
    {
      lat: 41.12537,
      lon: -98.268082,
      bbox: {
        xmin: -104.053514,
        ymin: 39.999998,
        xmax: -95.30829,
        ymax: 43.001708,
      },
    },
  ],
  [
    UsState.Nevada,
    {
      lat: 39.49324039039687,
      lon: -117.07184056640625,
      bbox: {
        xmin: -120.005746,
        ymin: 35.001857,
        xmax: -114.039648,
        ymax: 42.002207,
      },
    },
  ],
  [
    UsState.NewHampshire,
    {
      lat: 43.452492,
      lon: -71.563896,
      bbox: {
        xmin: -72.557247,
        ymin: 42.69699,
        xmax: -70.610621,
        ymax: 45.305476,
      },
    },
  ],
  [
    UsState.NewJersey,
    {
      lat: 40.298904,
      lon: -74.521011,
      bbox: {
        xmin: -75.559614,
        ymin: 38.928519,
        xmax: -73.893979,
        ymax: 41.357423,
      },
    },
  ],
  [
    UsState.NewMexico,
    {
      lat: 34.840515,
      lon: -106.248482,
      bbox: {
        xmin: -109.050173,
        ymin: 31.332301,
        xmax: -103.001964,
        ymax: 37.000232,
      },
    },
  ],
  [
    UsState.NewYork,
    {
      lat: 42.165726,
      lon: -74.948051,
      bbox: {
        xmin: -79.762152,
        ymin: 40.496103,
        xmax: -71.856214,
        ymax: 45.01585,
      },
    },
  ],
  [
    UsState.NorthCarolina,
    {
      lat: 35.554086640391965,
      lon: -78.86446480065439,
      bbox: {
        xmin: -84.321869,
        ymin: 33.842316,
        xmax: -75.460621,
        ymax: 36.588117,
      },
    },
  ],
  [
    UsState.NorthDakota,
    {
      lat: 47.528912,
      lon: -99.784012,
      bbox: {
        xmin: -104.0489,
        ymin: 45.935054,
        xmax: -96.554507,
        ymax: 49.000574,
      },
    },
  ],
  [
    UsState.Ohio,
    {
      lat: 40.388783,
      lon: -82.764915,
      bbox: {
        xmin: -84.820159,
        ymin: 38.403202,
        xmax: -80.518693,
        ymax: 41.977523,
      },
    },
  ],
  [
    UsState.Oklahoma,
    {
      lat: 35.565342,
      lon: -96.928917,
      bbox: {
        xmin: -103.002565,
        ymin: 33.615833,
        xmax: -94.430662,
        ymax: 37.002206,
      },
    },
  ],
  [
    UsState.Oregon,
    {
      lat: 44.572021,
      lon: -122.070938,
      bbox: {
        xmin: -124.566244,
        ymin: 41.991794,
        xmax: -116.463504,
        ymax: 46.292035,
      },
    },
  ],
  [
    UsState.Pennsylvania,
    {
      lat: 40.590752,
      lon: -77.209755,
      bbox: {
        xmin: -80.519891,
        ymin: 39.7198,
        xmax: -74.689516,
        ymax: 42.26986,
      },
    },
  ],
  [
    UsState.RhodeIsland,
    {
      lat: 41.680893,
      lon: -71.51178,
      bbox: {
        xmin: -71.862772,
        ymin: 41.146339,
        xmax: -71.12057,
        ymax: 42.018798,
      },
    },
  ],
  [
    UsState.SouthCarolina,
    {
      lat: 33.74682507495135,
      lon: -80.58462688800678,
      bbox: {
        xmin: -83.35391,
        ymin: 32.0346,
        xmax: -78.54203,
        ymax: 35.215402,
      },
    },
  ],
  [
    UsState.SouthDakota,
    {
      lat: 44.299782,
      lon: -99.438828,
      bbox: {
        xmin: -104.057698,
        ymin: 42.479635,
        xmax: -96.436589,
        ymax: 45.94545,
      },
    },
  ],
  [
    UsState.Tennessee,
    {
      lat: 35.860119,
      lon: -86.660156,
      bbox: {
        xmin: -90.310298,
        ymin: 34.982972,
        xmax: -81.6469,
        ymax: 36.678118,
      },
    },
  ],
  [
    UsState.Texas,
    {
      lat: 31.267625346734874,
      lon: -98.99314862276987,
      bbox: {
        xmin: -106.645646,
        ymin: 25.837377,
        xmax: -93.508292,
        ymax: 36.500704,
      },
    },
  ],
  [
    UsState.Utah,
    {
      lat: 39.32098065185547,
      lon: -111.09373100280762,
      bbox: {
        xmin: -114.052962,
        ymin: 36.997968,
        xmax: -109.041058,
        ymax: 42.001567,
      },
    },
  ],
  [
    UsState.Vermont,
    {
      lat: 44.045876,
      lon: -72.710686,
      bbox: {
        xmin: -73.508142,
        ymin: 41.237964,
        xmax: -69.928393,
        ymax: 42.886589,
      },
    },
  ],
  [
    UsState.Virginia,
    {
      lat: 37.769337,
      lon: -78.169968,
      bbox: {
        xmin: -83.675395,
        ymin: 36.540738,
        xmax: -75.242266,
        ymax: 39.466012,
      },
    },
  ],
  [
    UsState.Washington,
    {
      lat: 47.400902,
      lon: -121.490494,
      bbox: {
        xmin: -124.848974,
        ymin: 45.543541,
        xmax: -116.915989,
        ymax: 49.002494,
      },
    },
  ],
  [
    UsState.WestVirginia,
    {
      lat: 38.491226,
      lon: -80.954453,
      bbox: {
        xmin: -82.644739,
        ymin: 37.201483,
        xmax: -77.719519,
        ymax: 40.638801,
      },
    },
  ],
  [
    UsState.Wisconsin,
    {
      lat: 44.268543,
      lon: -89.616508,
      bbox: {
        xmin: -92.888114,
        ymin: 42.491983,
        xmax: -86.805415,
        ymax: 47.080621,
      },
    },
  ],
  [
    UsState.Wyoming,
    {
      lat: 42.755966,
      lon: -107.30249,
      bbox: {
        xmin: -111.056888,
        ymin: 40.994746,
        xmax: -104.05216,
        ymax: 45.005904,
      },
    },
  ],
  [
    UsState.NewfoundlandAndLabrador,
    {
      lat: 53.135509,
      lon: -57.660435,
      bbox: {
        xmin: -67.8,
        ymin: 46.61,
        xmax: -52.61,
        ymax: 60.37,
      },
    },
  ],
  [
    UsState.PrinceEdwardIsland,
    {
      lat: 46.510712,
      lon: -63.416813,
      bbox: {
        xmin: -64.41,
        ymin: 45.95,
        xmax: -61.97,
        ymax: 47.06,
      },
    },
  ],
  [
    UsState.NovaScotia,
    {
      lat: 44.681987,
      lon: -63.744311,
      bbox: {
        xmin: -66.32,
        ymin: 43.42,
        xmax: -59.68,
        ymax: 47.03,
      },
    },
  ],
  [
    UsState.NewBrunswick,
    {
      lat: 46.498392,
      lon: -66.159668,
      bbox: {
        xmin: -69.06,
        ymin: 44.6,
        xmax: -63.77,
        ymax: 48.07,
      },
    },
  ],
  [
    UsState.Quebec,
    {
      lat: 52.939915,
      lon: -73.549136,
      bbox: {
        xmin: -79.76,
        ymin: 44.99,
        xmax: -57.1,
        ymax: 62.59,
      },
    },
  ],
  [
    UsState.Ontario,
    {
      lat: 50.000678,
      lon: -85.323212,
      bbox: {
        xmin: -95.16,
        ymin: 41.66,
        xmax: -74.34,
        ymax: 56.86,
      },
    },
  ],
  [
    UsState.Manitoba,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -102.03,
        ymin: 48.99,
        xmax: -88.94,
        ymax: 60.0,
      },
    },
  ],
  [
    UsState.Saskatchewan,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -109.99,
        ymin: 48.99,
        xmax: -101.36,
        ymax: 60.0,
      },
    },
  ],
  [
    UsState.Alberta,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -120.0,
        ymin: 48.99,
        xmax: -109.99,
        ymax: 60.0,
      },
    },
  ],
  [
    UsState.BritishColumbia,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -139.06,
        ymin: 48.3,
        xmax: -114.03,
        ymax: 60.0,
      },
    },
  ],
  [
    UsState.Yukon,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -141.0,
        ymin: 60.0,
        xmax: -123.81,
        ymax: 69.65,
      },
    },
  ],
  [
    UsState.NorthwestTerritories,
    {
      lat: 54.760665,
      lon: -98.347656,
      bbox: {
        xmin: -136.44,
        ymin: 60.0,
        xmax: -101.98,
        ymax: 78.76,
      },
    },
  ],
]);

// assert(
//   STATE_MAP_VIEWS_INIT.size === Object.keys(UsState).length,
//   "Missing state map view data"
// );

export const STATE_MAP_VIEWS: Map<string, MapViewState> = new Map<
  string,
  MapViewState
>(
  Array.from(STATE_MAP_VIEWS_INIT).map(([key, value]) => {
    return [
      key,
      {
        longitude: value.lon,
        latitude: value.lat,
        zoom: 7,
        pitch: 0,
        bearing: 0,
        transitionDuration: "auto",
        transitionInterpolator: new FlyToInterpolator(),
      },
    ];
  })
);

export const createBoundsForStates = (
  states: UsState[]
): [[number, number], [number, number]] => {
  const bbox = states.reduce(
    (acc, state) => {
      const stateBbox = STATE_MAP_VIEWS_INIT.get(state)?.bbox;
      if (!stateBbox) return acc;
      return {
        xmin: Math.min(acc.xmin, stateBbox.xmin),
        ymin: Math.min(acc.ymin, stateBbox.ymin),
        xmax: Math.max(acc.xmax, stateBbox.xmax),
        ymax: Math.max(acc.ymax, stateBbox.ymax),
      };
    },

    { xmin: 180, ymin: 90, xmax: -180, ymax: -90 }
  );

  return [
    [bbox.xmin, bbox.ymin],
    [bbox.xmax, bbox.ymax],
  ];
};
