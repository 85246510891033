import React, { FC } from "react";
import classNames from "classnames";

export interface Tab {
  name: string;
  key: string;
  disabled?: boolean;
}

interface props {
  tabs: Tab[];
  onChange?: (tab: string) => void;
}

const HeadingMenu: FC<props> = ({ tabs, onChange }) => {
  const [current, setCurrent] = React.useState(tabs[0].key);

  const handleChange = (tab: Tab) => {
    setCurrent(tab.key);
    onChange && onChange(tab.key);
  };

  return (
    <div className="border-b border-gray-200 pb-5 sm:pb-0">
      <div className="mt-3 sm:mt-4">
        <div className="sm:hidden">
          <label htmlFor="current-tab" className="sr-only">
            Select a tab
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={tabs.find((tab) => tab.key === current)?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name} disabled={tab.disabled}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <nav className="-mb-px flex space-x-5">
            {tabs.map((tab) => (
              <button
                key={tab.key}
                className={classNames(
                  tab.key === current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                )}
                aria-current={tab.key === current ? "page" : undefined}
                disabled={tab.disabled}
                onClick={() => handleChange(tab)}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default HeadingMenu;
