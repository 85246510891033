import { Color } from "./geo";

export const COLOR_TRANSPARENT: Color = [0, 0, 0, 0];

export const DARK_EMERALD: Color = [6, 95, 70];
export const LIGHT_EMERALD: Color = [167, 243, 208];

export const MEDIUM_BLUE: Color = [59, 130, 246];
export const MEDIUM_GREEN: Color = [22, 163, 74];
export const MEDIUM_YELLOW: Color = [253, 224, 71];
export const MEDIUM_FUCHSIA: Color = [217, 70, 239];
export const MEDIUM_RED: Color = [239, 68, 68];
export const MEDIUM_GREY: Color = [161, 161, 170];
export const MEDIUM_ORANGE: Color = [249, 115, 22];

export const COLOR_GOLD_TRANSPARENT: Color = [255, 165, 0, 60];
export const COLOR_GOLD_HIGHLIGHT: Color = [255, 165, 0];
export const COLOR_YELLOW_HIGHLIGHT: Color = [255, 235, 60];

export const COLOR_ICON_RED: Color = [255, 68, 68];
export const COLOR_ICON_BLUE: Color = [51, 153, 102];
export const COLOR_ICON_CORAL: Color = [248, 131, 121];

export const COLOR_ICON_ORANGE: Color = [249, 115, 22];

export function interpolate(color1: Color, color2: Color, t: number): Color {
  return [
    color1[0] + (color2[0] - color1[0]) * t,
    color1[1] + (color2[1] - color1[1]) * t,
    color1[2] + (color2[2] - color1[2]) * t,
  ];
}
export const COLOR_SET_LENGTH = 6;

export const COLOR_SET_BI_1: Color[] = [
  [118, 43, 131],
  [176, 141, 195],
  [231, 212, 232],
  [217, 239, 211],
  [127, 190, 124],
  [28, 119, 55],
];

export const COLOR_SET_BI_2: Color[] = [
  [0, 147, 156, 150],
  [93, 186, 190, 150],
  [187, 225, 226, 150],
  [247, 191, 170, 150],
  [222, 118, 86, 150],
  [195, 45, 0, 150],
];

export const COLOR_SET_1: Color[] = [
  [239, 243, 255, 150],
  [199, 218, 239, 150],
  [157, 202, 224, 150],
  [106, 174, 214, 150],
  [48, 130, 189, 150],
  [10, 81, 156, 150],
];

export const COLOR_SET_2: Color[] = [
  [254, 236, 226, 150],
  [251, 197, 192, 150],
  [250, 159, 181, 150],
  [247, 104, 161, 150],
  [197, 28, 138, 150],
  [122, 2, 119, 150],
];

export const COLOR_SET_3: Color[] = [
  [255, 255, 204, 150],
  [216, 240, 163, 150],
  [173, 221, 142, 150],
  [120, 198, 121, 150],
  [50, 163, 84, 150],
  [1, 103, 55, 150],
];

export const COLOR_SET_4: Color[] = [
  [237, 248, 251, 150],
  [192, 210, 230, 150],
  [157, 188, 218, 150],
  [139, 150, 198, 150],
  [136, 86, 167, 150],
  [129, 14, 124, 150],
];

export function fromHex(hex: string): Color {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  const a = hex.length > 7 ? parseInt(hex.substring(7, 9), 16) : 255;
  return [r, g, b, a];
}

export function fromHexWithTransparency(hex: string, alpha: number): Color {
  const color = fromHex(hex);
  return [color[0], color[1], color[2], alpha];
}

export function toHex(color: Color): string {
  return (
    "#" +
    color
      .map((c) => Math.round(c).toString(16).padStart(2, "0"))
      .join("")
      .toUpperCase()
  );
}

export function randomColor(): Color {
  return [
    Math.floor(Math.random() * 255),
    Math.floor(Math.random() * 255),
    Math.floor(Math.random() * 255),
  ];
}

export function randomTransparentColor(alpha: number): Color {
  const color = randomColor();
  return [color[0], color[1], color[2], alpha];
}

export function getTransparentColor(alpha: number, color: number[]): Color {
  return [color[0], color[1], color[2], alpha];
}
