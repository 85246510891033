import { FeatureCollection, GeoJsonProperties, Polygon } from "geojson";
import { LayersConfig } from "../../../utils/appconfig";
import { Color } from "../../../utils/geo";
import { NumberRange } from "../../../utils/series";

export type FieldsDataItem = Record<string, number>;
export type FieldsData = Record<string, FieldsDataItem>;
export type GeojsonData = FeatureCollection<Polygon, GeoJsonProperties>;
export interface StatsDataItem {
  min: number;
  max: number;
  intervals: number[];
}
export type StatsData = Record<string, StatsDataItem>;
export const EMPTY_FIELDS_DATA: FieldsData = {};
export interface GeodataLayerData {
  geojson: GeojsonData;
  fields: FieldsData;
  stats: StatsData;
}

export type LayerDataItem = Record<string, any>;
export type LayerData = LayerDataItem[];

export interface IconLayerConfig {
  id: string;
  name: string;
  coordinates: (item: LayerDataItem) => [number, number];
  color: (item: LayerDataItem) => Color;
  propname: (item: LayerDataItem) => string;
  propaddress: (item: LayerDataItem) => string;
  loadOn: "mapmove" | "mapload";
  load: (lat: number, lon: number, zoom: number) => Promise<LayerData>;
}

export interface GeodataLayerState {
  data: Nullable<GeodataLayerData>;
  filters: Record<string, NumberRange>;
  filteredGeojson: Nullable<GeojsonData>;
  activeField: Nullable<string>;
}

export interface GeojsonLayerState {
  id: string;
  data: Nullable<GeojsonData>;
  visible: boolean;
}

export interface IconLayerState {
  id: string;
  data: LayerData;
  visible: boolean;
}

export interface LayerState {
  geodataLayers: Record<string, GeodataLayerState>;
  geojsonLayers: Record<string, GeojsonLayerState>;
  iconLayers: Record<string, IconLayerState>;
}

export const initialState = (layers: LayersConfig): LayerState => ({
  geodataLayers: Object.fromEntries(
    layers.geodataLayers.map((layer) => [
      layer.id,
      {
        data: null,
        filters: Object.fromEntries(
          Object.values(layer.filters).map((filter) => [
            filter,
            { min: -Infinity, max: Infinity },
          ])
        ),
        filteredGeojson: null,
        activeField: layer.initialField,
      },
    ])
  ),
  geojsonLayers: Object.fromEntries(
    layers.geojsonLayers.map((layer) => [
      layer.id,
      {
        id: layer.id,
        data: null,
        visible: false,
      },
    ])
  ),
  iconLayers: Object.fromEntries(
    layers.iconLayers.map((layer) => [
      layer.id,
      {
        id: layer.id,
        data: [],
        visible: false,
      },
    ])
  ),
});

export enum ActionTypes {
  LoadGeodataLayerData = "LoadGeodataLayerData",
  SetLayerActiveField = "SetLayerActiveField",
  SetLayerGroupVisible = "SetLayerGroupVisible",
  SetLayerFieldFilterRange = "SetLayerFieldFilterRange",
  LoadIconLayerData = "LoadIconLayerData",
  LoadGeojsonLayerData = "LoadGeojsonLayerData",
  SetVisibleIconLayers = "SetVisibleIconLayers",
  SetVisibleTemporaryIconLayer = "SetTemporaryIconLayer",
  RemoveTemporaryIconLayer = "RemoveTemporaryIconLayer",
  RemoveIconLayer = "RemoveIconLayer",
}

export interface Action {
  type: ActionTypes;
  payload: any;
}

export function reducer(state: LayerState, action: Action): LayerState {
  switch (action.type) {
    case ActionTypes.LoadGeodataLayerData:
      return {
        ...state,
        geodataLayers: {
          ...state.geodataLayers,
          [action.payload.id]: {
            ...state.geodataLayers[action.payload.id],
            data: action.payload.data,
            filteredGeojson: filterGeojson(
              action.payload.data,
              state.geodataLayers[action.payload.id].filters
            ),
          },
        },
      };
    case ActionTypes.SetLayerActiveField:
      return {
        ...state,
        geodataLayers: {
          ...state.geodataLayers,
          [action.payload.id]: {
            ...state.geodataLayers[action.payload.id],
            activeField: action.payload.field,
          },
        },
      };

    case ActionTypes.SetLayerGroupVisible:
      return {
        ...state,
        geojsonLayers: {
          ...state.geojsonLayers,
          ...Object.fromEntries(
            action.payload.geojson.map((id: string) => [
              id,
              { ...state.geojsonLayers[id], visible: action.payload.visible },
            ])
          ),
        },
        iconLayers: {
          ...state.iconLayers,
          ...Object.fromEntries(
            action.payload.icon.map((id: string) => [
              id,
              { ...state.iconLayers[id], visible: action.payload.visible },
            ])
          ),
        },
      };

    case ActionTypes.SetLayerFieldFilterRange: {
      const filters = {
        ...state.geodataLayers[action.payload.id].filters,
        [action.payload.field]: action.payload.range,
      };
      const geodata = state.geodataLayers[action.payload.id].data;
      const filteredGeojson = geodata ? filterGeojson(geodata, filters) : null;
      return {
        ...state,
        geodataLayers: {
          ...state.geodataLayers,
          [action.payload.id]: {
            ...state.geodataLayers[action.payload.id],
            filteredGeojson,
            filters,
          },
        },
      };
    }

    case ActionTypes.LoadIconLayerData:
      return {
        ...state,
        iconLayers: {
          ...state.iconLayers,
          [action.payload.id]: {
            ...state.iconLayers[action.payload.id],
            data: action.payload.data,
          },
        },
      };

    case ActionTypes.LoadGeojsonLayerData:
      return {
        ...state,
        geojsonLayers: {
          ...state.geojsonLayers,
          [action.payload.id]: {
            ...state.geojsonLayers[action.payload.id],
            data: action.payload.data,
          },
        },
      };

    case ActionTypes.SetVisibleIconLayers: {
      const arr = action.payload as string[];
      const visibleLayers = new Set(arr);
      Object.keys(state.iconLayers).forEach((key) => {
        state.iconLayers[key].visible = visibleLayers.has(key);
      });
      return {
        ...state,
        iconLayers: {
          ...state.iconLayers,
        },
      };
    }

    case ActionTypes.SetVisibleTemporaryIconLayer: {
      return {
        ...state,
        iconLayers: {
          ...state.iconLayers,
          [action.payload.id]: {
            id: action.payload.id,
            data: [],
            visible: true,
          },
        },
      };
    }

    case ActionTypes.RemoveTemporaryIconLayer: {
      const iconLayers = { ...state.iconLayers };

      Object.keys(iconLayers).map((iconLayer: string) => {
        if (iconLayers[iconLayer].data.length == 0)
          delete iconLayers[iconLayer];
      });

      return {
        ...state,
        iconLayers,
      };
    }

    case ActionTypes.RemoveIconLayer: {
      const iconLayers = { ...state.iconLayers };

      iconLayers[action.payload.id] = {
        id: action.payload.id,
        data: [],
        visible: false,
      };

      return {
        ...state,
        iconLayers,
      };
    }

    default:
      return state;
  }
}

function filterGeojson(
  data: GeodataLayerData,
  filters: Record<string, NumberRange>
): GeojsonData {
  JSON.stringify(
    data.geojson.features.filter(
      (feature) => feature.properties?.geoid === "T0H"
    )[1]
  );

  return {
    ...data.geojson,
    features: data.geojson.features.filter((f) => {
      if (!f.properties) return false;
      let properties: FieldsDataItem;

      if (data.fields === EMPTY_FIELDS_DATA) {
        properties = f.properties as FieldsDataItem;
      } else {
        const geoid = f.properties["geoid"];
        if (!geoid) return false;
        properties = data.fields[geoid];
      }
      if (!properties) return false;
      for (const [field, range] of Object.entries(filters)) {
        if (properties[field] < range.min || properties[field] > range.max) {
          return false;
        }
      }
      return true;
    }),
  };
}
