import { Dispatch } from "react";
import { GetDatasetFn, LoadDatasetFn } from "../hooks/useDataCache";
import { API_URL } from "../pages/templates/template02/constants";
import { BASE_APPDATA_URL, BASE_SHAPES_URL } from "./constants";
import {
  Action,
  ActionTypes,
  EMPTY_FIELDS_DATA,
  FieldsData,
  GeojsonData,
  LayerData,
  StatsData,
} from "../pages/templates/template02/reducer";
import {
  AppConfig,
  GeodataLayer,
  GeojsonLayer,
  IconLayer,
  LayersConfig,
} from "./appconfig";
import { zoomToRadius } from "./map";

function getBaseGeojsonUrl(shape: string, statefp: string): string {
  return `${BASE_SHAPES_URL}/${shape}/shapes_${statefp}.geojson.gz`;
}

function getBaseStatsUrl(
  shape: string,
  statefp: string,
  params: string[]
): string {
  const paramsStr = params.length > 0 ? "_" + params.join("_") : "";
  return `${BASE_SHAPES_URL}/${shape}/stats_${statefp}${paramsStr}.json`;
}

function getFieldsUrl(
  appName: string,
  layerName: string,
  statefp: string,
  params: string[]
): string {
  const paramsStr = params.length > 0 ? "_" + params.join("_") : "";
  return `${BASE_APPDATA_URL}/${appName}/layers/${layerName}/fields_${statefp}${paramsStr}.json.gz`;
}

function getStatsUrl(
  appName: string,
  layerName: string,
  statefp: string,
  params: string[]
): string {
  const paramsStr = params.length > 0 ? "_" + params.join("_") : "";
  return `${BASE_APPDATA_URL}/${appName}/layers/${layerName}/stats_${statefp}${paramsStr}.json`;
}

function getIconsCdnUrl(
  appName: string,
  layerName: string,
  statefp: string,
  nationwide: boolean,
  notCompressed?: boolean
): string {
  if (nationwide) {
    return `${BASE_APPDATA_URL}/${appName}/layers/${layerName}/icons.json${
      notCompressed ? "" : ".gz"
    }`;
  }
  return `${BASE_APPDATA_URL}/${appName}/layers/${layerName}/icons_${statefp}.json${
    notCompressed ? "" : ".gz"
  }`;
}

function getIconsApiUrl(
  appName: string,
  layerName: string,
  statefp: string,
  nationwide: boolean
): string {
  if (nationwide) {
    return `${API_URL}/apps/${appName}/icon-layers/${layerName}`;
  }
  return `${API_URL}/apps/${appName}/icon-layers/${layerName}?statefp=${statefp}`;
}

function getDynamicIconsApiUrl(
  appName: string,
  layerName: string,
  statefp: string,
  lat: number,
  lon: number,
  radius: number
): string {
  return `${API_URL}/apps/${appName}/dynamic-icon-layers/${layerName}?statefp=${statefp}&lat=${lat}&lon=${lon}&radius=${radius}`;
}

function getGeojsonCdnUrl(
  appName: string,
  layerName: string,
  statefp: string
): string {
  return `${BASE_APPDATA_URL}/${appName}/layers/${layerName}/shapes_${statefp}.geojson.gz`;
}

function getGeojsonApiUrl(
  appName: string,
  layerName: string,
  statefp: string
): string {
  return `${API_URL}/apps/${appName}/geojson-layers/${layerName}?statefp=${statefp}`;
}

class LayerLoader {
  appName: string;
  layers: LayersConfig;
  loadDataset: LoadDatasetFn;
  getDataset: GetDatasetFn;
  appConfig: AppConfig;

  constructor(
    appName: string,
    layers: LayersConfig,
    loadDataset: LoadDatasetFn,
    getDataset: GetDatasetFn,
    appConfig: AppConfig
  ) {
    this.appName = appName;
    this.layers = layers;
    this.loadDataset = loadDataset;
    this.getDataset = getDataset;
    this.appConfig = appConfig;
  }

  async loadGeodataLayer(
    layer: GeodataLayer,
    statefp: string | string[],
    dispatch: Dispatch<Action>,
    ...params: string[]
  ): Promise<void> {
    let [geojson, fields, stats]: [
      Nullable<GeojsonData>,
      Nullable<FieldsData>,
      Nullable<StatsData>
    ] = [null, null, null];

    console.log("loadGeodataLayer()", "statefp: ", statefp);

    if (Array.isArray(statefp)) {
      geojson = await statefp.reduce<Promise<GeojsonData>>(
        async (acc, statefp) => {
          (await acc).features.push(
            ...(await this.loadBaseGeojson(layer, statefp)).features
          );

          return acc;
        },
        Promise.resolve({ type: "FeatureCollection", features: [] })
      );

      fields = await statefp.reduce<Promise<FieldsData>>(
        async (acc, statefp) => {
          const fields = await this.loadFields(layer, statefp, params);
          return { ...acc, ...fields };
        },
        Promise.resolve({})
      );

      stats = await statefp.reduce<Promise<StatsData>>(async (acc, statefp) => {
        const stats = await this.loadStats(layer, statefp, params);
        return { ...acc, ...stats };
      }, Promise.resolve({}));
    } else {
      geojson = await this.loadBaseGeojson(layer, statefp);
      fields = await this.loadFields(layer, statefp, params);
      stats = await this.loadStats(layer, statefp, params);
    }

    console.log("geojson: ", geojson);
    console.log("fields: ", fields);
    console.log("stats: ", stats);

    dispatch({
      type: ActionTypes.LoadGeodataLayerData,
      payload: {
        id: layer.id,
        data: {
          geojson,
          fields,
          stats,
        },
      },
    });
  }

  async loadIconLayer(
    layer: IconLayer,
    statefp: string,
    dispatch: Dispatch<Action>
  ): Promise<void> {
    const data = await this.loadIcons(layer, statefp);
    dispatch({
      type: ActionTypes.LoadIconLayerData,
      payload: {
        id: layer.id,
        data,
      },
    });
  }

  async loadDynamicIconLayer(
    layer: IconLayer,
    statefp: string,
    lat: number,
    lon: number,
    zoom: number,
    dispatch: Dispatch<Action>
  ): Promise<void> {
    const data = await this.loadDynamicIcons(layer, statefp, lat, lon, zoom);
    dispatch({
      type: ActionTypes.LoadIconLayerData,
      payload: {
        id: layer.id,
        data,
      },
    });
  }

  async loadGeojsonLayer(
    layer: GeojsonLayer,
    statefp: string,
    dispatch: Dispatch<Action>
  ): Promise<void> {
    const data = await this.loadGeojson(layer, statefp);
    dispatch({
      type: ActionTypes.LoadGeojsonLayerData,
      payload: {
        id: layer.id,
        data,
      },
    });
  }

  async onMapLoad(
    statefp: string,
    dispatch: Dispatch<Action>,
    multiple?: boolean,
    aditionalStateCodes?: string[]
  ) {
    console.log(
      "onMapLoad",
      "statefp: ",
      statefp,
      "multiple: ",
      multiple,
      "aditionalStateCodes: ",
      aditionalStateCodes
    );

    const geodataPromises = this.layers.geodataLayers.map((layer) =>
      this.loadGeodataLayer(
        layer,
        multiple && aditionalStateCodes
          ? [...aditionalStateCodes].reverse()
          : statefp,
        dispatch
      )
    );

    console.log(
      "statefp to loadGeodataLayer(): ",
      multiple && aditionalStateCodes
        ? [...aditionalStateCodes].reverse()
        : statefp
    );

    const iconPromises = this.layers.iconLayers
      .filter((layer) => !layer.data.dynamic)
      .map((layer) => this.loadIconLayer(layer, statefp, dispatch));

    const geojsonPromises = this.layers.geojsonLayers
      .filter((layer) => !layer.data.dynamic)
      .map((layer) => this.loadGeojsonLayer(layer, statefp, dispatch));

    await Promise.all([
      ...geodataPromises,
      ...iconPromises,
      ...geojsonPromises,
    ]);
  }

  async onMapMove(
    visibleLayers: Set<string>,
    statefp: string,
    lat: number,
    lon: number,
    zoom: number,
    dispatch: Dispatch<Action>
  ) {
    const iconPromises = this.layers.iconLayers
      .filter(
        (layer) =>
          layer.data.dynamic &&
          visibleLayers.has(layer.id) &&
          zoom >= layer.minZoom
      )
      .map((layer) =>
        this.loadDynamicIconLayer(layer, statefp, lat, lon, zoom, dispatch)
      );
    await Promise.all(iconPromises);
  }

  private async loadBaseGeojson(
    layer: GeodataLayer,
    statefp: string
  ): Promise<GeojsonData> {
    const dataset = `${this.appName}/${layer.id}/${statefp}/${layer.shape}`;
    const url = getBaseGeojsonUrl(layer.shape, statefp);
    return await this.loadCached(dataset, url);
  }

  private async loadFields(
    layer: GeodataLayer,
    statefp: string,
    params: string[]
  ): Promise<FieldsData> {
    if (layer.fieldsEmbedded) return EMPTY_FIELDS_DATA;
    const dataset = `${this.appName}/${
      layer.id
    }/${statefp}/fields/${params.join("/")}`;
    const url = getFieldsUrl(this.appName, layer.id, statefp, params);
    return await this.loadCached(dataset, url);
  }

  private async loadStats(
    layer: GeodataLayer,
    statefp: string,
    params: string[]
  ): Promise<StatsData> {
    const dataset = `${this.appName}/${layer.id}/${statefp}/stats`;
    const url = layer.fieldsEmbedded
      ? getBaseStatsUrl(layer.shape, statefp, params)
      : getStatsUrl(this.appName, layer.id, statefp, params);
    return await this.loadCached(dataset, url);
  }

  private async loadIcons(
    layer: IconLayer,
    statefp: string
  ): Promise<LayerData> {
    const dataset = layer.nationwide
      ? `${this.appName}/${layer.id}/icons`
      : `${this.appName}/${layer.id}/${statefp}/icons`;
    let url: string;
    if (layer.data.source === "cdn") {
      url = getIconsCdnUrl(
        this.appName,
        layer.id,
        statefp,
        layer.nationwide,
        layer.data.notCompressed
      );
    } else if (layer.data.source === "api") {
      url = getIconsApiUrl(this.appName, layer.id, statefp, layer.nationwide);
    } else {
      throw new Error(`Invalid icon layer source: ${layer.data.source}`);
    }
    return await this.loadCached(dataset, url);
  }

  private async loadDynamicIcons(
    layer: IconLayer,
    statefp: string,
    lat: number,
    lon: number,
    zoom: number
  ): Promise<LayerData> {
    if (layer.data.source !== "api") {
      throw new Error(
        `Invalid icon layer source: ${layer.data.source} for layer ${layer.id}`
      );
    }
    const dataset = `${this.appName}/${layer.id}/${statefp}/dynamic-icons`;
    const radius = Math.min(Math.trunc(zoomToRadius(zoom)), 40000);
    const url = getDynamicIconsApiUrl(
      this.appName,
      layer.id,
      statefp,
      lat,
      lon,
      radius
    );
    return await this.loadDataset(dataset, url);
  }

  private async loadGeojson(
    layer: GeojsonLayer,
    statefp: string
  ): Promise<LayerData> {
    const dataset = `${this.appName}/${layer.id}/${statefp}/geojson`;
    let url: string;
    if (layer.data.source === "cdn") {
      url = getGeojsonCdnUrl(this.appName, layer.id, statefp);
    } else if (layer.data.source === "api") {
      url = getGeojsonApiUrl(this.appName, layer.id, statefp);
    } else {
      throw new Error(`Invalid geojson layer source: ${layer.data.source}`);
    }
    return await this.loadCached(dataset, url);
  }

  private async loadCached(dataset: string, url: string): Promise<any> {
    const cachedData = await this.getDataset(dataset);
    if (cachedData) {
      return cachedData;
    }
    const data = await this.loadDataset(dataset, url);
    return data;
  }
}

export default LayerLoader;
