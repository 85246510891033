import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
  Polygon,
} from "geojson";
import { AppConfig } from "../../../utils/appconfig";
import { COLOR_ICON_ORANGE } from "../../../utils/colors";
import { Color } from "../../../utils/geo";
import { MapPin } from "../../../utils/map";
import { TERRITORRY_FEATURES_COLORS_BY_TAGS } from "./constants";
import { User } from "@auth0/auth0-react";

export enum DropPinActionType {
  SetSearchPin = "setSearchPin",
  UnsetSearchPin = "unsetSearchPin",
  ConvertSearchPin = "convertSearchPin",
  AddPin = "addPin",
  RemovePin = "removePin",
  ClearPins = "clearPins",
  ModifyPin = "modifyPin",
  SelectPin = "selectPin",
  ClearSelection = "clearSelection",
  SetIsochrones = "setIsochrones",
  ClearIsochrones = "clearIsochrones",
}

export interface DropPinAction {
  type: DropPinActionType;
  payload: any;
}

export interface DroppedPinState {
  pins: MapPin[];
  searchPin?: MapPin;
  counter: number;
  selectedPinId?: number;
  isochroneMap: IsochroneMap;
}

export const initialDroppedPinState: DroppedPinState = {
  pins: [],
  searchPin: undefined,
  counter: 1,
  selectedPinId: undefined,
  isochroneMap: {},
};

export function dropPinReducer(
  state: DroppedPinState,
  action: DropPinAction
): DroppedPinState {
  switch (action.type) {
    case DropPinActionType.SetSearchPin:
      return {
        ...state,
        searchPin: {
          ...action.payload.pin,
          id: SEARCH_PIN_ID,
          color: COLOR_ICON_ORANGE,
        },
        selectedPinId: SEARCH_PIN_ID,
      };
    case DropPinActionType.UnsetSearchPin:
      return {
        ...state,
        searchPin: undefined,
      };
    case DropPinActionType.ConvertSearchPin:
      return {
        ...state,
        searchPin: undefined,
        pins: [
          ...state.pins,
          {
            ...state.searchPin!,
            id: state.counter,
            color: dropPinColor(state.counter),
          },
        ],
        counter: state.counter + 1,
      };
    case DropPinActionType.AddPin:
      return {
        ...state,
        pins: [
          ...state.pins,
          {
            ...action.payload,
            id: state.counter,
            color: dropPinColor(state.counter),
          },
        ],
        counter: state.counter + 1,
      };
    case DropPinActionType.RemovePin: {
      const updatedIsochroneMap = { ...state.isochroneMap };
      delete updatedIsochroneMap[action.payload.id];
      return {
        ...state,
        pins: state.pins.filter((pin) => pin.id !== action.payload.id),
        isochroneMap: updatedIsochroneMap,
      };
    }
    case DropPinActionType.SetIsochrones:
      return {
        ...state,
        isochroneMap: action.payload.updatedIsochroneMap,
      };
    case DropPinActionType.ClearIsochrones:
      return {
        ...state,
        isochroneMap: {},
      };
    case DropPinActionType.ClearPins:
      return {
        ...state,
        pins: [],
      };
    case DropPinActionType.ModifyPin:
      return {
        ...state,
        pins: state.pins.map((pin) => {
          if (pin.id === action.payload.id) {
            return {
              ...pin,
              ...action.payload,
            };
          }
          return pin;
        }),
      };
    case DropPinActionType.SelectPin:
      return {
        ...state,
        selectedPinId: action.payload.id,
      };
    case DropPinActionType.ClearSelection:
      return {
        ...state,
        selectedPinId: undefined,
      };
    default:
      return state;
  }
}

export const SEARCH_PIN_ID = -1;

export const PIN_COLORS: Color[] = [
  [6, 182, 212], // Cyan
  [255, 193, 48], // Yellow
  [0, 145, 58], // Green
  [0, 118, 250], // Blue
  [239, 68, 68], // Red
  [168, 85, 247], // Purple
  [20, 184, 166], // Teal
  [197, 94, 34], // Piper
  [236, 72, 153], // Pink
  [132, 204, 22], // Lime
];

export function dropPinColor(index: number): Color {
  return PIN_COLORS[index % PIN_COLORS.length];
}

export type IsochroneMap = {
  [key: number]: FeatureCollection<Geometry, GeoJsonProperties>;
};

export type SummaryMap = {
  [key: number]: Summary;
};

export interface Summary {
  [key: string]: number;
}

export enum DistanceProfile {
  Walking = "walking",
  Cycling = "cycling",
  Driving = "driving",
}

export enum EvaluateTabActionType {
  SetDistanceProfile = "SetDistanceProfile",
  SetDistanceMinutes = "SetDistanceMinutes",
  SetSummary = "SetSummary",
  SetLoading = "SetLoading",
  SetError = "SetError",
  SetRingRadiusEnabled = "SetRingRadiusEnabled",
  SetShowDrivingBoundary = "SetShowDrivingBoundary",
  SetSummaryMap = "SetSummaryMap",
  ClearSummaryMap = "ClearSummaryMap",
  RemoveSummary = "RemoveSummary",
  SetRingRadiusMiles = "SetRingRadiusMiles",
}

export interface EvaluateTabAction {
  type: EvaluateTabActionType;
  payload: any;
}

export interface EvaluateTabState {
  distanceProfile: DistanceProfile;
  distanceMinutes: number;
  summary: Summary[];
  loading: boolean;
  error: string;
  ringRadiusEnabled: boolean;
  ringRadiusMiles: number;
  showDrivingBoundary: boolean;
  summaryMap: SummaryMap;
}

const DEFAULT_DISTANCE_PROFILE = DistanceProfile.Driving;
const DEFAULT_DISTANCE_MINUTES = 30;

export function initialEvaluateTabState(
  distanceMinutes: number = DEFAULT_DISTANCE_MINUTES,
  distanceProfile: DistanceProfile = DEFAULT_DISTANCE_PROFILE
): EvaluateTabState {
  return {
    distanceProfile,
    distanceMinutes,
    summary: [],
    loading: false,
    error: "",
    ringRadiusEnabled: false,
    ringRadiusMiles: 5,
    showDrivingBoundary: true,
    summaryMap: {},
  };
}

export function evaluateTabReducer(
  state: EvaluateTabState,
  action: EvaluateTabAction
) {
  switch (action.type) {
    case EvaluateTabActionType.SetDistanceProfile:
      return {
        ...state,
        distanceProfile: action.payload as DistanceProfile,
      };
    case EvaluateTabActionType.SetDistanceMinutes:
      return {
        ...state,
        distanceMinutes: action.payload as number,
      };
    case EvaluateTabActionType.SetRingRadiusMiles:
      return {
        ...state,
        ringRadiusMiles: action.payload as number,
      };
    case EvaluateTabActionType.SetSummary:
      return {
        ...state,
        summary: action.payload as Summary[],
      };
    case EvaluateTabActionType.SetLoading:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case EvaluateTabActionType.SetError:
      return {
        ...state,
        error: action.payload as string,
      };
    case EvaluateTabActionType.SetRingRadiusEnabled:
      return {
        ...state,
        ringRadiusEnabled: action.payload as boolean,
      };
    case EvaluateTabActionType.SetShowDrivingBoundary:
      return {
        ...state,
        showDrivingBoundary: action.payload as boolean,
      };
    case EvaluateTabActionType.SetSummaryMap:
      return {
        ...state,
        summaryMap: action.payload as SummaryMap,
      };
    case EvaluateTabActionType.ClearSummaryMap:
      return {
        ...state,
        summaryMap: {},
      };
    case EvaluateTabActionType.RemoveSummary: {
      const updatedSummaryMap = { ...state.summaryMap };
      delete updatedSummaryMap[action.payload.id];
      return {
        ...state,
        summaryMap: updatedSummaryMap,
        summary: Object.values(updatedSummaryMap),
      };
    }
    default:
      return state;
  }
}

export function initialPolygonTabState(
  appConfig: AppConfig,
  user: User | undefined
): PolygonTabState {
  return {
    currentProfile: "Default",
    options: ["Default"],
    currentProfileId: 0,
    selectedPolygon: null,
    profilesMap: {},
    groups: [],
    changes: false,
    saving: true,
    readOnly:
      appConfig.id === "app15hellosugar" &&
      user &&
      user.email === "ana@hellosugar.salon"
        ? true
        : false,
  };
}

export interface PolygonTabState {
  currentProfile: string;
  options: string[];
  currentProfileId: number;
  selectedPolygon: Feature<Polygon, GeoJsonProperties> | null;
  profilesMap: Record<number, FeatureCollection<Polygon, GeoJsonProperties>>;
  groups: PolygonGroup[];
  changes: boolean;
  saving: boolean;
  readOnly: boolean;
}

export interface PolygonGroup {
  id: number;
  name: string;
  state: string;
  features: Feature<Polygon, GeoJsonProperties>[];
}

export interface PolygonSummary {
  [key: number]: {
    name: string;
    color: string;
    summary: {
      [key: string]: number;
    };
  }[];
}

export enum PolygonActionType {
  SetGroups = "setGroups",
  SetActiveProfile = "setSearchPin",
  SetOptions = "setOptions",
  SetSelectedPolygon = "setSelectedPolygon",
  SetProfileId = "setProfileId",
  ClearSelectedPolygon = "clearSelectedPolygon",
  SetProfileMap = "setProfileMap",
  RemovePolygon = "removePolygon",
  MovePolygon = "movePolygon",
  AddPolygon = "addPolygon",
  SetNoChanges = "setNoChanges",
  Saving = "saving",
  Saved = "saved",
  Rename = "rename",
  RenameGroup = "renameGroup",
  Remove = "remove",
  AddNewGroup = "addNewGroup",
  ToggleTag = "ToggleTag",
}

export interface PolygonAction {
  type: PolygonActionType;
  payload: any;
}

export function polygonReducer(
  state: PolygonTabState,
  action: PolygonAction
): PolygonTabState {
  switch (action.type) {
    case PolygonActionType.SetActiveProfile:
      return {
        ...state,
        currentProfile: action.payload,
      };
    case PolygonActionType.SetOptions:
      return {
        ...state,
        options: action.payload,
      };
    case PolygonActionType.SetSelectedPolygon:
      return {
        ...state,
        selectedPolygon: action.payload,
      };
    case PolygonActionType.ClearSelectedPolygon:
      return {
        ...state,
        selectedPolygon: null,
      };
    case PolygonActionType.SetProfileId:
      return {
        ...state,
        currentProfileId: action.payload,
      };
    case PolygonActionType.SetProfileMap:
      return {
        ...state,
        profilesMap: action.payload,
      };
    case PolygonActionType.RemovePolygon: {
      const currentFeatures = state.profilesMap[state.currentProfileId];
      const newFeatures = currentFeatures?.features.filter(
        (f) => f !== action.payload
      );
      const newFeatureCollection: FeatureCollection<
        Polygon,
        GeoJsonProperties
      > = {
        type: "FeatureCollection",
        features: newFeatures ? newFeatures : [],
      };
      const newRecord = { ...state.profilesMap };
      newRecord[state.currentProfileId] = newFeatureCollection;

      return {
        ...state,
        profilesMap: newRecord,
        selectedPolygon: null,
      };
    }
    case PolygonActionType.SetGroups: {
      return {
        ...state,
        changes: action.payload.changes ?? false,
        groups: action.payload.groups,
      };
    }
    case PolygonActionType.MovePolygon: {
      const {
        featureIndex,
        groupIndex,
        direction,
        selectedState,
      }: {
        featureIndex: number;
        groupIndex: number;
        direction: "up" | "down";
        selectedState: string;
      } = action.payload;

      if (
        featureIndex === undefined ||
        featureIndex === null ||
        groupIndex === undefined ||
        groupIndex === null ||
        !direction
      )
        return state;

      let groups = [...state.groups].filter(
        (group) => group.state === selectedState || !group.state
      );

      const feature = { ...groups[groupIndex].features[featureIndex] };

      groups[groupIndex].features = groups[groupIndex].features.filter(
        (_, index) => featureIndex !== index
      );

      groups[groupIndex + (direction === "up" ? -1 : 1)].features.push(feature);

      groups = [
        ...groups,
        ...state.groups.filter(
          (group) => group.state !== selectedState && group.state
        ),
      ];

      groups.sort((a, b) => {
        if (a.name === "Default") return -1;
        if (b.name === "Default") return 1;
        return a.name.localeCompare(b.name);
      });

      groups.forEach((group) => {
        group.features.sort((a, b) =>
          a.properties?.name?.localeCompare(b.properties?.name ?? "")
        );
      });

      return {
        ...state,
        groups: groups,
        changes: true,
      };
    }
    case PolygonActionType.AddPolygon: {
      let feature: Feature<Polygon, GeoJsonProperties> = action.payload;

      const groups = [...state.groups];

      const nameInUseCount = groups
        .map((group) => group.features)
        .flat(1)
        .filter((f) =>
          f.properties?.name.includes(feature.properties?.name ?? "")
        );

      let name = feature.properties?.name ?? "Untitled Polygon";

      if (nameInUseCount.length === 1) {
        name += " (1)";
      } else if (nameInUseCount.length >= 1) {
        const max = Math.max(
          ...nameInUseCount
            .map((feature) =>
              feature.properties?.name.match(/(?<=\()\d+(?=\))/g)
            )
            .filter((s) => s)
            .flat(1)
            .map((feature) => parseInt(feature))
        );
        name += ` (${max + 1})`;
      }

      feature = {
        ...feature,
        properties: {
          ...feature.properties,
          name: name,
        },
      };

      groups[0].features.push(feature);

      groups[0].features.sort((a, b) =>
        a.properties?.name?.localeCompare(b.properties?.name ?? "")
      );

      return {
        ...state,
        groups: groups,
        changes: true,
      };
    }
    case PolygonActionType.SetNoChanges: {
      return {
        ...state,
        changes: false,
      };
    }
    case PolygonActionType.Saving: {
      return {
        ...state,
        saving: true,
      };
    }
    case PolygonActionType.Saved: {
      return {
        ...state,
        saving: false,
      };
    }
    case PolygonActionType.Rename: {
      const { feature } = action.payload;
      const { name } = action.payload;

      if (!name || name.length === 0) return state;

      const groups = [...state.groups];

      const groupIndex = groups.findIndex((group) =>
        group.features.find(
          (f) => f.properties?.color === feature.properties?.color
        )
      );

      groups[groupIndex].features = groups[groupIndex].features.map((f) => {
        if (f.properties?.color === feature.properties.color) {
          return {
            ...f,
            properties: {
              ...f.properties,
              name: name,
            },
          };
        }
        return f;
      });

      groups[groupIndex].features.sort((a, b) =>
        a.properties?.name?.localeCompare(b.properties?.name ?? "")
      );

      return {
        ...state,
        groups: groups,
        changes: true,
      };
    }
    case PolygonActionType.Remove: {
      const { feature, group } = action.payload;

      const groups = [...state.groups];

      const groupIndex = groups.findIndex((g) => g.id === group.id);

      groups[groupIndex].features = groups[groupIndex].features.filter(
        (f) => f.properties?.color !== feature.properties?.color
      );

      const featureIndex = groups[groupIndex].features.findIndex(
        (f) => f.properties?.color === feature.properties?.color
      );

      groups[groupIndex].features = groups[groupIndex].features.filter(
        (_, index) => featureIndex !== index
      );

      return {
        ...state,
        groups: groups,
        changes: true,
        selectedPolygon:
          state.selectedPolygon?.properties?.color ===
          feature?.properties?.color
            ? null
            : state.selectedPolygon,
      };
    }
    case PolygonActionType.RenameGroup: {
      const { groupIndex, name } = action.payload;

      const groups = [...state.groups];

      groups[groupIndex].name = name;

      groups.sort((a, b) => {
        if (a.name === "Default") return -1;
        if (b.name === "Default") return 1;
        return a.name.localeCompare(b.name);
      });

      return {
        ...state,
        groups: groups,
      };
    }
    case PolygonActionType.AddNewGroup: {
      const { id, selectedState } = action.payload;

      const groups = [...state.groups];

      groups.push({
        id,
        name: "Untitled Group",
        state: selectedState,
        features: [],
      });

      return {
        ...state,
        groups: groups,
      };
    }
    case PolygonActionType.ToggleTag: {
      const { featureIndex, groupIndex, selectedState } = action.payload;

      let groups = [...state.groups].filter(
        (group) => group.state === selectedState || !group.state
      );

      const feature = { ...groups[groupIndex].features[featureIndex] };

      const tag = feature.properties?.tag || "Default";

      const tagKeys = Object.keys(TERRITORRY_FEATURES_COLORS_BY_TAGS);

      const tagIndex = tagKeys.findIndex((t) => t === tag);

      const nextTag = tagKeys[(tagIndex + 1) % tagKeys.length];

      feature.properties = {
        ...feature.properties,
        tag: nextTag,
      };

      groups[groupIndex].features[featureIndex] = feature;

      groups = [
        ...groups,
        ...state.groups.filter(
          (group) => group.state !== selectedState && group.state
        ),
      ];

      return {
        ...state,
        groups: groups,
        changes: true,
      };
    }

    default:
      return state;
  }
}
