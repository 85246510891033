import { Tab } from "../../../components/HeadingMenu";

export enum MenuTabs {
  Explore = "explore",
  Evaluate = "evaluate",
  Polygon = "polygon",
  Visitation = "visitation",
  Territory = "territory",
}

export const MENU_TABS: Tab[] = [
  { name: "Explore", key: MenuTabs.Explore },
  { name: "Evaluate", key: MenuTabs.Evaluate },
  { name: "Territory", key: MenuTabs.Polygon },
  { name: "Visitation", key: MenuTabs.Visitation },
  { name: "Territory", key: MenuTabs.Territory },
];

export const BASE_URL = "https://d1r2imh3bsgl8k.cloudfront.net";

export const API_URL = process.env.REACT_APP_API_URL;

export const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "invalid_token";

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
  marker2: { x: 128, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
};

export const ICON_MAPPING_COLOR = {
  marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: false },
  marker2: { x: 128, y: 0, width: 128, height: 128, anchorY: 128, mask: false },
  marker3: { x: 256, y: 0, width: 128, height: 128, anchorY: 128, mask: false },
};

export const PANEL_WIDTH = 360;

export const MAX_PINS = 8;

export type TerritoryFeaturesColorsByTagsKeys =
  keyof typeof TERRITORRY_FEATURES_COLORS_BY_TAGS;

export const TERRITORRY_FEATURES_COLORS_BY_TAGS = {
  "Existing Territory": "#D9544D",
  "Potential Territory": "#FADA5E",
  Zone: "#A9A9A9",
};

export type TerritoryFeaturesColorsByTagsKeys911 =
  keyof typeof TERRITORRY_FEATURES_COLORS_BY_TAGS_911;

export const TERRITORRY_FEATURES_COLORS_BY_TAGS_911 = {
  active: "#1AFF1A",
  pending: "#4B0092",
};
