import React, { FC, useMemo } from "react";
import { useDataCache } from "../../../hooks/useDataCache";
import { zoomToRadius } from "../../../utils/map";
import { PoiItem, nearbySearch } from "../../../utils/poi";
import { US_STATE_CODE_MAP, UsState } from "../../../utils/us";
import Template01 from "../../templates/template01";
import {
  DataLoadResults,
  IDataProvider,
} from "../../templates/template01/IDataProvider";
import { DataField } from "../../templates/template01/data";
import {
  IconLayerConfig,
  LayerConfig,
  LayerData,
  LayerDataItem,
} from "../../templates/template01/reducer";

export const LAYERS_FIELD_LIST = [
  DataField.TotalPop,
  DataField.MedianAge,
  DataField.HouseholdIncome,
  DataField.HhChildP,
  DataField.PopulationWhite,
  DataField.PopulationHispanic,
  DataField.PopulationBlack,
  DataField.PopulationAsian,
  DataField.TotalCrime,
  DataField.TotalBusinesses,
  DataField.TotalEmployees,
  DataField.BachelorPercent,
  DataField.HomeVal,
  DataField.HomeVal5yGrow,
];

const SUMMARY_FIELD_LIST = [
  DataField.TotalPop,
  DataField.MedianAge,
  DataField.HouseholdIncome,
  DataField.HhChildP,
  DataField.PopulationWhite,
  DataField.PopulationHispanic,
  DataField.PopulationBlack,
  DataField.PopulationAsian,
  DataField.TotalCrime,
  DataField.TotalBusinesses,
  DataField.TotalEmployees,
  DataField.BachelorPercent,
  DataField.HomeVal,
  DataField.HomeVal5yGrow,
];

const BASE_SHAPES_URL = "https://dfb6p47bmcaq.cloudfront.net";
const BASE_APP_URL = "https://d2g38wqpw98wv4.cloudfront.net";

const US_STATES = [
  UsState.Alabama,
  UsState.Arizona,
  UsState.Arkansas,
  UsState.California,
  UsState.Colorado,
  // UsState.Connecticut,
  UsState.Delaware,
  UsState.DistrictOfColumbia,
  UsState.Florida,
  UsState.Georgia,
  UsState.Hawaii,
  UsState.Idaho,
  UsState.Illinois,
  UsState.Indiana,
  UsState.Iowa,
  UsState.Kansas,
  UsState.Kentucky,
  UsState.Louisiana,
  UsState.Maine,
  UsState.Maryland,
  UsState.Massachusetts,
  UsState.Michigan,
  UsState.Minnesota,
  UsState.Mississippi,
  UsState.Missouri,
  UsState.Montana,
  UsState.Nebraska,
  UsState.Nevada,
  UsState.NewHampshire,
  UsState.NewJersey,
  UsState.NewMexico,
  UsState.NewYork,
  UsState.NorthCarolina,
  UsState.NorthDakota,
  UsState.Ohio,
  UsState.Oklahoma,
  UsState.Oregon,
  UsState.Pennsylvania,
  UsState.RhodeIsland,
  UsState.SouthCarolina,
  UsState.SouthDakota,
  UsState.Tennessee,
  UsState.Texas,
  UsState.Utah,
  UsState.Vermont,
  UsState.Virginia,
  UsState.Washington,
  UsState.WestVirginia,
  UsState.Wisconsin,
  UsState.Wyoming,
];

class AppDataProvider implements IDataProvider {
  loadDataset: (dataset: string, url: string) => Promise<any>;
  getDataset: (dataset: string) => any;

  constructor(
    loadDataset: (dataset: string, url: string) => Promise<any>,
    getDataset: (dataset: string) => any
  ) {
    this.loadDataset = loadDataset;
    this.getDataset = getDataset;
  }

  getGeoDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `bg/tl_2022_${code}_bg_001_mc.geojson.gz`;
  }

  getDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `usa_bg_data_${code}.json.gz`;
  }

  getStatsDatasetName(usState: UsState): string {
    const code = US_STATE_CODE_MAP.get(usState);
    return `usa_bg_data_stats_${code}.json`;
  }

  async load(usState: UsState): Promise<DataLoadResults> {
    const geoDatasetName = this.getGeoDatasetName(usState);
    const geoUrl = `${BASE_SHAPES_URL}/${geoDatasetName}`;
    const geo = await this.loadDataset(geoDatasetName, geoUrl);
    const dataDatasetName = this.getDatasetName(usState);
    const dataUrl = `${BASE_APP_URL}/${dataDatasetName}`;
    const data = await this.loadDataset(dataDatasetName, dataUrl);
    const statsDatasetName = this.getStatsDatasetName(usState);
    const statsUrl = `${BASE_APP_URL}/${statsDatasetName}`;
    const stats = await this.loadDataset(statsDatasetName, statsUrl);
    return { geo, data, stats };
  }
}

function queryPoiLayer(
  id: string,
  name: string,
  keywords: string,
  category: string,
  color: string
): IconLayerConfig {
  return {
    id,
    name,
    coordinates: (d: LayerDataItem): [number, number] => {
      const { lat, lon } = d as PoiItem;
      return [lon, lat];
    },
    color,
    propname: (d: LayerDataItem): string => {
      const item = d as PoiItem;
      return item.name;
    },
    propaddress: (d: LayerDataItem): string => {
      const item = d as PoiItem;
      return item.address.join(", ");
    },
    loadOn: "mapmove",
    load: async (
      lat: number,
      lon: number,
      zoom: number
    ): Promise<LayerData> => {
      if (zoom < 11) return [];
      const radius = zoomToRadius(zoom);
      return await nearbySearch(keywords, lat, lon, radius, category);
    },
  };
}

const layerConfig: LayerConfig = {
  iconLayers: [
    queryPoiLayer(
      "japanese_restaurants",
      "Japanese restaurants",
      "japanese restaurant",
      "restaurant",
      "red"
    ),
    queryPoiLayer(
      "all_restaurants",
      "All restaurants",
      "restaurant",
      "restaurant",
      "orange"
    ),
  ],
};

const App06RakkanRamen: FC<{}> = () => {
  const { loadDataset, getDataset } = useDataCache();
  const dataProvider = useMemo(
    () => new AppDataProvider(loadDataset, getDataset),
    []
  );
  return (
    <Template01
      title="App06RakkanRamen"
      usStates={US_STATES}
      layerFields={LAYERS_FIELD_LIST}
      summaryFields={SUMMARY_FIELD_LIST}
      fieldGroups={[]}
      dataProvider={dataProvider}
      useApi={true}
      showPois={false}
      layerConfig={layerConfig}
      nationwideStorePointsUrl="https://geodatasets-public.s3.us-west-2.amazonaws.com/app06rakkanramen/locations/locations-nationwide-point.json"
    />
  );
};

export default App06RakkanRamen;
