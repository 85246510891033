import React, { FC } from "react";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { AppConfig } from "../../../utils/appconfig";
import { MapPin, formatMapPin, formatMapPinCoords } from "../../../utils/map";
import { Summary } from "./types";
import { MAX_PINS } from "./constants";

interface Props {
  appConfig: AppConfig;
  pins: MapPin[];
  summaries: Summary[];
  summaryFields?: string[];
  summaryPois?: string[];
  onClose: () => void;
}

const LocationSummaryTable: FC<Props> = ({
  appConfig,
  pins,
  summaries,
  summaryFields,
  summaryPois,
  onClose,
}) => {
  if (!summaryFields || summaries.length < 2) return null;
  return (
    <div className="px-4 sm:px-6 lg:px-8 relative">
      <div className="absolute h-full top-0 -right-6 z-20 flex items-start">
        <button onClick={onClose} className="sticky top-3">
          <XMarkIcon className="text-gray-500 w-5 h-5 inline-block cursor-pointer duration-300 hover:text-gray-800" />
        </button>
      </div>
      <div className="mt-2 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full pb-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-2 lg:pl-3"
                  >
                    Metric
                  </th>
                  {[...pins.slice(0, MAX_PINS)].map((pin, idx) => (
                    <th
                      key={idx}
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell max-w-sm"
                    >
                      <div className="flex flex-col items-center">
                        <div>
                          <MapPinIcon
                            className="inline-block w-5 h-5 mb-1"
                            style={{ color: `rgb(${pin.color.join(",")})` }}
                          />
                        </div>
                        <div className="flex flex-col text-center">
                          <div>{formatMapPin(pin)}</div>
                          {pin.name && (
                            <div className="text-xs mt-1 text-gray-500">
                              {formatMapPinCoords(pin)}
                            </div>
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {summaryFields.map((fieldName, idx, { length }) => (
                  <TableRow
                    key={idx}
                    last={idx == length - 1}
                    header={appConfig.fields[fieldName].name}
                    summaries={summaries}
                    valueFn={(summary) =>
                      appConfig.fields[fieldName].format(summary[fieldName])
                    }
                  />
                ))}
                {summaryPois?.map((poi, idx) => (
                  <TableRow
                    key={idx}
                    header={poi}
                    summaries={summaries}
                    valueFn={(summary) => summary[poi].toLocaleString()}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableRow: FC<{
  header: string;
  summaries: Summary[];
  valueFn: (summary: Summary) => string;
  last?: boolean;
}> = ({ header, summaries, valueFn, last }) => (
  <tr>
    <td
      width="10px"
      className={classNames(
        !last && "border-b border-gray-200",
        "w-56 py-2 pl-5 pr-3 text-sm font-medium text-gray-900 sm:pl-2 lg:pl-3"
      )}
    >
      {header}
    </td>
    {summaries.map((summary, summaryIdx) => (
      <td
        key={`${summaryIdx}`}
        className={classNames(
          !last && "border-b border-gray-200",
          "whitespace-nowrap text-center hidden px-3 py-2 text-sm text-gray-500 sm:table-cell"
        )}
      >
        {valueFn(summary)}
      </td>
    ))}
    <td></td>
  </tr>
);

export default LocationSummaryTable;
