import classNames from "classnames";
import React, { FC, MouseEventHandler, ReactNode } from "react";

export const Button: FC<{
  children?: ReactNode;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  destructive?: boolean;
  className?: string;
}> = ({ children, onClick, disabled, destructive, className }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        "rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600",
        className,
        disabled
          ? "bg-gray-400 hover:bg-gray-500"
          : destructive
          ? "bg-red-600 hover:bg-red-400"
          : "bg-indigo-600 hover:bg-indigo-500"
      )}
    >
      {children}
    </button>
  );
};
