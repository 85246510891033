import { strict as assert } from "assert";

export enum UsState {
  Alabama = "Alabama",
  Alaska = "Alaska",
  Arizona = "Arizona",
  Arkansas = "Arkansas",
  California = "California",
  Colorado = "Colorado",
  Connecticut = "Connecticut",
  Delaware = "Delaware",
  DistrictOfColumbia = "District of Columbia",
  Florida = "Florida",
  Georgia = "Georgia",
  Hawaii = "Hawaii",
  Idaho = "Idaho",
  Illinois = "Illinois",
  Indiana = "Indiana",
  Iowa = "Iowa",
  Kansas = "Kansas",
  Kentucky = "Kentucky",
  Louisiana = "Louisiana",
  Maine = "Maine",
  Maryland = "Maryland",
  Massachusetts = "Massachusetts",
  Michigan = "Michigan",
  Minnesota = "Minnesota",
  Mississippi = "Mississippi",
  Missouri = "Missouri",
  Montana = "Montana",
  Nebraska = "Nebraska",
  Nevada = "Nevada",
  NewHampshire = "New Hampshire",
  NewJersey = "New Jersey",
  NewMexico = "New Mexico",
  NewYork = "New York",
  NorthCarolina = "North Carolina",
  NorthDakota = "North Dakota",
  Ohio = "Ohio",
  Oklahoma = "Oklahoma",
  Oregon = "Oregon",
  Pennsylvania = "Pennsylvania",
  RhodeIsland = "Rhode Island",
  SouthCarolina = "South Carolina",
  SouthDakota = "South Dakota",
  Tennessee = "Tennessee",
  Texas = "Texas",
  Utah = "Utah",
  Vermont = "Vermont",
  Virginia = "Virginia",
  Washington = "Washington",
  WestVirginia = "West Virginia",
  Wisconsin = "Wisconsin",
  Wyoming = "Wyoming",
  NewfoundlandAndLabrador = "Newfoundland and Labrador",
  PrinceEdwardIsland = "Prince Edward Island",
  NovaScotia = "Nova Scotia",
  NewBrunswick = "New Brunswick",
  Quebec = "Quebec",
  Ontario = "Ontario",
  Manitoba = "Manitoba",
  Saskatchewan = "Saskatchewan",
  Alberta = "Alberta",
  BritishColumbia = "British Columbia",
  Yukon = "Yukon",
  NorthwestTerritories = "Northwest Territories",
}

// assert(Object.keys(UsState).length === 51, "Missing state name mapping (enum)");

export const US_STATE_CODE_MAP = new Map<UsState, string>([
  [UsState.Alabama, "01"],
  [UsState.Alaska, "02"],
  [UsState.Arizona, "04"],
  [UsState.Arkansas, "05"],
  [UsState.California, "06"],
  [UsState.Colorado, "08"],
  [UsState.Connecticut, "09"],
  [UsState.Delaware, "10"],
  [UsState.DistrictOfColumbia, "11"],
  [UsState.Florida, "12"],
  [UsState.Georgia, "13"],
  [UsState.Hawaii, "15"],
  [UsState.Idaho, "16"],
  [UsState.Illinois, "17"],
  [UsState.Indiana, "18"],
  [UsState.Iowa, "19"],
  [UsState.Kansas, "20"],
  [UsState.Kentucky, "21"],
  [UsState.Louisiana, "22"],
  [UsState.Maine, "23"],
  [UsState.Maryland, "24"],
  [UsState.Massachusetts, "25"],
  [UsState.Michigan, "26"],
  [UsState.Minnesota, "27"],
  [UsState.Mississippi, "28"],
  [UsState.Missouri, "29"],
  [UsState.Montana, "30"],
  [UsState.Nebraska, "31"],
  [UsState.Nevada, "32"],
  [UsState.NewHampshire, "33"],
  [UsState.NewJersey, "34"],
  [UsState.NewMexico, "35"],
  [UsState.NewYork, "36"],
  [UsState.NorthCarolina, "37"],
  [UsState.NorthDakota, "38"],
  [UsState.Ohio, "39"],
  [UsState.Oklahoma, "40"],
  [UsState.Oregon, "41"],
  [UsState.Pennsylvania, "42"],
  [UsState.RhodeIsland, "44"],
  [UsState.SouthCarolina, "45"],
  [UsState.SouthDakota, "46"],
  [UsState.Tennessee, "47"],
  [UsState.Texas, "48"],
  [UsState.Utah, "49"],
  [UsState.Vermont, "50"],
  [UsState.Virginia, "51"],
  [UsState.Washington, "53"],
  [UsState.WestVirginia, "54"],
  [UsState.Wisconsin, "55"],
  [UsState.Wyoming, "56"],
  [UsState.NewfoundlandAndLabrador, "57"],
  [UsState.PrinceEdwardIsland, "58"],
  [UsState.NovaScotia, "59"],
  [UsState.NewBrunswick, "60"],
  [UsState.Quebec, "61"],
  [UsState.Ontario, "62"],
  [UsState.Manitoba, "63"],
  [UsState.Saskatchewan, "64"],
  [UsState.Alberta, "65"],
  [UsState.BritishColumbia, "66"],
  [UsState.Yukon, "67"],
  [UsState.NorthwestTerritories, "68"],
]);

// assert(
//   US_STATE_CODE_MAP.size === Object.keys(UsState).length,
//   "Missing state code mapping (FIPS)"
// );

export const US_POSTAL_CODE_TO_FIPS = new Map<string, string>([
  ["AL", "01"],
  ["AK", "02"],
  ["AZ", "04"],
  ["AR", "05"],
  ["CA", "06"],
  ["CO", "08"],
  ["CT", "09"],
  ["DE", "10"],
  ["DC", "11"],
  ["FL", "12"],
  ["GA", "13"],
  ["HI", "15"],
  ["ID", "16"],
  ["IL", "17"],
  ["IN", "18"],
  ["IA", "19"],
  ["KS", "20"],
  ["KY", "21"],
  ["LA", "22"],
  ["ME", "23"],
  ["MD", "24"],
  ["MA", "25"],
  ["MI", "26"],
  ["MN", "27"],
  ["MS", "28"],
  ["MO", "29"],
  ["MT", "30"],
  ["NE", "31"],
  ["NV", "32"],
  ["NH", "33"],
  ["NJ", "34"],
  ["NM", "35"],
  ["NY", "36"],
  ["NC", "37"],
  ["ND", "38"],
  ["OH", "39"],
  ["OK", "40"],
  ["OR", "41"],
  ["PA", "42"],
  ["RI", "44"],
  ["SC", "45"],
  ["SD", "46"],
  ["TN", "47"],
  ["TX", "48"],
  ["UT", "49"],
  ["VT", "50"],
  ["VA", "51"],
  ["WA", "53"],
  ["WV", "54"],
  ["WI", "55"],
  ["WY", "56"],
  ["NL", "57"],
  ["PE", "58"],
  ["NS", "59"],
  ["NB", "60"],
  ["QC", "61"],
  ["ON", "62"],
  ["MB", "63"],
  ["SK", "64"],
  ["AB", "65"],
  ["BC", "66"],
  ["YT", "67"],
  ["NT", "68"],
]);

// assert(
//   US_POSTAL_CODE_TO_FIPS.size === Object.keys(UsState).length,
//   "Missing state code mapping (FIPS)"
// );

export const US_POSTAL_CODE_MAP = new Map<string, UsState>([
  ["AL", UsState.Alabama],
  ["AK", UsState.Alaska],
  ["AZ", UsState.Arizona],
  ["AR", UsState.Arkansas],
  ["CA", UsState.California],
  ["CO", UsState.Colorado],
  ["CT", UsState.Connecticut],
  ["DE", UsState.Delaware],
  ["DC", UsState.DistrictOfColumbia],
  ["FL", UsState.Florida],
  ["GA", UsState.Georgia],
  ["HI", UsState.Hawaii],
  ["ID", UsState.Idaho],
  ["IL", UsState.Illinois],
  ["IN", UsState.Indiana],
  ["IA", UsState.Iowa],
  ["KS", UsState.Kansas],
  ["KY", UsState.Kentucky],
  ["LA", UsState.Louisiana],
  ["ME", UsState.Maine],
  ["MD", UsState.Maryland],
  ["MA", UsState.Massachusetts],
  ["MI", UsState.Michigan],
  ["MN", UsState.Minnesota],
  ["MS", UsState.Mississippi],
  ["MO", UsState.Missouri],
  ["MT", UsState.Montana],
  ["NE", UsState.Nebraska],
  ["NV", UsState.Nevada],
  ["NH", UsState.NewHampshire],
  ["NJ", UsState.NewJersey],
  ["NM", UsState.NewMexico],
  ["NY", UsState.NewYork],
  ["NC", UsState.NorthCarolina],
  ["ND", UsState.NorthDakota],
  ["OH", UsState.Ohio],
  ["OK", UsState.Oklahoma],
  ["OR", UsState.Oregon],
  ["PA", UsState.Pennsylvania],
  ["RI", UsState.RhodeIsland],
  ["SC", UsState.SouthCarolina],
  ["SD", UsState.SouthDakota],
  ["TN", UsState.Tennessee],
  ["TX", UsState.Texas],
  ["UT", UsState.Utah],
  ["VT", UsState.Vermont],
  ["VA", UsState.Virginia],
  ["WA", UsState.Washington],
  ["WV", UsState.WestVirginia],
  ["WI", UsState.Wisconsin],
  ["WY", UsState.Wyoming],
  ["NL", UsState.NewfoundlandAndLabrador],
  ["PE", UsState.PrinceEdwardIsland],
  ["NS", UsState.NovaScotia],
  ["NB", UsState.NewBrunswick],
  ["QC", UsState.Quebec],
  ["ON", UsState.Ontario],
  ["MB", UsState.Manitoba],
  ["SK", UsState.Saskatchewan],
  ["AB", UsState.Alberta],
  ["BC", UsState.BritishColumbia],
  ["YT", UsState.Yukon],
  ["NT", UsState.NorthwestTerritories],
]);

export const CANADA_ZIP_CODE_TO_POSTAL_CODE = new Map<string, string>([
['A0A',	'NL'],
['A0B',	'NL'],
['A0C',	'NL'],
['A0E',	'NL'],
['A0G',	'NL'],
['A0H',	'NL'],
['A0J',	'NL'],
['A0K',	'NL'],
['A0L',	'NL'],
['A0M',	'NL'],
['A0N',	'NL'],
['A0P',	'NL'],
['A0R',	'NL'],
['A1A',	'NL'],
['A1B',	'NL'],
['A1C',	'NL'],
['A1E',	'NL'],
['A1G',	'NL'],
['A1H',	'NL'],
['A1K',	'NL'],
['A1L',	'NL'],
['A1M',	'NL'],
['A1N',	'NL'],
['A1S',	'NL'],
['A1V',	'NL'],
['A1W',	'NL'],
['A1X',	'NL'],
['A1Y',	'NL'],
['A2A',	'NL'],
['A2B',	'NL'],
['A2H',	'NL'],
['A2N',	'NL'],
['A2V',	'NL'],
['A5A',	'NL'],
['A8A',	'NL'],
['B0C',	'NS'],
['B0E',	'NS'],
['B0H',	'NS'],
['B0J',	'NS'],
['B0K',	'NS'],
['B0L',	'NS'],
['B0M',	'NS'],
['B0N',	'NS'],
['B0P',	'NS'],
['B0R',	'NS'],
['B0S',	'NS'],
['B0T',	'NS'],
['B0V',	'NS'],
['B0W',	'NS'],
['B1A',	'NS'],
['B1B',	'NS'],
['B1C',	'NS'],
['B1E',	'NS'],
['B1G',	'NS'],
['B1H',	'NS'],
['B1J',	'NS'],
['B1K',	'NS'],
['B1L',	'NS'],
['B1M',	'NS'],
['B1N',	'NS'],
['B1P',	'NS'],
['B1R',	'NS'],
['B1S',	'NS'],
['B1T',	'NS'],
['B1V',	'NS'],
['B1W',	'NS'],
['B1X',	'NS'],
['B1Y',	'NS'],
['B2A',	'NS'],
['B2C',	'NS'],
['B2E',	'NS'],
['B2G',	'NS'],
['B2H',	'NS'],
['B2J',	'NS'],
['B2N',	'NS'],
['B2R',	'NS'],
['B2S',	'NS'],
['B2T',	'NS'],
['B2V',	'NS'],
['B2W',	'NS'],
['B2X',	'NS'],
['B2Y',	'NS'],
['B2Z',	'NS'],
['B3A',	'NS'],
['B3B',	'NS'],
['B3E',	'NS'],
['B3G',	'NS'],
['B3H',	'NS'],
['B3J',	'NS'],
['B3K',	'NS'],
['B3L',	'NS'],
['B3M',	'NS'],
['B3N',	'NS'],
['B3P',	'NS'],
['B3R',	'NS'],
['B3S',	'NS'],
['B3T',	'NS'],
['B3V',	'NS'],
['B3Z',	'NS'],
['B4A',	'NS'],
['B4B',	'NS'],
['B4C',	'NS'],
['B4E',	'NS'],
['B4G',	'NS'],
['B4H',	'NS'],
['B4N',	'NS'],
['B4P',	'NS'],
['B4R',	'NS'],
['B4V',	'NS'],
['B5A',	'NS'],
['B6L',	'NS'],
['B9A',	'NS'],
['C0A',	'PE'],
['C0B',	'PE'],
['C1A',	'PE'],
['C1B',	'PE'],
['C1C',	'PE'],
['C1E',	'PE'],
['C1N',	'PE'],
['E1A',	'NB'],
['E1B',	'NB'],
['E1C',	'NB'],
['E1E',	'NB'],
['E1G',	'NB'],
['E1H',	'NB'],
['E1J',	'NB'],
['E1N',	'NB'],
['E1V',	'NB'],
['E1W',	'NB'],
['E1X',	'NB'],
['E2A',	'NB'],
['E2E',	'NB'],
['E2G',	'NB'],
['E2H',	'NB'],
['E2J',	'NB'],
['E2K',	'NB'],
['E2L',	'NB'],
['E2M',	'NB'],
['E2N',	'NB'],
['E2P',	'NB'],
['E2R',	'NB'],
['E2S',	'NB'],
['E2V',	'NB'],
['E3A',	'NB'],
['E3B',	'NB'],
['E3C',	'NB'],
['E3E',	'NB'],
['E3G',	'NB'],
['E3L',	'NB'],
['E3N',	'NB'],
['E3V',	'NB'],
['E3Y',	'NB'],
['E3Z',	'NB'],
['E4A',	'NB'],
['E4B',	'NB'],
['E4C',	'NB'],
['E4E',	'NB'],
['E4G',	'NB'],
['E4H',	'NB'],
['E4J',	'NB'],
['E4K',	'NB'],
['E4L',	'NB'],
['E4M',	'NB'],
['E4N',	'NB'],
['E4P',	'NB'],
['E4R',	'NB'],
['E4S',	'NB'],
['E4T',	'NB'],
['E4V',	'NB'],
['E4W',	'NB'],
['E4X',	'NB'],
['E4Y',	'NB'],
['E4Z',	'NB'],
['E5A',	'NB'],
['E5B',	'NB'],
['E5C',	'NB'],
['E5E',	'NB'],
['E5G',	'NB'],
['E5H',	'NB'],
['E5J',	'NB'],
['E5K',	'NB'],
['E5L',	'NB'],
['E5M',	'NB'],
['E5N',	'NB'],
['E5P',	'NB'],
['E5R',	'NB'],
['E5S',	'NB'],
['E5T',	'NB'],
['E5V',	'NB'],
['E6A',	'NB'],
['E6B',	'NB'],
['E6C',	'NB'],
['E6E',	'NB'],
['E6G',	'NB'],
['E6H',	'NB'],
['E6J',	'NB'],
['E6K',	'NB'],
['E6L',	'NB'],
['E7A',	'NB'],
['E7B',	'NB'],
['E7C',	'NB'],
['E7E',	'NB'],
['E7G',	'NB'],
['E7H',	'NB'],
['E7J',	'NB'],
['E7K',	'NB'],
['E7L',	'NB'],
['E7M',	'NB'],
['E7N',	'NB'],
['E7P',	'NB'],
['E8A',	'NB'],
['E8B',	'NB'],
['E8C',	'NB'],
['E8E',	'NB'],
['E8G',	'NB'],
['E8J',	'NB'],
['E8K',	'NB'],
['E8L',	'NB'],
['E8M',	'NB'],
['E8N',	'NB'],
['E8P',	'NB'],
['E8R',	'NB'],
['E8S',	'NB'],
['E8T',	'NB'],
['E9A',	'NB'],
['E9B',	'NB'],
['E9C',	'NB'],
['E9E',	'NB'],
['E9G',	'NB'],
['E9H',	'NB'],
['G0A',	'QC'],
['G0C',	'QC'],
['G0E',	'QC'],
['G0G',	'QC'],
['G0H',	'QC'],
['G0J',	'QC'],
['G0K',	'QC'],
['G0L',	'QC'],
['G0M',	'QC'],
['G0N',	'QC'],
['G0P',	'QC'],
['G0R',	'QC'],
['G0S',	'QC'],
['G0T',	'QC'],
['G0V',	'QC'],
['G0W',	'QC'],
['G0X',	'QC'],
['G0Y',	'QC'],
['G0Z',	'QC'],
['G1A',	'QC'],
['G1B',	'QC'],
['G1C',	'QC'],
['G1E',	'QC'],
['G1G',	'QC'],
['G1H',	'QC'],
['G1J',	'QC'],
['G1K',	'QC'],
['G1L',	'QC'],
['G1M',	'QC'],
['G1N',	'QC'],
['G1P',	'QC'],
['G1R',	'QC'],
['G1S',	'QC'],
['G1T',	'QC'],
['G1V',	'QC'],
['G1W',	'QC'],
['G1X',	'QC'],
['G1Y',	'QC'],
['G2A',	'QC'],
['G2B',	'QC'],
['G2C',	'QC'],
['G2E',	'QC'],
['G2G',	'QC'],
['G2J',	'QC'],
['G2K',	'QC'],
['G2L',	'QC'],
['G2M',	'QC'],
['G2N',	'QC'],
['G3A',	'QC'],
['G3B',	'QC'],
['G3C',	'QC'],
['G3E',	'QC'],
['G3G',	'QC'],
['G3H',	'QC'],
['G3J',	'QC'],
['G3K',	'QC'],
['G3L',	'QC'],
['G3M',	'QC'],
['G3N',	'QC'],
['G3S',	'QC'],
['G3Z',	'QC'],
['G4A',	'QC'],
['G4R',	'QC'],
['G4S',	'QC'],
['G4T',	'QC'],
['G4V',	'QC'],
['G4W',	'QC'],
['G4X',	'QC'],
['G4Z',	'QC'],
['G5A',	'QC'],
['G5B',	'QC'],
['G5C',	'QC'],
['G5H',	'QC'],
['G5J',	'QC'],
['G5L',	'QC'],
['G5M',	'QC'],
['G5N',	'QC'],
['G5R',	'QC'],
['G5T',	'QC'],
['G5V',	'QC'],
['G5X',	'QC'],
['G5Y',	'QC'],
['G5Z',	'QC'],
['G6A',	'QC'],
['G6B',	'QC'],
['G6C',	'QC'],
['G6E',	'QC'],
['G6G',	'QC'],
['G6H',	'QC'],
['G6J',	'QC'],
['G6K',	'QC'],
['G6L',	'QC'],
['G6P',	'QC'],
['G6R',	'QC'],
['G6S',	'QC'],
['G6T',	'QC'],
['G6V',	'QC'],
['G6W',	'QC'],
['G6X',	'QC'],
['G6Y',	'QC'],
['G6Z',	'QC'],
['G7A',	'QC'],
['G7B',	'QC'],
['G7G',	'QC'],
['G7H',	'QC'],
['G7J',	'QC'],
['G7K',	'QC'],
['G7N',	'QC'],
['G7P',	'QC'],
['G7S',	'QC'],
['G7T',	'QC'],
['G7X',	'QC'],
['G7Y',	'QC'],
['G7Z',	'QC'],
['G8A',	'QC'],
['G8B',	'QC'],
['G8C',	'QC'],
['G8E',	'QC'],
['G8G',	'QC'],
['G8H',	'QC'],
['G8J',	'QC'],
['G8K',	'QC'],
['G8L',	'QC'],
['G8M',	'QC'],
['G8N',	'QC'],
['G8P',	'QC'],
['G8T',	'QC'],
['G8V',	'QC'],
['G8W',	'QC'],
['G8Y',	'QC'],
['G8Z',	'QC'],
['G9A',	'QC'],
['G9B',	'QC'],
['G9C',	'QC'],
['G9H',	'QC'],
['G9N',	'QC'],
['G9P',	'QC'],
['G9R',	'QC'],
['G9T',	'QC'],
['G9X',	'QC'],
['H0M',	'QC'],
['H1A',	'QC'],
['H1B',	'QC'],
['H1C',	'QC'],
['H1E',	'QC'],
['H1G',	'QC'],
['H1H',	'QC'],
['H1J',	'QC'],
['H1K',	'QC'],
['H1L',	'QC'],
['H1M',	'QC'],
['H1N',	'QC'],
['H1P',	'QC'],
['H1R',	'QC'],
['H1S',	'QC'],
['H1T',	'QC'],
['H1V',	'QC'],
['H1W',	'QC'],
['H1X',	'QC'],
['H1Y',	'QC'],
['H1Z',	'QC'],
['H2A',	'QC'],
['H2B',	'QC'],
['H2C',	'QC'],
['H2E',	'QC'],
['H2G',	'QC'],
['H2H',	'QC'],
['H2J',	'QC'],
['H2K',	'QC'],
['H2L',	'QC'],
['H2M',	'QC'],
['H2N',	'QC'],
['H2P',	'QC'],
['H2R',	'QC'],
['H2S',	'QC'],
['H2T',	'QC'],
['H2V',	'QC'],
['H2W',	'QC'],
['H2X',	'QC'],
['H2Y',	'QC'],
['H2Z',	'QC'],
['H3A',	'QC'],
['H3B',	'QC'],
['H3C',	'QC'],
['H3E',	'QC'],
['H3G',	'QC'],
['H3H',	'QC'],
['H3J',	'QC'],
['H3K',	'QC'],
['H3L',	'QC'],
['H3M',	'QC'],
['H3N',	'QC'],
['H3P',	'QC'],
['H3R',	'QC'],
['H3S',	'QC'],
['H3T',	'QC'],
['H3V',	'QC'],
['H3W',	'QC'],
['H3X',	'QC'],
['H3Y',	'QC'],
['H3Z',	'QC'],
['H4A',	'QC'],
['H4B',	'QC'],
['H4C',	'QC'],
['H4E',	'QC'],
['H4G',	'QC'],
['H4H',	'QC'],
['H4J',	'QC'],
['H4K',	'QC'],
['H4L',	'QC'],
['H4M',	'QC'],
['H4N',	'QC'],
['H4P',	'QC'],
['H4R',	'QC'],
['H4S',	'QC'],
['H4T',	'QC'],
['H4V',	'QC'],
['H4W',	'QC'],
['H4X',	'QC'],
['H4Y',	'QC'],
['H4Z',	'QC'],
['H5A',	'QC'],
['H5B',	'QC'],
['H7A',	'QC'],
['H7B',	'QC'],
['H7C',	'QC'],
['H7E',	'QC'],
['H7G',	'QC'],
['H7H',	'QC'],
['H7J',	'QC'],
['H7K',	'QC'],
['H7L',	'QC'],
['H7M',	'QC'],
['H7N',	'QC'],
['H7P',	'QC'],
['H7R',	'QC'],
['H7S',	'QC'],
['H7T',	'QC'],
['H7V',	'QC'],
['H7W',	'QC'],
['H7X',	'QC'],
['H7Y',	'QC'],
['H8N',	'QC'],
['H8P',	'QC'],
['H8R',	'QC'],
['H8S',	'QC'],
['H8T',	'QC'],
['H8Y',	'QC'],
['H8Z',	'QC'],
['H9A',	'QC'],
['H9B',	'QC'],
['H9C',	'QC'],
['H9E',	'QC'],
['H9G',	'QC'],
['H9H',	'QC'],
['H9J',	'QC'],
['H9K',	'QC'],
['H9P',	'QC'],
['H9R',	'QC'],
['H9S',	'QC'],
['H9W',	'QC'],
['H9X',	'QC'],
['J0A',	'QC'],
['J0B',	'QC'],
['J0C',	'QC'],
['J0E',	'QC'],
['J0G',	'QC'],
['J0H',	'QC'],
['J0J',	'QC'],
['J0K',	'QC'],
['J0L',	'QC'],
['J0M',	'QC'],
['J0N',	'QC'],
['J0P',	'QC'],
['J0R',	'QC'],
['J0S',	'QC'],
['J0T',	'QC'],
['J0V',	'QC'],
['J0W',	'QC'],
['J0X',	'QC'],
['J0Y',	'QC'],
['J0Z',	'QC'],
['J1A',	'QC'],
['J1C',	'QC'],
['J1E',	'QC'],
['J1G',	'QC'],
['J1H',	'QC'],
['J1J',	'QC'],
['J1K',	'QC'],
['J1L',	'QC'],
['J1M',	'QC'],
['J1N',	'QC'],
['J1R',	'QC'],
['J1S',	'QC'],
['J1T',	'QC'],
['J1X',	'QC'],
['J1Z',	'QC'],
['J2A',	'QC'],
['J2B',	'QC'],
['J2C',	'QC'],
['J2E',	'QC'],
['J2G',	'QC'],
['J2H',	'QC'],
['J2J',	'QC'],
['J2K',	'QC'],
['J2L',	'QC'],
['J2M',	'QC'],
['J2N',	'QC'],
['J2R',	'QC'],
['J2T',	'QC'],
['J2S',	'QC'],
['J2X',	'QC'],
['J2W',	'QC'],
['J3A',	'QC'],
['J2Y',	'QC'],
['J3E',	'QC'],
['J3B',	'QC'],
['J3H',	'QC'],
['J3G',	'QC'],
['J3M',	'QC'],
['J3L',	'QC'],
['J3P',	'QC'],
['J3N',	'QC'],
['J3T',	'QC'],
['J3R',	'QC'],
['J3X',	'QC'],
['J3V',	'QC'],
['J3Z',	'QC'],
['J3Y',	'QC'],
['J4G',	'QC'],
['J4B',	'QC'],
['J4J',	'QC'],
['J4H',	'QC'],
['J4L',	'QC'],
['J4K',	'QC'],
['J4N',	'QC'],
['J4M',	'QC'],
['J4R',	'QC'],
['J4P',	'QC'],
['J4T',	'QC'],
['J4S',	'QC'],
['J4W',	'QC'],
['J4V',	'QC'],
['J4Y',	'QC'],
['J4X',	'QC'],
['J5A',	'QC'],
['J4Z',	'QC'],
['J5C',	'QC'],
['J5B',	'QC'],
['J5K',	'QC'],
['J5J',	'QC'],
['J5M',	'QC'],
['J5L',	'QC'],
['J5R',	'QC'],
['J5N',	'QC'],
['J5V',	'QC'],
['J5T',	'QC'],
['J5X',	'QC'],
['J5W',	'QC'],
['J5Z',	'QC'],
['J5Y',	'QC'],
['J6E',	'QC'],
['J6A',	'QC'],
['J6K',	'QC'],
['J6J',	'QC'],
['J6R',	'QC'],
['J6N',	'QC'],
['J6T',	'QC'],
['J6S',	'QC'],
['J6W',	'QC'],
['J6V',	'QC'],
['J6Y',	'QC'],
['J6X',	'QC'],
['J7A',	'QC'],
['J6Z',	'QC'],
['J7C',	'QC'],
['J7B',	'QC'],
['J7G',	'QC'],
['J7E',	'QC'],
['J7J',	'QC'],
['J7H',	'QC'],
['J7L',	'QC'],
['J7K',	'QC'],
['J7N',	'QC'],
['J7M',	'QC'],
['J7R',	'QC'],
['J7P',	'QC'],
['J7V',	'QC'],
['J7T',	'QC'],
['J7X',	'QC'],
['J7W',	'QC'],
['J7Z',	'QC'],
['J7Y',	'QC'],
['J8B',	'QC'],
['J8A',	'QC'],
['J8E',	'QC'],
['J8C',	'QC'],
['J8H',	'QC'],
['J8G',	'QC'],
['J8M',	'QC'],
['J8L',	'QC'],
['J8P',	'QC'],
['J8N',	'QC'],
['J8T',	'QC'],
['J8R',	'QC'],
['J8X',	'QC'],
['J8V',	'QC'],
['J8Z',	'QC'],
['J8Y',	'QC'],
['J9B',	'QC'],
['J9A',	'QC'],
['J9H',	'QC'],
['J9E',	'QC'],
['J9L',	'QC'],
['J9J',	'QC'],
['J9T',	'QC'],
['J9P',	'QC'],
['J9X',	'QC'],
['J9V',	'QC'],
['J9Z',	'QC'],
['J9Y',	'QC'],
['K0B',	'ON'],
['K0A',	'ON'],
['K0E',	'ON'],
['K0C',	'ON'],
['K0H',	'ON'],
['K0G',	'ON'],
['K0K',	'ON'],
['K0J',	'ON'],
['K0M',	'ON'],
['K0L',	'ON'],
['K1B',	'ON'],
['K1A',	'ON'],
['K1E',	'ON'],
['K1C',	'ON'],
['K1H',	'ON'],
['K1G',	'ON'],
['K1K',	'ON'],
['K1J',	'ON'],
['K1M',	'ON'],
['K1L',	'ON'],
['K1P',	'ON'],
['K1N',	'ON'],
['K1S',	'ON'],
['K1R',	'ON'],
['K1V',	'ON'],
['K1T',	'ON'],
['K1X',	'ON'],
['K1W',	'ON'],
['K1Z',	'ON'],
['K1Y',	'ON'],
['K2B',	'ON'],
['K2A',	'ON'],
['K2E',	'ON'],
['K2C',	'ON'],
['K2H',	'ON'],
['K2G',	'ON'],
['K2K',	'ON'],
['K2J',	'ON'],
['K2M',	'ON'],
['K2L',	'ON'],
['K2R',	'ON'],
['K2P',	'ON'],
['K2T',	'ON'],
['K2S',	'ON'],
['K2W',	'ON'],
['K2V',	'ON'],
['K4B',	'ON'],
['K4A',	'ON'],
['K4K',	'ON'],
['K4C',	'ON'],
['K4P',	'ON'],
['K4M',	'ON'],
['K6A',	'ON'],
['K4R',	'ON'],
['K6J',	'ON'],
['K6H',	'ON'],
['K6T',	'ON'],
['K6K',	'ON'],
['K7A',	'ON'],
['K6V',	'ON'],
['K7G',	'ON'],
['K7C',	'ON'],
['K7K',	'ON'],
['K7H',	'ON'],
['K7M',	'ON'],
['K7L',	'ON'],
['K7P',	'ON'],
['K7N',	'ON'],
['K7S',	'ON'],
['K7R',	'ON'],
['K8A',	'ON'],
['K7V',	'ON'],
['K8H',	'ON'],
['K8B',	'ON'],
['K8P',	'ON'],
['K8N',	'ON'],
['K8V',	'ON'],
['K8R',	'ON'],
['K9H',	'ON'],
['K9A',	'ON'],
['K9K',	'ON'],
['K9J',	'ON'],
['K9V',	'ON'],
['K9L',	'ON'],
['L0B',	'ON'],
['L0A',	'ON'],
['L0E',	'ON'],
['L0C',	'ON'],
['L0H',	'ON'],
['L0G',	'ON'],
['L0K',	'ON'],
['L0J',	'ON'],
['L0M',	'ON'],
['L0L',	'ON'],
['L0P',	'ON'],
['L0N',	'ON'],
['L0S',	'ON'],
['L0R',	'ON'],
['L1B',	'ON'],
['L1A',	'ON'],
['L1E',	'ON'],
['L1C',	'ON'],
['L1H',	'ON'],
['L1G',	'ON'],
['L1K',	'ON'],
['L1J',	'ON'],
['L1M',	'ON'],
['L1L',	'ON'],
['L1P',	'ON'],
['L1N',	'ON'],
['L1S',	'ON'],
['L1R',	'ON'],
['L1V',	'ON'],
['L1T',	'ON'],
['L1X',	'ON'],
['L1W',	'ON'],
['L1Z',	'ON'],
['L1Y',	'ON'],
['L2E',	'ON'],
['L2A',	'ON'],
['L2H',	'ON'],
['L2G',	'ON'],
['L2M',	'ON'],
['L2J',	'ON'],
['L2P',	'ON'],
['L2N',	'ON'],
['L2S',	'ON'],
['L2R',	'ON'],
['L2V',	'ON'],
['L2T',	'ON'],
['L3B',	'ON'],
['L2W',	'ON'],
['L3K',	'ON'],
['L3C',	'ON'],
['L3M',	'ON'],
['L3L',	'ON'],
['L3R',	'ON'],
['L3P',	'ON'],
['L3T',	'ON'],
['L3S',	'ON'],
['L3X',	'ON'],
['L3V',	'ON'],
['L3Z',	'ON'],
['L3Y',	'ON'],
['L4B',	'ON'],
['L4A',	'ON'],
['L4E',	'ON'],
['L4C',	'ON'],
['L4H',	'ON'],
['L4G',	'ON'],
['L4K',	'ON'],
['L4J',	'ON'],
['L4M',	'ON'],
['L4L',	'ON'],
['L4P',	'ON'],
['L4N',	'ON'],
['L4S',	'ON'],
['L4R',	'ON'],
['L4V',	'ON'],
['L4T',	'ON'],
['L4X',	'ON'],
['L4W',	'ON'],
['L4Z',	'ON'],
['L4Y',	'ON'],
['L5B',	'ON'],
['L5A',	'ON'],
['L5E',	'ON'],
['L5C',	'ON'],
['L5H',	'ON'],
['L5G',	'ON'],
['L5K',	'ON'],
['L5J',	'ON'],
['L5M',	'ON'],
['L5L',	'ON'],
['L5P',	'ON'],
['L5N',	'ON'],
['L5S',	'ON'],
['L5R',	'ON'],
['L5V',	'ON'],
['L5T',	'ON'],
['L6A',	'ON'],
['L5W',	'ON'],
['L6C',	'ON'],
['L6B',	'ON'],
['L6G',	'ON'],
['L6E',	'ON'],
['L6J',	'ON'],
['L6H',	'ON'],
['L6L',	'ON'],
['L6K',	'ON'],
['L6P',	'ON'],
['L6M',	'ON'],
['L6S',	'ON'],
['L6R',	'ON'],
['L6V',	'ON'],
['L6T',	'ON'],
['L6X',	'ON'],
['L6W',	'ON'],
['L6Z',	'ON'],
['L6Y',	'ON'],
['L7B',	'ON'],
['L7A',	'ON'],
['L7E',	'ON'],
['L7C',	'ON'],
['L7J',	'ON'],
['L7G',	'ON'],
['L7L',	'ON'],
['L7K',	'ON'],
['L7N',	'ON'],
['L7M',	'ON'],
['L7R',	'ON'],
['L7P',	'ON'],
['L7T',	'ON'],
['L7S',	'ON'],
['L8E',	'ON'],
['L8B',	'ON'],
['L8H',	'ON'],
['L8G',	'ON'],
['L8K',	'ON'],
['L8J',	'ON'],
['L8M',	'ON'],
['L8L',	'ON'],
['L8P',	'ON'],
['L8N',	'ON'],
['L8S',	'ON'],
['L8R',	'ON'],
['L8V',	'ON'],
['L8T',	'ON'],
['L9A',	'ON'],
['L8W',	'ON'],
['L9C',	'ON'],
['L9B',	'ON'],
['L9G',	'ON'],
['L9E',	'ON'],
['L9J',	'ON'],
['L9H',	'ON'],
['L9L',	'ON'],
['L9K',	'ON'],
['L9N',	'ON'],
['L9M',	'ON'],
['L9R',	'ON'],
['L9P',	'ON'],
['L9T',	'ON'],
['L9S',	'ON'],
['L9W',	'ON'],
['L9V',	'ON'],
['L9Y',	'ON'],
['L9X',	'ON'],
['M1B',	'ON'],
['L9Z',	'ON'],
['M1E',	'ON'],
['M1C',	'ON'],
['M1H',	'ON'],
['M1G',	'ON'],
['M1K',	'ON'],
['M1J',	'ON'],
['M1M',	'ON'],
['M1L',	'ON'],
['M1P',	'ON'],
['M1N',	'ON'],
['M1S',	'ON'],
['M1R',	'ON'],
['M1V',	'ON'],
['M1T',	'ON'],
['M1X',	'ON'],
['M1W',	'ON'],
['M2J',	'ON'],
['M2H',	'ON'],
['M2L',	'ON'],
['M2K',	'ON'],
['M2N',	'ON'],
['M2M',	'ON'],
['M2R',	'ON'],
['M2P',	'ON'],
['M3B',	'ON'],
['M3A',	'ON'],
['M3H',	'ON'],
['M3C',	'ON'],
['M3K',	'ON'],
['M3J',	'ON'],
['M3M',	'ON'],
['M3L',	'ON'],
['M4A',	'ON'],
['M3N',	'ON'],
['M4C',	'ON'],
['M4B',	'ON'],
['M4G',	'ON'],
['M4E',	'ON'],
['M4J',	'ON'],
['M4H',	'ON'],
['M4L',	'ON'],
['M4K',	'ON'],
['M4N',	'ON'],
['M4M',	'ON'],
['M4R',	'ON'],
['M4P',	'ON'],
['M4T',	'ON'],
['M4S',	'ON'],
['M4W',	'ON'],
['M4V',	'ON'],
['M4Y',	'ON'],
['M4X',	'ON'],
['M5B',	'ON'],
['M5A',	'ON'],
['M5E',	'ON'],
['M5C',	'ON'],
['M5H',	'ON'],
['M5G',	'ON'],
['M5K',	'ON'],
['M5J',	'ON'],
['M5M',	'ON'],
['M5L',	'ON'],
['M5P',	'ON'],
['M5N',	'ON'],
['M5S',	'ON'],
['M5R',	'ON'],
['M5V',	'ON'],
['M5T',	'ON'],
['M5X',	'ON'],
['M5W',	'ON'],
['M6B',	'ON'],
['M6A',	'ON'],
['M6E',	'ON'],
['M6C',	'ON'],
['M6H',	'ON'],
['M6G',	'ON'],
['M6K',	'ON'],
['M6J',	'ON'],
['M6M',	'ON'],
['M6L',	'ON'],
['M6P',	'ON'],
['M6N',	'ON'],
['M6S',	'ON'],
['M6R',	'ON'],
['M7R',	'ON'],
['M7A',	'ON'],
['M8V',	'ON'],
['M7Y',	'ON'],
['M8X',	'ON'],
['M8W',	'ON'],
['M8Z',	'ON'],
['M8Y',	'ON'],
['M9B',	'ON'],
['M9A',	'ON'],
['M9L',	'ON'],
['M9C',	'ON'],
['M9N',	'ON'],
['M9M',	'ON'],
['M9R',	'ON'],
['M9P',	'ON'],
['M9W',	'ON'],
['M9V',	'ON'],
['N0B',	'ON'],
['N0A',	'ON'],
['N0E',	'ON'],
['N0C',	'ON'],
['N0H',	'ON'],
['N0G',	'ON'],
['N0K',	'ON'],
['N0J',	'ON'],
['N0M',	'ON'],
['N0L',	'ON'],
['N0P',	'ON'],
['N0N',	'ON'],
['N1A',	'ON'],
['N0R',	'ON'],
['N1E',	'ON'],
['N1C',	'ON'],
['N1H',	'ON'],
['N1G',	'ON'],
['N1L',	'ON'],
['N1K',	'ON'],
['N1P',	'ON'],
['N1M',	'ON'],
['N1S',	'ON'],
['N1R',	'ON'],
['N2A',	'ON'],
['N1T',	'ON'],
['N2C',	'ON'],
['N2B',	'ON'],
['N2G',	'ON'],
['N2E',	'ON'],
['N2J',	'ON'],
['N2H',	'ON'],
['N2L',	'ON'],
['N2K',	'ON'],
['N2N',	'ON'],
['N2M',	'ON'],
['N2R',	'ON'],
['N2P',	'ON'],
['N2V',	'ON'],
['N2T',	'ON'],
['N3A',	'ON'],
['N2Z',	'ON'],
['N3C',	'ON'],
['N3B',	'ON'],
['N3H',	'ON'],
['N3E',	'ON'],
['N3P',	'ON'],
['N3L',	'ON'],
['N3S',	'ON'],
['N3R',	'ON'],
['N3V',	'ON'],
['N3T',	'ON'],
['N3Y',	'ON'],
['N3W',	'ON'],
['N4G',	'ON'],
['N4B',	'ON'],
['N4L',	'ON'],
['N4K',	'ON'],
['N4S',	'ON'],
['N4N',	'ON'],
['N4V',	'ON'],
['N4T',	'ON'],
['N4X',	'ON'],
['N4W',	'ON'],
['N5A',	'ON'],
['N4Z',	'ON'],
['N5H',	'ON'],
['N5C',	'ON'],
['N5P',	'ON'],
['N5L',	'ON'],
['N5V',	'ON'],
['N5R',	'ON'],
['N5X',	'ON'],
['N5W',	'ON'],
['N5Z',	'ON'],
['N5Y',	'ON'],
['N6B',	'ON'],
['N6A',	'ON'],
['N6E',	'ON'],
['N6C',	'ON'],
['N6H',	'ON'],
['N6G',	'ON'],
['N6K',	'ON'],
['N6J',	'ON'],
['N6M',	'ON'],
['N6L',	'ON'],
['N6P',	'ON'],
['N6N',	'ON'],
['N7G',	'ON'],
['N7A',	'ON'],
['N7M',	'ON'],
['N7L',	'ON'],
['N7T',	'ON'],
['N7S',	'ON'],
['N7W',	'ON'],
['N7V',	'ON'],
['N8A',	'ON'],
['N7X',	'ON'],
['N8M',	'ON'],
['N8H',	'ON'],
['N8P',	'ON'],
['N8N',	'ON'],
['N8S',	'ON'],
['N8R',	'ON'],
['N8V',	'ON'],
['N8T',	'ON'],
['N8X',	'ON'],
['N8W',	'ON'],
['N9A',	'ON'],
['N8Y',	'ON'],
['N9C',	'ON'],
['N9B',	'ON'],
['N9G',	'ON'],
['N9E',	'ON'],
['N9J',	'ON'],
['N9H',	'ON'],
['N9V',	'ON'],
['N9K',	'ON'],
['P0A',	'ON'],
['N9Y',	'ON'],
['P0C',	'ON'],
['P0B',	'ON'],
['P0G',	'ON'],
['P0E',	'ON'],
['P0J',	'ON'],
['P0H',	'ON'],
['P0L',	'ON'],
['P0K',	'ON'],
['P0N',	'ON'],
['P0M',	'ON'],
['P0R',	'ON'],
['P0P',	'ON'],
['P0T',	'ON'],
['P0S',	'ON'],
['P0W',	'ON'],
['P0V',	'ON'],
['P0Y',	'ON'],
['P0X',	'ON'],
['P1B',	'ON'],
['P1A',	'ON'],
['P1H',	'ON'],
['P1C',	'ON'],
['P1P',	'ON'],
['P1L',	'ON'],
['P2B',	'ON'],
['P2A',	'ON'],
['P3A',	'ON'],
['P2N',	'ON'],
['P3C',	'ON'],
['P3B',	'ON'],
['P3G',	'ON'],
['P3E',	'ON'],
['P3N',	'ON'],
['P3L',	'ON'],
['P3Y',	'ON'],
['P3P',	'ON'],
['P4P',	'ON'],
['P4N',	'ON'],
['P5A',	'ON'],
['P4R',	'ON'],
['P5N',	'ON'],
['P5E',	'ON'],
['P6B',	'ON'],
['P6A',	'ON'],
['P7A',	'ON'],
['P6C',	'ON'],
['P7C',	'ON'],
['P7B',	'ON'],
['P7G',	'ON'],
['P7E',	'ON'],
['P7K',	'ON'],
['P7J',	'ON'],
['P8N',	'ON'],
['P7L',	'ON'],
['P9A',	'ON'],
['P8T',	'ON'],
['R0A',	'MB'],
['P9N',	'ON'],
['R0C',	'MB'],
['R0B',	'MB'],
['R0G',	'MB'],
['R0E',	'MB'],
['R0J',	'MB'],
['R0H',	'MB'],
['R0L',	'MB'],
['R0K',	'MB'],
['R1A',	'MB'],
['R0M',	'MB'],
['R1C',	'MB'],
['R1B',	'MB'],
['R2C',	'MB'],
['R1N',	'MB'],
['R2G',	'MB'],
['R2E',	'MB'],
['R2J',	'MB'],
['R2H',	'MB'],
['R2L',	'MB'],
['R2K',	'MB'],
['R2N',	'MB'],
['R2M',	'MB'],
['R2R',	'MB'],
['R2P',	'MB'],
['R2W',	'MB'],
['R2V',	'MB'],
['R2Y',	'MB'],
['R2X',	'MB'],
['R3B',	'MB'],
['R3A',	'MB'],
['R3E',	'MB'],
['R3C',	'MB'],
['R3H',	'MB'],
['R3G',	'MB'],
['R3K',	'MB'],
['R3J',	'MB'],
['R3M',	'MB'],
['R3L',	'MB'],
['R3P',	'MB'],
['R3N',	'MB'],
['R3S',	'MB'],
['R3R',	'MB'],
['R3V',	'MB'],
['R3T',	'MB'],
['R3X',	'MB'],
['R3W',	'MB'],
['R4A',	'MB'],
['R3Y',	'MB'],
['R4H',	'MB'],
['R4G',	'MB'],
['R4K',	'MB'],
['R4J',	'MB'],
['R5A',	'MB'],
['R4L',	'MB'],
['R5H',	'MB'],
['R5G',	'MB'],
['R5K',	'MB'],
['R5J',	'MB'],
['R5M',	'MB'],
['R5L',	'MB'],
['R5P',	'MB'],
['R5N',	'MB'],
['R5T',	'MB'],
['R5R',	'MB'],
['R6P',	'MB'],
['R6M',	'MB'],
['R6W',	'MB'],
['R6T',	'MB'],
['R7B',	'MB'],
['R7A',	'MB'],
['R7N',	'MB'],
['R7C',	'MB'],
['R8N',	'MB'],
['R8A',	'MB'],
['S0A',	'SK'],
['R9A',	'MB'],
['S0E',	'SK'],
['S0C',	'SK'],
['S0H',	'SK'],
['S0G',	'SK'],
['S0K',	'SK'],
['S0J',	'SK'],
['S0M',	'SK'],
['S0L',	'SK'],
['S0P',	'SK'],
['S0N',	'SK'],
['S3N',	'SK'],
['S2V',	'SK'],
['S4H',	'SK'],
['S4A',	'SK'],
['S4L',	'SK'],
['S4K',	'SK'],
['S4N',	'SK'],
['S4M',	'SK'],
['S4R',	'SK'],
['S4P',	'SK'],
['S4T',	'SK'],
['S4S',	'SK'],
['S4W',	'SK'],
['S4V',	'SK'],
['S4Y',	'SK'],
['S4X',	'SK'],
['S6H',	'SK'],
['S4Z',	'SK'],
['S6K',	'SK'],
['S6J',	'SK'],
['S6W',	'SK'],
['S6V',	'SK'],
['S7A',	'SK'],
['S6X',	'SK'],
['S7C',	'SK'],
['S7B',	'SK'],
['S7J',	'SK'],
['S7H',	'SK'],
['S7L',	'SK'],
['S7K',	'SK'],
['S7N',	'SK'],
['S7M',	'SK'],
['S7R',	'SK'],
['S7P',	'SK'],
['S7T',	'SK'],
['S7S',	'SK'],
['S7W',	'SK'],
['S7V',	'SK'],
['S9H',	'SK'],
['S9A',	'SK'],
['S9X',	'SK'],
['S9V',	'SK'],
['T0B',	'AB'],
['T0A',	'AB'],
['T0E',	'AB'],
['T0C',	'AB'],
['T0H',	'AB'],
['T0G',	'AB'],
['T0K',	'AB'],
['T0J',	'AB'],
['T0M',	'AB'],
['T0L',	'AB'],
['T0V',	'AB'],
['T0P',	'AB'],
['T1B',	'AB'],
['T1A',	'AB'],
['T1G',	'AB'],
['T1C',	'AB'],
['T1J',	'AB'],
['T1H',	'AB'],
['T1L',	'AB'],
['T1K',	'AB'],
['T1P',	'AB'],
['T1M',	'AB'],
['T1S',	'AB'],
['T1R',	'AB'],
['T1W',	'AB'],
['T1V',	'AB'],
['T1Y',	'AB'],
['T1X',	'AB'],
['T2A',	'AB'],
['T1Z',	'AB'],
['T2C',	'AB'],
['T2B',	'AB'],
['T2G',	'AB'],
['T2E',	'AB'],
['T2J',	'AB'],
['T2H',	'AB'],
['T2L',	'AB'],
['T2K',	'AB'],
['T2N',	'AB'],
['T2M',	'AB'],
['T2R',	'AB'],
['T2P',	'AB'],
['T2T',	'AB'],
['T2S',	'AB'],
['T2W',	'AB'],
['T2V',	'AB'],
['T2Y',	'AB'],
['T2X',	'AB'],
['T3A',	'AB'],
['T2Z',	'AB'],
['T3C',	'AB'],
['T3B',	'AB'],
['T3G',	'AB'],
['T3E',	'AB'],
['T3J',	'AB'],
['T3H',	'AB'],
['T3L',	'AB'],
['T3K',	'AB'],
['T3N',	'AB'],
['T3M',	'AB'],
['T3R',	'AB'],
['T3P',	'AB'],
['T3T',	'AB'],
['T3S',	'AB'],
['T4A',	'AB'],
['T3Z',	'AB'],
['T4C',	'AB'],
['T4B',	'AB'],
['T4G',	'AB'],
['T4E',	'AB'],
['T4J',	'AB'],
['T4H',	'AB'],
['T4M',	'AB'],
['T4L',	'AB'],
['T4P',	'AB'],
['T4N',	'AB'],
['T4S',	'AB'],
['T4R',	'AB'],
['T4V',	'AB'],
['T4T',	'AB'],
['T5A',	'AB'],
['T4X',	'AB'],
['T5C',	'AB'],
['T5B',	'AB'],
['T5G',	'AB'],
['T5E',	'AB'],
['T5J',	'AB'],
['T5H',	'AB'],
['T5L',	'AB'],
['T5K',	'AB'],
['T5N',	'AB'],
['T5M',	'AB'],
['T5R',	'AB'],
['T5P',	'AB'],
['T5T',	'AB'],
['T5S',	'AB'],
['T5W',	'AB'],
['T5V',	'AB'],
['T5Y',	'AB'],
['T5X',	'AB'],
['T6A',	'AB'],
['T5Z',	'AB'],
['T6C',	'AB'],
['T6B',	'AB'],
['T6G',	'AB'],
['T6E',	'AB'],
['T6J',	'AB'],
['T6H',	'AB'],
['T6L',	'AB'],
['T6K',	'AB'],
['T6N',	'AB'],
['T6M',	'AB'],
['T6R',	'AB'],
['T6P',	'AB'],
['T6T',	'AB'],
['T6S',	'AB'],
['T6W',	'AB'],
['T6V',	'AB'],
['T6Y',	'AB'],
['T6X',	'AB'],
['T7E',	'AB'],
['T7A',	'AB'],
['T7P',	'AB'],
['T7N',	'AB'],
['T7V',	'AB'],
['T7S',	'AB'],
['T7Y',	'AB'],
['T7X',	'AB'],
['T8A',	'AB'],
['T7Z',	'AB'],
['T8C',	'AB'],
['T8B',	'AB'],
['T8G',	'AB'],
['T8E',	'AB'],
['T8L',	'AB'],
['T8H',	'AB'],
['T8R',	'AB'],
['T8N',	'AB'],
['T8T',	'AB'],
['T8S',	'AB'],
['T8W',	'AB'],
['T8V',	'AB'],
['T9A',	'AB'],
['T8X',	'AB'],
['T9E',	'AB'],
['T9C',	'AB'],
['T9H',	'AB'],
['T9G',	'AB'],
['T9K',	'AB'],
['T9J',	'AB'],
['T9N',	'AB'],
['T9M',	'AB'],
['T9V',	'AB'],
['T9S',	'AB'],
['T9X',	'AB'],
['T9W',	'AB'],
['V0B',	'BC'],
['V0A',	'BC'],
['V0E',	'BC'],
['V0C',	'BC'],
['V0H',	'BC'],
['V0G',	'BC'],
['V0K',	'BC'],
['V0J',	'BC'],
['V0M',	'BC'],
['V0L',	'BC'],
['V0P',	'BC'],
['V0N',	'BC'],
['V0S',	'BC'],
['V0R',	'BC'],
['V0V',	'BC'],
['V0T',	'BC'],
['V0X',	'BC'],
['V0W',	'BC'],
['V1B',	'BC'],
['V1A',	'BC'],
['V1E',	'BC'],
['V1C',	'BC'],
['V1H',	'BC'],
['V1G',	'BC'],
['V1K',	'BC'],
['V1J',	'BC'],
['V1M',	'BC'],
['V1L',	'BC'],
['V1P',	'BC'],
['V1N',	'BC'],
['V1S',	'BC'],
['V1R',	'BC'],
['V1V',	'BC'],
['V1T',	'BC'],
['V1X',	'BC'],
['V1W',	'BC'],
['V1Z',	'BC'],
['V1Y',	'BC'],
['V2B',	'BC'],
['V2A',	'BC'],
['V2E',	'BC'],
['V2C',	'BC'],
['V2H',	'BC'],
['V2G',	'BC'],
['V2K',	'BC'],
['V2J',	'BC'],
['V2M',	'BC'],
['V2L',	'BC'],
['V2P',	'BC'],
['V2N',	'BC'],
['V2S',	'BC'],
['V2R',	'BC'],
['V2V',	'BC'],
['V2T',	'BC'],
['V2X',	'BC'],
['V2W',	'BC'],
['V2Z',	'BC'],
['V2Y',	'BC'],
['V3B',	'BC'],
['V3A',	'BC'],
['V3E',	'BC'],
['V3C',	'BC'],
['V3H',	'BC'],
['V3G',	'BC'],
['V3K',	'BC'],
['V3J',	'BC'],
['V3M',	'BC'],
['V3L',	'BC'],
['V3R',	'BC'],
['V3N',	'BC'],
['V3T',	'BC'],
['V3S',	'BC'],
['V3W',	'BC'],
['V3V',	'BC'],
['V3Y',	'BC'],
['V3X',	'BC'],
['V4A',	'BC'],
['V3Z',	'BC'],
['V4C',	'BC'],
['V4B',	'BC'],
['V4G',	'BC'],
['V4E',	'BC'],
['V4L',	'BC'],
['V4K',	'BC'],
['V4N',	'BC'],
['V4M',	'BC'],
['V4R',	'BC'],
['V4P',	'BC'],
['V4T',	'BC'],
['V4S',	'BC'],
['V4W',	'BC'],
['V4V',	'BC'],
['V4Z',	'BC'],
['V4X',	'BC'],
['V5B',	'BC'],
['V5A',	'BC'],
['V5E',	'BC'],
['V5C',	'BC'],
['V5H',	'BC'],
['V5G',	'BC'],
['V5K',	'BC'],
['V5J',	'BC'],
['V5M',	'BC'],
['V5L',	'BC'],
['V5P',	'BC'],
['V5N',	'BC'],
['V5S',	'BC'],
['V5R',	'BC'],
['V5V',	'BC'],
['V5T',	'BC'],
['V5X',	'BC'],
['V5W',	'BC'],
['V5Z',	'BC'],
['V5Y',	'BC'],
['V6B',	'BC'],
['V6A',	'BC'],
['V6E',	'BC'],
['V6C',	'BC'],
['V6H',	'BC'],
['V6G',	'BC'],
['V6K',	'BC'],
['V6J',	'BC'],
['V6M',	'BC'],
['V6L',	'BC'],
['V6P',	'BC'],
['V6N',	'BC'],
['V6S',	'BC'],
['V6R',	'BC'],
['V6V',	'BC'],
['V6T',	'BC'],
['V6X',	'BC'],
['V6W',	'BC'],
['V6Z',	'BC'],
['V6Y',	'BC'],
['V7B',	'BC'],
['V7A',	'BC'],
['V7E',	'BC'],
['V7C',	'BC'],
['V7H',	'BC'],
['V7G',	'BC'],
['V7K',	'BC'],
['V7J',	'BC'],
['V7M',	'BC'],
['V7L',	'BC'],
['V7P',	'BC'],
['V7N',	'BC'],
['V7S',	'BC'],
['V7R',	'BC'],
['V7V',	'BC'],
['V7T',	'BC'],
['V7X',	'BC'],
['V7W',	'BC'],
['V7Z',	'BC'],
['V7Y',	'BC'],
['V8B',	'BC'],
['V8A',	'BC'],
['V8E',	'BC'],
['V8C',	'BC'],
['V8J',	'BC'],
['V8G',	'BC'],
['V8L',	'BC'],
['V8K',	'BC'],
['V8N',	'BC'],
['V8M',	'BC'],
['V8R',	'BC'],
['V8P',	'BC'],
['V8T',	'BC'],
['V8S',	'BC'],
['V8W',	'BC'],
['V8V',	'BC'],
['V8Y',	'BC'],
['V8X',	'BC'],
['V9A',	'BC'],
['V8Z',	'BC'],
['V9C',	'BC'],
['V9B',	'BC'],
['V9G',	'BC'],
['V9E',	'BC'],
['V9J',	'BC'],
['V9H',	'BC'],
['V9L',	'BC'],
['V9K',	'BC'],
['V9N',	'BC'],
['V9M',	'BC'],
['V9R',	'BC'],
['V9P',	'BC'],
['V9T',	'BC'],
['V9S',	'BC'],
['V9W',	'BC'],
['V9V',	'BC'],
['V9Y',	'BC'],
['V9X',	'BC'],
['X0A',	'NT'],
['V9Z',	'BC'],
['X0C',	'NT'],
['X0B',	'NT'],
['X0G',	'NT'],
['X0E',	'NT'],
['Y0A',	'YT'],
['X1A',	'NT'],
['Y1A',	'YT'],
['Y0B',	'YT'],
])

// assert(
//   US_POSTAL_CODE_MAP.size === Object.keys(UsState).length,
//   "Missing state code mapping (postal)"
// );
