import React, { FC } from "react";
import { AppConfig } from "../../../utils/appconfig";
import {
  PolygonAction,
  PolygonActionType,
  PolygonGroup,
  PolygonTabState,
} from "./types";
import LoadingSpinner from "../../../components/LoadingSpinner";
import PolygonSummary from "./PolygonSummary";
import PolygonTerritoriesMenu from "./PolygonTerritoriesMenu";
import { API_URL } from "./constants";
import { Feature, Geometry, GeoJsonProperties } from "geojson";

interface Props {
  appConfig: AppConfig;
  state: PolygonTabState;
  dispatch: React.Dispatch<PolygonAction>;
  selectedStateCode: string;
}

const PolygonTab: FC<Props> = ({
  appConfig,
  state,
  dispatch,
  selectedStateCode,
}) => {
  const savePolygons = (
    group: PolygonGroup,
    features: Feature<Geometry, GeoJsonProperties>[]
  ) =>
    fetch(
      API_URL + "/apps/" + appConfig.id + "/territory-profiles/" + group.id,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          featureCollection: {
            type: "FeatureCollection",
            features,
          },
        }),
      }
    );

  const fetchSummaries = async (group: PolygonGroup) => {
    const groups = state.groups;

    try {
      const response = await fetch(
        API_URL + "/apps/" + appConfig.id + "/territory-profiles/" + group.id,
        {
          method: "GET",
        }
      );

      const features = await response.json();

      groups[
        groups.findIndex((localGroup) => localGroup.id == group.id)
      ].features = features.features;

      const localGroupIndex = groups.findIndex(
        (localGroup) => localGroup.id == group.id
      );

      const selectedGroupIndex = groups.findIndex((group) =>
        group.features.find(
          (f) =>
            f.properties?.color === state?.selectedPolygon?.properties?.color
        )
      );

      if (selectedGroupIndex === localGroupIndex) {
        const featureIndex = groups[localGroupIndex].features.findIndex(
          (f) =>
            f.properties?.color === state?.selectedPolygon?.properties?.color
        );

        dispatch({
          type: PolygonActionType.SetSelectedPolygon,
          payload: groups[localGroupIndex].features[featureIndex],
        });
      }

      dispatch({
        type: PolygonActionType.SetGroups,
        payload: {
          groups,
          changes: false,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const saveChanges = async () => {
    if (!state.changes || state.saving) return;

    const groups = state.groups;

    dispatch({
      type: PolygonActionType.Saving,
      payload: {},
    });

    await Promise.all(
      groups.map(async (group: PolygonGroup) => {
        return await savePolygons(group, group.features);
      })
    );

    const missingSummary = groups.filter((group: PolygonGroup) => {
      return group.features.some((feature: any) => {
        return !feature.properties.summary;
      });
    });

    if (missingSummary.length > 0) {
      await Promise.all(
        missingSummary.map(async (group: PolygonGroup) => {
          return await fetchSummaries(group);
        })
      );
    }

    dispatch({
      type: PolygonActionType.Saved,
      payload: {},
    });

    dispatch({
      type: PolygonActionType.SetNoChanges,
      payload: false,
    });
  };

  return (
    <>
      <div className="flex items-center justify-between mt-4">
        <h2 className="font-bold text-xl">Territory Management</h2>
        {state.saving && (
          <div className="w-4 h-4 bg-green-100 flex items-center justify-center rounded-full animate-pulse">
            <div className="w-2 h-2 bg-green-500 rounded-full"></div>
          </div>
        )}
      </div>
      <div>
        <div className="mb-3"></div>
        <PolygonTerritoriesMenu
          polygonTabState={state}
          polygonTabDispatch={dispatch}
          appConfig={appConfig}
          selectedStateCode={selectedStateCode}
        />
        {state.changes && !state.saving && (
          <div className="w-full mt-8 mb-4 rounded-md flex justify-start items-stretch">
            <div className="bg-[#4f46e5] w-1.5 mr-2.5 rounded-full"></div>
            <div className="py-0.5 flex flex-col justify-center">
              <div className="text-[#4f46e5] text-sm font-semibold">
                You have unsaved changes
              </div>
              <div className="text-gray-600 text-xs font-medium mt-1">
                Click on the button below to backup your changes and{" "}
                <span className="font-semibold text-[#4f46e5]">
                  load the summaries for any new territory
                </span>
                .
              </div>
            </div>
          </div>
        )}
        {state.changes && (
          <button
            type="button"
            className={`rounded-md bg-indigo-50 px-4 py-2 mb-3 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100 duration-300 ${
              state.saving && "animating-pulse"
            }`}
            onClick={saveChanges}
            disabled={state.saving}
          >
            Save & Load Summaries
            {state.saving && (
              <span className="pl-2">
                <LoadingSpinner small />
              </span>
            )}
          </button>
        )}
        <PolygonSummary PolygonTabState={state} appConfig={appConfig} />
      </div>
    </>
  );
};

export default PolygonTab;
