import React, { FC } from "react";
import { Color } from "../utils/geo";

interface ColorLegendProps {
  title?: string;
  points?: number[];
  intervals?: number[];
  colors: Color[];
  transform: (d: number) => string;
}

const ColorLegend: FC<ColorLegendProps> = ({
  points,
  intervals,
  colors,
  transform,
  title,
}) => (
  <div className="bg-white text-gray-700 min-h-[120px] w-[220px] rounded-sm p-4 drop-shadow-md flex flex-col text-sm">
    {title && <h4 className="font-bold mb-1">{title}</h4>}
    {points &&
      points.map((curr, idx) => (
        <Item key={curr} color={colors[idx]} text={transform(curr)} />
      ))}
    {intervals &&
      intervals.map((curr, idx, arr) => {
        if (idx === 0) return null;
        const prev = arr[idx - 1];
        const color = colors[idx - 1];
        const text = `${transform(prev)} → ${transform(curr)}`;
        return <Item key={idx} color={color} text={text} />;
      })}
  </div>
);

const Item: FC<{ color: Color; text: string }> = ({ color, text }) => {
  const backgroundColor = getHexColor(color);
  return (
    <div>
      <span className="w-3 h-3 inline-block mr-2" style={{ backgroundColor }}>
        {" "}
      </span>
      {text}
    </div>
  );
};

function getHexColor(color: Color): string {
  return (
    "#" +
    color.map((val) => ("0" + Number(val).toString(16)).slice(-2)).join("")
  );
}

export default ColorLegend;
