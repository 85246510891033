export interface NumberRange {
  min: number;
  max: number;
}

export class SeriesStats {
  range: NumberRange;
  intervals: number[];

  constructor(min: number, max: number, intervals: number[]) {
    this.range = { min, max };
    this.intervals = intervals;
  }

  static from(arr: number[]) {
    arr.sort((a, b) => a - b);
    const intervals = quantize(arr, 6);
    return new SeriesStats(arr[0], arr[arr.length - 1], intervals);
  }

  findInterval(val: number): number {
    for (let i = 1; i < this.intervals.length; i++) {
      if (this.intervals[i] >= val) {
        return i - 1;
      }
    }
    return this.intervals.length - 1;
  }
}

function quantize(arr: number[], n: number): number[] {
  const step = Math.floor(arr.length / n);
  const result = [];
  for (let i = 0; i < n; i++) {
    result.push(arr[i * step]);
  }
  result.push(arr[arr.length - 1]);
  return result;
}
