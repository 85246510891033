import { Tab } from "../../../components/HeadingMenu";

export enum MenuTabs {
  Explore = "explore",
  Evaluate = "evaluate",
}

export const MENU_TABS: Tab[] = [
  { name: "Explore Layers", key: MenuTabs.Explore },
  { name: "Evaluate Location", key: MenuTabs.Evaluate },
];

export const BASE_URL = "https://d1r2imh3bsgl8k.cloudfront.net";

export const API_URL = process.env.REACT_APP_API_URL;

export const DEFAULT_MAPBOX_STYLE_URL = "mapbox://styles/mapbox/streets-v12";
export const MAPBOX_STYLE_URL_NOPOI =
  process.env.REACT_APP_MAPBOX_STYLE_URL_NOPOI;

export const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "invalid_token";

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
};

export const ICON_MAPPING_COLOR = {
  marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: false },
};

export const PANEL_WIDTH = 360;
