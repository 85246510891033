import React, { FC, useState } from "react";
import { DataCacheContext } from "../hooks/useDataCache";

interface DataCacheProviderProps {
  children: React.ReactNode;
}

export const DataCacheProvider: FC<DataCacheProviderProps> = ({ children }) => {
  const [datasets, setDatasets] = useState<Record<string, any>>({});

  const loadDataset = async (dataset: string, url: string): Promise<any> => {
    const response = await fetch(url);
    const data = await response.json();
    setDatasets((prev) => ({ ...prev, [dataset]: data }));
    return data;
  };

  const getDataset = (dataset: string): any => {
    return datasets[dataset];
  };

  return (
    <DataCacheContext.Provider
      value={{
        loadDataset,
        getDataset,
      }}
    >
      {children}
    </DataCacheContext.Provider>
  );
};
