import React, { FC } from "react";

const WarningTooltip: FC<{
  title: string;
  description: string;
}> = ({ title, description }) => (
  <div className="w-full mt-3 mb-4 rounded-md flex justify-start items-stretch">
    <div className="bg-[#ffc12e] w-1.5 mr-2.5 rounded-l-m"></div>
    <div className="py-0.5 flex flex-col justify-center">
      <div className="text-[#ffc12e] text-sm font-semibol">{title}</div>
      <div className="text-gray-600 text-xs font-medium">{description}</div>
    </div>
  </div>
);

export default WarningTooltip;
