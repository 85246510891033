import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { FC } from "react";
import MultiRangeSlider from "../../../components/MultiRangeSlider";
import SelectLayers from "../../../components/SelectLayers";
import { NumberRange } from "../../../utils/series";
import {
  Action,
  ActionType,
  DATA_FIELD_PROPS,
  DataField,
  DataVizState,
  FieldState,
} from "./data";
import {
  IconLayerConfig,
  Action as LayerAction,
  ActionTypes as LayerActionTypes,
} from "./reducer";

interface ExploreTabProps {
  appid: string;
  title: string;
  dataVizState: DataVizState;
  dataVizDispatch: React.Dispatch<Action>;
  showPoiSection?: boolean;
  onTogglePoi?: (val: string[]) => void;
  nearbyPoiLayers: IconLayerConfig[];
  layerDispatch: React.Dispatch<LayerAction>;
  poiSectionLayers: string[];
  poiSectionDisplayNames: string[];
}

const ExploreTab: FC<ExploreTabProps> = ({
  appid,
  title,
  dataVizState,
  dataVizDispatch,
  showPoiSection,
  onTogglePoi,
  nearbyPoiLayers,
  layerDispatch,
  poiSectionLayers,
  poiSectionDisplayNames,
}) => {
  const handleLayerChange = (val: string[]) => {
    let activeField: Nullable<DataField> = null;
    if (val.length > 0) {
      activeField = val[0] as DataField;
    }
    dataVizDispatch({
      type: ActionType.SetActiveField,
      setActiveField: { activeField },
    });
  };
  const handleGroupFieldChange = (index: number, val: string[]) => {
    dataVizDispatch({
      type: ActionType.SetGroupActiveFields,
      setGroupActiveFields: { index, activeFields: val as DataField[] },
    });
  };

  const fieldList = Object.values(dataVizState.fields).map((f) => f.field);
  const fieldLabels = fieldList.map((f) => DATA_FIELD_PROPS[f].displayName);

  return (
    <>
      <h2 className="font-bold text-xl my-4 mb-2">Layer Control</h2>
      <div className="mb-2">
        <div className="flex items-center">
          <span className="mr-2">{title}</span>
          {dataVizState.error && (
            <ExclamationTriangleIcon width={16} height={16} color="orange" />
          )}
        </div>
        <div className="ml-3 text-sm leading-6 text-gray-900">
          <SelectLayers
            items={fieldList}
            displayNames={fieldLabels}
            single
            initialSelected={
              [dataVizState.activeField].filter((e) => e) as string[]
            }
            onChange={handleLayerChange}
          />
        </div>
      </div>
      {dataVizState.fieldGroups.map((group, idx) => (
        <div key={idx} className="mb-2">
          <div>Traffic Data</div>
          <div className="ml-3 text-sm leading-6 text-gray-900">
            <SelectLayers
              items={group.fields}
              displayNames={group.fields.map(
                (f) => DATA_FIELD_PROPS[f].displayName
              )}
              single
              initialSelected={[]}
              onChange={(val: string[]) => handleGroupFieldChange(idx, val)}
            />
          </div>
        </div>
      ))}
      {showPoiSection && (
        <div className="mb-2">
          <div>{appid === "App06RakkanRamen" ? "My POIs" : "POIs"}</div>
          <div className="ml-3 text-sm leading-6 text-gray-900">
            <SelectLayers
              items={poiSectionLayers}
              displayNames={poiSectionDisplayNames}
              initialSelected={["stores"]}
              onChange={(val: string[]) => onTogglePoi && onTogglePoi(val)}
            />
          </div>
        </div>
      )}
      {nearbyPoiLayers.length > 0 && (
        <div className="mb-2">
          <div>POIs</div>
          <div className="ml-3 text-sm leading-6 text-gray-900">
            <SelectLayers
              items={nearbyPoiLayers.map((l) => l.id)}
              displayNames={nearbyPoiLayers.map((l) => l.name)}
              initialSelected={[]}
              single
              onChange={(val: string[]) => {
                layerDispatch({
                  type: LayerActionTypes.SetVisibleIconLayers,
                  payload: val,
                });
              }}
            />
          </div>
        </div>
      )}

      <h2 className="font-bold text-xl my-4 mb-2">Define Criteria</h2>
      {Object.values(dataVizState.fields).map(
        (field: FieldState, idx: number) => (
          <div key={idx} data-cy="layer-filter-item">
            <div className="mb-1 text-sm">
              {DATA_FIELD_PROPS[field.field].displayName}
            </div>
            <MultiRangeSlider
              min={field.stats.range.min}
              max={field.stats.range.max}
              onChange={(range: NumberRange) => {
                dataVizDispatch({
                  type: ActionType.FilterChanged,
                  filterChanged: { field: field.field, range },
                });
              }}
              format={DATA_FIELD_PROPS[field.field].format}
            />
          </div>
        )
      )}
    </>
  );
};

export default ExploreTab;
