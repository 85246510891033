import { MapViewState, PickingInfo } from "@deck.gl/core/typed";
import { GeoJsonLayer } from "@deck.gl/layers/typed";
import DeckGL from "@deck.gl/react/typed";
import { ExclamationTriangleIcon, PlusIcon } from "@heroicons/react/24/outline";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "geojson";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { default as MapGL } from "react-map-gl";
import { Button } from "../../../components/Button";
import GeoLocator from "../../../components/GeoLocator";
import LoadingSpinner from "../../../components/LoadingSpinner";
import LogoutButton from "../../../components/LogoutButton";
import MultiRangeSlider from "../../../components/MultiRangeSlider";
import SelectLayers from "../../../components/SelectLayers";
import { default as SelectMenu } from "../../../components/DeprecatedSelectMenu";
import joeLogo from "../../../images/joe-logo.png";
import { COLOR_SET_1, COLOR_SET_2 } from "../../../utils/colors";
import { currencyFormatter } from "../../../utils/format";
import { Color, extractSeries } from "../../../utils/geo";
import { US_VIEW_STATE } from "../../../utils/map";
import { NumberRange, SeriesStats } from "../../../utils/series";
import ColorLegend from "../../../components/ColorLegend";

const GEOJSON_URL =
  "https://geodatasets-public.s3.us-west-2.amazonaws.com/joehome/home_value_pop1.geojson";
const MAPBOX_TOKEN =
  process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "invalid_token";

const DATA_LAYERS = ["home_value", "population"];
const DATA_LAYER_DISPLAY_MAP = new Map<string, string>([
  ["home_value", "Median Home Value"],
  ["population", "Population"],
]);
const DATA_LAYER_DISPLAY = Array.from(DATA_LAYER_DISPLAY_MAP.values());

const HOME_VALUE_COLORS = COLOR_SET_2;
const POPULATION_COLORS = COLOR_SET_1;

const TERRITORIES = [
  "Select Territory",
  "Riverside",
  "Fort Collins",
  // "Hartford",
  "Orlando",
  "Athens",
];

const TERRITORY_GEOIDS = new Map<string, string[]>([
  ["Riverside", ["06065", "06071"]],
  ["Fort Collins", ["08069", "08123"]],
  // ["Hartford", ["51119"]], // TODO: Missing States
  ["Orlando", ["12069", "12095", "12097", "12117"]],
  [
    "Athens",
    ["13059", "13133", "13157", "13195", "13211", "13219", "13221", "13237"],
  ],
]);

interface TerritoryStats {
  medianHomeValue: number;
  totalPopulation: number;
}

const DEFAULT_HOMEVAL_STATS: SeriesStats = new SeriesStats(0, 1_000_000, []);
const DEFAULT_POP_STATS: SeriesStats = new SeriesStats(0, 1000_000, []);

interface ViewStateType {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
}

const TERRITORY_VIEW = new Map<string, ViewStateType>([
  [
    "Riverside",
    {
      longitude: -117.37343745928383,
      latitude: 33.951122117789104,
      zoom: 6,
      pitch: 0,
      bearing: 0,
    },
  ],
  [
    "Fort Collins",
    {
      longitude: -105.07211703974048,
      latitude: 40.563679726793886,
      zoom: 6,
      pitch: 0,
      bearing: 0,
    },
  ],
  [
    "Orlando",
    {
      longitude: -81.3588034551829,
      latitude: 28.531237489080013,
      zoom: 6,
      pitch: 0,
      bearing: 0,
    },
  ],
  [
    "Athens",
    {
      longitude: -83.37230167337603,
      latitude: 33.95080509601675,
      zoom: 6,
      pitch: 0,
      bearing: 0,
    },
  ],
]);

const App01JoeHome: FC<{}> = () => {
  const [data, setData] = useState<FeatureCollection>();
  const [loadError, setLoadError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedTerritory, setSelectedTerritory] = useState<string>();
  const [viewState, setViewState] = useState<MapViewState>(US_VIEW_STATE);

  const handleSelectTerritory = useCallback((territory: string) => {
    if (territory === TERRITORIES[0]) {
      setSelectedTerritory(undefined);
      return;
    }
    setSelectedTerritory(territory);
    const newViewState = TERRITORY_VIEW.get(territory);
    if (newViewState) {
      setViewState(newViewState);
    }
  }, []);

  const selectedTerritoryStats = useMemo<TerritoryStats>(() => {
    const stats = { medianHomeValue: 0, totalPopulation: 0 };
    if (!selectedTerritory || !data) {
      return stats;
    }
    const counties = TERRITORY_GEOIDS.get(selectedTerritory);
    if (!counties || !counties.length) {
      return stats;
    }
    const records = data.features.filter(
      (f) => f.properties && counties.includes(f.properties.GEOID)
    );
    let totalHomeVal = 0;
    let totalPopulation = 0;
    for (const record of records) {
      totalHomeVal += record.properties?.home_value || 0;
      totalPopulation += record.properties?.population || 0;
    }
    return {
      medianHomeValue: totalHomeVal / records.length,
      totalPopulation,
    };
  }, [selectedTerritory, data]);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const resp = await fetch(GEOJSON_URL);
        const respData = await resp.json();
        setData(respData);
      } catch (err) {
        console.error(err);
        setLoadError(true);
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const homeValStats = useMemo(() => {
    if (!data) return DEFAULT_HOMEVAL_STATS;
    const series = extractSeries(data, "home_value");
    return SeriesStats.from(series);
  }, [data]);

  const popStats = useMemo(() => {
    if (!data) return DEFAULT_POP_STATS;
    const series = extractSeries(data, "population");
    const st = SeriesStats.from(series);
    // st.jenks = [48, 7_600, 15_132, 25_891, 45_632, 116_625, 10_019_635];
    return st;
  }, [data]);

  const [homeValueFilterRange, setHomeValueFilterRange] = useState<NumberRange>(
    { min: 0, max: 1_000_000 }
  );
  const [homeValueFilterSelRange, setHomeValueFilterSelRange] =
    useState<NumberRange>({
      min: 0,
      max: 1_000_000,
    });

  useEffect(() => {
    setHomeValueFilterRange({ min: 0, max: homeValStats.range.max });
    setHomeValueFilterSelRange({ min: 0, max: homeValStats.range.max });
  }, [homeValStats]);

  const [popFilterRange, setPopFilterRange] = useState<NumberRange>({
    min: 0,
    max: 1_000_000,
  });
  const [popFilterSelRange, setPopFilterSelRange] = useState<NumberRange>({
    min: 0,
    max: 1_000_000,
  });

  useEffect(() => {
    setPopFilterRange({ min: 0, max: popStats.range.max });
    setPopFilterSelRange({ min: 0, max: popStats.range.max });
  }, [popStats]);

  const filteredGeomFeatures = useMemo(() => {
    if (!data) return [];
    return data.features.filter(
      (f) =>
        f.properties &&
        f.properties.home_value >= homeValueFilterSelRange.min &&
        f.properties.home_value <= homeValueFilterSelRange.max &&
        f.properties.population >= popFilterSelRange.min &&
        f.properties.population <= popFilterSelRange.max
    );
  }, [data, homeValueFilterSelRange, popFilterSelRange]);

  const [hoverInfo, setHoverInfo] = useState<PickingInfo>();

  const [visibleDataLayer, setVisibleDataLayer] =
    useState<Nullable<string>>("home_value");
  const handleVisibleDataLayerChange = (val: string[]) => {
    if (val.length === 0) {
      setVisibleDataLayer(null);
    } else {
      setVisibleDataLayer(val[0]);
    }
  };

  const getFillColorForHomeValue = (value: number): Color => {
    if (!value) {
      return [0, 0, 0, 0];
    }
    const interval = homeValStats.findInterval(value);
    return HOME_VALUE_COLORS[interval];
  };

  const getFillColorForHomePopulation = (value: number): Color => {
    if (!value) {
      return [0, 0, 0, 0];
    }
    const interval = popStats.findInterval(value);
    return POPULATION_COLORS[interval];
  };

  const geoJsonLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    const getFillColor = (d: Feature<Geometry, GeoJsonProperties>): Color => {
      if (!d.properties) return [0, 0, 0, 0];
      switch (visibleDataLayer) {
        case "home_value":
          return getFillColorForHomeValue(d.properties.home_value);
        case "population":
          return getFillColorForHomePopulation(d.properties.population);
        default:
          return [0, 0, 0, 0];
      }
    };
    const getLineColor = (d: Feature<Geometry, GeoJsonProperties>): Color => {
      const defaultColor: Color = [248, 148, 116, 64];
      const highlightColor: Color = [255, 255, 0, 255];
      if (!d.properties || !d.properties.GEOID) return [0, 0, 0, 0];
      if (!selectedTerritory) return defaultColor;
      const territoryGeoids = TERRITORY_GEOIDS.get(selectedTerritory);
      if (!territoryGeoids) return defaultColor;
      if (territoryGeoids.includes(d.properties.GEOID)) {
        return highlightColor;
      }
      return defaultColor;
    };
    return new GeoJsonLayer({
      id: `geojson-layer-${visibleDataLayer}-${selectedTerritory}`,
      data: filteredGeomFeatures,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 1,
      getFillColor,
      getLineColor,
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [visibleDataLayer, filteredGeomFeatures, selectedTerritory]);

  const deckglLayers = useMemo<GeoJsonLayer[]>(() => {
    if (!geoJsonLayer) return [];
    return [geoJsonLayer];
  }, [geoJsonLayer]);

  return (
    <div>
      {hoverInfo && hoverInfo.object && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            pointerEvents: "none",
            left: hoverInfo.x + 8 + 360,
            top: hoverInfo.y + 8,
          }}
        >
          <div className="w-48 h-24 bg-gray-50 pl-4 pt-4 rounded-md drop-shadow-md text-sm">
            <p className="pb-1">
              <span className="pr-1 font-bold">County:</span>
              {hoverInfo.object.properties.NAME}
            </p>
            <p>
              <span className="pr-1 font-bold">Home Value:</span>
              {currencyFormatter.format(
                Number.parseFloat(hoverInfo.object.properties.home_value)
              )}
            </p>
            <p>
              <span className="pr-1 font-bold">Population:</span>
              {Number.parseInt(
                hoverInfo.object.properties.population
              ).toLocaleString()}
            </p>
          </div>
        </div>
      )}
      {visibleDataLayer === "home_value" && (
        <ColorLegend
          intervals={homeValStats.intervals}
          colors={HOME_VALUE_COLORS}
          transform={currencyFormatter.format}
        />
      )}
      {visibleDataLayer === "population" && (
        <ColorLegend
          intervals={popStats.intervals}
          colors={POPULATION_COLORS}
          transform={(d: number) => d.toLocaleString()}
        />
      )}
      <div className="absolute bg-white text-gray-700 min-h-[200px] h-auto w-[360px] top-0 left-0 bottom-0 rounded-sm p-8 z-10 drop-shadow-md flex flex-col overflow-auto">
        <div className="flex flex-col">
          <img className="mb-4" src={joeLogo} height={144} width={84} alt="" />
          <h2 className="font-bold text-xl my-4">Layer Control</h2>
          <div className="mb-2">
            <div className="flex items-baseline">
              <span className="mr-2">Data</span>
              {loading && <LoadingSpinner small />}
              {loadError && (
                <ExclamationTriangleIcon width={16} height={16} color="red" />
              )}
            </div>
            <div className="ml-3 text-sm leading-6 text-gray-900">
              <SelectLayers
                items={DATA_LAYERS}
                displayNames={DATA_LAYER_DISPLAY}
                single
                initialSelected={["home_value"]}
                onChange={handleVisibleDataLayerChange}
              />
            </div>
          </div>
          <div className="mb-2">
            Territory
            <div className="ml-3 text-sm leading-6">
              <SelectMenu
                items={TERRITORIES}
                onChange={handleSelectTerritory}
              />
              <div className="my-2">
                <Button disabled>
                  <div className="flex flex-row items-center">
                    <span className="mr-1">Add Territory</span>
                    <PlusIcon width={16} height={16} />
                  </div>
                </Button>
              </div>
            </div>
          </div>

          {selectedTerritory && (
            <>
              <h2 className="font-bold text-xl my-4 mt-8">Territory Summary</h2>
              <div className="ml-3 text-sm leading-6 text-gray-900">
                <div>
                  Territory Name:{" "}
                  <span className="font-bold">{selectedTerritory}</span>
                </div>
                <div>
                  Counties Included:{" "}
                  <span className="font-bold">
                    {TERRITORY_GEOIDS.get(selectedTerritory)?.length}
                  </span>
                </div>
                <div>
                  Median Home Value:{" "}
                  <span className="font-bold">
                    {currencyFormatter.format(
                      selectedTerritoryStats.medianHomeValue
                    )}
                  </span>
                </div>
                <div>
                  Total Population:{" "}
                  <span className="font-bold">
                    {selectedTerritoryStats.totalPopulation.toLocaleString()}
                  </span>
                </div>
              </div>
            </>
          )}
          <h2 className="font-bold text-xl my-4 mt-8">Define Criteria</h2>
          <div className="mb-1">Median Home Value</div>
          <MultiRangeSlider
            min={homeValueFilterRange.min}
            max={homeValueFilterRange.max}
            onChange={setHomeValueFilterSelRange}
            format={currencyFormatter.format}
          />
          <div className="mb-1">Population</div>
          <MultiRangeSlider
            min={popFilterRange.min}
            max={popFilterRange.max}
            onChange={setPopFilterSelRange}
            format={(d) => d.toLocaleString()}
          />
        </div>
        <div className="mt-auto border-t border-gray-100 py-2">
          <LogoutButton />
        </div>
      </div>
      <div className="absolute top-2 right-2 drop-shadow-md z-10">
        <GeoLocator
          mapboxAccessToken={MAPBOX_TOKEN}
          onViewStateChange={setViewState}
          selectedPoi={null}
          onSelectPoi={() => {
            //TODO: implement dropped pin
          }}
        />
      </div>
      <DeckGL
        style={{ left: "360px" }}
        width={"calc(100vw - 360px)"}
        controller={true}
        layers={deckglLayers}
        onHover={setHoverInfo}
        viewState={viewState}
        onViewStateChange={({ viewState }) =>
          setViewState(viewState as ViewStateType)
        }
      >
        <MapGL
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/mapbox/light-v11"
        />
      </DeckGL>
    </div>
  );
};

export default App01JoeHome;
