import classNames from "classnames";
import React, { FC, ReactNode } from "react";

export interface props {
  selected: boolean;
  setSelected: (selected: boolean) => void;
  children?: ReactNode;
  className?: string;
}

const ToggleButton: FC<props> = ({
  children,
  selected,
  setSelected,
  className,
}) => {
  return (
    <button
      className={classNames(
        className ?? "",
        "p-1 border rounded-md",
        selected
          ? "bg-gray-500 text-gray-50 border-gray-300"
          : "bg-gray-50 border-gray-300 text-gray-500"
      )}
      onClick={() => {
        setSelected(!selected);
      }}
    >
      {children}
    </button>
  );
};

export default ToggleButton;
