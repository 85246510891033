import React, { FC, ReactElement } from "react";
import { props } from "./ToggleButton";

interface ToggleButtonGroupProps {
  children: ReactElement<props>[];
}

const ToggleButtonGroup: FC<ToggleButtonGroupProps> = ({ children }) => {
  const handleButtonClick = (index: number) => {
    children.forEach((child, i) => {
      if (i === index && !child.props.selected) {
        child?.props.setSelected(true);
      } else {
        child?.props.setSelected(false);
      }
    });
  };

  return (
    <>
      {children.map((child, index) => (
        <li key={index} onClick={() => handleButtonClick(index)}>
          {child}
        </li>
      ))}
    </>
  );
};

export default ToggleButtonGroup;
