type EnvType = "stage" | "prod";
export const ENVIROMENT =
  (process.env.REACT_APP_ENVIROMENT as EnvType) ?? "stage";

export const BASE_SHAPES_URL =
  "https://unearth-prod.s3.us-west-2.amazonaws.com/appdata/shapes";

export const BASE_APPDATA_URL_MAP = {
  stage: "https://unearth-stage.s3.us-west-2.amazonaws.com/appdata/apps",
  prod: "https://unearth-prod.s3.us-west-2.amazonaws.com/appdata/apps",
};

export const BASE_APPDATA_URL = BASE_APPDATA_URL_MAP[ENVIROMENT];
