import React, { FC, useState } from "react";
import { EyeIcon, EyeSlashIcon, TrashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Action, ActionTypes } from "../pages/templates/template02/reducer";
import { AppConfig, FilterLayerConfig, IconLayer } from "../utils/appconfig";

interface SelectLayersControlledProps<T> {
  items: T[];
  selected: T[];
  onToggle: (item: T) => void;
  deletable?: boolean;
  setSavedIconLayers?: (value: IconLayer[]) => void;
  setAppConfig?: React.Dispatch<React.SetStateAction<AppConfig | undefined>>;
  appConfig?: AppConfig;
  layerDispatch?: React.Dispatch<Action>;
}

const SelectLayersControlled = <T extends { name: string }>({
  items,
  selected,
  onToggle,
  deletable,
  setSavedIconLayers,
  appConfig,
  setAppConfig,
  layerDispatch,
}: SelectLayersControlledProps<T>) => {
  const isSelected = (item: T): boolean => {
    return selected.includes(item);
  };

  const deleteItem = (item) => {
    if (!layerDispatch || !setAppConfig || !setSavedIconLayers || !appConfig)
      return;

    setSavedIconLayers(
      JSON.parse(localStorage.getItem("layers") || "[]").filter(
        (layer: IconLayer) => layer.id !== item.layers[0]
      )
    );

    const localAppConfig = {
      ...appConfig,
    };

    const poisFilterGroupIndex = localAppConfig.filterGroups
      .map((group) => group.id)
      .indexOf("pois");

    localAppConfig.filterGroups[poisFilterGroupIndex].items = (
      localAppConfig.filterGroups[poisFilterGroupIndex]
        .items as FilterLayerConfig[]
    ).filter((localItem) => !localItem.layers.includes(item.layers[0]));

    setAppConfig(localAppConfig);

    layerDispatch({
      type: ActionTypes.RemoveIconLayer,
      payload: { id: item.layers[0] },
    });
  };

  return (
    <div>
      {items.map((item, idx) => (
        <div
          key={idx}
          className={classNames(
            "flex items-center cursor-pointer justify-between",
            isSelected(item) && "font-medium"
          )}
          data-cy="select-layer-item"
        >
          <div>{item.name}</div>

          <div className="flex items-center mb-1">
            {deletable && (
              <div className="mr-2">
                <TrashIcon
                  onClick={() => deleteItem(item)}
                  width={16}
                  height={16}
                  className="hover:text-[#d62b36] duration-300 cursor-pointer"
                />
              </div>
            )}
            <div className="cursor-pointer" onClick={() => onToggle(item)}>
              {isSelected(item) ? (
                <EyeIcon width={16} height={16} />
              ) : (
                <EyeSlashIcon width={16} height={16} />
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectLayersControlled;
