import {
  Layer,
  MapViewState,
  PickingInfo,
  Position,
} from "@deck.gl/core/typed";
import { GeoJsonLayer, IconLayer } from "@deck.gl/layers/typed";
import DeckGL from "@deck.gl/react/typed";
import classNames from "classnames";

import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "geojson";
import mixpanel from "mixpanel-browser";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { default as MapGL } from "react-map-gl";
import ColorLegend from "../../../components/ColorLegend";
import GeoLocator from "../../../components/GeoLocator";
import HeadingMenu from "../../../components/HeadingMenu";
import LoadingOverlay from "../../../components/LoadingOverlay";
import LogoutButton from "../../../components/LogoutButton";
import SelectMenu from "../../../components/SelectMenu";
import { useDataCache } from "../../../hooks/useDataCache";
import competitorIcon from "../../../images/competitor.png";
import cotenantIcon from "../../../images/cotenant.png";
import gopherIcon from "../../../images/gopher.png";
import hellosugarIcon from "../../../images/hellosugar.png";
import rakkanIcon from "../../../images/rakkan.png";
import iconAtlasImg from "../../../images/icon-atlas-2.png";
import restaurantsOrangeImg from "../../../images/restaurants-icon-orange.png";
import restaurantsRedImg from "../../../images/restaurants-icon-red.png";
import unearthLogo from "../../../images/unearth-logo.png";
import {
  COLOR_GOLD_HIGHLIGHT,
  COLOR_GOLD_TRANSPARENT,
  COLOR_ICON_BLUE,
  COLOR_ICON_RED,
  COLOR_TRANSPARENT,
  COLOR_YELLOW_HIGHLIGHT,
  interpolate,
} from "../../../utils/colors";
import { Color } from "../../../utils/geo";
import { MapPin, STATE_MAP_VIEWS, US_VIEW_STATE } from "../../../utils/map";
import { normalize } from "../../../utils/stats";
import { PoiFeature } from "../../../utils/types";
import EvaluateTab, {
  DistanceProfile,
  ActionType as EvaluateTabActionType,
  reducer as evaluateTabReducer,
  initialState as initialEvaluateTabState,
} from "./EvaluateTab";
import ExploreTab from "./ExploreTab";
import { IDataProvider } from "./IDataProvider";
import Tooltip, { BaseTooltip } from "./Tooltip";
import {
  API_URL,
  BASE_URL,
  DEFAULT_MAPBOX_STYLE_URL,
  ICON_MAPPING,
  ICON_MAPPING_COLOR,
  MAPBOX_STYLE_URL_NOPOI,
  MAPBOX_TOKEN,
  MENU_TABS,
  MenuTabs,
} from "./constants";
import {
  ActionType,
  DATA_FIELD_PROPS,
  DataField,
  DataVizReducer,
  FieldGroupInit,
  GroupStats,
  InitDataVizState,
} from "./data";
import {
  ActionTypes,
  IconLayerConfig,
  LayerConfig,
  LayerDataItem,
  initialState as initalLayerState,
  reducer as layerStateReducer,
} from "./reducer";

import useDebounced from "../../../hooks/useDebounced";
import {
  US_POSTAL_CODE_MAP,
  US_STATE_CODE_MAP,
  UsState,
} from "../../../utils/us";
import { extractUsStateFromPoiFeature } from "../../../utils/helper";

const ICON_LAYER_PREFIX = "iconlayer";
const toIconLayerId = (id: string) => `${ICON_LAYER_PREFIX}-${id}`;
const fromIconLayerId = (id: string) => id.replace(`${ICON_LAYER_PREFIX}-`, "");

interface Template01Props {
  title: string;
  usStates: UsState[];
  layerFields: DataField[];
  summaryFields: DataField[];
  fieldGroups?: FieldGroupInit[];
  dataProvider?: IDataProvider;
  useApi?: boolean;
  evaluateParams?: { minutes?: number; profile?: string };
  showPois?: boolean;
  storeAreaUrl?: (code: string) => string;
  storePointUrl?: (code: string) => string;
  layerConfig: LayerConfig;
  initMapViewState?: MapViewState;
  nationwideStorePointsUrl?: string;
}

type GeoData = Record<string, Record<string, number>>;

interface PoiRecord {
  store: string;
  name: string;
  address: string;
  category: string;
  location: [number, number];
}

interface StoreAreaFeature {
  properties: {
    location: string;
    address1: string;
    address2: string;
  };
}

interface StorePointRecord {
  location: string;
  address1: string;
  address2: string;
  coordinates: [number, number];
}

const Template01: FC<Template01Props> = ({
  title,
  usStates,
  layerFields,
  summaryFields,
  fieldGroups,
  dataProvider,
  useApi,
  evaluateParams,
  showPois,
  storeAreaUrl,
  storePointUrl,
  layerConfig,
  initMapViewState,
  nationwideStorePointsUrl,
}) => {
  const { loadDataset, getDataset } = useDataCache();
  const [layerState, layerDispatch] = useReducer(
    layerStateReducer,
    initalLayerState(layerConfig)
  );
  const [activeTab, setActiveTab] = useState<string>(MenuTabs.Explore);
  const [geom, setGeom] = useState<Nullable<FeatureCollection>>(null);
  const [geoData, setGeoData] = useState<Nullable<GeoData>>(null);
  const [roadGeom, setRoadGeom] = useState<Nullable<FeatureCollection>>(null);
  const [dataVizState, dataVizDispatch] = useReducer(
    DataVizReducer(layerFields),
    InitDataVizState(layerFields, fieldGroups)
  );
  const activeDataField = dataVizState.activeField;
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [selectedUsState, setSelectedUsState] = useState<UsState>(usStates[0]);
  const [mapViewState, setMapViewState] = useState<MapViewState>(
    initMapViewState ?? US_VIEW_STATE
  );

  const getIconLayerConfig = (id: string): IconLayerConfig =>
    layerConfig.iconLayers.find((l) => l.id === id) as IconLayerConfig;

  const debouncedMapViewState = useDebounced(mapViewState, 500);
  useEffect(() => {
    const { latitude, longitude, zoom } = debouncedMapViewState;
    const iconLayers = layerConfig.iconLayers.filter(
      (l) => l.loadOn === "mapmove"
    );
    iconLayers.forEach((layer) => {
      layer
        .load(latitude, longitude, zoom)
        .then((data) => {
          layerDispatch({
            type: ActionTypes.LoadIconLayerData,
            payload: {
              id: layer.id,
              data,
            },
          });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }, [debouncedMapViewState]);

  const [initialViewState, setInitialViewState] = useState<MapViewState>(
    initMapViewState ?? US_VIEW_STATE
  );
  const [droppedPin, setDroppedPin] = useState<Nullable<MapPin>>(null);
  const [droppedPinSelected, setDroppedPinSelected] = useState<boolean>(false);
  const [evaluateTabState, evaluateTabDispatch] = useReducer(
    evaluateTabReducer,
    initialEvaluateTabState(
      evaluateParams?.profile as DistanceProfile,
      evaluateParams?.minutes
    )
  );
  const [selectedPoi, setSelectedPoi] = useState<Nullable<PoiFeature>>(null);
  const [isochroneGeom, setIsochroneGeom] =
    useState<Nullable<FeatureCollection>>(null);
  const [hoverInfo, setHoverInfo] = useState<PickingInfo>();
  const [storesGeo, setStoresGeo] = useState<Nullable<FeatureCollection>>(null);
  const [storesRecords, setStoresRecords] =
    useState<Nullable<StorePointRecord[]>>(null);
  const [poiCotenantsVisible, setCotenantsVisible] = useState<boolean>(false);
  const [poiCompetitorsVisible, setCompetitorsVisible] =
    useState<boolean>(false);
  const [storesVisible, setStoresVisible] = useState<boolean>(true);

  const handleTogglePoi = useCallback((val: string[]) => {
    setCotenantsVisible(val.includes("cotenants"));
    setCompetitorsVisible(val.includes("competitors"));
    setStoresVisible(val.includes("stores"));
  }, []);

  useEffect(() => {
    const loadAsync = async () => {
      if (!storeAreaUrl || !storePointUrl) return;
      const usState = selectedUsState;
      const code = US_STATE_CODE_MAP.get(usState);
      if (!code) return;
      try {
        const urlArea = storeAreaUrl(code);
        const respArea = await fetch(urlArea);
        const dataArea = await respArea.json();
        const urlPoint = storePointUrl(code);
        const respPoint = await fetch(urlPoint);
        const dataPoint = await respPoint.json();
        setStoresGeo(dataArea as FeatureCollection);
        setStoresRecords(dataPoint as StorePointRecord[]);
      } catch (err) {
        setStoresGeo(null);
        setStoresRecords(null);
      }
    };
    loadAsync();
  }, [selectedUsState]);

  useEffect(() => {
    const loadAsync = async () => {
      if (!nationwideStorePointsUrl) return;
      try {
        const resp = await fetch(nationwideStorePointsUrl);
        const data = await resp.json();
        setStoresRecords(data as StorePointRecord[]);
      } catch (err) {
        setStoresRecords(null);
      }
    };
    loadAsync();
  }, [nationwideStorePointsUrl]);

  const handleSelectUsState = useCallback((usState: UsState) => {
    setSelectedUsState(usState);
    const newMapViewState = STATE_MAP_VIEWS.get(usState);
    if (newMapViewState) {
      setInitialViewState(newMapViewState);
    }
    mixpanel.track("Select State", {
      State: usState,
    });
  }, []);

  const [cotenantPois, setCotenantPois] = useState<PoiRecord[]>([]);
  const [competitorPois, setCompetitorPois] = useState<PoiRecord[]>([]);

  useEffect(() => {
    const loadAsync = async () => {
      if (!showPois) return;
      const code = US_STATE_CODE_MAP.get(selectedUsState);
      if (!code) return;
      const url = `${API_URL}/pois?statefp=${code}`;
      try {
        const resp = await fetch(url);
        const data = await resp.json();
        const poiRecords = data.pois as PoiRecord[];
        setCotenantPois(poiRecords.filter((p) => p.category !== "competition"));
        setCompetitorPois(
          poiRecords.filter((p) => p.category === "competition")
        );
      } catch (err) {
        setCotenantPois([]);
        setCompetitorPois([]);
      }
    };
    loadAsync();
  }, [showPois, selectedUsState]);

  const handleDropPin = useCallback((coordinates: Position) => {
    const coordStr = (coordinates as [number, number])
      .map((c) => c.toFixed(6))
      .join(", ");
    const poi: PoiFeature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: coordinates as [number, number],
      },
      properties: {},
      place_name: `Dropped Pin (${coordStr})`,
      center: coordinates as [number, number],
      context: [],
    };
    setSelectedPoi(poi);
    setDroppedPin({ coordinates, id: 1, color: [0, 0, 0] });
    setIsochroneGeom(null);
    evaluateTabDispatch({
      type: EvaluateTabActionType.SetSummary,
      payload: {},
    });
    mixpanel.track("Drop Pin", {
      Lon: poi.center[0],
      Lat: poi.center[1],
    });
  }, []);

  const handleSelectPoi = useCallback((poi: PoiFeature) => {
    setSelectedPoi(poi);
    setDroppedPin({ coordinates: poi.center, id: 1, color: [0, 0, 0] });
    setIsochroneGeom(null);
    evaluateTabDispatch({
      type: EvaluateTabActionType.SetSummary,
      payload: {},
    });
    const usState = extractUsStateFromPoiFeature(poi);
    if (usState) {
      setSelectedUsState(usState);
    }
    mixpanel.track("Geolocate POI", {
      Poi: poi.place_name,
      Lon: poi.center[0],
      Lat: poi.center[1],
    });
  }, []);

  const setDataLoadErr = useCallback((err: boolean) => {
    const error = err ? "Error loading data" : "";
    dataVizDispatch({
      type: ActionType.DataLoadErr,
      dataLoadErr: { error },
    });
  }, []);

  useEffect(() => {
    const loadDataAsync = async (dataset: string): Promise<any> => {
      setDataLoading(true);
      setDataLoadErr(false);
      try {
        const data = await loadDataset(dataset, `${BASE_URL}/${dataset}`);
        return data;
      } catch (err) {
        console.error(err);
        setDataLoadErr(true);
      } finally {
        setDataLoading(false);
      }
    };

    const loadCachedDataAsync = async () => {
      if (dataProvider) {
        try {
          setDataLoadErr(false);
          setDataLoading(true);
          const { geo, data, stats } = await dataProvider.load(selectedUsState);
          setGeoData(data);
          setGeom(geo);
          dataVizDispatch({
            type: ActionType.DataLoaded,
            dataLoaded: { dataStats: stats, groupStats: [] },
          });
        } catch (err) {
          console.error(err);
          setDataLoadErr(true);
        } finally {
          setDataLoading(false);
        }
        return;
      }
      const code = US_STATE_CODE_MAP.get(selectedUsState);
      const geoDatasetName = `v2/gopher_${code}.geojson.gz`;
      let geoDataset = getDataset(geoDatasetName);
      if (!geoDataset) {
        geoDataset = await loadDataAsync(geoDatasetName);
      }
      const dataStatsName = `v2/gopher_${code}_stats.json`;
      let dataStats = getDataset(dataStatsName);
      if (!dataStats) {
        dataStats = await loadDataAsync(dataStatsName);
      }
      const aadtDatasetName = `gzip/aadt_${code}.geojson.gz`;
      let aadtDataset = getDataset(aadtDatasetName);
      if (!aadtDataset) {
        aadtDataset = await loadDataAsync(aadtDatasetName);
      }
      setRoadGeom(aadtDataset);
      const aadtStatsDatasetName = `aadt_${code}_stats.json`;
      let aadtStatsDataset = getDataset(aadtStatsDatasetName);
      if (!aadtStatsDataset) {
        aadtStatsDataset = await loadDataAsync(aadtStatsDatasetName);
      }
      if (!geoDataset || !dataStats) {
        return;
      }
      setGeom(geoDataset);
      dataVizDispatch({
        type: ActionType.DataLoaded,
        dataLoaded: { dataStats, groupStats: [aadtStatsDataset as GroupStats] },
      });
    };
    loadCachedDataAsync();
  }, [selectedUsState]);

  const filteredGeomFeatures = useMemo(() => {
    if (!geom) return [];
    if (dataProvider) {
      return geom.features.filter((f) => {
        if (!f.properties || !f.properties["geoid"] || !geoData) return false;
        const geoid = f.properties["geoid"];
        const properties = geoData[geoid];
        for (const [field, range] of Object.entries(dataVizState.filters)) {
          if (properties[field] < range.min || properties[field] > range.max) {
            return false;
          }
        }
        return true;
      });
    } else {
      return geom.features.filter((f) => {
        if (!f.properties) return false;
        for (const [field, range] of Object.entries(dataVizState.filters)) {
          if (
            f.properties[field] < range.min ||
            f.properties[field] > range.max
          ) {
            return false;
          }
        }
        return true;
      });
    }
  }, [geom, dataVizState.filters, geoData]);

  const isDataLayer = (layer: Layer) => {
    if (!layer) return false;
    return (
      layer.id.startsWith("geojson-layer-") ||
      layer.id.startsWith("geojson-road-layer-")
    );
  };

  const isPoiLayer = (layer: Layer) => {
    return layer.id.startsWith("poi-layer-");
  };

  const isCustomLayer = (layer: Layer) => {
    return layer.id.startsWith("custom-layer");
  };

  const isCustomIconLayer = (layer: Layer) => {
    return layer.id.startsWith("custom-icon-layer");
  };

  const isRoadDataLayer = (layer: Layer) => {
    return layer.id.startsWith("geojson-road-layer-");
  };

  const geoJsonLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    const getFillColor = (d: Feature<Geometry, GeoJsonProperties>): Color => {
      if (!d.properties || !activeDataField) return COLOR_TRANSPARENT;
      let value = 0;
      if (dataProvider) {
        if (!geoData) return COLOR_TRANSPARENT;
        const geoid = d.properties["geoid"];
        value = geoData[geoid][activeDataField];
      } else {
        value = d.properties[activeDataField];
      }
      return dataVizState.fields[activeDataField].getFillColor(value);
    };
    return new GeoJsonLayer({
      id: `geojson-layer-${activeDataField}-${selectedUsState}`,
      data: filteredGeomFeatures,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 1,
      getFillColor,
      getLineColor: COLOR_TRANSPARENT,
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [activeDataField, filteredGeomFeatures, selectedUsState, geoData]);

  const hoverLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!hoverInfo?.object) return null;
    if (!hoverInfo.layer) return null;
    if (!isDataLayer(hoverInfo.layer) && !isCustomLayer(hoverInfo.layer))
      return null;
    if (isDataLayer(hoverInfo.layer) && !dataVizState.activeField) return null;

    return new GeoJsonLayer({
      id: `hover-layer-${hoverInfo.x}-${hoverInfo.y}`,
      data: hoverInfo.object,
      pickable: false,
      stroked: true,
      filled: isCustomLayer(hoverInfo.layer),
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 2,
      getFillColor: isCustomLayer(hoverInfo.layer)
        ? COLOR_GOLD_TRANSPARENT
        : COLOR_TRANSPARENT,
      getLineColor: isCustomLayer(hoverInfo.layer)
        ? COLOR_GOLD_HIGHLIGHT
        : COLOR_YELLOW_HIGHLIGHT,
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [hoverInfo?.object]);

  const activeRoadField = useMemo<Nullable<DataField>>(() => {
    if (dataVizState.fieldGroups.length === 0 || !dataVizState.fieldGroups[0])
      return null;
    const activeFields = dataVizState.fieldGroups[0].activeFields;
    if (activeFields.length === 0) return null;
    return activeFields[0];
  }, [dataVizState]);

  const roadLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!roadGeom || !activeRoadField) return null;
    const getColor = (d: Feature<Geometry, GeoJsonProperties>): Color => {
      if (!d.properties) return COLOR_TRANSPARENT;
      const stats = dataVizState.fieldGroups[0].stats[activeRoadField];
      const val = normalize(
        d.properties[activeRoadField],
        stats.q10,
        stats.q90
      );
      const [col1, col2, col3] = DATA_FIELD_PROPS[activeRoadField].colorSet;
      if (val < 0.5) return interpolate(col1, col2, val * 2);
      return interpolate(col2, col3, (val - 0.5) * 2);
    };
    const getFillColor = getColor;
    const getLineColor = getColor;

    return new GeoJsonLayer({
      id: `geojson-road-layer-${selectedUsState}-${activeRoadField}`,
      data: roadGeom,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "circle",
      lineWidthScale: 1,
      lineWidthMinPixels: 2,
      getFillColor,
      getLineColor,
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 10,
    });
  }, [roadGeom, selectedUsState, activeRoadField]);

  const isochroneLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!isochroneGeom) return null;
    return new GeoJsonLayer({
      id: `iso-layer-${isochroneGeom.features.length}`,
      data: isochroneGeom,
      pickable: false,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "text",
      lineWidthScale: 1,
      lineWidthMinPixels: 3,
      getFillColor: [60, 60, 60, 40],
      getLineColor: [255, 235, 60],
      getPointRadius: 100,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [isochroneGeom]);

  const iconData = useMemo(() => {
    return [
      {
        coordinates: droppedPin?.coordinates,
      },
    ];
  }, [droppedPin]);

  const poiLayer = useMemo<Nullable<IconLayer>>(() => {
    if (!poiCotenantsVisible || !cotenantPois || mapViewState.zoom < 6)
      return null;
    return new IconLayer({
      id: `poi-layer-cotenant-${selectedUsState}`,
      data: cotenantPois,
      pickable: true,
      iconAtlas: cotenantIcon,
      iconMapping: ICON_MAPPING_COLOR,
      getIcon: () => "marker",
      sizeScale: 15,
      getPosition: (d: PoiRecord) => d.location,
      getSize: () => 1.8,
      getColor: () => COLOR_ICON_RED,
    });
  }, [cotenantPois, selectedUsState, mapViewState.zoom, poiCotenantsVisible]);

  const poiLayer2 = useMemo<Nullable<IconLayer>>(() => {
    if (!poiCompetitorsVisible || !competitorPois || mapViewState.zoom < 6)
      return null;
    return new IconLayer({
      id: `poi-layer-competitor-${selectedUsState}`,
      data: competitorPois,
      pickable: true,
      iconAtlas: competitorIcon,
      iconMapping: ICON_MAPPING_COLOR,
      getIcon: () => "marker",
      sizeScale: 15,
      getPosition: (d: PoiRecord) => d.location,
      getSize: () => 1.8,
      getColor: () => COLOR_ICON_BLUE,
    });
  }, [
    competitorPois,
    selectedUsState,
    mapViewState.zoom,
    poiCompetitorsVisible,
  ]);

  const iconLayers = useMemo<IconLayer[]>(() => {
    if (!layerConfig || !layerConfig.iconLayers) return [];
    if (!layerState || !layerConfig.iconLayers) return [];
    const isVisible = (l: IconLayerConfig) =>
      layerState.iconLayers[l.id]?.visible;
    const visibleLayers = layerConfig.iconLayers.filter(isVisible);
    return visibleLayers.map(
      (layer) =>
        new IconLayer({
          id: toIconLayerId(layer.id),
          data: layerState.iconLayers[layer.id].data,
          pickable: true,
          iconAtlas:
            layer.color === "red" ? restaurantsRedImg : restaurantsOrangeImg,
          iconMapping: ICON_MAPPING_COLOR,
          getIcon: () => "marker",
          sizeScale: 15,
          getPosition: (d: LayerDataItem) => layer.coordinates(d),
          getSize: () => 1.8,
          getColor: () => COLOR_ICON_RED,
        })
    );
  }, [layerState.iconLayers]);

  const customLayer = useMemo<Nullable<GeoJsonLayer>>(() => {
    if (!storesGeo || !storesVisible) return null;
    return new GeoJsonLayer({
      id: "custom-layer",
      data: storesGeo,
      pickable: true,
      stroked: true,
      filled: true,
      extruded: false,
      pointType: "circle",
      lineWidthScale: 1,
      lineWidthMinPixels: 1,
      getFillColor: [60, 100, 255, 100],
      getLineColor: [60, 100, 255],
      getPointRadius: 250,
      getLineWidth: 1,
      getElevation: 30,
    });
  }, [storesGeo, storesVisible]);

  const droppedPinLayer = useMemo<IconLayer>(() => {
    return new IconLayer({
      id: "icon-layer",
      data: iconData,
      pickable: true,
      iconAtlas: iconAtlasImg,
      iconMapping: ICON_MAPPING,
      getIcon: () => "marker",
      sizeScale: 15,
      getPosition: (d) => d.coordinates,
      getSize: () => 2.5,
      getColor: () => [60, 100, 255],
    });
  }, [iconData]);

  const customIconLayer = useMemo<Nullable<IconLayer>>(() => {
    if (!storesRecords || !storesVisible) return null;
    return new IconLayer({
      id: "custom-icon-layer",
      data: storesRecords,
      pickable: title === "app03gopher" || title === "App06RakkanRamen",
      iconAtlas:
        title === "app03gopher"
          ? gopherIcon
          : title === "App06RakkanRamen"
          ? rakkanIcon
          : hellosugarIcon,
      iconMapping: ICON_MAPPING_COLOR,
      getIcon: () => "marker",
      sizeScale: 15,
      getPosition: (d: StorePointRecord) => d.coordinates,
      getSize: () => 2.5,
      getColor: () => [255, 165, 0],
    });
  }, [storesRecords, storesVisible]);

  const deckglLayers = useMemo<Layer[]>(() => {
    const layers = [
      geoJsonLayer,
      customLayer,
      roadLayer,
      isochroneLayer,
      hoverLayer,
      poiLayer,
      poiLayer2,
      droppedPinLayer,
      ...iconLayers,
      customIconLayer,
    ];
    return layers.filter((l) => l !== null) as Layer[];
  }, [
    geoJsonLayer,
    customLayer,
    roadLayer,
    isochroneLayer,
    poiLayer,
    poiLayer2,
    droppedPinLayer,
    iconLayers,
    customIconLayer,
    hoverLayer,
  ]);

  const trafficLegendPoints = useMemo(() => {
    if (!activeRoadField || !dataVizState.fieldGroups.length) return [];
    const stats = dataVizState.fieldGroups[0].stats[activeRoadField];
    return [stats.q10, stats.q50, stats.q90];
  }, [activeRoadField]);

  const pinContextMenuXY = useMemo<[number, number]>(() => {
    const viewport =
      droppedPinLayer.internalState?.viewport ??
      droppedPinLayer.context?.viewport;
    if (!droppedPin || !viewport) {
      return [0, 0];
    }
    const [x, y] = viewport.project(droppedPin.coordinates as [number, number]);
    return [x + 360, y];
  }, [
    droppedPinLayer.internalState?.viewport,
    droppedPinLayer.context?.viewport,
    mapViewState,
    droppedPin,
  ]);

  return (
    <div>
      {dataLoading && <LoadingOverlay />}
      {hoverInfo &&
        hoverInfo.layer &&
        hoverInfo.layer.id.startsWith(ICON_LAYER_PREFIX) && (
          <IconLayerTooltip
            info={hoverInfo}
            config={getIconLayerConfig(fromIconLayerId(hoverInfo.layer.id))}
          />
        )}
      {hoverInfo && hoverInfo.layer && isDataLayer(hoverInfo.layer) && (
        <Tooltip
          info={hoverInfo}
          field={
            isRoadDataLayer(hoverInfo.layer) ? activeRoadField : activeDataField
          }
          geoData={geoData}
          hasDataProvider={!!dataProvider}
        />
      )}
      {hoverInfo && hoverInfo.layer && isPoiLayer(hoverInfo.layer) && (
        <BaseTooltip
          info={hoverInfo}
          title={(object: PoiRecord) => object.store}
          content={(object: PoiRecord) => (
            <div>
              <div className="text-sm">{object.name}</div>
              <div className="text-sm text-gray-500">{object.address}</div>
            </div>
          )}
        />
      )}
      {hoverInfo && hoverInfo.layer && isCustomLayer(hoverInfo.layer) && (
        <BaseTooltip
          info={hoverInfo}
          title={(object: StoreAreaFeature) => object.properties.location}
          content={(object: StoreAreaFeature) => (
            <div>
              <div className="text-sm text-gray-500">
                {object.properties.address1}
              </div>
              <div className="text-sm text-gray-500">
                {object.properties.address2}
              </div>
            </div>
          )}
        />
      )}
      {hoverInfo &&
        hoverInfo.layer &&
        isCustomIconLayer(hoverInfo.layer) &&
        (title === "app03gopher" || title === "App06RakkanRamen") && (
          <BaseTooltip
            info={hoverInfo}
            title={(object: StorePointRecord) => object.location}
            content={(object: StorePointRecord) => (
              <div>
                <div className="text-sm text-gray-500">{object.address1}</div>
                <div className="text-sm text-gray-500">{object.address2}</div>
              </div>
            )}
          />
        )}
      <PinContextMenu
        xy={pinContextMenuXY}
        droppedPin={droppedPin}
        droppedPinSelected={droppedPinSelected}
        clearDroppedPin={() => {
          setDroppedPin(null);
          setIsochroneGeom(null);
        }}
      />
      <div className="absolute right-2 bottom-2 z-10">
        {activeRoadField && trafficLegendPoints && (
          <div className="mb-1">
            <ColorLegend
              title={DATA_FIELD_PROPS[activeRoadField].displayName}
              points={trafficLegendPoints}
              colors={DATA_FIELD_PROPS[activeRoadField].colorSet}
              transform={DATA_FIELD_PROPS[activeRoadField].format}
            />
          </div>
        )}
        {activeDataField && (
          <ColorLegend
            title={DATA_FIELD_PROPS[activeDataField].displayName}
            intervals={dataVizState.fields[activeDataField].stats.intervals}
            colors={DATA_FIELD_PROPS[activeDataField].colorSet}
            transform={
              DATA_FIELD_PROPS[activeDataField].formatShort ??
              DATA_FIELD_PROPS[activeDataField].format
            }
          />
        )}
      </div>
      <div className="absolute bg-white text-gray-700 min-h-[200px] h-auto w-[360px] top-0 left-0 bottom-0 rounded-sm p-8 z-10 drop-shadow-md flex flex-col overflow-auto">
        <div className="flex flex-col">
          <div className="self-center">
            <img src={unearthLogo} height={32} width={244} alt="" />
          </div>
          <div className="my-6 mx-5 text-sm leading-6">
            <SelectMenu
              value={selectedUsState}
              options={usStates}
              onChange={(val: string) => handleSelectUsState(val as UsState)}
            />
          </div>
          <div className="self-center">
            <HeadingMenu tabs={MENU_TABS} onChange={setActiveTab} />
          </div>
          {activeTab === MenuTabs.Explore && (
            <ExploreTab
              appid={title}
              title={
                dataProvider ? "Demographic" : "Demographic & Neighborhood Data"
              }
              dataVizState={dataVizState}
              dataVizDispatch={dataVizDispatch}
              showPoiSection={
                showPois ||
                !!(storeAreaUrl && storePointUrl) ||
                !!nationwideStorePointsUrl
              }
              poiSectionLayers={
                title === "app03gopher"
                  ? ["stores"]
                  : title === "App06RakkanRamen"
                  ? ["stores"]
                  : ["cotenants", "competitors", "stores"]
              }
              poiSectionDisplayNames={
                title === "app03gopher"
                  ? ["My locations"]
                  : title === "App06RakkanRamen"
                  ? ["My stores"]
                  : ["Co-tenants", "Competitors", "My stores"]
              }
              nearbyPoiLayers={layerConfig.iconLayers}
              onTogglePoi={handleTogglePoi}
              layerDispatch={layerDispatch}
            />
          )}
          {activeTab === MenuTabs.Evaluate && (
            <EvaluateTab
              title={title}
              pin={droppedPin}
              geom={geom}
              usState={selectedUsState}
              onIsochrone={setIsochroneGeom}
              fields={summaryFields}
              state={evaluateTabState}
              dispatch={evaluateTabDispatch}
              useApi={useApi}
              defaultParams={evaluateParams}
            />
          )}
        </div>
        <div className="mt-auto border-t border-gray-100 py-2">
          <LogoutButton />
        </div>
      </div>
      <div className="absolute top-2 right-2 drop-shadow-md z-10">
        <GeoLocator
          mapboxAccessToken={MAPBOX_TOKEN}
          mapViewState={mapViewState}
          onViewStateChange={setInitialViewState}
          selectedPoi={selectedPoi}
          onSelectPoi={handleSelectPoi}
        />
      </div>
      <DeckGL
        style={{ left: "360px" }}
        width={"calc(100vw - 360px)"}
        controller={true}
        layers={deckglLayers}
        onHover={setHoverInfo}
        initialViewState={initialViewState}
        onViewStateChange={({ viewState }) => {
          setMapViewState(viewState as MapViewState);
        }}
        onClick={(info, e) => {
          let clearSelectedPin = true;
          if (
            e.type === "click" &&
            info.object &&
            info.layer &&
            info.layer.id === "icon-layer"
          ) {
            clearSelectedPin = false;
            setDroppedPinSelected(true);
          } else if (
            info.coordinate &&
            info.coordinate.length >= 2 &&
            e.type === "click"
          ) {
            handleDropPin(info.coordinate as Position);
          }
          if (clearSelectedPin) {
            setDroppedPinSelected(false);
          }
        }}
        onDrag={(info, e) => {
          setDroppedPinSelected(false);
        }}
      >
        <MapGL
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={
            (title === "App06RakkanRamen"
              ? MAPBOX_STYLE_URL_NOPOI
              : DEFAULT_MAPBOX_STYLE_URL) || DEFAULT_MAPBOX_STYLE_URL
          }
        />
      </DeckGL>
    </div>
  );
};

const IconLayerTooltip = ({
  info,
  config,
}: {
  info: PickingInfo;
  config: IconLayerConfig;
}) => {
  return (
    <BaseTooltip
      info={info}
      title={(object: LayerDataItem) => config.propname(object)}
      content={(object: LayerDataItem) => (
        <div>
          <div className="text-sm text-gray-500">
            {config.propaddress(object)}
          </div>
        </div>
      )}
    />
  );
};

const PinContextMenu: FC<{
  xy: [number, number];
  droppedPin: Nullable<MapPin>;
  droppedPinSelected: boolean;
  clearDroppedPin: () => void;
}> = ({ xy, droppedPin, droppedPinSelected, clearDroppedPin }) => {
  if (!droppedPin || !droppedPinSelected) return null;
  const [x, y] = xy;
  const top = y + 4;
  const left = x + 2;
  const handleRemove = () => {
    clearDroppedPin();
  };
  return (
    <div
      className={classNames(
        "absolute bg-white border border-gray-300 rounded-md shadow-md z-10"
      )}
      style={{ top, left }}
    >
      <div className="flex flex-col">
        <button
          className={classNames(
            "text-red-500 hover:bg-red-600 hover:text-white p-2 border-t border-t-gray-100",
            "rounded-md"
          )}
          onClick={handleRemove}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default Template01;
