import { MapPinIcon } from "@heroicons/react/20/solid";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Button } from "../../../components/Button";
import LoadingSpinner from "../../../components/LoadingSpinner";
import RadioCards from "../../../components/RadioCards";
import SelectLayersControlled from "../../../components/SelectLayersControlled";
import LayerLoader from "../../../utils/LayerLoader";
import { AppConfig, FilterFieldConfig } from "../../../utils/appconfig";
import { BASE_APPDATA_URL } from "../../../utils/constants";
import {
  Action as LayerAction,
  ActionTypes as LayerActionTypes,
  LayerState,
} from "./reducer";
import { formatInt } from "../../../utils/format";

const CHART_COLORS = ["#988eee", "#84d888"];

interface FilterGroupFieldsSelctProps {
  items: FilterFieldConfig[];
  layerState: LayerState;
  layerDispatch: React.Dispatch<LayerAction>;
}

const FilterGroupFieldsSelect: FC<FilterGroupFieldsSelctProps> = ({
  items,
  layerState,
  layerDispatch,
}) => {
  const isActiveField = (item: FilterFieldConfig): boolean => {
    return layerState.geodataLayers[item.layer].activeField === item.field;
  };
  const selected = useMemo(
    () => items.filter(isActiveField),
    [layerState.geodataLayers]
  );
  const onToggle = useCallback(
    (item: FilterFieldConfig) => {
      layerDispatch({
        type: LayerActionTypes.SetLayerActiveField,
        payload: {
          id: item.layer,
          field: isActiveField(item) ? null : item.field,
        },
      });
    },
    [layerDispatch, layerState.geodataLayers]
  );
  return (
    <SelectLayersControlled
      items={items}
      selected={selected}
      onToggle={onToggle}
    />
  );
};

interface Summary {
  avg_weekly_visits: number;
  latest_weekly_visits: number;
  avg_distance_from_home: number;
  avg_visit_length: number;
}

const SUMMARY_LABELS: Record<string, string> = {
  avg_weekly_visits: "Average weekly visits",
  latest_weekly_visits: "Latest weekly visits",
  avg_distance_from_home: "Average distance traveled (miles)",
  avg_visit_length: "Length of stay (minutes)",
};

interface DataPoint {
  name: string;
  val: number;
  fill?: string;
}
type DataSeries = DataPoint[];
type DataSeriesCollection = Record<string, DataSeries>;

interface Demographics {
  home: DataSeriesCollection;
  work: DataSeriesCollection;
}

enum DemographicGroups {
  Home = "home",
  Work = "work",
}

interface Visitations {
  hour_of_day: DataSeries;
  day_of_week: DataSeries;
}

enum TrafficTrendType {
  HourOfDay = "hour_of_day",
  DayOfWeek = "day_of_week",
}

const DEMOGRAPHICS_LABELS: Record<string, string> = {
  age: "Age",
  race: "Race",
  income: "Income",
};

const DEMOGRAPHICS_TICK_LABELS: Record<string, Record<string, string>> = {
  age: {
    age_18_29: "18-29",
    age_20_29: "20-29",
    age_30_39: "30-39",
    age_40_49: "40-49",
    age_50_59: "50-59",
    age_60_69: "60-69",
    age_70_79: "70-79",
    age_over_80: "80+",
  },
  race: {
    amerindian: "amerindian",
    asian: "asian",
    black: "black",
    other: "other",
    other_race: "other",
    two_or_more_races: "mixed",
    mixed: "mixed",
    white: "white",
  },
  income: {
    "0_24999": "0-25k",
    "25000_49999": "25-50k",
    "50000_74999": "50-75k",
    "50000_79999": "50-80k",
    "75000_99999": "75-100k",
    "80000_99999": "80-100k",
    "100000_124999": "100-125k",
    more_than_125000: "125k+",
  },
};

export interface Location {
  id: string;
  title: string;
  content: string | string[];
  coordinates: [number, number];
}

export interface State {
  locationId?: string;
  locations: Location[];
  summary?: Summary;
  demographics?: Demographics;
  visitations?: Visitations;
  selectedDemographicsGroup: DemographicGroups;
  selectedTrafficTrend: TrafficTrendType;
}

export function initialState(): State {
  return {
    locationId: undefined,
    locations: [],
    summary: undefined,
    demographics: undefined,
    visitations: undefined,
    selectedDemographicsGroup: DemographicGroups.Home,
    selectedTrafficTrend: TrafficTrendType.DayOfWeek,
  };
}

export enum ActionType {
  SetLocationId = "setLocationId",
  SetLocations = "setLocations",
  SetSummary = "setSummary",
  SetDemographics = "setDemographics",
  SetVisitations = "setVisitations",
  SetSelectedDemographicsGroup = "setSelectedDemographicsGroup",
  SetSelectedTrafficTrend = "setSelectedTrafficTrend",
}

export interface Action {
  type: ActionType;
  payload: any;
}

export function reducer(state: State, action: Action): State {
  let reset = false;
  switch (action.type) {
    case ActionType.SetLocationId:
      reset = action.payload !== state.locationId;
      return {
        ...state,
        locationId: action.payload,
        summary: reset ? undefined : state.summary,
        demographics: reset ? undefined : state.demographics,
        visitations: reset ? undefined : state.visitations,
      };
    case ActionType.SetLocations:
      return {
        ...state,
        locations: action.payload,
      };
    case ActionType.SetSummary:
      return {
        ...state,
        summary: action.payload,
      };
    case ActionType.SetDemographics:
      return {
        ...state,
        demographics: action.payload,
      };
    case ActionType.SetVisitations:
      return {
        ...state,
        visitations: action.payload,
      };
    case ActionType.SetSelectedDemographicsGroup:
      return {
        ...state,
        selectedDemographicsGroup: action.payload,
      };
    case ActionType.SetSelectedTrafficTrend:
      return {
        ...state,
        selectedTrafficTrend: action.payload,
      };
    default:
      return state;
  }
}

interface FootTrafficTabProps {
  appConfig: AppConfig;
  layerState: LayerState;
  layerDispatch: React.Dispatch<LayerAction>;
  layerLoader: LayerLoader;
  usStateCode: string;
  state: State;
  dispatch: React.Dispatch<Action>;
}

const FootTrafficTab: FC<FootTrafficTabProps> = ({
  appConfig,
  layerState,
  layerDispatch,
  layerLoader,
  usStateCode,
  state,
  dispatch,
}) => {
  const [loading, setLoading] = useState(false);
  const layer = appConfig.layers.geodataLayers.find(
    (l) => l.id === "foottraffic"
  );
  const selectedLocation = useMemo(
    () => state.locations.find((l) => l.id === state.locationId),
    [state.locationId, state.locations]
  );
  const filterGroupItems = useMemo(
    () => [
      {
        layer: "foottraffic",
        field: "catchment_home",
        name: "Home as origin",
      },
      {
        layer: "foottraffic",
        field: "catchment_work",
        name: "Work as origin",
      },
    ],
    []
  );

  const loadSummary = useCallback((locationId: string) => {
    const loadAsync = async (locationId: string) => {
      const obj = await fetch(
        `${BASE_APPDATA_URL}/${appConfig.id}/foot-traffic/summary_${locationId}.json`
      );
      const data = await obj.json();
      dispatch({
        type: ActionType.SetSummary,
        payload: data.summary as Summary,
      });
      dispatch({
        type: ActionType.SetDemographics,
        payload: data.demographics as Demographics,
      });
      dispatch({
        type: ActionType.SetVisitations,
        payload: data.visitations as Visitations,
      });
    };
    loadAsync(locationId);
  }, []);

  const handleCompute = async () => {
    if (!layer || !state.locationId) return;
    setLoading(true);
    try {
      await Promise.all([
        layerLoader.loadGeodataLayer(
          layer,
          usStateCode,
          layerDispatch,
          state.locationId
        ),
        loadSummary(state.locationId),
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!usStateCode) return;
    const loadAsync = async () => {
      const obj = await fetch(
        `${BASE_APPDATA_URL}/${appConfig.id}/foot-traffic/locations_${usStateCode}.json`
      );
      const data = await obj.json();
      dispatch({
        type: ActionType.SetLocations,
        payload: data as Location[],
      });
    };
    loadAsync();
  }, [usStateCode]);

  return (
    <>
      <h2 className="font-bold text-xl my-4 mb-2">Visitation</h2>
      <div className="mb-2">
        <div>Selected location</div>
        <div className="text-sm leading-6 text-gray-900">
          <div className="flex flex-row align-middle">
            <div>
              <MapPinIcon className="inline-block w-5 h-5 mr-1 mb-1 text-yellow-500" />
            </div>
            <div>
              {!selectedLocation && <span>Please select a location</span>}
              <div>{selectedLocation?.title}</div>
              {selectedLocation?.content &&
                (Array.isArray(selectedLocation?.content) ? (
                  <>
                    {selectedLocation?.content.map((c, i) => (
                      <div key={i}>{c}</div>
                    ))}
                  </>
                ) : (
                  <div>{selectedLocation?.content}</div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2">
        <Button disabled={!state.locationId || loading} onClick={handleCompute}>
          Compute visitation metrics
          {loading && (
            <span className="pl-2">
              <LoadingSpinner small />
            </span>
          )}
        </Button>
      </div>
      {state.summary && (
        <>
          <div className="mb-2">
            <div>Catchment area</div>
            <div className="ml-3 text-sm leading-6 text-gray-900">
              <FilterGroupFieldsSelect
                items={filterGroupItems}
                layerState={layerState}
                layerDispatch={layerDispatch}
              />
            </div>
          </div>
          <div className="mb-2">
            <div>Visitation summary</div>
            <div className="ml-3 text-sm leading-6 text-gray-900">
              {Object.entries(state.summary).map(([key, value]) => (
                <div key={key} className="text-sm leading-6 text-gray-900">
                  <span className="font-bold">{SUMMARY_LABELS[key]}: </span>
                  {formatInt(value)}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {state.demographics && (
        <div className="mb-2">
          <div>Visitor demographics</div>
          <RadioCards
            options={[DemographicGroups.Home, DemographicGroups.Work]}
            labels={{
              [DemographicGroups.Home]: "Residents",
              [DemographicGroups.Work]: "Workers",
            }}
            initialValue={DemographicGroups.Home}
            onChange={(val: string) => {
              dispatch({
                type: ActionType.SetSelectedDemographicsGroup,
                payload: val as DemographicGroups,
              });
            }}
          />
          {Object.entries(
            state.demographics[state.selectedDemographicsGroup] || {}
          )
            .filter(([key]) => key in DEMOGRAPHICS_LABELS)
            .map(([key, val]) => (
              <div key={key} className="h-48 my-4">
                <div className="ml-3 text-sm leading-6 text-gray-900 font-bold">
                  {DEMOGRAPHICS_LABELS[key]}
                </div>
                <ResponsiveContainer>
                  <BarChart
                    layout="vertical"
                    data={val}
                    margin={{
                      top: 8,
                      right: 0,
                      left: 8,
                      bottom: 0,
                    }}
                  >
                    <XAxis
                      type="number"
                      tick={{ fontSize: 12 }}
                      domain={[0, 100]}
                      tickFormatter={(v) => `${v}%`}
                    />
                    <YAxis
                      type="category"
                      dataKey="name"
                      tick={{ fontSize: 12 }}
                      tickFormatter={(v) => DEMOGRAPHICS_TICK_LABELS[key][v]}
                      interval={0}
                    />
                    <Tooltip
                      formatter={(value) => `${value}%`}
                      labelFormatter={(v) => DEMOGRAPHICS_TICK_LABELS[key][v]}
                    />
                    <Bar dataKey="val" fill="#877cec" name="percentage">
                      {val.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={CHART_COLORS[0]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ))}
        </div>
      )}
      {state.visitations && (
        <div className="mb-2">
          <div>Visitation trend</div>
          <RadioCards
            options={[TrafficTrendType.DayOfWeek, TrafficTrendType.HourOfDay]}
            labels={{
              [TrafficTrendType.DayOfWeek]: "Day of week",
              [TrafficTrendType.HourOfDay]: "Hour of day",
            }}
            initialValue={TrafficTrendType.DayOfWeek}
            onChange={(val: string) => {
              dispatch({
                type: ActionType.SetSelectedTrafficTrend,
                payload: val as TrafficTrendType,
              });
            }}
          />
          <div className="h-48">
            <ResponsiveContainer>
              <BarChart
                data={state.visitations[state.selectedTrafficTrend]}
                margin={{
                  top: 30,
                  right: 0,
                  left: -16,
                  bottom: 0,
                }}
              >
                <XAxis type="category" dataKey="name" tick={{ fontSize: 12 }} />
                <YAxis
                  type="number"
                  tick={{ fontSize: 12 }}
                  tickFormatter={(v) => `${v}%`}
                />
                <Tooltip />
                <Bar dataKey="val" fill="#988eee" name="percentage" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </>
  );
};

export default FootTrafficTab;
