import React, { useState, useEffect } from "react";

const useDebounced = (value: any, ms: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const debounceValue = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setDebouncedValue(value);
      }, ms);
    };

    debounceValue();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, ms]);

  return debouncedValue;
};

export default useDebounced;
