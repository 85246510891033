import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { Button } from "./Button";

const LogoutButton = () => {
  const { logout } = useAuth0();
  const [, setApp] = useLocalStorage<Nullable<string>>("app", null);

  return (
    <Button
      onClick={() => {
        setApp(null);
        logout({ logoutParams: { returnTo: window.location.origin } });
      }}
    >
      Log Out
    </Button>
  );
};

export default LogoutButton;
