import React, { useState } from "react";
import { FC, Dispatch, SetStateAction } from "react";
import {
  MapViewState,
  WebMercatorViewport,
  FlyToInterpolator,
} from "@deck.gl/core/typed";
import { motion, AnimatePresence, useAnimate } from "framer-motion";
import {
  PaperAirplaneIcon,
  ArrowPathIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import chatgptLogo from "../../../images/chatgpt.png";
import chatgptBlackLogo from "../../../images/chatgptblack.png";
import { IconLayer, AppConfig } from "../../../utils/appconfig";
import { US_POSTAL_CODE_TO_FIPS } from "../../../utils/us";
import { randomColor, toHex } from "../../../utils/colors";
import { Action, ActionTypes } from "./reducer";
import { API_URL } from "./constants";
import mixpanel from "mixpanel-browser";

const MAXIMUM_LAYERS = 8;

const SUGGESTIONS: string[] = [
  "Show me all the pet stores in Los Angeles",
  "Show me gas stations in the U.S.",
  "How many Starbucks are in Texas?",
];

const US_FIPS_TO_POSTAL_CODE = (code: string) =>
  [...US_POSTAL_CODE_TO_FIPS].find(([, value]) => code === value)![0];

export interface QueryResponse {
  layer?: {
    id: string;
    name: string;
  };
  category?: string;
  text: string | number;
  error?: string;
  bbox_maxx: number;
  bbox_maxy: number;
  bbox_minx: number;
  bbox_miny: number;
}

const getQuery = (
  queryText: string,
  userId: string,
  usState: string,
  apppConfig: AppConfig | undefined
) =>
  fetch(API_URL + "/overture/query", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      queryText,
      userId,
      appid: apppConfig?.id || "app23overture",
    }),
  });

type SetState<T> = Dispatch<SetStateAction<T>>;

interface Props {
  temporaryIconLayer: Nullable<IconLayer>;
  setTemporaryIconLayer: SetState<Nullable<IconLayer>>;
  appConfig: AppConfig | undefined;
  setAppConfig: SetState<AppConfig | undefined>;
  layerDispatch: React.Dispatch<Action>;
  demoEmail: Nullable<string>;
  selectedStateCode: string;
  savedIconLayers: IconLayer[];
  setSavedIconLayers: (value: IconLayer[]) => void;
  setInitialViewState: SetState<MapViewState>;
  mapViewState: MapViewState;
}

const OvertureQuery: FC<Props> = ({
  temporaryIconLayer,
  setTemporaryIconLayer,
  appConfig,
  setAppConfig,
  layerDispatch,
  demoEmail,
  selectedStateCode,
  savedIconLayers,
  setSavedIconLayers,
  setInitialViewState,
  mapViewState,
}) => {
  const [query, setQuery] = useState<string>("");
  const [queryFocus, setQueryFocus] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const [result, setResult] = useState<Nullable<QueryResponse>>(null);

  const [error, setError] = useState<Nullable<string>>();

  const [modalScope, modalAnimate] = useAnimate();

  const reset = () => {
    setQuery("");
    setError(null);
    setResult(null);
    layerDispatch({ type: ActionTypes.RemoveTemporaryIconLayer, payload: {} });
    setTemporaryIconLayer(null);
    setLoading(false);
  };

  const animateError = () => {
    setError(
      "Unfortunately we didn’t find any locations for this query. Please try again."
    );
    modalAnimate(
      modalScope.current,
      { x: [-8, 8, -8, 8], borderColor: "#cb0000" },
      {
        type: "spring",
        stiffness: 1000,
        damping: 8,
        velocity: 1000,
        delay: 0.2,
      }
    );
  };

  const sendLayer = async (instantQuery?: string) => {
    setLoading(true);

    try {
      const response = await getQuery(
        instantQuery || query,
        demoEmail || "",
        selectedStateCode,
        appConfig
      );
      const data: QueryResponse = await response.json();

      if (data?.error) {
        throw Error(data.error);
      }

      if (data.layer) {
        const category = data.category ?? "empty";
        const color = randomColor();
        const hexColor = toHex(color);
        const iconUrlParams = new URLSearchParams({
          category,
          color: hexColor,
        });
        const iconUrl = `${API_URL}/get-icon?${iconUrlParams.toString()}`;

        const bounds: [[number, number], [number, number]] = [
          [data["bbox_maxx"], data["bbox_maxy"]],
          [data["bbox_minx"], data["bbox_miny"]],
        ];

        setInitialViewState({
          ...new WebMercatorViewport(mapViewState).fitBounds(bounds, {
            padding: { top: 50, left: 70, right: 70, bottom: 150 },
          }),
          transitionDuration: "auto",
          transitionInterpolator: new FlyToInterpolator(),
        });

        setTemporaryIconLayer({
          id: data.layer.id,
          data: { source: "cdn", dynamic: false, notCompressed: true },
          color: randomColor(),
          url: iconUrl,
          coordinates: (item) => item["coordinates"],
          minZoom: 0,
          pickable: true,
          nationwide: true,
          name: data.layer.name,
        });

        layerDispatch({
          type: ActionTypes.SetVisibleTemporaryIconLayer,
          payload: {
            id: data.layer.id,
          },
        });
      }
      setResult(data);
    } catch (_) {
      animateError();
    }

    setLoading(false);
  };

  const addToLayer = () => {
    if (!appConfig || !temporaryIconLayer || !result || !result.layer) return;

    const localAppConfig = { ...appConfig };

    localAppConfig.layers.iconLayers.push({ ...temporaryIconLayer });

    if (!localAppConfig.filterGroups.length)
      localAppConfig.filterGroups.push({
        id: "pois",
        name: "POIs",
        type: "layers",
        items: [],
      });

    const poisFilterGroupIndex = localAppConfig.filterGroups
      .map((group) => group.id)
      .indexOf("pois");

    const filterItem = {
      name: result.layer.name,
      layers: [result.layer.id],
      layer: "",
      field: "",
    };

    if (poisFilterGroupIndex == -1)
      localAppConfig.filterGroups.push({
        id: "pois",
        name: "POIs",
        items: [filterItem],
        type: "layers",
      });
    else
      localAppConfig.filterGroups[poisFilterGroupIndex].items.push(filterItem);

    setSavedIconLayers([...savedIconLayers, temporaryIconLayer]);
    setAppConfig(localAppConfig);
    mixpanel.track("Add Overture Layer", {
      LayerId: result.layer?.id,
      LayerName: result.layer?.name,
    });
    reset();
  };

  return (
    <div className="absolute w-screen left-0 bottom-1 z-40 flex pointer-events-none overflow-y-hidden">
      <div className="w-[360px] pointer-events-none"></div>
      <div className="flex-1 bg-white-500 h-full flex items-center justify-center pointer-events-none border-2 border-transparent">
        <motion.div
          ref={modalScope}
          className="w-2/4 py-1.5 pointer-events-auto flex-col relative justify-center items-center"
        >
          <AnimatePresence>
            {(queryFocus || query.length > 0 || error) && (
              <motion.div
                key="suggestions"
                initial={{
                  height: 0,
                }}
                animate={{
                  height: "auto",
                }}
                transition={{
                  when: "afterChildren",
                }}
                exit={{
                  height: 0,
                  transition: {
                    delay: 0.55,
                  },
                }}
                className="w-full bg-white relative -bottom-3 rounded-t-xl"
              >
                <motion.div className="pt-3 pb-5">
                  {!loading && !temporaryIconLayer && !error && !result && (
                    <motion.div>
                      <motion.h4
                        key="suggestions"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        exit={{ transition: { delay: 0.1 }, opacity: 0 }}
                        className="text-[0.95rem] font-semibold text-[#545454] pl-3"
                      >
                        Try any of these
                      </motion.h4>
                      {SUGGESTIONS.map((suggestion, index) => (
                        <motion.h6
                          key={`suggestion-${index}`}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.1 + index * 0.1 }}
                          exit={{
                            transition: { delay: 0.2 + index * 0.1 },
                            opacity: 0,
                          }}
                          className="w-full text-sm text-[#828598] mtt-1 hover:bg-[#e8e9fb] duration-300 px-5 py-1.5 cursor-pointer"
                          onClick={() => {
                            setQuery(suggestion);
                            sendLayer(suggestion);
                          }}
                        >
                          {suggestion}
                        </motion.h6>
                      ))}
                    </motion.div>
                  )}
                  {!loading && result?.layer && !error && (
                    <>
                      <motion.h4
                        id="results-parent"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        exit={{ transition: { delay: 0 }, opacity: 0 }}
                        className="text-[0.95rem] font-semibold text-[#545454] pl-3"
                      >
                        Result layer
                      </motion.h4>

                      <div className="flex w-full items-center justify-between">
                        <motion.h6
                          key="result-description"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.3 }}
                          exit={{ transition: { delay: 0 }, opacity: 0 }}
                          className="w-full text-sm text-[#828598] duration-300 pl-5 pr-6 py-1.5"
                        >
                          {result?.text}
                        </motion.h6>
                        <div className="flex items-center justify-center pr-5">
                          <motion.button
                            key="result-add"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.4 }}
                            exit={{
                              transition: { delay: 0 },
                              opacity: 0,
                            }}
                            className="font-semibold text-[#6366f1] px-3 py-2 rounded-lg bg-[#6366f1] bg-opacity-20 mr-4 hover:bg-opacity-40 duration-300 cursor-pointer whitespace-nowrap"
                            onClick={addToLayer}
                          >
                            Add to layer
                          </motion.button>
                          <motion.button
                            key="result-remove"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                            exit={{
                              transition: { delay: 0 },
                              opacity: 0,
                            }}
                            className="font-semibold w-9 h-9 flex items-center justify-center rounded-full bg-[#d62b36] group cursor-pointer"
                            onClick={reset}
                          >
                            <ArrowPathIcon className="w-7 h-7 text-white group-hover:rotate-12 duration-300" />
                          </motion.button>
                        </div>
                      </div>
                    </>
                  )}
                  {!loading && result?.text && !result?.layer && !error && (
                    <>
                      <div className="flex w-full items-start justify-between pt-4">
                        <div className="ml-4">
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.3 }}
                            exit={{ transition: { delay: 0 }, opacity: 0 }}
                            className="flex items-top"
                          >
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0 1.11292C0 0.49827 0.49827 3.77245e-08 1.11292 3.77245e-08H11V10L0.440839 1.99999C0.163147 1.7896 0 1.46131 0 1.11292V1.11292Z"
                                fill="#eff2fd"
                              />
                            </svg>

                            <h6
                              key="result-description"
                              className="bg-[#eff2fd] text-[#343b4a] duration-300 pl-7 pr-5 py-1.5 rounded-r-xl rounded-bl-xl text-left inline-block"
                            >
                              {query}
                            </h6>
                          </motion.div>
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1 }}
                            exit={{ transition: { delay: 0 }, opacity: 0 }}
                            className="w-full flex justify-end items-start mt-4 relative"
                          >
                            <h6
                              key="result-description"
                              className="bg-[#ddf9c6] text-[#343b4a] duration-300 pl-5 pr-3 py-1.5 rounded-bl-xl rounded-tl-xl rounded-br-xl text-left inline-block"
                            >
                              Your answer is{" "}
                              <span className="font-semibold">
                                {typeof result.text == "number"
                                  ? result.text.toLocaleString()
                                  : result.text}
                              </span>
                              .
                            </h6>
                            <svg
                              width="11"
                              height="10"
                              viewBox="0 0 11 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11 1.11292C11 0.49827 10.5017 0 9.88708 0H0V10L10.5592 1.99999C10.8369 1.7896 11 1.46131 11 1.11292V1.11292Z"
                                fill="#ddf9c6"
                              />
                            </svg>
                            <div className="absolute -right-8 w-7 h-7 p-[0.30rem] rounded-full bg-[#ddf9c6] z-10 flex items-center justify-center">
                              <img
                                className="w-full h-full"
                                src={chatgptBlackLogo}
                                alt=""
                              />
                            </div>
                          </motion.div>
                        </div>
                        <div className="flex items-center justify-center pr-5">
                          <motion.button
                            key="result-remove"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.5 }}
                            exit={{
                              transition: { delay: 0 },
                              opacity: 0,
                            }}
                            className="font-semibold w-9 h-9 flex items-center justify-center rounded-full bg-[#d62b36] group cursor-pointer"
                            onClick={reset}
                          >
                            <ArrowPathIcon className="w-7 h-7 text-white group-hover:rotate-12 duration-300" />
                          </motion.button>
                        </div>
                      </div>
                    </>
                  )}
                  {loading && !error && (
                    <motion.div
                      id="query-loading"
                      className="w-full flex items-center justify-center h-40"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <img
                        className="w-16 pb-5 absolute animate-pulse"
                        src="https://i.ibb.co/ZMY9zwD/image.png"
                        alt=""
                      />
                    </motion.div>
                  )}
                  {error && (
                    <>
                      <motion.h4
                        id="results-parent"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                        exit={{ transition: { delay: 0 }, opacity: 0 }}
                        className="text-[0.95rem] font-semibold pl-3 text-[#d62b36]"
                      >
                        Prompt failed
                      </motion.h4>

                      <div className="flex w-full items-center justify-between">
                        <motion.h6
                          key="result-description"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ delay: 0.2 }}
                          exit={{ transition: { delay: 0 }, opacity: 0 }}
                          className="w-full text-sm text-[#828598] duration-300 pl-5 py-1.5"
                        >
                          {error}
                        </motion.h6>
                        <div className="flex items-center justify-end pr-5">
                          <motion.button
                            key="result-remove"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ delay: 0.2 }}
                            exit={{
                              transition: { delay: 0 },
                              opacity: 0,
                            }}
                            className="font-semibold w-9 h-9 flex items-center justify-center rounded-full bg-[#d62b36] group cursor-pointer"
                            onClick={reset}
                          >
                            <ArrowPathIcon className="w-7 h-7 text-white group-hover:rotate-12 duration-300" />
                          </motion.button>
                        </div>
                      </div>
                    </>
                  )}
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="w-full flex items-center relative bg-white rounded-xl">
            <input
              className={`w-full bg-white rounded-xl border-2 duration-300 ${
                (loading || temporaryIconLayer || error || result) &&
                "opacity-0"
              } border-${
                query.length > 0 ? "gray-300" : "transparent"
              } outline-transparent outline-none focus:border-gray-300 focus:ring-0 ring-white text-gray-400 focus:text-gray-600 shadow-sm ${
                savedIconLayers.length >= MAXIMUM_LAYERS
                  ? "placeholder:text-[#d62b36] font-medium"
                  : "placeholder:text-gray-400"
              } pl-3 duration-300`}
              placeholder={
                savedIconLayers.length >= MAXIMUM_LAYERS
                  ? `You can add a maximum of ${MAXIMUM_LAYERS} layers. Remove an existing layer to add a new one.`
                  : "Ask me anything about places in the U.S."
              }
              type="text"
              onChange={(e) => setQuery(e.target.value)}
              onFocus={() => setQueryFocus(true)}
              onBlur={() => setQueryFocus(false)}
              onKeyDown={(e) => e.key === "Enter" && sendLayer()}
              value={query}
              disabled={savedIconLayers.length >= MAXIMUM_LAYERS}
            />
            {query.length > 0 &&
            !error &&
            savedIconLayers.length < MAXIMUM_LAYERS ? (
              <PaperAirplaneIcon
                className={`h-4 w-4 text-gray-400 absolute right-3 hover:text-gray-700 duration-300 cursor-pointer ${
                  (loading || temporaryIconLayer || result) && "opacity-0"
                }`}
                aria-hidden="true"
                onClick={() => sendLayer()}
              />
            ) : savedIconLayers.length < MAXIMUM_LAYERS ? (
              <img
                src={chatgptLogo}
                className={`h-4 w-4 absolute right-3 duration-300 ${
                  (loading || error || result) && "opacity-0"
                }`}
              />
            ) : null}
            {savedIconLayers.length >= MAXIMUM_LAYERS && (
              <XMarkIcon className="text-[#d62b36] h-4 w-4 absolute right-3" />
            )}
          </div>
        </motion.div>
      </div>
      <div className="w-[228px]"></div>
    </div>
  );
};

export default OvertureQuery;
