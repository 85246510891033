import { createContext, useContext } from "react";

export type LoadDatasetFn = (dataset: string, url: string) => Promise<any>;
export type GetDatasetFn = (dataset: string) => any;

export interface DataCacheState {
  loadDataset: (dataset: string, url: string) => Promise<any>;
  getDataset: (dataset: string) => any;
}

export const DataCacheContext = createContext<DataCacheState>(
  {} as DataCacheState
);

export function useDataCache(): DataCacheState {
  return useContext(DataCacheContext);
}
