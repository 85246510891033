import { FeatureCollection } from "geojson";
import mixpanel from "mixpanel-browser";
import React, { FC, useCallback, useEffect } from "react";
import classNames from "classnames";
import { MapPinIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../../../components/LoadingSpinner";
import RadioCards from "../../../components/RadioCards";
import Toggle from "../../../components/Toggle";
import WarningTooltip from "../../../components/WarningTooltip";
import { AppConfig } from "../../../utils/appconfig";
import { MapPin, formatMapPin } from "../../../utils/map";
import { API_URL, MAX_PINS } from "./constants";
import {
  DropPinAction,
  DropPinActionType,
  IsochroneMap,
  DistanceProfile,
  EvaluateTabState,
  Summary,
  EvaluateTabActionType,
  EvaluateTabAction,
  SummaryMap,
} from "./types";

const DISTANCE_PROFILES = Object.values(DistanceProfile);
const MIN_DISTANCE_MINUTES = 1;
const MAX_DISTANCE_MINUTES = 60;

function getApiUrl(
  appid: string,
  lon: number,
  lat: number,
  ringRadiusEnabled: boolean,
  ringRadiusMiles: number,
  distanceProfile: DistanceProfile,
  minutes: number
) {
  if (ringRadiusEnabled) {
    return `${API_URL}/apps/${appid}/evaluate-radius?lat=${lat}&lon=${lon}&radius=${ringRadiusMiles}`;
  }
  return `${API_URL}/apps/${appid}/evaluate-traveltime?lat=${lat}&lon=${lon}&minutes=${minutes}&profile=${distanceProfile}`;
}

interface EvaluateApiResp {
  isochrone: FeatureCollection;
  summary: Summary;
  pois?: Record<string, number>;
}

interface Props {
  appConfig: AppConfig;
  pins: MapPin[];
  state: EvaluateTabState;
  dispatch: React.Dispatch<EvaluateTabAction>;
  droppedPinDispatch: React.Dispatch<DropPinAction>;
}

const EvaluateTab: FC<Props> = ({
  appConfig,
  pins,
  state: {
    distanceProfile,
    distanceMinutes,
    summary,
    loading,
    error,
    ringRadiusEnabled,
    ringRadiusMiles,
    showDrivingBoundary,
  },
  dispatch,
  droppedPinDispatch,
}) => {
  const validate = useCallback(() => {
    if (!pins.length) {
      dispatch({
        type: EvaluateTabActionType.SetError,
        payload: "Please drop a pin on the map first.",
      });
      return false;
    } else if (!distanceProfile) {
      dispatch({
        type: EvaluateTabActionType.SetError,
        payload: "Please select between walking, cycling, or driving.",
      });
      return false;
    } else if (
      !distanceMinutes ||
      distanceMinutes < MIN_DISTANCE_MINUTES ||
      distanceMinutes > MAX_DISTANCE_MINUTES
    ) {
      dispatch({
        type: EvaluateTabActionType.SetError,
        payload: "Please select a distance in minutes between 1 and 60.",
      });
      return false;
    }
    dispatch({ type: EvaluateTabActionType.SetError, payload: "" });
    return true;
  }, [distanceMinutes, distanceProfile, pins]);

  const handleEvaluate = useCallback(() => {
    const evaluateAsync = async () => {
      dispatch({ type: EvaluateTabActionType.SetSummary, payload: [] });
      if (!validate() || !pins.length) {
        return;
      }
      dispatch({ type: EvaluateTabActionType.SetLoading, payload: true });
      try {
        const fetchSummary = async (lng: number, lat: number) => {
          const url = getApiUrl(
            appConfig.id,
            lng,
            lat,
            ringRadiusEnabled,
            ringRadiusMiles,
            distanceProfile,
            distanceMinutes
          );
          const resp = await fetch(url);
          const respData = await resp.json();
          const data = respData as EvaluateApiResp;
          if (!data || !data.isochrone || !data.summary) {
            throw new Error(
              "No data found in the selected area. Please try again."
            );
          }
          mixpanel.track("Evaluate Location", {
            Lat: lat,
            Lon: lng,
          });
          return data;
        };
        const dataItems = await Promise.all(
          [...pins.slice(0, MAX_PINS)].map((pin) =>
            fetchSummary(pin.coordinates[0], pin.coordinates[1])
          )
        );
        const summaries = dataItems.map((data) => {
          const summary = {} as Summary;
          appConfig.summary!.fields.forEach((field) => {
            summary[field] = data.summary[field];
          });
          appConfig.summary?.pois?.forEach((poi) => {
            summary[poi.name] = data.pois![poi.id];
          });
          if (appConfig.summary?.score) {
            summary["score"] = data.summary["score"];
          }
          return summary;
        });

        const tempSummaryMap: SummaryMap = {};
        for (let i = 0; i < dataItems.length; i++) {
          tempSummaryMap[pins[i].id] = summaries[i];
        }

        dispatch({
          type: EvaluateTabActionType.SetSummaryMap,
          payload: tempSummaryMap,
        });

        droppedPinDispatch({
          type: DropPinActionType.ClearIsochrones,
          payload: {},
        });

        //Add metadata for associated id to isochrone
        for (let i = 0; i < dataItems.length; i++) {
          for (let j = 0; j < dataItems[i].isochrone.features.length; j++) {
            dataItems[i].isochrone.features[j].properties!["pinId"] =
              pins[i].id;
          }
        }

        if (ringRadiusEnabled || showDrivingBoundary) {
          const tempIsoMap: IsochroneMap = {};
          for (let i = 0; i < dataItems.length; i++) {
            tempIsoMap[pins[i].id] = dataItems[i].isochrone;
          }

          droppedPinDispatch({
            type: DropPinActionType.SetIsochrones,
            payload: {
              updatedIsochroneMap: tempIsoMap,
            },
          });
        }
        dispatch({
          type: EvaluateTabActionType.SetSummary,
          payload: summaries,
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: EvaluateTabActionType.SetError,
          payload: "Error fetching data. Please try again",
        });
        mixpanel.track("Evaluate Location Error");
      } finally {
        dispatch({ type: EvaluateTabActionType.SetLoading, payload: false });
      }
    };
    evaluateAsync();
  }, [
    distanceMinutes,
    distanceProfile,
    showDrivingBoundary,
    ringRadiusEnabled,
    ringRadiusMiles,
    validate,
  ]);

  useEffect(() => {
    const checked = pins.length <= 1;
    dispatch({
      type: EvaluateTabActionType.SetShowDrivingBoundary,
      payload: checked,
    });
  }, [pins.length]);

  return (
    <>
      <h2 className="font-bold text-xl mt-4">Select Location</h2>
      <div>
        {pins.length === 0 && (
          <div className="text-sm my-2">
            Select a location to evaluate by dropping a pin on the map.
          </div>
        )}
        {pins.length > 0 && (
          <div className="text-sm leading-6 text-gray-900 my-2">
            <div className="font-bold">
              Coordinates (
              <span
                className={classNames(
                  pins.length >= MAX_PINS && "text-red-600"
                )}
              >
                {pins.length}
              </span>
              /{MAX_PINS}):
            </div>

            {pins.map((pin, idx) => (
              <div
                key={idx}
                className={classNames(
                  "relative group hover:bg-indigo-50 rounded-sm",
                  idx >= MAX_PINS ? "opacity-50" : "opacity-100"
                )}
              >
                {pin.name && (
                  <div className="hidden opacity-0 group-hover:opacity-100 group-hover:block duration-300 absolute bottom-6 left-16 bg-white border border-gray-200 rounded-sm drop-shadow-md z-30 p-1 text-xs">
                    {pin.name}
                  </div>
                )}
                <div className="truncate">
                  <MapPinIcon
                    className="inline-block w-5 h-5 mr-1 mb-1"
                    style={{ color: `rgb(${pin.color.join(",")})` }}
                  />
                  {formatMapPin(pin)}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="py-2 text-sm leading-6 text-gray-900">
          <Toggle
            enabled={ringRadiusEnabled}
            label="radius ring"
            setEnabled={(enabled: boolean) => {
              dispatch({
                type: EvaluateTabActionType.SetRingRadiusEnabled,
                payload: enabled,
              });
            }}
          />
        </div>
        {ringRadiusEnabled && (
          <div className="py-2 text-sm leading-6 text-gray-900">
            <span className="font-bold mr-2">Radius miles: </span>
            <input
              type="number"
              min="1"
              max="10"
              value={ringRadiusMiles}
              onChange={(e) => {
                dispatch({
                  type: EvaluateTabActionType.SetRingRadiusMiles,
                  payload: Number.parseInt(e.target.value),
                });
              }}
            />
          </div>
        )}
        {!ringRadiusEnabled && (
          <>
            <div className="text-sm leading-6 text-gray-900">
              <span className="font-bold">Travel time: </span>
              <input
                value={distanceMinutes}
                onChange={(e) => {
                  dispatch({
                    type: EvaluateTabActionType.SetDistanceMinutes,
                    payload: Number.parseInt(e.target.value),
                  });
                }}
                type="number"
                className="w-20 mx-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              ></input>
              <span>minutes</span>
              <RadioCards
                options={DISTANCE_PROFILES}
                initialValue={distanceProfile}
                onChange={(val: string) =>
                  dispatch({
                    type: EvaluateTabActionType.SetDistanceProfile,
                    payload: val as DistanceProfile,
                  })
                }
              />
            </div>
            <div className="mt-2 relative flex items-start">
              <div className="flex h-6 items-center">
                <input
                  id="showBoundaries"
                  name="showBoundaries"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={showDrivingBoundary}
                  onChange={(e) =>
                    dispatch({
                      type: EvaluateTabActionType.SetShowDrivingBoundary,
                      payload: e.target.checked,
                    })
                  }
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="showBoundaries" className="text-gray-900">
                  show driving boundary
                </label>
              </div>
            </div>
          </>
        )}
        <button
          type="button"
          className="mt-4 rounded-md bg-indigo-50 px-4 py-3 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          onClick={handleEvaluate}
          disabled={loading}
        >
          {pins.length > 1 ? "Compare Locations" : "Calculate Location Summary"}
          {loading && (
            <span className="pl-2">
              <LoadingSpinner small />
            </span>
          )}
        </button>
        {error && (
          <div className="mt-1 text-red-500 text-sm">Error: {error}</div>
        )}
        {pins.length > MAX_PINS && (
          <WarningTooltip
            title="Too Many Pins"
            description={`Only the first ${MAX_PINS} pins will be compared.`}
          />
        )}
      </div>
      {summary.length === 1 && (
        <>
          <h2 className="font-bold text-xl my-4 mt-6">Location summary</h2>
          <div className="mb-2">
            {Object.entries(summary[0]).map(([key, value]) => (
              <div key={key} className="text-sm leading-6 text-gray-900">
                <span className="font-bold">
                  {key in appConfig.fields ? appConfig.fields[key].name : key}:{" "}
                </span>
                {key in appConfig.fields
                  ? appConfig.fields[key].format(value)
                  : value}
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default EvaluateTab;
