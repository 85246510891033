import React from "react";

const FAQButton = () => {
  return (
    <button
      type="button"
      data-te-ripple-init
      data-te-ripple-color="light"
      className="rounded-md px-3 py-2 text-sm font-semibold text-white bg-indigo-400 hover:bg-indigo-500"
      onClick={() => {
        window.open(
          "https://docs.google.com/document/d/1MXqAj-H9XcK79lRw-MXg9u2Ea_2x8cHeSxfert4Ar50/edit#heading=h.4z8il5oj9o8r",
          "_blank"
        );
      }}
    >
      <span>FAQ</span>
    </button>
  );
};

export default FAQButton;
