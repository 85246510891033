import booleanIntersects from "@turf/boolean-intersects";
import { Feature, FeatureCollection } from "geojson";
import React, { FC, useCallback } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner";
import RadioCards from "../../../components/RadioCards";
import { MapPin } from "../../../utils/map";
import { API_URL, MAPBOX_TOKEN } from "./constants";
import { UsState } from "../../../utils/us";
import { AggregateType, DATA_FIELD_PROPS, DataField } from "./data";
import mixpanel from "mixpanel-browser";
import Toggle from "../../../components/Toggle";

export enum DistanceProfile {
  Walking = "walking",
  Cycling = "cycling",
  Driving = "driving",
}
const DISTANCE_PROFILES = Object.values(DistanceProfile);
const DEFAULT_DISTANCE_PROFILE = DistanceProfile.Driving;
const DEFAULT_DISTANCE_MINUTES = 15;
const MIN_DISTANCE_MINUTES = 1;
const MAX_DISTANCE_MINUTES = 60;

interface Summary {
  [key: string]: number;
}

export interface State {
  distanceProfile: DistanceProfile;
  distanceMinutes: number;
  summary: Summary;
  loading: boolean;
  error: string;
  ringRadiusEnabled: boolean;
}

export function initialState(
  distanceProfile: DistanceProfile = DEFAULT_DISTANCE_PROFILE,
  distanceMinutes: number = DEFAULT_DISTANCE_MINUTES
): State {
  return {
    distanceProfile,
    distanceMinutes,
    summary: {},
    loading: false,
    error: "",
    ringRadiusEnabled: false,
  };
}

export enum ActionType {
  SetDistanceProfile = "SetDistanceProfile",
  SetDistanceMinutes = "SetDistanceMinutes",
  SetSummary = "SetSummary",
  SetLoading = "SetLoading",
  SetError = "SetError",
  SetRingRadiusEnabled = "SetRingRadiusEnabled",
}

export interface Action {
  type: ActionType;
  payload: DistanceProfile | number | Summary | boolean | string;
}

export function reducer(state: State, action: Action) {
  switch (action.type) {
    case ActionType.SetDistanceProfile:
      return {
        ...state,
        distanceProfile: action.payload as DistanceProfile,
      };
    case ActionType.SetDistanceMinutes:
      return {
        ...state,
        distanceMinutes: action.payload as number,
      };
    case ActionType.SetSummary:
      return {
        ...state,
        summary: action.payload as Summary,
      };
    case ActionType.SetLoading:
      return {
        ...state,
        loading: action.payload as boolean,
      };
    case ActionType.SetError:
      return {
        ...state,
        error: action.payload as string,
      };
    case ActionType.SetRingRadiusEnabled:
      return {
        ...state,
        ringRadiusEnabled: action.payload as boolean,
      };
    default:
      return state;
  }
}

function getIsochroneUrl(
  lng: number,
  lat: number,
  distanceProfile: DistanceProfile,
  minutes: number
) {
  const coordinates = `${lng},${lat}`;
  const profile = "mapbox/" + distanceProfile;
  return `https://api.mapbox.com/isochrone/v1/${profile}/${coordinates}?contours_minutes=${minutes}&polygons=true&access_token=${MAPBOX_TOKEN}`;
}

function getApiUrl(
  title: string,
  lon: number,
  lat: number,
  distanceProfile: DistanceProfile,
  minutes: number,
  useRadius: boolean
) {
  if (title === "App06RakkanRamen") {
    if (useRadius)
      return `${API_URL}/evaluate-radius?lat=${lat}&lon=${lon}&radius=5`;
    else
      return `${API_URL}/evaluate-isochrone?lat=${lat}&lon=${lon}&minutes=${minutes}&profile=${distanceProfile}`;
  }
  return `${API_URL}/evaluate?lat=${lat}&lon=${lon}&minutes=${minutes}&profile=${distanceProfile}`;
}

interface EvaluateApiResp {
  isochrone: FeatureCollection;
  summary: Summary;
}

interface Props {
  pin: Nullable<MapPin>;
  geom: Nullable<FeatureCollection>;
  usState: UsState;
  onIsochrone: (iso: FeatureCollection) => void;
  fields: DataField[];
  state: State;
  dispatch: React.Dispatch<Action>;
  useApi?: boolean;
  defaultParams?: { minutes?: number; profile?: string };
  title: string;
}

const EvaluateTab: FC<Props> = ({
  pin,
  geom,
  usState,
  onIsochrone,
  fields,
  state: {
    distanceProfile,
    distanceMinutes,
    summary,
    loading,
    error,
    ringRadiusEnabled,
  },
  dispatch,
  useApi,
  defaultParams,
  title,
}) => {
  const validate = useCallback(() => {
    if (!geom) {
      dispatch({
        type: ActionType.SetError,
        payload: "Missing geometry data. Please select a state first.",
      });
      return false;
    } else if (!pin) {
      dispatch({
        type: ActionType.SetError,
        payload: "Please drop a pin on the map first.",
      });
      return false;
    } else if (!distanceProfile) {
      dispatch({
        type: ActionType.SetError,
        payload: "Please select between walking, cycling, or driving.",
      });
      return false;
    } else if (
      !distanceMinutes ||
      distanceMinutes < MIN_DISTANCE_MINUTES ||
      distanceMinutes > MAX_DISTANCE_MINUTES
    ) {
      dispatch({
        type: ActionType.SetError,
        payload: "Please select a distance in minutes between 1 and 60.",
      });
      return false;
    }
    dispatch({ type: ActionType.SetError, payload: "" });
    return true;
  }, [distanceMinutes, distanceProfile, pin, geom]);

  const handleEvaluate = useCallback(() => {
    const evaluateAsync = async () => {
      dispatch({ type: ActionType.SetSummary, payload: {} });
      if (!validate() || !pin || !geom) {
        return;
      }
      dispatch({ type: ActionType.SetLoading, payload: true });
      const [lng, lat] = pin.coordinates;
      if (!useApi) {
        const url = getIsochroneUrl(lng, lat, distanceProfile, distanceMinutes);
        try {
          const resp = await fetch(url);
          const respData = await resp.json();
          const data = respData as FeatureCollection;
          if (!data || data.features.length == 0) {
            dispatch({
              type: ActionType.SetError,
              payload: "No data found in the selected area. Please try again.",
            });
            return;
          }
          onIsochrone(data);
          computeSummary(geom, data.features[0]);
        } catch (err) {
          console.error(err);
          dispatch({
            type: ActionType.SetError,
            payload: "Error fetching data. Please try again",
          });
        } finally {
          dispatch({ type: ActionType.SetLoading, payload: false });
        }
      } else {
        try {
          const url = getApiUrl(
            title,
            lng,
            lat,
            distanceProfile,
            distanceMinutes,
            ringRadiusEnabled
          );
          const resp = await fetch(url);
          const respData = await resp.json();
          const data = respData as EvaluateApiResp;
          if (!data || !data.isochrone || !data.summary) {
            dispatch({
              type: ActionType.SetError,
              payload: "No data found in the selected area. Please try again.",
            });
            return;
          }
          onIsochrone(data.isochrone);
          const summary = {} as Summary;
          fields.forEach((field) => {
            summary[field] = data.summary[field];
          });
          dispatch({ type: ActionType.SetSummary, payload: summary });
          mixpanel.track("Evaluate Location", {
            Lat: lat,
            Lon: lng,
          });
        } catch (err) {
          console.error(err);
          dispatch({
            type: ActionType.SetError,
            payload: "Error fetching data. Please try again",
          });
          mixpanel.track("Evaluate Location Error", {
            Lat: lat,
            Lon: lng,
          });
        } finally {
          dispatch({ type: ActionType.SetLoading, payload: false });
        }
      }
    };
    evaluateAsync();
  }, [distanceMinutes, distanceProfile, validate, ringRadiusEnabled]);

  function computeSummary(geom: FeatureCollection, isoFeature: Feature) {
    const intersectFeatures = geom.features.filter((feature) =>
      booleanIntersects(feature, isoFeature)
    );
    if (intersectFeatures.length == 0) {
      dispatch({
        type: ActionType.SetError,
        payload: `No data found in the selected area. Please select a location in the state of ${usState}.`,
      });
      return;
    }
    const summary: Summary = {};
    intersectFeatures.forEach((feature) => {
      if (!feature.properties) {
        return;
      }
      const featureProps = feature.properties;
      fields.forEach((key) => {
        const value = featureProps[key] || 0;
        const aggregateValue =
          DATA_FIELD_PROPS[key].aggregate == AggregateType.Sum
            ? value
            : value / intersectFeatures.length;
        summary[key] = (summary[key] || 0) + aggregateValue;
      });
      dispatch({ type: ActionType.SetSummary, payload: summary });
    });
  }

  return (
    <>
      <h2 className="font-bold text-xl my-4">Select Location</h2>
      <div>
        <div className="text-sm">
          Select a location to evaluate by dropping a pin on the map.
        </div>
        <div className="text-sm leading-6 text-gray-900 my-2">
          <span className="font-bold">Coordinates: </span>
          {pin
            ? (pin.coordinates as number[]).map((d) => d.toFixed(6)).join(", ")
            : "None"}
        </div>
        {title === "App06RakkanRamen" && (
          <div className="py-2 text-sm leading-6 text-gray-900">
            <Toggle
              enabled={ringRadiusEnabled}
              label="5-mi radius ring"
              setEnabled={(enabled: boolean) => {
                dispatch({
                  type: ActionType.SetRingRadiusEnabled,
                  payload: enabled,
                });
              }}
            />
          </div>
        )}
        {!ringRadiusEnabled && (
          <div className="pt-2 text-sm leading-6 text-gray-900">
            <span className="font-bold">Travel time: </span>
            <input
              value={distanceMinutes}
              onChange={(e) => {
                dispatch({
                  type: ActionType.SetDistanceMinutes,
                  payload: Number.parseInt(e.target.value),
                });
              }}
              type="number"
              className="w-20 mx-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            ></input>
            <span>minutes</span>
            <RadioCards
              options={DISTANCE_PROFILES}
              initialValue={distanceProfile}
              onChange={(val: string) =>
                dispatch({
                  type: ActionType.SetDistanceProfile,
                  payload: val as DistanceProfile,
                })
              }
            />
          </div>
        )}

        <button
          type="button"
          className="mt-4 rounded-md bg-indigo-50 px-4 py-3 font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
          onClick={handleEvaluate}
          disabled={loading}
        >
          Calculate Location Summary
          {loading && (
            <span className="pl-2">
              <LoadingSpinner small />
            </span>
          )}
        </button>
        {error && (
          <div className="mt-1 text-red-500 text-sm">Error: {error}</div>
        )}
      </div>
      {Object.keys(summary).length > 0 && (
        <h2 className="font-bold text-xl my-4 mt-6">Location summary</h2>
      )}
      <div className="mb-2">
        {Object.entries(summary).map(([key, value]) => (
          <div key={key} className="text-sm leading-6 text-gray-900">
            <span className="font-bold">
              {DATA_FIELD_PROPS[key as DataField].displayName}:{" "}
            </span>
            {DATA_FIELD_PROPS[key as DataField].format(value)}
          </div>
        ))}
      </div>
    </>
  );
};

export default EvaluateTab;
