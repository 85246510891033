import { API_URL } from "../pages/templates/template01/constants";

const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY || "";

export interface PoiItem {
  name: string;
  address: string[];
  lat: number;
  lon: number;
}

export async function nearbySearch(
  keyword: string,
  lat: number,
  lon: number,
  radius: number,
  category: string
) {
  const params = new URLSearchParams({
    key: REACT_APP_API_KEY,
    keyword,
    category,
    lat: lat.toString(),
    lon: lon.toString(),
    radius: Math.trunc(radius).toString(),
  });
  const url = `${API_URL}/nearby-pois?${params.toString()}`;
  const response = await fetch(url);
  const { results } = (await response.json()) as { results: PoiItem[] };
  return results;
}
