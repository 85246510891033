export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const formatCurrency = (value: number) => {
  return currencyFormatter.format(value);
};

export const formatPercent = (value: number) => {
  return `${value.toFixed(1)}%`;
};

export const formatFloat = (value: number) => {
  return (Math.round(value * 10) / 10).toLocaleString();
};

export const formatInt = (value: number) => {
  return Math.round(value).toLocaleString();
};
