import React, { FC, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import classNames from "classnames";

interface RadioCardsProps {
  initialValue?: string;
  options: string[];
  onChange?: (value: string) => void;
  disabled?: boolean;
  labels?: Record<string, string>;
}

const RadioCards: FC<RadioCardsProps> = ({
  options,
  onChange,
  disabled,
  initialValue,
  labels,
}) => {
  const [selected, setSelected] = useState(initialValue ?? options[0]);
  const handleChange = (value: string) => {
    setSelected(value);
    onChange && onChange(value);
  };

  return (
    <div>
      <RadioGroup value={selected} onChange={handleChange} className="mt-2">
        <div className="grid grid-cols-3 gap-3">
          {options.map((option) => (
            <RadioGroup.Option
              key={option}
              value={option}
              className={({ active, checked }) =>
                classNames(
                  disabled
                    ? "cursor-not-allowed opacity-25"
                    : "cursor-pointer focus:outline-none",
                  active ? "ring-2 ring-indigo-600 ring-offset-2" : "",
                  checked
                    ? "bg-indigo-600 text-white hover:bg-indigo-500"
                    : "ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50",
                  "flex items-center justify-center rounded-md py-2 px-2 text-sm font-semibold sm:flex-1 whitespace-nowrap"
                )
              }
              disabled={disabled}
            >
              <RadioGroup.Label as="span">
                {labels ? labels[option] : option}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

export default RadioCards;
