import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { DataCacheProvider } from "./contexts/DataCacheProvider";

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || "invalid";
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || "invalid";

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);
root.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <DataCacheProvider>
      <App />
    </DataCacheProvider>
  </Auth0Provider>
);
