import React, { FC } from "react";
import {
  PolygonAction,
  PolygonActionType,
  PolygonGroup,
  PolygonTabState,
} from "./types";
import fontColorContrast from "font-color-contrast";
import { CubeIcon as CubeIconSolid } from "@heroicons/react/24/solid";
import {
  CubeIcon as CubeIconOutline,
  XMarkIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
  LightBulbIcon,
  FolderIcon,
} from "@heroicons/react/24/outline";
import { API_URL, TERRITORRY_FEATURES_COLORS_BY_TAGS } from "./constants";
import type { TerritoryFeaturesColorsByTagsKeys } from "./constants";
import { AppConfig } from "../../../utils/appconfig";

interface PolygonTerritoriesMenuProps {
  polygonTabState: PolygonTabState;
  polygonTabDispatch: React.Dispatch<PolygonAction>;
  appConfig: AppConfig;
  selectedStateCode: string;
}

const PolygonTerritoriesMenu: FC<PolygonTerritoriesMenuProps> = ({
  polygonTabState,
  polygonTabDispatch,
  appConfig,
  selectedStateCode,
}) => {
  const updateGroupName = async (
    e: React.FocusEvent<HTMLInputElement, Element>,
    group: PolygonGroup
  ) => {
    polygonTabDispatch({
      type: PolygonActionType.RenameGroup,
      payload: {
        groupIndex: polygonTabState.groups.findIndex((g) => g.id === group.id),
        name: e.target.value,
      },
    });

    try {
      polygonTabDispatch({
        type: PolygonActionType.Saving,
        payload: {},
      });

      await fetch(
        API_URL + "/apps/0/territory-profiles/" + group.id + "/name",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            profile_name: e.target.value,
          }),
        }
      );

      polygonTabDispatch({
        type: PolygonActionType.Saved,
        payload: {},
      });
    } catch (error) {
      //
    }
  };

  return (
    <div className="relative">
      {polygonTabState.groups
        .filter((group) => group.state === selectedStateCode || !group.state)
        .map((group: PolygonGroup, groupIndex: number, arr: PolygonGroup[]) => (
          <div key={"group" + group.id} className="mb-5">
            {group.name !== "Default" && (
              <div className="flex items-center">
                <FolderIcon className={`text-gray-500 w-4 h-4 mr-2`} />

                <input
                  readOnly={polygonTabState.readOnly}
                  onBlur={(e) => {
                    if (polygonTabState.readOnly) return;

                    updateGroupName(e, group);
                  }}
                  className={`outline-none border-none text-gray-700 font-medium text-sm ${
                    polygonTabState.readOnly && "cursor-default"
                  }`}
                  defaultValue={group.name}
                />
              </div>
            )}
            <div className="relative">
              {!group.features.length && group.name !== "Default" && (
                <div className="flex items-center pl-3 ml-5 mt-2">
                  <LightBulbIcon className={`text-gray-600 w-4 h-4`} />
                  <div className="text-gray-600 text-sm ml-1.5">
                    Move a territory here to begin
                  </div>
                </div>
              )}
              {group.features.map((feature, featureIndex) => {
                return (
                  <div
                    key={
                      group.name +
                      " " +
                      feature.properties?.name +
                      " " +
                      featureIndex
                    }
                    className={`mt-1 group cursor-pointer ${
                      group.name !== "Default" && "ml-5"
                    }`}
                  >
                    <div
                      className={`${
                        polygonTabState.selectedPolygon?.properties?.color ===
                          feature.properties?.color && "bg-blue-100"
                      } py-1 pl-3 pr-5 rounded-lg flex items-center justify-between relative`}
                      onClick={(event) => {
                        event.preventDefault();

                        const target = event.target as HTMLElement;

                        if (target.id !== "remove") {
                          polygonTabDispatch({
                            type: PolygonActionType.SetSelectedPolygon,
                            payload: feature,
                          });
                        }
                      }}
                    >
                      <div className="flex items-center group">
                        <div className="flex items-center relative z-10">
                          <CubeIconSolid
                            color={
                              TERRITORRY_FEATURES_COLORS_BY_TAGS[
                                (feature.properties
                                  ?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                                  "Existing Territory"
                              ]
                            }
                            className="w-4 h-4 mr-1.5 hover:bg-white peer"
                            onClick={() => {
                              if (polygonTabState.readOnly) return;

                              polygonTabDispatch({
                                type: PolygonActionType.ToggleTag,
                                payload: {
                                  featureIndex,
                                  groupIndex,
                                  selectedState: selectedStateCode,
                                },
                              });
                            }}
                          />
                          <div
                            className="absolute -top-1 -left-3 h-7 w-[34px] rounded-l-lg opacity-0 peer-hover:opacity-100 duration-500 z-0 flex items-center pointer-events-none"
                            style={{
                              backgroundColor:
                                TERRITORRY_FEATURES_COLORS_BY_TAGS[
                                  (feature.properties
                                    ?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                                    "Existing Territory"
                                ],
                            }}
                          >
                            <CubeIconOutline
                              color={fontColorContrast(
                                TERRITORRY_FEATURES_COLORS_BY_TAGS[
                                  (feature.properties
                                    ?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                                    "Existing Territory"
                                ],
                                0.7
                              )}
                              className="w-4 h-4 mr-1.5 ml-3 duration-500"
                            />
                          </div>
                          <div
                            className="absolute text-sm left-[22px] opacity-0 peer-hover:opacity-100 h-7 -top-1 flex items-center pr-4 rounded-r-full duration-500"
                            style={{
                              backgroundColor:
                                TERRITORRY_FEATURES_COLORS_BY_TAGS[
                                  (feature.properties
                                    ?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                                    "Existing Territory"
                                ],
                              color: fontColorContrast(
                                TERRITORRY_FEATURES_COLORS_BY_TAGS[
                                  (feature.properties
                                    ?.tag as TerritoryFeaturesColorsByTagsKeys) ||
                                    "Existing Territory"
                                ],
                                0.7
                              ),
                            }}
                          >
                            {
                              (
                                feature.properties?.tag || "Existing Territory"
                              ).split(" ")[0]
                            }
                          </div>
                          <div className="text-gray-600 text-sm peer-hover:opacity-0 duration-500">
                            {feature.properties?.name}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end">
                        {!polygonTabState.readOnly && (
                          <XMarkIcon
                            id="remove"
                            className={`text-gray-700 w-4 h-4 mr-4 ${
                              polygonTabState.selectedPolygon?.properties
                                ?.color === feature.properties?.color
                                ? "opacity-60"
                                : "opacity-0"
                            } hover:opacity-100 group-hover:opacity-60 duration-300`}
                            onClick={() => {
                              polygonTabDispatch({
                                type: PolygonActionType.Remove,
                                payload: {
                                  feature,
                                  group,
                                },
                              });
                            }}
                          />
                        )}
                        <div className="flex flex-col items-center justify-center relative">
                          {groupIndex > 0 && !polygonTabState.readOnly && (
                            <ChevronUpIcon
                              id="chevron-down"
                              onClick={() =>
                                polygonTabDispatch({
                                  type: PolygonActionType.MovePolygon,
                                  payload: {
                                    direction: "up",
                                    groupIndex,
                                    featureIndex,
                                    selectedState: selectedStateCode,
                                  },
                                })
                              }
                              className={`text-gray-500 absolute ${
                                groupIndex < arr.length - 1 && "-top-3"
                              } w-4 h-4 ${
                                polygonTabState.selectedPolygon?.properties
                                  ?.color === feature.properties?.color
                                  ? "opacity-60"
                                  : "opacity-0"
                              } group-hover:hover:opacity-100 group-hover:opacity-60 duration-500`}
                            />
                          )}
                          {groupIndex < arr.length - 1 &&
                            !polygonTabState.readOnly && (
                              <ChevronDownIcon
                                id="chevron-down"
                                onClick={() =>
                                  polygonTabDispatch({
                                    type: PolygonActionType.MovePolygon,
                                    payload: {
                                      direction: "down",
                                      groupIndex,
                                      featureIndex,
                                      selectedState: selectedStateCode,
                                    },
                                  })
                                }
                                className={`text-gray-500 absolute ${
                                  groupIndex > 0 && "-bottom-3"
                                } w-4 h-4 ${
                                  polygonTabState.selectedPolygon?.properties
                                    ?.color === feature.properties?.color
                                    ? "opacity-60"
                                    : "opacity-0"
                                } group-hover:hover:opacity-100 group-hover:opacity-60 duration-300 cursor-pointer`}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      {!polygonTabState.readOnly && (
        <div
          className="items-center cursor-pointer hover:opacity-100 opacity-60 duration-300 mb-5 inline-flex"
          onClick={async () => {
            polygonTabDispatch({
              type: PolygonActionType.Saving,
              payload: {},
            });

            const response = await fetch(
              API_URL + "/apps/" + appConfig.id + "/territory-profiles",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  profile_name: "Untitled Group",
                  state: selectedStateCode,
                }),
              }
            );

            const group = await response.json();

            polygonTabDispatch({
              type: PolygonActionType.AddNewGroup,
              payload: {
                id: group.id,
                selectedState: selectedStateCode,
              },
            });

            polygonTabDispatch({
              type: PolygonActionType.Saved,
              payload: {},
            });
          }}
        >
          <PlusIcon className={`text-gray-500 w-4 h-4`} />
          <div className="text-gray-600 text-sm ml-1.5">Add new group</div>
        </div>
      )}
    </div>
  );
};

export default PolygonTerritoriesMenu;
