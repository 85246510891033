import React, { FC } from "react";
import { UsState } from "../../../utils/us";
import Template01 from "../../templates/template01";
import { DataField, FieldGroupInit } from "../../templates/template01/data";

export const LAYERS_FIELD_LIST = [
  DataField.PopDensity,
  DataField.Pop5yGrow,
  DataField.HomeVal,
  DataField.HomeVal5yGrow,
  DataField.HomeVal1yGrow,
  DataField.MedianHHIncome,
  DataField.MedianAge,
  DataField.PercentU18,
  DataField.PercentBachelorDegree,
  DataField.TotalCrime,
  DataField.JobDensity,
  DataField.AvgFamSize,
];

const SUMMARY_FIELD_LIST = [
  DataField.MedianHHIncome,
  DataField.MedianAge,
  DataField.HomeVal5yGrow,
  DataField.HomeVal1yGrow,
  DataField.HomeVal,
  DataField.Pop5yGrow,
  DataField.PopCount,
  DataField.AvgFamSize,
  DataField.TotalCrime,
  DataField.JobCount,
  DataField.JobCountU30,
  DataField.JobCountHigh,
  DataField.JobCountMed,
  DataField.JobCountLow,
  DataField.Pop24,
  DataField.Pop25_34,
  DataField.Pop35_44,
  DataField.Pop45_54,
  DataField.Pop55_64,
  DataField.Pop65_74,
  DataField.Pop75,
  DataField.Income24,
  DataField.Income25_34,
  DataField.Income35_44,
  DataField.Income45_54,
  DataField.Income55_64,
  DataField.Income65_74,
  DataField.Income75,
];

const FIELD_GROUPS: FieldGroupInit[] = [
  {
    name: "Road Network",
    fields: [DataField.AvgDailyTraffic, DataField.AvgDailyTraffic5yGrow],
  },
];

const US_STATES = [
  UsState.Florida,
  UsState.Georgia,
  UsState.NorthCarolina,
  UsState.SouthCarolina,
  UsState.Texas,
];

const App03Gopher: FC<{}> = () => {
  return (
    <Template01
      title="app03gopher"
      usStates={US_STATES}
      layerFields={LAYERS_FIELD_LIST}
      summaryFields={SUMMARY_FIELD_LIST}
      fieldGroups={FIELD_GROUPS}
      layerConfig={{ iconLayers: [] }}
      nationwideStorePointsUrl="https://geodatasets-public.s3.us-west-2.amazonaws.com/app03gopher/locations/locations-nationwide-point.json"
    />
  );
};

export default App03Gopher;
