import { FeatureCollection } from "geojson";

export type RGBColor = [number, number, number];
export type RGBAColor = [number, number, number, number];
export type Color = RGBColor | RGBAColor;

export function extractSeries(
  collection: FeatureCollection,
  key: string
): number[] {
  return collection.features.map((f) => (f.properties ? f.properties[key] : 0));
}

export function extractSeriesWithTransform<T>(
  collection: FeatureCollection,
  key: string,
  transform?: (record: T) => number
): number[] {
  const tf = transform ? transform : (e: T) => e as number;
  const v = collection.features.map((f) =>
    f.properties ? tf(f.properties[key]) : 0
  );
  return v;
}

export function mergeFeatureCollections(
  v: FeatureCollection[]
): FeatureCollection {
  return v.reduce(
    (acc, cur) => {
      acc.features.push(...cur.features);
      return acc;
    },
    { type: "FeatureCollection", features: [] } as FeatureCollection
  );
}
