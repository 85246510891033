import { PickingInfo } from "@deck.gl/core/typed";
import classNames from "classnames";
import React, { FC } from "react";
import { PANEL_WIDTH } from "./constants";
import { DATA_FIELD_PROPS, DataField } from "./data";

const TOOLTIP_MARGIN = 8;

const Tooltip: FC<{
  info: PickingInfo;
  field: Nullable<DataField>;
  geoData?: Nullable<Record<string, Record<string, number>>>;
  hasDataProvider?: boolean;
}> = ({ info, field, geoData, hasDataProvider }) => {
  if (!info || !field) {
    return null;
  }
  const { object, x, y } = info;
  if (!object) {
    return null;
  }
  const { properties } = object;
  if (!properties) {
    return null;
  }
  let value = properties[field];
  if (hasDataProvider) {
    if (!geoData) return null;
    const geoid = properties["geoid"];
    if (!geoid || !geoData[geoid]) return null;
    value = geoData[geoid][field];
  }
  const top = y + TOOLTIP_MARGIN;
  const left = x + TOOLTIP_MARGIN + PANEL_WIDTH;
  const { format, displayName } = DATA_FIELD_PROPS[field];
  return (
    <div
      className={classNames(
        "absolute z-20 pointer-events-none bg-gray-50 p-2 rounded-md shadow-md"
      )}
      style={{ top, left }}
    >
      <div className="font-bold">{displayName}</div>
      <div>{format(Number.parseFloat(value))}</div>
    </div>
  );
};

export const BaseTooltip: FC<{
  info: PickingInfo;
  title?: (object: any) => string;
  content?: (object: any) => string | JSX.Element;
}> = ({ info, title, content }) => {
  const { object, x, y } = info;
  if (!object) {
    return null;
  }
  const top = y + TOOLTIP_MARGIN;
  const left = x + TOOLTIP_MARGIN + PANEL_WIDTH;

  if (!title || !content) {
    return null;
  }
  return (
    <div
      className={classNames(
        "absolute z-20 pointer-events-none bg-gray-50 p-2 rounded-md shadow-md"
      )}
      style={{ top, left }}
    >
      <div className="font-bold">{title(object)}</div>
      <div>{content(object)}</div>
    </div>
  );
};

export default Tooltip;
