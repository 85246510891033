import React, { useState, useEffect } from "react";
import type { FC } from "react";
import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";
import LoadingOverlay from "../../../components/LoadingOverlay";
import { API_URL } from "./constants";

const FORM_ID = "bxBbnJok";

const getTypeformResponseUrl = (responseId: string) =>
  `${API_URL}/typeform/response?response_id=${responseId}`;

const Typeform: FC<{ setDemoEmail: (value: null) => void }> = ({
  setDemoEmail,
}) => {
  const [responseId, setResponseId] = useState<Nullable<string>>(null);
  const [error, setError] = useState<Nullable<boolean>>();

  const getEmailFromResponse = async (
    id: string,
    attempt?: number
  ): Promise<void> => {
    if (attempt) await new Promise((r) => setTimeout(r, 1000));

    if (attempt && attempt > 3) setError(true);

    try {
      const response = await fetch(getTypeformResponseUrl(id));
      const data = await response.json();

      if (!response || !data)
        return getEmailFromResponse(id, !attempt ? 1 : attempt + 1);

      setDemoEmail(data.email);
    } catch (_) {
      return getEmailFromResponse(id, !attempt ? 1 : attempt + 1);
    }
  };

  useEffect(() => {
    createWidget(FORM_ID, {
      container: document.querySelector("#typeform") as HTMLElement,
      onSubmit: (event) => {
        setResponseId(event.responseId);
      },
    });
  }, []);

  useEffect(() => {
    if (!responseId) return;

    setTimeout(() => {
      getEmailFromResponse(responseId);
    }, 1000);
  }, [responseId]);

  if (error)
    return (
      <div className="flex w-full h-full">
        <div className="m-auto">
          <p className="text-[#e83f36] text-2xl font-medium">
            Something went wrong, please try again later.
          </p>
        </div>
      </div>
    );

  if (responseId) return <LoadingOverlay />;

  return <div className="w-screen max-w-full h-screen" id="typeform"></div>;
};

export default Typeform;
