import { FlyToInterpolator, MapViewState } from "@deck.gl/core/typed";
import { Combobox } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { FC, useEffect, useState } from "react";
import { debounce } from "../utils/debounce";
import { PoiFeature } from "../utils/types";

const MAPBOX_GEOCODE_API =
  "https://api.mapbox.com/geocoding/v5/mapbox.places/{search_text}.json?country=US&access_token={mapbox_token}";

interface GeoLocatorProps {
  mapboxAccessToken: string;
  mapViewState: MapViewState;
  onViewStateChange: (viewState: MapViewState) => void;
  selectedPoi: Nullable<PoiFeature>;
  onSelectPoi?: (poi: PoiFeature) => void;
}

const GeoLocator: FC<GeoLocatorProps> = ({
  mapboxAccessToken,
  mapViewState,
  onViewStateChange,
  selectedPoi,
  onSelectPoi,
}) => {
  const [query, setQuery] = useState("");
  const [pois, setPois] = useState<PoiFeature[]>();

  const handleSelectPoi = (poi: PoiFeature) => {
    const [longitude, latitude] = poi.center;
    const viewState: MapViewState = {
      latitude,
      longitude,
      zoom: mapViewState.zoom,
      pitch: 0,
      bearing: 0,
      transitionDuration: "auto",
      transitionInterpolator: new FlyToInterpolator(),
    };
    onViewStateChange(viewState);
    onSelectPoi && onSelectPoi(poi);
  };

  useEffect(() => {
    const loadAsync = async () => {
      const url = MAPBOX_GEOCODE_API.replace(
        "{search_text}",
        encodeURIComponent(query)
      ).replace("{mapbox_token}", mapboxAccessToken);
      const resp = await fetch(url);
      const data = await resp.json();
      setPois(data.features as PoiFeature[]);
    };
    debounce(() => loadAsync(), 200)();
  }, [query, mapboxAccessToken]);

  return (
    <Combobox
      className="min-w-[360px]"
      as="div"
      value={selectedPoi}
      onChange={handleSelectPoi}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(poi: PoiFeature) => poi?.place_name}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <MagnifyingGlassIcon
            className="h-4 w-4 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {pois && pois.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {pois.map((poi) => (
              <Combobox.Option
                key={poi.id}
                value={poi}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-indigo-600 text-white" : "text-gray-900"
                  )
                }
              >
                <span className="block truncate">{poi.place_name}</span>
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default GeoLocator;
